import {isSet,getterSetter,_tplRenderPromiss} from '../utils/common'
import {replyInit} from '../controller/replyController'
import {gtmClickButton} from '../services/gtmService'
import {getCookie} from '../services/cookieService'
import {userMapping,intentMapping} from '../reply/replyservices'
require('../services/gaService')
var entityMapping ={};
export function vapReplyInit(){
	$('#commonpopup').on('show.bs.modal', function (e) {
		$(this).off('shown.bs.modal');
		var entity = e.relatedTarget.getAttribute('data-entity')
		entityMapping = {}
		entityMapping.emailList = []
		entityMapping.mobileList = []
		_tplRenderPromiss('replyAdnew').then(function(template) {
			if(isSet(template)){
				var tpl = _.template(template);
				var formData = entityConfig(entity,e,entityMapping);
				reply_Container.classList.remove('thnkup')	
				commonpopup.classList.remove('thankyou')	
				replyInit(entityMapping,tpl);
			}
		})
	});
}
export function entityConfig(enitytype,currentclick,entityMapping){
		switch (enitytype) {
			case "HP_FEATURED_PROJECT":
				//entityMapping.tilesDetails = JSON.parse(atob(globals.propertySnippet));
				var entity_id = currentclick.relatedTarget.getAttribute('data-id')
				
				getEnquiryLogic(entity_id);
				getterSetter.set('adDetails',entityMapping.tilesDetails)
				entityMapping.tilesDetails.projectUnits = JSON.parse(atob(currentclick.relatedTarget.getAttribute('data-units')));
				entityMapping.showViewpart = true;
				getterSetter.set('isOtpverify',false)
		    	getterSetter.set('isdetectmobile',false)
		    	getterSetter.set('reqSource',enitytype);
		    	getterSetter.set('sendEnquiry',true);
		    	entityMapping.headingValue = document.getElementById("pname" + entity_id).innerText
		    	entityMapping.staticStrong = document.getElementById("city"+entity_id).innerText
		    	var srcImage = document.getElementById("logo"+entity_id).getAttribute('data-src');
		    	entityMapping.imageLogo = (srcImage ? srcImage : '')
		    	entityMapping.showText = `I am interested in this project`
		    	entityMapping.showBank = true
		    	gaService.addEvent('RealEstate', "leadForm_click", 'fp_init_homepage');
				break;
			case "HP_PROJECT_BACKDROP":
				var entity_id = currentclick.relatedTarget.getAttribute('data-id')
				getEnquiryLogic(entity_id);
				getterSetter.set('adDetails',entityMapping.tilesDetails)
				entityMapping.tilesDetails.projectUnits = JSON.parse(atob(currentclick.relatedTarget.getAttribute('data-units')));
				entityMapping.showViewpart = true;
				getterSetter.set('isOtpverify',false)
		    	getterSetter.set('isdetectmobile',false)
		    	getterSetter.set('reqSource',enitytype);
		    	getterSetter.set('sendEnquiry',true);
		    	entityMapping.headingValue = document.getElementById("pname" + entity_id).innerText
		    	entityMapping.staticStrong = document.getElementById("city"+entity_id).innerText
		    	var srcImage = document.getElementById("logo"+entity_id).getAttribute('data-src');
		    	entityMapping.imageLogo = (srcImage ? srcImage : '')
		    	entityMapping.showText = `I am interested in this project`
		    	entityMapping.showBank = true
		    	gaService.addEvent('RealEstate', "leadForm_click", 'banner_init_homepage');
				break;
			case "CONTACT_IN_LISTING_SPOTLIGHT":
				var tilesDetails = $(currentclick.relatedTarget).data('listing');
				entityMapping.tilesDetails = JSON.parse(atob(tilesDetails));
				getterSetter.set('adDetails',entityMapping.tilesDetails)
				entityMapping.showViewpart = true;
				getterSetter.set('isOtpverify',false)
		    	getterSetter.set('isdetectmobile',false)
		    	getterSetter.set('reqSource',enitytype)
		    	getterSetter.set('sendEnquiry',false);
		    	getterSetter.set('formType','listing');
		    	entityMapping.imageLogo = ''
		    	entityMapping.headingValue = entityMapping.tilesDetails.adTitle
		    	entityMapping.showText = `Interested in this property, please share your details.`
		    	entityMapping.staticStrong = ` I'm interested! <b>Tell me more...</b>`
		    	entityMapping.userType = userMapping(entityMapping.tilesDetails)
		    	entityMapping.showBank = (intentMapping(entityMapping.tilesDetails) == 'buy') ? true:false
				break;	
		}
	return entityMapping;
}

export function getEnquiryLogic(entity_id){
	let url = document.getElementById("phref"+entity_id).getAttribute('href')
	var id = '';
	var pageType
    if(url && url.match(/quikr.com/)){
        url = url.split('?');
        url = url[0].split('/');
        if(url[3] == 'homes' && (url[4] == 'project'|| url[4] == 'builder')){
            var urlParse = url[5];
            pageType =  url[4];
            id = urlParse.split('+').pop();
        }

    }
    else if(url){
		if (url.indexOf('?') >= 0) {
			url = url.split('?')[0];
		}
        url = url.split('/');
        if(url[1] == 'homes' && (url[2] == 'project'|| url[2] == 'builder')){
            var urlParse = url[3];
            pageType =  url[2];
            id = urlParse.split('+').pop();
        }

    }
    entityMapping.tilesDetails = {}

    if(pageType === 'project'){ 
        entityMapping.tilesDetails.id = id;
        
    }else{
        entityMapping.tilesDetails.id = id;
    }
    if(pageType === 'project' && entityMapping.tilesDetails.id) {
        var projectname =  getProjectName(urlParse.split('+')[0]);
        var localityname = getProjectName(urlParse.split('+')[1]);
        entityMapping.tilesDetails.pageType = 'HomePage'
        entityMapping.tilesDetails.cityId = getCookie('prefer_city_id')
        entityMapping.tilesDetails.projectName = projectname;
        entityMapping.tilesDetails.locality = localityname;
		entityMapping.tilesDetails.city = getCookie("new_prefer_city");
    }
}
function getProjectName(elem) {
    var str = elem;
    str = str.replace("-"," ");
    str = str.toLowerCase().replace(/\b[a-z]/g, function(letter) {
        return letter.toUpperCase();
    });
    return str;
}
export function gtmEvent(){
	gaService.addEvent('RealEstate', "leadForm_click", 'fp_init_homepage');
}
vapReplyInit();
