if (typeof _paqv3 !== 'object') {
    _paqv3 = [];
}
var qanalyticsv3 = (function() {

    var asyncTracker;
    var pageuid = generatePageUid();
    var customVariablesUser = [];
    var customVariablesHit = [];
    var customVariablesSession = [];
    var eventApiJson = {};
    var event_property;

    function generatePageUid() {
        var s = [];
        var hexDigits = "0123456789";
        for (var i = 0; i < 10; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[19] = hexDigits.substr((s[19] & 0x4) | 0x7, 1);
        var pid = s.join("");
        return pid;
    }


    function tryJS() {
        asyncTracker = new Tracker();
        var rate = 10.0;
        var per = 1.0;
        var allowance = rate;
        var last_check = Date.now();
        var time_passed;
        for (iterator = 0; iterator < _paqv3.length; iterator++) {
            if (_paqv3[iterator]) {
                var current = Date.now();
                time_passed = current - last_check;
                last_check = current;
                allowance += time_passed * (rate / per);
                if (allowance > rate) {
                    allowance = rate;
                }
                if (allowance < 1.0) {
                    _paqv3.shift();
                } else {
                    apply(_paqv3[iterator]);
                    allowance = allowance - 1.0;
                }
            }
        }

        _paqv3.splice(0, _paqv3.length);

        _paqv3.push = function(e) {
            try {
                Array.prototype.push.call(_paqv3, e);
                for (it = 0; it < _paqv3.length; it++)
                    apply(_paqv3[it]);

            } catch (err) {
                if (debuganalytics == true)
                    console.log("Error in push is " + err);
            }

        };


    }




    function isString(property) {
        return typeof property === 'string' || property instanceof String;
    }

    function TrackerProxy() {
        return {
            push: apply
        };
    }

    function apply() {
        var i, f, parameterArray;

        for (i = 0; i < arguments.length; i++) {
            parameterArray = arguments[i];
            f = parameterArray.shift();
            if (isString(f)) {
                for (var item in asyncTracker) {
                    var type = typeof asyncTracker[item];
                    if (debuganalytics == true)
                        console.log(item + "(" + type + ")");
                }
                //  asyncTracker.eventtrack("track", "asdf", "dsf", "safd");
                if (asyncTracker[f])
                    asyncTracker[f].apply(asyncTracker, parameterArray);
            }
        }
    }


    function Tracker() {

        var configCookieNamePrefix = '_jk_',
            configCookiesDisabled = false,
            visitorUUID = '',
            configCookiePath = '/',
            configCookieDomain = '',
            configVisitorCookieTimeout = 33955200000,
            configReferralCookieTimeout = 15768000000,
            browserFeatures = {},
            urlc = window.location.href;
        debuganalytics = (getParmFromUrl(urlc) === 'true'),
            defaultRequestMethod = 'GET',
            configRequestMethod = defaultRequestMethod,
            configCampaignNameParameters = ['jk_campaign', 'utm_campaign', 'utm_source', 'utm_medium'],
            configCampaignKeywordParameters = ['jk_kwd', 'utm_term'],
            // Staging & Local Host 
            //eventserverurl = 'http://analytics1.kuikr.com:8080/events';
            eventserverurl = 'https://analytics1.quikr.com/events';
            
            // Production Host 
            // eventserverurl = 'https://analytics1.kuikr.com/events';
            
        configTrackerUrl = 'http://analytics1.kuikr.com:8080/logo.gif',
            encodeWrapper = window.encodeURIComponent,
            defaultRequestContentType = 'application/x-www-form-urlencoded; charset=UTF-8',
            configRequestContentType = defaultRequestContentType;
        var customVariableMaximumLength = 200;

        function trackEvent() {
            var category,
                action,
                name,
                value,
                localcv;

            category = arguments[0];
            action = arguments[1];
            name = arguments[2];
            value = arguments[3];
            if (arguments[4]) {
                localcv = arguments[4];
                customvarproc = processLocalCV(localcv);
                printLog("INJK CV " + customvarproc);
                logEvent(category, action, name, value, customvarproc);

            } else
                logEvent(category, action, name, value);
        }

        function processLocalCV(localcv) {
            var customvarList = [];
            for (var cv in localcv) {
                var customvarst = "";
                customvarst += cv;
                customvarst += "%^*";
                customvarst += localcv[cv].slice(0, customVariableMaximumLength);
                printLog('custom variable added ' + cv + ',' + localcv[cv]);
                customvarList.push(customvarst);
            }

            return customvarList;

        }



        function printLog() {
            if (debuganalytics == true)
                console.log(arguments);
        }

        function set() {
            if (arguments.length < 2) {
                printLog('custom variable does not have sufficient parameters');
                return;
            }
            var dimension;
            var value;
            var name;
            var scope;
            var toRecord = [];
            dimension = !isString(arguments[0]) ? String(arguments[0]) : arguments[0];
            value = !isString(arguments[1]) ? String(arguments[1]) : arguments[1];
            scope = !isString(arguments[2]) ? String(arguments[2]) : arguments[2];
            toRecord.push(dimension);
            toRecord.push(value.slice(0, customVariableMaximumLength));
            if (scope === 'user' || scope === 2) {
                customVariablesUser.push(toRecord.join("%^*"));
            }
            //TODO: Remove scope session when session scope gets implemented at backend. For the time being both are getting appended in hits.
            else if (scope === 'hit' || scope === 'session') {
                customVariablesHit.push(toRecord.join("%^*"));
            }
            //else if(scope === 'session')
            //{
            //    customVariablesSession.push(toRecord.join("%^*"));
            // }
            printLog('custom variable added ' + toRecord);
            return;
        }

        function appendCustomVariablesToRequest(customData) {
            var totalst = encodeWrapper(customVariablesHit.join("!@#"));
            if (customData)
                var totalst = encodeWrapper(customVariablesHit.join("!@#") + "!@#" + customData.join("!@#"));

            return '&cvars' + '=' + encodeWrapper(customVariablesSession.join("!@#")) + '&cvaru' + '=' + encodeWrapper(customVariablesUser.join("!@#")) + '&cvar_p' + '=' + totalst;
        }


        function isDefined(property) {
            var propertyType = typeof property;

            return propertyType !== 'undefined';
        }

        function isObject(property) {
            return typeof property === 'object';
        }

        function isString(property) {
            return typeof property === 'string' || property instanceof String;
        }

        function logEvent(category, action, name, value, customData) {

            if (String(category).length === 0 || String(action).length === 0) {
                return false;
            }

            var request = getRequest(buildEventRequest(category, action, name, value),
                customData,
                'event');
            //sendRequest(request);
        }

        function buildEventRequest(category, action, name, value) {
            return '&e_c=' + category + '&e_a=' + action + (isDefined(name) ? '&e_n=' + name : '') + (isDefined(value) ? '&e_v=' + value : '');
        }

        function generateRandomUuid() {

            var s = [];
            var hexDigits = "0123456789abcdef";
            for (var i = 0; i < 36; i++) {
                s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
            }
            s[14] = "4";
            s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
            s[8] = s[13] = s[18] = s[23] = "-";

            var uuid = s.join("");

            if (debuganalytics == true)
                console.log('uuid is ' + uuid);
            return uuid;

        }


        function getCookie(cookieName) {
            if (configCookiesDisabled) {
                return 0;
            }


            var cookiePattern = new RegExp('(^|;)[ ]*' + cookieName + '=([^;]*)'),
                cookieMatch = cookiePattern.exec(document.cookie);
            return cookieMatch ? cookieMatch[2] : 0;
        }

        function loadVisitorIdCookie() {
            var now = new Date(),
                nowTS = Math.round(now.getTime() / 1000),
                visitorIdCookieName = getCookieName('id'),
                id = getCookie(visitorIdCookieName),
                cookieValue,
                uuid;


            if (id) {
                cookieValue = id.split('.');
                cookieValue.unshift('0');
                cookieValue[3] = 0;
                return cookieValue;
            }

            if (visitorUUID.length) {
                uuid = visitorUUID;
            } else if ('0' == hasCookies()) {
                uuid = '';
            } else {
                uuid = generateRandomUuid();
            }

            cookieValue = [
                '1',
                uuid,
                nowTS,
                '1',
                nowTS,
                '',
                ''

            ];

            if (debuganalytics == true)
                console.log('Cookie value is ' + cookieValue);

            return cookieValue;
        }

        function sendXmlHttpRequest(request) {
            try {

                var xhr = new XMLHttpRequest();
                var httpprotocol = location.protocol;
                if (httpprotocol == 'https:') {
                    configTrackerUrl = 'https://analytics1.quikr.com/logo.gif';
                }
                xhr.open('POST', configTrackerUrl, true);

                xhr.setRequestHeader('Content-Type', configRequestContentType);
                //xhr.send(request);
            } catch (e) {
                if (debuganalytics == true)
                    console.log("Exception" + e);
            }
        }
        //TODO: Set cookie path
        function setCookie(cookieName, value, msToExpire, path, domain, secure) {
            if (configCookiesDisabled) {
                return;
            }
            var expiryDate;

            if (msToExpire) {
                expiryDate = new Date();
                expiryDate.setTime(expiryDate.getTime() + msToExpire);
            }

            var cookie;
            cookie = cookieName + '=' + value + ';expires=' + expiryDate.toGMTString() + ";" + 'path=' + (path || '/') + (domain ? ';domain=' + domain : '') + (secure ? ';secure' : '');

            document.cookie = cookie;
            //    ';path=' + (path||'/');

            var x = document.cookie;
        }

        function getRemainingVisitorCookieTimeout() {
            var now = new Date(),
                nowTS = now.getTime(),
                cookieCreatedTs = getValuesFromVisitorIdCookie().createTs;

            var createTs = parseInt(cookieCreatedTs, 10);
            var originalTimeout = (createTs * 1000) + configVisitorCookieTimeout - nowTS;
            return originalTimeout;
        }

        function getValuesFromVisitorIdCookie() {
            var cookieVisitorIdValue = loadVisitorIdCookie(),
                newVisitor = cookieVisitorIdValue[0],
                uuid = cookieVisitorIdValue[1],
                createTs = cookieVisitorIdValue[2],
                visitCount = cookieVisitorIdValue[3],
                currentVisitTs = cookieVisitorIdValue[4],
                lastVisitTs = cookieVisitorIdValue[5];
            return {
                newVisitor: newVisitor,
                uuid: uuid,
                createTs: createTs,
                visitCount: visitCount,
                currentVisitTs: currentVisitTs,
                lastVisitTs: lastVisitTs
            };
        }


        function setVisitorIdCookie(visitorIdCookieValues) {
            var now = new Date(),
                nowTS = Math.round(now.getTime() / 1000);

            if (!isDefined(visitorIdCookieValues)) {
                visitorIdCookieValues = getValuesFromVisitorIdCookie();
            }

            var cookieValue = visitorIdCookieValues.uuid + '.' + visitorIdCookieValues.createTs + '.' +
                visitorIdCookieValues.newVisitor + '.' + nowTS + '.' +
                visitorIdCookieValues.lastVisitTs;

            setCookie(getCookieName('id'), cookieValue, getRemainingVisitorCookieTimeout(), configCookiePath, configCookieDomain);
        }

        function getCookieName(baseName) {
            return configCookieNamePrefix + baseName;
        }

        function isDefined(property) {
            var propertyType = typeof property;

            return propertyType !== 'undefined';
        }

        function hasCookies() {
            if (configCookiesDisabled) {
                return '0';
            }

            if (!isDefined(navigator.cookieEnabled)) {
                var testCookieName = getCookie('testcookie');
                setCookie(testCookieName, '1');

                return getCookie(testCookieName) == '1' ? '1' : '0';
            }

            return navigator.cookieEnabled ? '1' : '0';

        }

        function isArray(myArray) {
            return myArray.constructor.toString().indexOf("Array") > -1;
        }

        function getRequest(request, customData, pluginMethod) {
            var i,
                now = new Date(),
                attributionCookie = loadReferrerAttributionCookie(),
                campaignNameDetected,
                utms,
                utmm,
                cookieReferrerName = getCookieName('ref'),
                campaignKeywordDetected,
                referrerTs,
                referralUrl,
                newcamp,
                cookieVisitorIdValues = getValuesFromVisitorIdCookie();

            if (debuganalytics == true)
                console.log('cookie Visitor id values ' + cookieVisitorIdValues);


            var urlm = window.location.href;

            if (!isDefined(request)) {
                request = '';
            }
            if (pluginMethod)
                request += '&e_t=' + 2;
            else
                request += '&e_t=' + 1;

            request += '&time=' + now.getTime() + '&url=' + urlm + '&refurl=' + document.referrer + '&uuid=' + cookieVisitorIdValues.uuid + '&ccts=' + cookieVisitorIdValues.createTs + '&nv=' + cookieVisitorIdValues.newVisitor + '&lvts=' + cookieVisitorIdValues.lastVisitTs;


            campaignNameDetected = attributionCookie[0];
            campaignKeywordDetected = attributionCookie[1];
            referralUrl = attributionCookie[2];
            referralTs = attributionCookie[3];
            currentUrl = window.location.href

            for (i in browserFeatures) {

                if (Object.prototype.hasOwnProperty.call(browserFeatures, i)) {
                    request += '&' + i + '=' + browserFeatures[i];
                }
            }
            setVisitorIdCookie(cookieVisitorIdValues);

            //  if(!campaignNameDetected.length)
            //  {
            for (i in configCampaignNameParameters) {
                if (Object.prototype.hasOwnProperty.call(configCampaignNameParameters, i)) {
                    newcamp = getParameter(currentUrl, configCampaignNameParameters[i]);
                    if (newcamp.length) {

                        campaignNameDetected = newcamp;


                        if (campaignNameDetected.length) {
                            utms = getParameter(currentUrl, 'utm_source');
                            utmm = getParameter(currentUrl, 'utm_medium');
                            break;
                        }
                    }
                }
            }

            for (i in configCampaignKeywordParameters) {
                if (Object.prototype.hasOwnProperty.call(configCampaignKeywordParameters, i)) {
                    campaignKeywordDetected = getParameter(currentUrl, configCampaignKeywordParameters[i]);

                    if (campaignKeywordDetected.length) {
                        break;
                    }
                }
            }
            //  }

            if (newcamp.length) {
                referralTs = now;
                attributionCookie = [campaignNameDetected,
                    campaignKeywordDetected,
                    referralUrl,
                    referralTs
                ];
                setCookie(getCookieName('ref'), JSON.stringify(attributionCookie), configReferralCookieTimeout, configCookiePath, configCookieDomain);
            }


            request += (campaignNameDetected.length ? '&rcn=' + campaignNameDetected : '') +
                (campaignNameDetected.length ? '&rcs=' + utms : '') +
                (campaignNameDetected.length ? '&rcm=' + utmm : '') +
                '&refcu=' + referralUrl;
            //pageuid = generatePageUid();
            request += '&pid=' + pageuid;

            request += appendCustomVariablesToRequest(customData);
            printLog(" REQUEST SENT " + request);
            return request;
        }


        function getParameter(url, name) {
            var regexSearch = "[\\?&#]" + name + "=([^&#]*)";
            var regex = new RegExp(regexSearch);
            var results = regex.exec(url);
            return results ? results[1] : '';
        }

        function logPageView(customData) {
            try {
                detectBrowserFeatures();
                if (customData) {
                    customvarproc = processLocalCV(customData);
                    request = getRequest('', customvarproc);
                } else
                    request = getRequest();
                if (debuganalytics == true)
                    console.log('request is ' + request);
                //sendRequest(request);
            } catch (e) {
                if (debuganalytics == true)
                    console.log("Exception" + e);

            }
        }


        function sendEventApiData() {
            if (arguments.length < 3)
                throw "Min 3 parameters expected";
            detectBrowserFeatures();
            eventName = arguments[0]
            eventSource = arguments[1]
            eventJson = arguments[2];
            if (IsJsonString(eventJson) == false) {
                throw "Not valid JSON";
            }
            //setting events array in eventApiJson.
            eventsArr = [];
            eventJsonstr = JSON.parse(eventJson);
            eventJsonstr.event_name = eventName;
            eventJsonstr.event_time_epoc_ms = (new Date).getTime();
            eventJsonstr.event_id = generateRandomUuid();
            eventsArr.push(eventJsonstr);
            eventApiJson.events = eventsArr;

            utmJson = arguments[3];
            event_property = arguments[4];

            if(isDefined(utmJson)){
                var eventJsonUtmstr = JSON.parse(utmJson);     
                eventApiJson.utm_info = eventJsonUtmstr;
            }

            generateEventApiJson(eventSource);
            var str = JSON.stringify(eventApiJson, null, 2);
            printLog("evet api" + str);
            sendEventApi();
        }

        function sendEventApi() {
            try {
                var xhr = new XMLHttpRequest();
                var httpprotocol = location.protocol;
                if (httpprotocol == 'https:') {
                    eventserverurl = 'https://analytics1.quikr.com/events';
                }
                xhr.open('POST', eventserverurl, true);
                xhr.setRequestHeader('Content-Type', configRequestContentType);
                xhr.send(JSON.stringify(eventApiJson, null, 2));
            } catch (e) {
                if (debuganalytics == true)
                    console.log("Exception" + e);
            }
        }

        function IsJsonString(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        }

        function generateEventApiJson(source) {
            var i,
                now = new Date(),
                attributionCookie = loadReferrerAttributionCookie(),
                campaignNameDetected,
                utms,
                utmm,
                cookieReferrerName = getCookieName('ref'),
                campaignKeywordDetected,
                referrerTs,
                referralUrl,
                newcamp,
                cookieVisitorIdValues = getValuesFromVisitorIdCookie();

            if (debuganalytics == true)
                console.log('cookie Visitor id values ' + cookieVisitorIdValues);

            //setting eventAPIJson Message.
            eventApiJson.cookie_id = cookieVisitorIdValues.uuid;
            printLog(eventApiJson.cookie_id);
            eventApiJson.source = source;


            campaignNameDetected = attributionCookie[0];
            campaignKeywordDetected = attributionCookie[1];
            referralUrl = attributionCookie[2];
            referralTs = attributionCookie[3];
            currentUrl = window.location.href
            setVisitorIdCookie(cookieVisitorIdValues);

            for (i in configCampaignNameParameters) {
                if (Object.prototype.hasOwnProperty.call(configCampaignNameParameters, i)) {
                    newcamp = getParameter(currentUrl, configCampaignNameParameters[i]);
                    if (newcamp.length) {

                        campaignNameDetected = newcamp;

                        if (campaignNameDetected.length) {
                            utms = getParameter(currentUrl, 'utm_source');
                            utmm = getParameter(currentUrl, 'utm_medium');
                            break;
                        }
                    }
                }
            }

            for (i in configCampaignKeywordParameters) {
                if (Object.prototype.hasOwnProperty.call(configCampaignKeywordParameters, i)) {
                    campaignKeywordDetected = getParameter(currentUrl, configCampaignKeywordParameters[i]);

                    if (campaignKeywordDetected.length) {
                        break;
                    }
                }
            }
            //  }
            if (campaignNameDetected.length) {
                utminfo = {};
                utminfo.source = utms;
                utminfo.medium = utmm;
                utminfo.name = campaignNameDetected;
                eventApiJson.utm_info = utminfo;
            }

            if (newcamp.length) {
                referralTs = now;
                attributionCookie = [campaignNameDetected,
                    campaignKeywordDetected,
                    referralUrl,
                    referralTs
                ];
                setCookie(getCookieName('ref'), JSON.stringify(attributionCookie), configReferralCookieTimeout, configCookiePath, configCookieDomain);
            }



            //setting device info
            deviceInfo = {};
            for (i in browserFeatures) {
                if ((Object.prototype.hasOwnProperty.call(browserFeatures, i)) && (i == 'nver')) {
                    deviceInfo.browser_agent = browserFeatures[i];
                    eventApiJson.device_info = deviceInfo;
                    printLog("device info broser event" + deviceInfo["browser_agent"]);
                }
            }
            // setting page info
            page_info = {};
            page_info.url = currentUrl;
            page_info.ref_url = document.referrer;
            eventApiJson.page_info = page_info;

            // adding property to event json 
            if(isDefined(event_property)){
                eventApiJson.property = event_property;
            } else{
                eventApiJson.property = "Quikr";
            }
        }

        function getParmFromUrl(url) {
            var re = new RegExp(".*[?&]" + 'debuganalytics' + "=([^&]+)(&|$)");
            var match = url.match(re);
            return (match ? match[1] : false);
        }

        function sendRequest(request) {

            if (configRequestMethod == 'POST') {
                sendXmlHttpRequest(request);
            } else {
                getImage(request);
            }
        }

        function getImage(request) {
            var image = new Image(1, 1);
            image.onload = function() {
                iterator = 0;
            };

            if (location.protocol == 'https:') {
                configTrackerUrl = 'https://analytics1.quikr.com/logo.gif';
            }
            image.src = configTrackerUrl + (configTrackerUrl.indexOf('?') < 0 ? '?' : '&') + request;
        }


        function loadReferrerAttributionCookie() {
            var cookie = getCookie(getCookieName('ref'));

            if (cookie.length) {
                return JSON.parse(cookie);

            }
            var t = [
                '',
                '',
                '',
                0
            ];
            return t;
        }



        function detectBrowserFeatures() {
            var i,
                mimeType,
                pluginMap = {
                    // document types
                    pdf: 'application/pdf',

                    // media players
                    qt: 'video/quicktime',
                    realp: 'audio/x-pn-realaudio-plugin',
                    wma: 'application/x-mplayer2',

                    // interactive multimedia
                    dir: 'application/x-director',
                    fla: 'application/x-shockwave-flash',

                    // RIA
                    java: 'application/x-java-vm',
                    gears: 'application/x-googlegears',
                    ag: 'application/x-silverlight'
                },
                devicePixelRatio = window.devicePixelRatio || 1;

            // detect browser features except IE < 11 (IE 11 user agent is no longer MSIE)
            if (!((new RegExp('MSIE')).test(navigator.userAgent))) {
                // general plugin detection
                if (navigator.mimeTypes && navigator.mimeTypes.length) {
                    for (i in pluginMap) {
                        if (Object.prototype.hasOwnProperty.call(pluginMap, i)) {
                            mimeType = navigator.mimeTypes[pluginMap[i]];
                            browserFeatures[i] = (mimeType && mimeType.enabledPlugin) ? '1' : '0';
                        }
                    }
                }

                // Safari and Opera
                // IE6/IE7 navigator.javaEnabled can't be aliased, so test directly
                if (typeof navigator.javaEnabled !== 'unknown' &&
                    isDefined(navigator.javaEnabled) &&
                    navigator.javaEnabled()) {
                    browserFeatures.java = '1';
                }

                // other browser features
                browserFeatures.cookie = hasCookies();
            }

            // screen resolution
            browserFeatures.res = screen.width * devicePixelRatio + 'x' + screen.height * devicePixelRatio;
            browserFeatures.nver = navigator.appVersion;
            browserFeatures.nAgt = navigator.platform;
        }

        return {
            _sendEventApiData: sendEventApiData,
            _trackPageview: logPageView,
            _trackEvent: trackEvent,
            _set: set
        };
    };

    return {
        push: apply,
        tryjs: tryJS
    };

})();
try {
    qanalyticsv3.tryjs();
} catch (err) {
    if (debuganalytics == true)
        console.log("Error is " + err);

}