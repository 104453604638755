import {getCookie} from '../services/cookieService'
import {isSet,replaceSpace,replaceSpacetounderscore,jsUcfirst,convertspace} from '../utils/common'
var json = [];
export function resentSearch(){
    var object = {}
    object.intent = snbFilterController.filter.data.type
    object.category = snbFilterController.filter.data.cat
    var recentLocName = localStorage.getItem('resentSearchLocName')
    var recentLocId = localStorage.getItem('resentSearchLocId')
    var recenpropType = localStorage.getItem('resentSearchPropType');
    var recenpropVal = localStorage.getItem('resentSearchPropVal');
    var recenbhkVal = localStorage.getItem('resentSearchBhk');
    object.ad_locality = recentLocName
    object.ad_locids = recentLocId
    object.bedrooms = snbFilterController.filter.data.bedrooms
    var recent_obj = snbRecentSearchLink(object);
    object.searchLink = recent_obj.recent_link;
    object.searchTitle = recent_obj.recent_title;
    object.searchCountPaylod = recent_obj.recent_count_paylod;
    if(object.intent || object.category){
        if(localStorage.getItem(snbFilterController.filter.data.c_id)){
            var dataCount = JSON.parse(localStorage.getItem(snbFilterController.filter.data.c_id))
            
            if(recentLocId || recenpropType || recenbhkVal){

                if(recenpropType){
                    propertyRecentSearch();
                }else if(recenbhkVal) {
                    bhkRecentSearch();
                } else if(recentLocId) {
                    localityRecentSearch(object);
                }
            }
        }else{
            json.unshift(object) 
            localStorage.setItem(snbFilterController.filter.data.c_id,JSON.stringify(json)); 
        }  
    }
    checkRecentSearchLen();
}

function propertyRecentSearch() {
    var dataCount = JSON.parse(localStorage.getItem(snbFilterController.filter.data.c_id))
    var copyDatacount = jQuery.extend(true, [], dataCount);
    for(var j= 0;j<copyDatacount.length;j++){
        copyDatacount[j].property_type = localStorage.getItem('resentSearchPropType');
        copyDatacount[j].property_val = localStorage.getItem('resentSearchPropVal');
        var recent_obj = snbRecentSearchLink(copyDatacount[j]);
        copyDatacount[j].searchLink = recent_obj.recent_link;
        copyDatacount[j].searchTitle = recent_obj.recent_title;
        copyDatacount[j].searchCountPaylod = recent_obj.recent_count_paylod;
    }
    if(snbFilterController.filter.data.property_type.length>1){
        copyDatacount = [...copyDatacount, ...dataCount];
    }
    localStorage.removeItem(snbFilterController.filter.data.c_id);
    localStorage.setItem(snbFilterController.filter.data.c_id,JSON.stringify(copyDatacount));
    Object.assign(json,copyDatacount);
}

function checkRecentSearchLen(){
    var dataCount = JSON.parse(localStorage.getItem(snbFilterController.filter.data.c_id))
    if(dataCount.length>3){
        var final_data = dataCount.slice(0,3);
        Object.assign(json,final_data);
        localStorage.removeItem(snbFilterController.filter.data.c_id);
        localStorage.setItem(snbFilterController.filter.data.c_id,JSON.stringify(final_data)); 
    }
    localStorage.removeItem('resentSearchLocName')
    localStorage.removeItem('resentSearchLocId')
    localStorage.removeItem('resentSearchPropType');
    localStorage.removeItem('resentSearchPropVal');
    localStorage.removeItem('resentSearchBhk');
    changeRecentSearchli();
}

function changeRecentSearchli(){
    topRecentSearch().then((dataCount)=> {
        var homeRecentSearchli = '';
        if(dataCount && dataCount != null  && dataCount.length){
                homeRecentSearchli  +='<li><div class="re_search"><span>RECENT SEARCHES</span>';
                dataCount.map(function(value) {
                    var property_count = (value.propertyCount) ? value.propertyCount : '';
                    homeRecentSearchli += `<a href="${value.searchLink}" class="se_li"> 
                                        <svg version="1" width="22px" height="22px" viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M38,68c-8.5,0-16.6-3.3-22.6-9.4c-0.6-0.6-0.6-1.5,0-2.1s1.5-0.6,2.1,0C23,61.9,30.3,65,38,65c16,0,29-13,29-29
                                                C67,20,54,7,38,7C22.6,7,9.9,19.2,9.1,34.5l1.6-1.6c0.6-0.6,1.5-0.6,2.1,0s0.6,1.5,0,2.1l-4.1,4.1c-0.6,0.6-1.5,0.6-2.1,0L2.4,35
                                                c-0.6-0.6-0.6-1.5,0-2.1c0.6-0.6,1.5-0.6,2.1,0l1.6,1.6C6.9,17.6,20.9,4,38,4c17.6,0,32,14.3,32,32C70,53.6,55.7,68,38,68z
                                                 M47.1,45.2c-0.3,0-0.6-0.1-0.9-0.3l-9.2-7.1c-0.4-0.3-0.6-0.7-0.6-1.2V21.9c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5v13.9l8.6,6.7
                                                c0.7,0.5,0.8,1.4,0.3,2.1C48,45,47.5,45.2,47.1,45.2z"/>
                                        </svg>
                                     ${value.searchTitle}<small>${property_count} Properties</small></a>`;
                });

            homeRecentSearchli += `</div></li>`;
            $("ul#showHeadersearchSuggest li:nth-child(1)" ).html(homeRecentSearchli);   
        }
    })
}

function bhkRecentSearch() {
    var dataCount = JSON.parse(localStorage.getItem(snbFilterController.filter.data.c_id));
    for(var i= 0;i<dataCount.length;i++){
        if(snbFilterController.filter.data.bedrooms.length) {
            dataCount[i].bedrooms = snbFilterController.filter.data.bedrooms;
            var recent_obj = snbRecentSearchLink(dataCount[i]);
            dataCount[i].searchLink = recent_obj.recent_link;
            dataCount[i].searchTitle = recent_obj.recent_title;
            dataCount[i].searchCountPaylod = recent_obj.recent_count_paylod;
        }
        localStorage.removeItem(snbFilterController.filter.data.c_id);
        localStorage.setItem(snbFilterController.filter.data.c_id,JSON.stringify(dataCount));
        Object.assign(json,dataCount);
    }
}

function localityRecentSearch(objectData){
    var dataCount = JSON.parse(localStorage.getItem(snbFilterController.filter.data.c_id));
    if(dataCount.length && dataCount[0].ad_locality == ''){
        for(var i= 0;i<dataCount.length;i++){
            if(dataCount[i].ad_locality == '' &&  objectData.ad_locality ) {
                dataCount[i].ad_locality = objectData.ad_locality;
                dataCount[i].ad_locids = objectData.ad_locids;
                var recent_obj = snbRecentSearchLink(dataCount[i]);
                dataCount[i].searchLink = recent_obj.recent_link;
                dataCount[i].searchTitle = recent_obj.recent_title;
                dataCount[i].searchCountPaylod = recent_obj.recent_count_paylod;
            }
        }
        localStorage.setItem(snbFilterController.filter.data.c_id,JSON.stringify(dataCount));
        Object.assign(json,dataCount);
    }else{
        if(dataCount.length && dataCount[0].property_type){
            objectData.property_type = dataCount[0].property_type;
            objectData.property_val = dataCount[0].property_val;
            var recent_obj = snbRecentSearchLink(objectData);
            objectData.searchLink = recent_obj.recent_link;
            objectData.searchTitle = recent_obj.recent_title;
            objectData.searchCountPaylod = recent_obj.recent_count_paylod;
        }
        var mergeTitle = [];
        for(var i= 0;i<dataCount.length;i++){
            mergeTitle.push(dataCount[i].searchTitle);
        }
        if(mergeTitle.indexOf(objectData.searchTitle) == -1){
            dataCount.unshift(objectData)
            localStorage.setItem(snbFilterController.filter.data.c_id,JSON.stringify(dataCount));
        }   
    }
}

function snbRecentSearchLink(data) {
    var obj = {
        cat: data.category,
        type: getType(data.intent),
        property_type: (data.property_val)?data.property_val:'',
        bhk: ( data.bedrooms && data.bedrooms.length)? getBhk(data):'',
        loc: (data.ad_locality)?data.ad_locality : "",
        lid: (data.ad_locids) ? "lid_"+data.ad_locids : "",
        cid: "cid_" + snbFilterController.filter.data.c_id,
        city: snbFilterController.filter.data.c
    };


    var recent_count_paylod = {
        "cityId" : snbFilterController.filter.data.c_id,
        "bhk" : ( data.bedrooms && data.bedrooms.length)? data.bedrooms:[] ,
        "category" : data.category,
        "page" : getCountPageType(obj.type),
        "intent" : getCountIntentType(data.intent),
        "localityIds" :  (data.ad_locids) ?  [data.ad_locids] : [],
        "propertyTypes" :  (data.property_type)? [getCountPropertyType(data.property_type) ]:[]
    };


    var catType = '';
    var type = obj.type === 'projects' ? 'sale' : obj.type;
    if(obj.type == 'projects'){
        catType = 'projects'
    }
    if(obj.type == 'brokers'){
        catType = 'brokers'
    }

    if(obj.type == 'brokers') {
        var ad_type = (snbFilterController.filter.data.ad_type && snbFilterController.filter.data.ad_type.length)?snbFilterController.filter.data.ad_type[0]:'';
        var path = (" " 
            + 'real-estate-'+ " " 
            + (catType ? (catType + " ") : "")
            + (ad_type ? ("for " + brokerAdtype(ad_type)) : "" )+ " "
            + "in" + " "
            + (obj.loc ? ( convertspace(obj.loc) + " ") : "")
            + convertspace(obj.city) + " "
            + obj.cid + " "
            + (obj.lid ? (obj.lid + " ") : "")
            );
    } else {
        var path = (" " 
            + obj.cat + " " 
            + (obj.property_type ? (obj.property_type + " ") : "")
            + (obj.bhk ? (obj.bhk + " ") : "")
            + (catType ? (catType + " ") : "")
            + (type ? ("for " + type) : "") + " "
            + (obj.ad_type ? ("for " + obj.ad_type) : "") + " "
            + "in" + " "
            + (obj.loc ? ( convertspace(obj.loc) + " ") : "")
            + convertspace(obj.city) + " "
            + obj.cid + " "
            + (obj.lid ? (obj.lid + " ") : "")
            );
    }

    var recent_link = location.protocol+"//"+location.host+"/homes/property/"+formatPath(path);
    
    var recent_title = "";
    if(obj.type == 'projects') {
    recent_title = (jsUcfirst(obj.cat) 
        +' | '
        + (obj.bhk ? (obj.bhk.toUpperCase() + " ") : "")
        + (obj.property_type ? (obj.property_type + " ") : " ")
        + obj.type
        + (obj.loc ? ( ", "+jsUcfirst(obj.loc)) : "")
        );
    } else if(obj.type == 'brokers') {
        recent_title = (jsUcfirst(data.intent) 
        + (obj.loc ? (' | ' + jsUcfirst(obj.loc)) : "")
        );
    } else {
    
    recent_title = (jsUcfirst(data.intent)
        +' | '
        + (obj.bhk ? (obj.bhk.toUpperCase() + " ") : "")
        + (obj.property_type ? (obj.property_type + " ") : (jsUcfirst(obj.cat) + ' Properties'))
        + (obj.loc ? ( ", "+jsUcfirst(obj.loc)) : "")
        );
    }

    var recent_obj = {
        'recent_link' : recent_link,
        'recent_title' : recent_title,
        'recent_count_paylod' : recent_count_paylod
    }

    return recent_obj;
}


function getCountPageType(type) {
    if(type == 'projects'){
        return "project";
    }else if(type == 'brokers') {
        return  "broker";
    }else{
        return "listing";
    }
}

function getCountIntentType(type) {
    if(type == "buy") {
        return "sale";
    }
    if(type == "pg") {
        return "pg";
    }
    if(type == "projects") {
        return "sale";
    }
    if(type == "brokers") {
        return "sale";
    }
    return "rent";
}

function getCountPropertyType(propType) {
    if(propType == "ap") {
        return "Apartment";
    }
    if(propType == "bf") {
        return "BuilderFloor";
    }
    if(propType == "rp") {
        return "Plot";
    }
    if(propType == "vi") {
        return "Villa";
    }
    if(propType == "of") {
        return "Office";
    }
    if(propType == "sh") {
        return "Shop";
    }
    return "Apartment";
}

function formatPath(path) {
    path = path.trim();
    path = path.replace(/\//g, '');
    path = path.replace(/ /g, '-');
    path = path.replace(/\-+/g, "-");
    path = path.toLowerCase();
    return path;
}


function getType(type) {
    if(type == "buy") {
        return "sale";
    }
    if(type == "pg") {
        return "pg_flatmates";
    }
    if(type == "projects") {
        return "projects";
    }
    if(type == "brokers") {
        return "brokers";
    }
    return "rent";
}

function brokerAdtype(type) {
    if(type == "sell") {
        return "buy";
    }
    return "rent";
}

function getBhk(data) {
    var bhk = "";
    if(data.category == "residential" && data.property_val != "Plots") {
        if(!data.bedrooms) {
            return "";
        }
        bhk = data.bedrooms.toString();
        bhk = bhk.replace(/5/, "4+");
        if(bhk) {
            bhk += "bhk";
        }
    }
    return bhk;
}

export function setRecentSearch(){
    var setProperty;
    if(snbFilterController.filtersLabel.property_type && snbFilterController.filtersLabel.property_type.length){
        if(snbFilterController.filtersLabel.property_type.indexOf(',')>-1){
            setProperty= snbFilterController.filtersLabel.property_type.split(',')[0];
        } else{
            setProperty= snbFilterController.filtersLabel.property_type;
        }
    }
    var object = {}
    object.intent = snbFilterController.filter.data.type;
    object.category = snbFilterController.filter.data.cat;
    object.ad_locality = (snbFilterController.filter.data.ad_locality.length) ? snbFilterController.filter.data.ad_locality[0] :'';
    object.ad_locids = (snbFilterController.filter.data.ad_locids.length) ? snbFilterController.filter.data.ad_locids[0]:'';
    object.bedrooms = (snbFilterController.filter.data.bedrooms && snbFilterController.filter.data.bedrooms.length)? snbFilterController.filter.data.bedrooms :'';
    object.property_type = (snbFilterController.filter.data.property_type && snbFilterController.filter.data.property_type.length)? snbFilterController.filter.data.property_type[0] :'';
    object.property_val = (setProperty && setProperty.length)? setProperty :'';
    var recent_obj = snbRecentSearchLink(object);
    object.searchLink = recent_obj.recent_link;
    object.searchTitle = recent_obj.recent_title;
    object.searchCountPaylod = recent_obj.recent_count_paylod;
    var dataCount = JSON.parse(localStorage.getItem(snbFilterController.filter.data.c_id));
    if(dataCount && dataCount.length){
        var mergeTitle = [];
        for(var i= 0;i<dataCount.length;i++){
            mergeTitle.push(dataCount[i].searchTitle);
        }
        if(mergeTitle.indexOf(object.searchTitle) == -1){
            dataCount.unshift(object)
            localStorage.setItem(snbFilterController.filter.data.c_id,JSON.stringify(dataCount));
        }
    } else{
        json.unshift(object) 
        localStorage.setItem(snbFilterController.filter.data.c_id,JSON.stringify(json)); 
        Object.assign(json,object);
    }
    checkRecentSearchLen();
}

export function showFooterData() { 
    $.ajax({
        url : "/homes/re2/viewFooter?aj=1",
        method : "POST",
        data : {
            "filter":snbFilterController.filter.data
        },//JSON.stringify(snbFilterController.filter.data),
        success: function (response) {
            $("#snb_interlinking").html(response);
        },
        error: function (error) {
            reject(error);
        }
    })
}

function topRecentSearch() {
    var cityId = getCookie('prefer_city_id');
    var promise = new Promise(function (resolve, reject) {
        var dataCount = JSON.parse(localStorage.getItem(cityId));

        var filters= [];
        if(dataCount && dataCount != null  && dataCount.length){
            dataCount.map(function(value) {
                filters.push(value.searchCountPaylod);
            });
        }

        $.ajax({
            url: "/homes/re2/getCountForRecentSearch?aj=1",
            method: "POST",
            data: {
                filters: filters
            },
           success: function(res) {
                if(res && res.length>0){
                    dataCount.forEach(function(value,index) {
                       value.propertyCount = res[index];
                    });
                }
                resolve(dataCount);
            }
        });
    });
    return promise;
}

function topProject() {
    var cityId = getCookie('prefer_city_id');
    var promise = new Promise(function (resolve, reject) {
        $.ajax({
            url: "/homes/re2/snbProjectBanner?aj=1",
            method : "POST",
            data : {cityId:cityId},
            success: function (response) { 
                resolve(response);
            },
            error: function (error) {
                reject(error);
            }
        })
    });
    return promise;
}


function topLocality() {
    var cityId = getCookie('prefer_city_id');
    var promise = new Promise(function (resolve, reject) {
        $.ajax({
            url: "/homes/re2/getallLocality?aj=1",
            method : "POST",
            data : {
                "cityId": cityId,
                "size" : 5,
                "source" : "qh", 
                "entityType" : "Listing"},
            success: function (response) {
                resolve(response);
            },
            error: function (error) {
                reject(error);
            }
        })
    });
    return promise;
}

window.searchsnbSubmit = function(id,name){
    var cityName = getCookie('new_prefer_city');
    var cityId = getCookie('prefer_city_id');
    var category = (localStorage.getItem("category")) ? catNameMap[localStorage.getItem("category")] : catNameMap['res'];
    var adType= (localStorage.getItem("type")) ? getType(localStorage.getItem("type")) : getType('buy');
    var localityUrl = '';
    if(adType == 'projects') {
        localityUrl = `/homes/property/${category}-${adType}-for-sale-in-${isSet(name)?replaceSpacetounderscore(name):''}-${cityName}-cid_${cityId}-lid_${isSet(id)?id:''}`; 
    } else {
        localityUrl = `/homes/property/${category}-for-${adType}-in-${isSet(name)?replaceSpacetounderscore(name):''}-${cityName}-cid_${cityId}-lid_${isSet(id)?id:''}`; 
    }
    window.location.href = localityUrl;
}


export function searchSuggest() {
    Promise.all([topRecentSearch(),topLocality(),topProject()]).then(function(values) {
        var homeRecentSearch = '';
        if(values[0] && values[0] != null  && values[0].length){
            homeRecentSearch +='<span>Your <b>Recent Searches</b></span>';
            values[0].map(function(value) {
               homeRecentSearch += `<li><a title="${value.searchTitle}" href="${value.searchLink}" onclick="homeCommonGa('topsection','recentSearch');">${value.searchTitle}</a></li>`;
            });
        }

        var topSearchLocalityProjectHtml = '';
        if(values[0] && values[0] != null  && values[0].length){
            topSearchLocalityProjectHtml +='<li><div class="re_search"><span>RECENT SEARCHES</span>';

            values[0].map(function(value) {
                var property_count = (value.propertyCount) ? value.propertyCount : '';
                topSearchLocalityProjectHtml += `<a href="${value.searchLink}" class="se_li" onclick="homeRecentSearch();"> 
                                    <svg version="1" width="20px" height="20px" viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M38,68c-8.5,0-16.6-3.3-22.6-9.4c-0.6-0.6-0.6-1.5,0-2.1s1.5-0.6,2.1,0C23,61.9,30.3,65,38,65c16,0,29-13,29-29
                                            C67,20,54,7,38,7C22.6,7,9.9,19.2,9.1,34.5l1.6-1.6c0.6-0.6,1.5-0.6,2.1,0s0.6,1.5,0,2.1l-4.1,4.1c-0.6,0.6-1.5,0.6-2.1,0L2.4,35
                                            c-0.6-0.6-0.6-1.5,0-2.1c0.6-0.6,1.5-0.6,2.1,0l1.6,1.6C6.9,17.6,20.9,4,38,4c17.6,0,32,14.3,32,32C70,53.6,55.7,68,38,68z
                                             M47.1,45.2c-0.3,0-0.6-0.1-0.9-0.3l-9.2-7.1c-0.4-0.3-0.6-0.7-0.6-1.2V21.9c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5v13.9l8.6,6.7
                                            c0.7,0.5,0.8,1.4,0.3,2.1C48,45,47.5,45.2,47.1,45.2z"/>
                                    </svg>
                                 ${value.searchTitle}<small>${property_count} Properties</small></a>`;
            });


            topSearchLocalityProjectHtml += `</div></li>`;
        }

        if(values[1] && values[1] != null  && values[1].length){
            var toplocClass = (values[2] && values[2].statusCode == 200 && values[2].data != null ) ? " " : "toploc";
            topSearchLocalityProjectHtml +=`<li class="clearfix ${toplocClass}"><div class="topl"><span>TOP LOCALITY</span><div class="listul">`;

            values[1].map(function(value) {
               topSearchLocalityProjectHtml += `<span onclick="searchsnbSubmit(${value.id},'${value.name}');homeTopSearchGa('topLocality','${value.name}')">${value.name}</span>`;
            });

            topSearchLocalityProjectHtml += `</div></div>`;
        }

        if(values[2] && values[2].statusCode == 200 && values[2].data != null ){
            var topProject = values[2].data;
            topSearchLocalityProjectHtml +=`<div class="fepro"><span>TOP PROJECTS</span><div class="feproject clearfix">`;

            topProject.map(function(value) {
                var projUrl = "/homes/project/";
                var projectname = '';
                projUrl += projectname =isSet(value.name) ? replaceSpace(value.name) : '';
                projUrl += isSet(value.localityName) ? "+" + replaceSpace(value.localityName) : '';
                projUrl += isSet(value.cityName) ? "+" + replaceSpace(value.cityName) : '';
                projUrl += "+" + value.id;
                var imageUrl = isSet(value.bannerImage) ? "https://teja10.kuikr.com"+ value.bannerImage : 'https://teja10.kuikr.com/restatic/image/tile-no-photo.jpg';     
                topSearchLocalityProjectHtml += `<a href="${projUrl}" class="febox" onclick="homeTopSearchGa('topProject','${value.name}')"><div class="feimg"><img src="${imageUrl}" alt="${value.name}"/></div><strong>${value.name}</strong><small>${value.localityName}</small></a>`;
            });

            topSearchLocalityProjectHtml += `</div></div>`;
        }

        topSearchLocalityProjectHtml +=`</li>`;
        if(globals.pageType == 'Home'){
            if(homeRecentSearch.length){
                $("#homeRecentSearch").html(homeRecentSearch);
            }else {
                $("#qhsrchlinkUl").removeClass('hide');
            }
            $("#showPresearchSuggest").html(topSearchLocalityProjectHtml);
        } else{
            var headertopSearchLocalityProjectHtml ='<ul id="showHeadersearchSuggest" class="dropdown-menu presearchsuggest headersrch" style="display:none;">'+topSearchLocalityProjectHtml+'</ul>'
            $(".quikr-search").append(headertopSearchLocalityProjectHtml);
        }
    });
}

// default values
var catNameMap = {
    "res": "residential",
    "com": "commercial",
    "agr": "agricultural"
};
