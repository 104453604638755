require('../services/gaService.js')
import {isSet,getFormObject,getterSetter, propertyType} from '../utils/common'
import {errorTrack} from '../utils/errorTrack'
import {getCookie,setCookieWithExpirationHours} from '../services/cookieService'
import {get,set} from '../services/localStorageService'
import {formValidator} from '../services/formValidator'
import {getSubCategory} from '../services/getSubCategory'
import {otpService} from '../services/otpService'
import {getLoggedInUser,login} from '../loginService'
import {windowWidthService} from '../services/windowWidthService'
import {requirementService} from '../services/requirementService'
import {urlBuilderService} from '../services/urlBuilderService'
import {seoService} from '../services/seoService'
import {dataService} from '../services/dataService'
import {dateTimeService} from '../services/dateTimeService'
import {mailService} from '../services/mailService'
window.otpController = function () {

    var error = {};
    error['otpRequired'] = "OTP is required.";
    error['otpLength'] = "Enter 4 digit otp";
    error['otpVerify'] = "Please Wait...Verifying OTP";
    error['otpExp'] = "Wrong or expired OTP, Please re-enter";
    var user = {};
    var otpController = {};
    var otpId;
    var otpClientId;
    var sellerMobile;
    var sellerName = "";
    var sellerEmail = "";
    var page = "";
    var showVerifiedSuccessSection = false;
    var userdata = {};
    var otpObj = {};
    var showSubmitDetailsSection = false;
    var showSubmitOTPSection = false;
    var otpMatchError = false;
    var otpError = "";
    var otpSuccess = "";
    var mobileValid = false;
    var clickedEl;
    otpController.loggedIn = false;
    otpController.enquiry_phone = '';
    otpController.enquiry_email = '';
    var showEnquiryPopUp = false;
    otpController.enquiry_name = '';
    otpController.selectedIsdCode = '';
    otpObj.submitOtp = true;
    otpController.propertySnippet = globals.propertySnippet ? JSON.parse(atob(globals.propertySnippet)) : {};
    otpController.individual = {};
    otpController.userId = '';
    otpController.userType = otpController.propertySnippet ? otpController.propertySnippet.userType : '';
    otpController.sellerSnippet = globals.sellerSnippet ? JSON.parse(atob(globals.sellerSnippet)) : {};
    otpController.projectSnippet = globals.projectSnippet ? JSON.parse(atob(globals.projectSnippet)) : {};
    //########### REQUIREMENT DETAILS LOGIG ###########//
    // otpController.userTypeValue = '';
    // otpController.relocating = '30';
    // otpController.relocatingText = '1 Month';
    // otpController.schoolOffice = 'school';
    // otpController.familyDetails = 'single'
    // otpController.familyDetailsText = 'Bachelor'
    // otpController.schoolOffice = 'office'
    // otpController.schoolOfficeText = 'Office'

    otpController.localitySuggest = function () {
        var localitydropDown = '';
        var cityId = otpController.propertySnippet.cityId;
        dataService.getLocalitySearchNew({
                    'query': $('#locality_suggest').val(),
                    'cityId': cityId,
                    'limit': 8
                }).done(function (data) {
                    if (isSet(data)) {
                        for (var i = 0; i < data.length; i++) {
                            localitydropDown += '<li><a href="javascript:;" onclick="otpController.setLocality('+data[i].id+',\''+data[i].title+'\')";>'+data[i].title+'</a></li> ';
                        }
                        document.getElementById("autoShowLocality").innerHTML = localitydropDown
                    }
                });
    }; 

    otpController.doRender = function () {
         var $createAlertPopup = $('#requirementDetails');
         var htmlData = _.template($('#requirement_details').html());
         otpController.enquiry_phone = getterSetter.get('mobile')
         otpController.enquiry_email = getterSetter.get('email')
        otpController.loanRequirement = getterSetter.get('loanRequirement');
        otpController.viewNumberData = ''
        if(getterSetter.get('reqSource') === 'CLICK_TO_VIEW_IN_LISTING' && getterSetter.get('viewNumberData')){
            otpController.viewNumberData =  getterSetter.get('viewNumberData');
        }
         $createAlertPopup.html(htmlData({
              'otpController': otpController
         }));
    }
    /*if(globals.pageType == 'VAP'){
        otpController.doRender();   
    }*/

    otpController.userType = function (value,text) {
         otpController.userTypeValue = value
    }
    otpController.relocate = function (value,text) {
         otpController.relocating = value
         otpController.relocatingText = text
         otpController.doRender();
    }
    otpController.family = function (value,text) {
         otpController.familyDetails = value
         otpController.familyDetailsText = text
         otpController.doRender();
    }
    otpController.schoolOffice = function (value,text) {
         otpController.school = value
         otpController.schoolOfficeText = text
         otpController.doRender();
    }
    otpController.setLocality = function(id,title){
        otpController.locality = title
        otpController.id = id
        otpController.doRender();
    }
    otpController.furnished = function(value,text){
        otpController.furnishedType = value
        otpController.furnishedText = text
        otpController.doRender();
    }

    otpController.professoin = function(value,text){
        otpController.professoinValue = value
        otpController.professoinText = text
        otpController.doRender();
    }
    
    otpController.homePurchase = function (value, text) {
        otpController.homePurchaseValue = value
        otpController.homePurchaseText = text
        otpController.doRender()
    }

    otpController.possessionType = function (value) {
        otpController.possessionTypeValue = value
        otpController.doRender()
    }

    otpController.brokerContacted = function (value, text) {
        otpController.brokerContactedValue = value
        otpController.brokerContactedText = text
        otpController.doRender()
    }

    otpController.propertiesVisited = function (value, text) {
        otpController.propertiesVisitedValue = value
        otpController.propertiesVisitedText = text
        otpController.doRender()
    }

    otpController.HDFCContactType = function (value, text) {
        otpController.HDFCContactTypeValue = value
        otpController.HDFCContactTypeText = text
        otpController.doRender()
    }

    otpController.HDFCContactTime = function (value, text) {
        otpController.HDFCContactTimeValue = value
        otpController.HDFCContactTimeText = text
        otpController.doRender()
    }

    otpController.Thankyou = function(){

        // GA for Enrichment form submit
        if(globals.pageType == 'VAP'){
            var propertySnippet = JSON.parse(atob(globals.propertySnippet));
            var prop_type = propertyType(propertySnippet.propertyFor);
            gaService.addEvent('RealEstate', 'QH_vap_' +prop_type , 'QH_vap_'+prop_type+'_leadForm_enrichmentForm_submit_click');
        }
        
        var details = getterSetter.get('loginData');
        if (details && details.data && details.data.user) {
           var details = getterSetter.get('loginData');
            if (!details.error) {
               otpController.loggedIn = true;
                otpController.userId = details.data.user.id;

            } 
        }
        var cityID = otpController.propertySnippet.cityId;
        var category = otpController.propertySnippet.category;


        if(globals.pageType === 'project'){
            cityID = globals.projectSnippet.address.cityId;
            category = globals.projectSnippet.category;
        }
        var userComments = (document.getElementById("userComments") !== null) ? document.getElementById("userComments").value : '';

        var dataObject = {
                'userName': getterSetter.get('name')|| '',
                'gender': otpController.userTypeValue,
                'phone': requirementController.enquiry_phone || getterSetter.get('mobile'),
                'emailId': requirementController.enquiry_email || getterSetter.get('email'),
                "listingId": getterSetter.get('adDetails').id,
                'userId': otpController.userId,
                'cityId': cityID,
                'category': category,
                'propertyFor':requirementController.adTypeValue,
                'urgency':otpController.relocating,
                'profession': otpController.professoinValue,
                'location_near_to': otpController.school,
                'localityIds': otpController.id,
                'furnishings':otpController.furnishedType,
                'priceMin': $('#maxBudget').val(),
                'priceMax': $('#maxBudget').val(),
                'maritalStatus': otpController.familyDetails,
                'channel': windowWidthService.channel,
                'purchaseTimeLine': otpController.homePurchaseValue,
                'brokerContactStatus': otpController.brokerContactedValue,
                'visitedPropertiesEstimate': otpController.propertiesVisitedValue,
                'preferredHDFCContactMode': otpController.HDFCContactTypeValue,
                'hdfcContactTimeLine': otpController.HDFCContactTimeValue,
                'possessionTimeLine':otpController.possessionTypeValue,
                'userComments': (typeof userComments === 'string' && userComments.trim() !== '') ? userComments.trim() : '',
                'loanRequirement': otpController.loanRequirement ? 'YES' : 'NO',
                'source': 'LEAD_ENRICHMENT'
        };
        //(globals.pageType === 'project') ? dataObject.projectId = getterSetter.get('adDetails').id || '' : dataObject.listingId = getterSetter.get('adDetails').id;
        if (dataObject.urgency || dataObject.profession || dataObject.location_near_to || dataObject.localityIds || dataObject.furnishings || dataObject.priceMin || dataObject.priceMax || dataObject.maritalStatus ||  dataObject.purchaseTimeLine || dataObject.brokerContactStatus || dataObject.visitedPropertiesEstimate || dataObject.preferredHDFCContactMode || dataObject.hdfcContactTimeLine || dataObject.possessionTimeLine || dataObject.userComments){
            requirementService.addRequirement(dataObject)
        }
        $('#myModal').modal('hide');
    }
    otpController.resendOTP = function () {

        gaService.addEvent('link-RealestateResponsiveUI', "VAP", 're_submit_phone_number');

        var payload = {
            "otpId": otpController.otpId,
            "otpClientId": otpClientId ? otpClientId : '',
            "source": source(),
            "page": "VAP"
        }

        otpService.resendOTP(payload).then(function (result) {
            if (result && result.ResendOTPApplicationResponse && result.ResendOTPApplicationResponse.ResendOTPApplication) {
            }
        });
    };

    otpController.verifyOTP = function (jsonData) {
        var deferred = $.Deferred();
        gaService.addEvent('Button-RealestateResponsiveUI', 'Mobile_As_Login', page + '_submit_OTP');

        if ((!sellerMobile && !sellerEmail) || (otpObj.otpNumber && otpObj.otpNumber.length < 4) || !otpObj.otpNumber) {
            return false;
        }
        var enquiry_otp = otpObj.otpNumber || '';
        enquiry_otp = ('0000' + enquiry_otp).slice(-4);

        //if (!enquiry_otp) {
        //    otpSubmitForm.enquiry_otp.$dirty = true;
        //    otpSubmitForm.enquiry_otp.$invalid = true;
        //    return false;
        //}

        var payload = {
            "otpId": otpId,
            "otp": enquiry_otp,
            "source": source(),
            "page": page,
            "otpClientId": otpClientId
        }

        otpService.verifyOTP(payload).then(function (result) {
            if (isSet(result)) {
                result = JSON.parse(result.response);
                if (result && result.VerifyAndDeleteOTPApplicationResponse) {
                    if (result.VerifyAndDeleteOTPApplicationResponse.VerifyAndDeleteOTPApplication && result.VerifyAndDeleteOTPApplicationResponse.VerifyAndDeleteOTPApplication.success) {
                        setCookieWithExpirationHours('otp_verified_no', sellerMobile, 1);
                        otpMatchError = false;
                        showSubmitOTPSection = false;
                        showVerifiedSuccessSection = true;
                        otpService.setOtpVariable(true, 'showVerifiedSuccessSection');
                        handleEventsOnSuccess();
                        gaService.addEvent('link-RealestateResponsiveUI', 'Mobile_As_Login', page + '_OTP_Success');
                        deferred.resolve(result);
                    } else {
                        otpMatchError = true;
                        otpObj.submitOtp = false;
                        showVerifiedSuccessSection = false;
                        otpService.setOtpVariable(false, 'showVerifiedSuccessSection');
                        otpController.setOtpErrorClass(error.otpExp);
                        gaService.addEvent('link-RealestateResponsiveUI', 'Mobile_As_Login', page + '_OTP_Failure');
                        deferred.reject(result);
                    }
                }
            }
        });
        return deferred.promise();
    };

    otpController.addMobileNumber = function () {
        if (userdata.id && sellerMobile) {
            var addMobilePayLoad = {
                "userId": userdata.id,
                "mobileNumber": sellerMobile,
                "userConcent": 1
            }
            otpService.addMobileNumber(addMobilePayLoad).then(function (result) {
                result = result.AddMobileApplicationResponse;
                if ('data' in result) {
                    result = result.data;
                    if (result.status == "success") {
                        otpId = result.otpResponse;
                        otpClientId = result.otpClient;
                    } else {
                        // console.log("UNSUCCESS");
                    }
                } else {
                    generateOTP();
                }
            });
        } else {
            var errorMsg = "Invalid Deatails to add mobile number";
        }
    }

    otpController.setOtpNumber = function () {
        var val = $(this).val();
        if (val && val.length == 4) {
            otpObj.otpNumber = val;
            otpService.setOtpVariable(otpObj.otpNumber, 'otpNumber');
            otpController.setOtpErrorClass();
        }
        else {
            otpController.setOtpErrorClass(error.otpLength);
        }
    };

    otpController.setOtpErrorClass = function (error) {
        if(typeof clickedEl!='undefined'){
            var errorElement = clickedEl.closest('.formBox').find('.otpSubmitForm .error-container');
            if (error) {
                errorElement.removeClass('hide').find('.error-msg').text(error);
            } else {
                errorElement.addClass('hide').find('.error-msg').text('');
            }
        }
    };

    function handleEventsOnSuccess() {
        var element = clickedEl.closest('.formBox').find('.otpSubmitForm .colorgreen');
        if (error) {
            element.removeClass('hide');
        } else {
            element.addClass('hide');
        }
    }


    otpController.reGenerateOTP = function (element) {

        gaService.addEvent('Button-RealestateResponsiveUI', 'Mobile_As_Login', page + '_Resend_OTP');

        if (!sellerMobile && !sellerEmail) {
            return false;
        }

        var payload = {
            "otpId": otpId,
            "otpClientId": otpClientId ? otpClientId : '',
            "source": source(),
            "page": page
        }

        document.getElementById('pap_otp').value = '';

        otpService.resendOTP(payload).then(function (result) {
            if (result && JSON.parse(result.response).ResendOTPApplicationResponse.ResendOTPApplication.isSmsSent) {
                var resentOTP = true;
                showOtpBox();
            } else if (result && result.ResendOTPApplicationResponse && ("errors" in result.ResendOTPApplicationResponse) && result.ResendOTPApplicationResponse.errors[0].code == 'INVALID_OTP_ID') {
                otpController.generateOTP();
            }else{
                errorTrack(globals.pageType,'otpResend',result)
            }
            if (isSet(element))
                $(element).hide();
        });
    }

    // General OTP Functions START Here

    // General function to generate OTP Either mobile or Email or both
    otpController.generateOTP = function (isMobileExist) {
        // GA for Mobile
        if (sellerMobile) {
            gaService.addEvent('link-RealestateResponsiveUI', page, 'submit_phone_number');
        }

        if (!sellerMobile && !sellerEmail) {
            return false;
        }

        var payload = {
            "email": (sellerEmail ? sellerEmail : ''),
            "mobile": (sellerMobile ? sellerMobile : ''),
            "firstName": (sellerName ? sellerName : ''),
            "source": source(),
            "page": page
        };

        if (isMobileExist) {
            dataService.anonymousUser(JSON.stringify(payload))
                .then(function (data) {
                    if (error == false) {
                        otpError = "";
                        otpSuccess = "Otp will be sent within 1 min";
                        generateOTPAjax(payload);
                    } else {
                        errorTrack(globals.pageType,'anonymousUser',data.data)
                        otpError = data.data;
                        otpSuccess = "";
                        return false;
                    }
                });
        } else {
            generateOTPAjax(payload);
        }
    };

    function generateOTPAjax(payload) {
        otpController.htmlMobileValidation();
        if (!isNaN(sellerMobile)) {
            otpService.generateOTP(payload).then(function (result) {
                otpClientId = result.clientId;
                result = JSON.parse(result.response);
                if (result && result.GenerateOTPApplicationResponse && result.GenerateOTPApplicationResponse.GenerateOTPApplication) {
                    otpId = result.GenerateOTPApplicationResponse.GenerateOTPApplication.otpId;
                    otpService.setOtpVariable(otpId, 'otpId');
                    otpObj.otpNumber = "";
                    showVerifiedSuccessSection = false;
                    otpService.setOtpVariable(false, 'showVerifiedSuccessSection');
                    showOtpBox();
                }else{
                    errorTrack(globals.pageType,'generateOTPAjax',result)
                }
            });
        }
    }

    function source() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent) ? 'MOBILE' : 'DESK';
    }

    otpController.htmlMobileValidation = function () {
        var numberArr = ["9", "8", "7"];
        if (sellerMobile && sellerMobile.length == 10 && numberArr.indexOf(sellerMobile.slice(1, 1))) {
            mobileValid = true;
            $(document).trigger("generateOTPTrue");
        }
    }

    function showOtpBox() {
        var $formBox = clickedEl.closest('.formBox');
        $formBox.addClass('otpOpen');

        $('#otpMsg').removeClass('hide');
		$('.otpalert').find('span').text(`OTP sent successfully to the ${sellerMobile}`);
        //$formBox.find('#otpNeeded').addClass('otpShow');
        // otpController.setOtpErrorClass(error.otpRequired);
    }

    otpController.hideOtpBox = function () {
        var $formBox = clickedEl.closest('.formBox');
        $formBox.removeClass('otpOpen');
        otpController.setOtpErrorClass();
    };

    // General OTP Functions END Here

    otpController.init = function (data, isMobileExist) {
        clickedEl = $(this);
        sellerEmail = data.sellerEmail;
        sellerMobile = data.sellerMobile;
        sellerName = data.sellerName;
        userdata = data.userdata;
        page = data.page;
        otpClientId = '';
        otpService.setOtpVariable(false, 'showVerifiedSuccessSection');
        otpService.setOtpVariable('', 'otpId');
        otpService.setOtpVariable('', 'otpNumber');
        userdata.errorLabel == 'MOBILE_ALREADY_REGISTERED' ? otpController.addMobileNumber() : otpController.generateOTP(isMobileExist);
    };

    otpController.enquiryPopUp = function () {
        var user = userdata || {};

        $('.clickToViewNumber').toggle();
        $('.vap-offer-by').toggle();
        otpMatchError = false;
        var otpVerifiedNo = getCookie('otp_verified_no');
        var showSuccessPopUp = false;
        showEnquiryPopUp = !showEnquiryPopUp;
        otpController.enquiry_phone = parseInt(otpVerifiedNo) || get("autofill_no");
        otpController.enquiry_name = get("autofill_contactname");
        otpController.enquiry_email = get("autofill_email");
        if (otpController.loggedIn) {
            if (user.mobile == otpVerifiedNo) {
                showSuccessPopUp = true;
            } else {
                otpController.enquiry_phone = parseInt(user.mobile);
                otpController.enquiry_email = user.email;
            }
        } else if (otpVerifiedNo && otpVerifiedNo.length > 0) {
            showSuccessPopUp = true;
        }

        if (showEnquiryPopUp) {
            gaService.addEvent('link-RealestateResponsiveUI', "VAP", 'get_phone_number');

            if (showSuccessPopUp) {
                showSubmitDetailsSection = false;
                showSubmitOTPSection = false;
                //$rootScope.$broadcast( 'otpVerified', $scope );
                showVerifiedSuccessSection = true;
                showOtpSuccessSection(otpVerifiedNo);
                hideSubmitDetailsSection();
                //dataService.sendSms(buildSmsPayload());
            } else {
                showSubmitDetailsSection = true;
                showSubmitOTPSection = false;
                showVerifiedSuccessSection = false;
                autoFillForm();
            }
        } else {
            // Removes the success box if OTP is already veriifed.
            $("#otp-success-box").hide();
        }
    };

    function autoFillForm() {
        if (isSet(otpController.enquiry_phone)) {
            $('input[name="enquiry_phone"]').val(otpController.enquiry_phone);
        }
        if (isSet(otpController.enquiry_email)) {
            $('input[name="enquiry_email"]').val(otpController.enquiry_email);
        }
        if (isSet(otpController.enquiry_name)) {
            $('input[name="enquiry_name"]').val(otpController.enquiry_name);
        }
    }

    otpController.enquirySubmit = function (obj) {
        deflateObj();
        clickedEl = $(obj);
        var enquiry_email = otpController.enquiry_email || '';
        var enquiry_phone = otpController.enquiry_phone || '';
        var enquiry_name = otpController.enquiry_name || '';

        if (!otpController.enquiry_phone) {
            return false;
        }

        if (otpController.enquiry_email && (otpController.enquiry_email.length < 3 || otpController.enquiry_email.length > 30)) {
            return false;
        }

        if(getCookie('prefer_city_id') != '28'){
            requirementService.addRequirement(buildLeadPayload());
        }
        //dataService.saveLead(buildLeadPayload());
        /*dataService.saveAutoFillData({
            'autofill_contactname': enquiry_name,
            'autofill_no': enquiry_phone,
            'autofill_email': enquiry_email
        });*/
        dataService.saveDatalocaStorage({
            'autofill_contactname': enquiry_name,
            'autofill_no': enquiry_phone,
            'autofill_email': enquiry_email
        });
        
        if (isSet(otpController.propertySnippet)) {
            createAlert();
        }

        gaService.addEvent('link-RealestateResponsiveUI', "VAP", 'submit_phone_number');

        var payload = {
            "email": "" + enquiry_email,
            "mobile": "" + enquiry_phone,
            "source": source(),
            "page": "VAP"
        }

        otpService.generateOTP(payload).then(function (result) {
            otpClientId = result.clientId
            result = JSON.parse(result.response);
            if (result && result && result.GenerateOTPApplicationResponse && result.GenerateOTPApplicationResponse.GenerateOTPApplication) {
                otpController.otpId = result.GenerateOTPApplicationResponse.GenerateOTPApplication.otpId;
                hideSubmitDetailsSection();
                showOtpSubmitSection();
            }
        });
    };

    function hideSubmitDetailsSection() {
        $('#submitDetailsSection').addClass('hide');
    };

    function showOtpSubmitSection() {
        $('#resendMobileNo').html(otpController.enquiry_phone);
        $('#otpSubmitSection').removeClass('hide');
    };

    function showOtpSuccessSection() {
       if(otpController.propertySnippet.pageName === 'VAP') {
            var userPayload = {
                "entityType":"listing",
                "entityId":otpController.propertySnippet.id,
                "phone":otpController.enquiry_phone,
                "email":otpController.enquiry_email
            };
            $.ajax({
                url: "/homes/re2/detectSpamUser?aj=1",
                type: "POST",
                data: userPayload,
                success: function(data) {
                    var res = JSON.parse(data);
                    if(res.data.mobile != null) {
                        $('#otpSubmitSection').addClass('hide');
                        $('#encryptedMobileNo').addClass('hide');
                        $('#otp-success-box .otpsuccessok #emailLink').html(res.data.email);
                        $('#otp-success-box .owner-number #telLink').html(res.data.mobile);
                        $('#otp-success-box').removeClass('hide');
                        $('.vap-interested-box .userType').html(getUserType());
                    }else {
                        $('#otpSubmitSection').addClass('hide');
                        $('#spam-detect-box #spamDetectMsg').html(res.data.message);
                        $('#spam-detect-box').removeClass('hide');

                    }
                }
            });
        } else {
            $('#otpSubmitSection').addClass('hide');
            $('#encryptedMobileNo').addClass('hide');
            $('#otp-success-box .otpsuccessok #emailLink').html(JSON.parse(atob(globals.sellerSnippet)).email);
            $('#otp-success-box .owner-number #telLink').html(JSON.parse(atob(globals.sellerSnippet)).mobileNo);
            $('#otp-success-box').removeClass('hide');
            $('.vap-interested-box .userType').html(getUserType());
        }
    }


    function buildSmsPayload() {
        var smsPayload = {
            "mobile": ["" + otpController.enquiry_phone],
            "sms_content": "User details for " + (otpController.propertySnippet.adTitle ? "Ad " + otpController.propertySnippet.adTitle : "") + "Email: " + JSON.parse(atob(globals.sellerSnippet)).email + " Contact Number:" + JSON.parse(atob(globals.sellerSnippet)).mobileNo,
            "smsFrom": "HighPriorityQueue",
            "notification_engine_sms_context": 23
        };

        return smsPayload;
    };

    function createAlert(){
      var cityId= (otpController.propertySnippet.cityId!=undefined)?otpController.propertySnippet.cityId:getCookie('prefer_city_id');
      var attributesMap = {"city":[otpController.propertySnippet.city],"area_Sq_Feet":[parseInt(otpController.propertySnippet.area)],"Price":[otpController.propertySnippet.price],"No_of_Rooms":[parseInt(otpController.propertySnippet.noOfBedrooms)]};
      var localityName = "";
      if(otpController.propertySnippet.locality[0] != undefined){
        localityName = [otpController.propertySnippet.locality[0]];
      }
        var details = getterSetter.get('loginData');
        if (details && details.data && details.data.user) {
           var details = getterSetter.get('loginData');
            if (!details.error) {
               otpController.loggedIn = true;
                otpController.userId = details.data.user.id;

            } 
        }
      var subcatid = getSubCategory.subCatId(otpController.propertySnippet.propertyFor,otpController.propertySnippet.type)||31;
      if(subcatid == 217){
          attributesMap["Type_of_land"] = [otpController.propertySnippet.category];
      }
      if(subcatid == 114 || subcatid == 138 && otpController.propertySnippet.attributes.accommodationFor){
          attributesMap["Accommodation_for"] = [otpController.propertySnippet.attributes.accommodationFor];
      }
      var payload = {
          "email":otpController.enquiry_email,
          "source":'CLICK_TO_VIEW_IN_LISTING',
          "mobile":otpController.enquiry_phone,
          "alertEntityType":0,
          "attributesMap":attributesMap,
          "cityId":cityId,
          "locations": localityName,
          "alertStatus":1,
          "alertCreateType":"implicit",
          "metaCatGlobalId":20,
          "subCatGlobalId":subcatid,
          "userId":otpController.userId,
          "alertType":"buy",
          "referrer":windowWidthService.PlatFormChannel,
          "useragent":window.navigator.userAgent.substring(0, 90)
      };
        $.ajax({
            url: "/homes/re2/createAlertNew",
            type: "POST",
            data: payload,
            success: function(data){
               if(!data){
                errorTrack(globals.pageType,'createAlertNew',data)
               } 
            }
        });
    }
    function buildLeadPayload() {
        var pSnippet = JSON.parse(atob(globals.propertySnippet));
        var builderSnippet = JSON.parse(atob(globals.builderSnippet));
        var leadPayload = {
            'userName': otpController.enquiry_name,
            'cityId': pSnippet ? pSnippet.cityId : getCookie("prefer_city_id"),
            'phone': otpController.enquiry_phone,
            'emailId': otpController.enquiry_email,
            "builderId": builderSnippet ? builderSnippet.id : '',
            'listingId': (otpController.propertySnippet ? otpController.propertySnippet.id : ''),
            'channel': windowWidthService.channel,
            'source': 'CLICK_TO_VIEW_IN_' + (otpController.individual && Object.keys(otpController.individual).length > 0 ? 'INDIVIDUAL' : 'LISTING')
        };
        requirementService.setTrafficGoalConversionParameters(leadPayload);
        if (otpController.propertySnippet.type || otpController.propertySnippet.propertyFor) leadPayload.subCategoryName = 'HN-' + otpController.propertySnippet.type + '-' + otpController.propertySnippet.propertyFor;
        if (otpController.propertySnippet.name) leadPayload.subCategoryServiceType = otpController.propertySnippet.name;

        return leadPayload;
    }

    function deflateObj() {
        var form = getFormObject($("form[name='enquiryForm']"));
        otpController.enquiry_phone = form.enquiry_phone;
        otpController.enquiry_email = form.enquiry_email;
        otpController.enquiry_name = form.enquiry_name;
        otpController.selectedIsdCode = $('.stdcode select').val();
    }

    otpController.otpSubmit = function () {
        deflateObj();
        var enquiry_phone = otpController.enquiry_phone || '';
        var enquiry_otp = otpService.getOtpVariable().otpNumber ? otpService.getOtpVariable().otpNumber : '';
        enquiry_otp = ('0000' + enquiry_otp).slice(-4);

        if (!isSet(enquiry_otp)) {
            return false;
        }

        gaService.addEvent('link-RealestateResponsiveUI', "VAP", 'submit_OTP');

        var payload = {
            "otpId": otpController.otpId,
            "otp": enquiry_otp,
            "source": source(),
            "page": "VAP",
            "otpClientId": otpClientId,
            "enquiry_phone": otpController.enquiry_phone || '',
            "adId": otpController.propertySnippet.id
        }
        otpService.verifyOTP(payload).then(function (result) {
            var result = result && result.response ? JSON.parse(result.response).VerifyAndDeleteOTPApplicationResponse : {};
           if (result) {
                if (result.VerifyAndDeleteOTPApplication.success) {
                    if(getCookie('prefer_city_id') === '28'){
                        requirementService.addRequirement(buildLeadPayload());
                    }
                     //dataService.sendSms(buildSmsPayload());
                     setCookieWithExpirationHours('otp_verified_no', enquiry_phone, 1);
                     showOtpSuccessSection();

                     //$rootScope.$broadcast( 'otpVerified', $scope );

                     gaService.addEvent('link-RealestateResponsiveUI', "VAP", 'OTP_success');
                     if ('T' == otpController.propertySnippet.adStyle[0]||'HT'== otpController.propertySnippet.adStyle[0]) {
                        // sendSmsToPoster();
                        // sendMailToPoster();
                     }
                } else {
                     otpMatchError = true;
                     otpController.setOtpErrorClass(result.VerifyAndDeleteOTPApplication.errors[0].code);
                     gaService.addEvent('link-RealestateResponsiveUI', "VAP", 'OTP_failure');
                }
            }
        });
    };

    function sendSmsToPoster() {
        var mob = otpController.sellerSnippet.mobileNo || null;
        var name = otpController.sellerSnippet.name || "";
        if (!mob || mob.toString().length < 10) return;
        var smsPayload = buildPosterSmsPayload(mob,name);
        if (smsPayload) dataService.sendSms(smsPayload);
    }

    function buildPosterSmsPayload(sendTo, senderName) {
        var sms_content = "Hello " + senderName + ",\n\nSomeone is interested in " + (isSet(globals.projectSnippet) && isSet(globals.projectSnippet.name) ? "Project " + globals.projectSnippet.name : "Ad " + otpController.propertySnippet.title) + " from RealEstate.\nPlease call +91" + otpController.enquiry_phone + " immediately.";
        var smsPayload = {
            "mobile": ["" + sendTo],
            "sms_content": sms_content,
            "smsFrom": "HighPriorityQueue",
            "notification_engine_sms_context": 23
        };

        return smsPayload;
    }

    function sendMailToPoster() {
        let propertySnippet = {};
        let sellerSnippet = JSON.parse(atob(globals.sellerSnippet))
        if (!isSet(sellerSnippet.email)) {
            return;
        }
        var redirectUrl = '';
        var mailPayload = '';
        var phone = '';
        var budget = otpController.propertySnippet.price ? otpController.propertySnippet.price :'';
        var lookingFor = '';
        var address = isSet(otpController.projectSnippet) ? otpController.projectSnippet.address : {};
        var unitType = "";

        if (otpController.propertySnippet.title) {
            lookingFor = otpController.propertySnippet.title;
        } else {
            lookingFor = isSet(otpController.projectSnippet) ? otpController.projectSnippet.name + " " : '';
            lookingFor += (address.locality ? address.locality : "") + (address.city ? " " + address.city : "");
            lookingFor = lookingFor.replace(/^\s+|\s+$/g, '');
        }

        redirectUrl = urlBuilderService.getHomesUrl() + 'listing/';
        propertySnippet.location = otpController.propertySnippet.locality[0];
        propertySnippet.sqft = otpController.propertySnippet.area;
        propertySnippet.bhk = otpController.propertySnippet.noOfBedrooms;
        propertySnippet.availableFor = otpController.propertySnippet.propertyFor;
        propertySnippet.postedBy = otpController.propertySnippet.userType;
        propertySnippet.propertyType = otpController.propertySnippet.type;
        propertySnippet.projectName = otpController.projectSnippet.name || '';
        redirectUrl += seoService.constructVapUrl(propertySnippet, 'listing');

        var mailObj = {
            "title": otpController.propertySnippet.title,
            "redirect": redirectUrl,
            "userName": otpController.enquiry_name  || "",
            "userMobile": otpController.enquiry_phone,
            "userEmail": otpController.enquiry_email || "",
            "time": buildDateTimeText(),
            "lookingFor": lookingFor,
            "budget": budget
        }

        phone = sellerSnippet.mobileNo;
        if (phone && phone.length > 10) phone = phone.slice(phone.length - 10);
        mailObj.toEmail = sellerSnippet.email || individual.email || '';
        mailObj.toName = sellerSnippet.name;

        if (!mailObj.toName) {
            mailObj.toName = individual.firstName || '';
            mailObj.toName += (individual.lastName) ? ' ' + individual.lastName : '';
        }

        mailObj.toPhone = sellerSnippet.mobileNo || individual.mobile;
        mailPayload = buildBuilderMailPayload(mailObj);

        if (mailPayload) dataService.sendMail(mailPayload);
    }


    var buildDateTimeText = function () {
        var date = new Date();
        var dateText = dateTimeService.getHours12Format(date);

        dateText += ':' + dateTimeService.getFormattedMins();
        dateText += dateTimeService.getAmPmText(date);
        dateText += ' ' + dateTimeService.getWeekDay(date);
        dateText += ' ' + dateTimeService.getDayWithPostscript(date);
        dateText += ' ' + dateTimeService.getMonthText(date);
        dateText += ' ' + date.getFullYear();
        return dateText;
    };

    function buildBuilderMailPayload(obj) {
        var payload = {
            "to": obj.toEmail,
            "emailFrom": "RegularQueue",
            "track": "yes",
            "notification_engine_email_context": 23,
            "from_address": "service@quikr.com",
            "from_name": "Quikr Service",
            "replyto": "service@quikr.com",
            "subject": "Good news! we have a lead for you",
            "body": mailService.getPropertyCampaignUserContent(obj)
        };

        return payload;
    }

    function getUserType() {
        if ('Individual' == otpController.userType) {
            otpController.userType = 'Owner';
        }
        return otpController.userType || 'Ad Poster';
    };
     $(document).on('change', "form[name='otpSubmitForm'] input[name='enquiry_otp']", otpController.setOtpNumber);
     $(document).on('keyup', "input[name='enquiry_otp']", otpController.setOtpNumber);
     $(document).on('renderRequirement', function(){
         
         // GA LeadForm for enrichmentForm Impression
         if(globals.pageType === 'VAP'){
             let propertySnippet = JSON.parse(atob(globals.propertySnippet));
             let prop_type = propertyType(propertySnippet.propertyFor);
             gaService.addEvent('RealEstate', 'QH_vap_' +prop_type , 'QH_vap_'+prop_type+'_leadForm_enrichmentForm_impression');
         }

        otpController.doRender();
     });

    return otpController;
}();
