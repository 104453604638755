export const otpService = function() {

    var factory = {};
    var otp = [];
    var genericFactory = function(uriFragment, payload) {
        var deferred = $.Deferred();
        var url = '/homes/re2/' + uriFragment;
        $.ajax({
            url: url,
            type: "POST",
            data : payload,
            success : function(data){
                deferred.resolve(data)
            },
            error : function(data){
               deferred.reject(data)
            }
        });
        return deferred.promise();
    }

    factory.generateOTP = function(payload) {
        return genericFactory('otp/generateOTP?aj=1&_t='+FTOKEN, payload);
    }

    factory.verifyOTP = function(payload) {
        return genericFactory('otp/verifyOTP?aj=1', payload);
    }

    factory.resendOTP = function(payload) {
        return genericFactory('otp/resendOTP?aj=1&_t='+FTOKEN, payload);
    }

    factory.addMobileNumber = function (payload) {
        return genericFactory('addMobile', payload);
    }

    factory.claimedUserMobile = function (payload) {
        return genericFactory('otp/verifyClaimedUserMobile?aj=1', payload);
    }

    factory.setOtpVariable = function (value, otpVariable) {
        otp[otpVariable] = value;
    }

    factory.getOtpVariable = function () {
        return otp;
    }

    return factory;

}();
