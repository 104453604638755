import {getterSetter} from '../utils/common'
require('../services/gaService.js')

var propertySnippet = globals.propertySnippet && JSON.parse(atob(globals.propertySnippet));
export var getTopBanks  = () => {

    return fetch('/homes/re2/getTopbanks?aj=1',{
        method: 'GET',
        credentials: 'include'
    }).then((res) => {
        return res.json()
    }).then((data) => {
        getterSetter.set( 'topBankData', data );
    });
}
getTopBanks();
export function similarListing(listingId,page_type,isGrabHouse=0) {
    return new Promise((resolve, reject)=> {
        const propertySnippet = globals.propertySnippet && JSON.parse(atob(globals.propertySnippet));
        var request = { 
            "seedType": "listing", 
            "entityType": "listing", 
            "numResults": "6",
            "sort": "true", 
            "horizontalId": listingId, 
            "adSource": "0", 
            "startLevel": "3", 
            "endLevel": "5" ,
            "city": propertySnippet && propertySnippet.city,
            "type": propertySnippet && propertySnippet.propertyFor,
            "isQAP": propertySnippet && propertySnippet.isQAP
        };
		if (propertySnippet && propertySnippet.availableFor && propertySnippet.availableFor === 'pg' && (propertySnippet.isQAP || propertySnippet.isGrabHouse)) {
			gaService.addEvent('VAP_Real-QuikrPG_Assured', 'VAP_Real-QuikrPG_Assured_similar-listings', 'VAP_Real-QuikrPG_Assured_similar-listings_init');
		}
        $.ajax({
            url: "/homes/re2/similarAd/"+page_type+"?aj=1&isGrabHouse="+isGrabHouse,
            method : "POST",
            data: request,
            beforeSend: function(request) { request.setRequestHeader("Accept", isWebpSupport); },
            success: function(res) {
                if(res.length > 1){
                    if(page_type=='Thankyou_page'){
                        $("#commonpopup #similarListing").html(res);
                        if($('#commonpopup #similarListing').hasClass("hide")){
                            $('#commonpopup #similarListing').removeClass("hide");
                        }
                    }else{
                        if (propertySnippet && propertySnippet.availableFor && propertySnippet.availableFor === 'pg' && (propertySnippet.isQAP || propertySnippet.isGrabHouse)) {
                            gaService.addEvent('VAP_Real-QuikrPG_Assured', 'VAP_Real-QuikrPG_Assured_similar-listings', 'VAP_Real-QuikrPG_Assured_similar-listings_success');
                        }
                        $("#projectSimilarSearch").html(res); 
                        if (res.trim() === '') {
                            return resolve('notshowlisting');
                        }
                    }
                    return resolve('showlisting');
                }else{
                    return resolve('error');
                }               
            }
        });
    })       
}

