require('./gaService.js')
var propertySnippet = ((globals.propertySnippet) ? JSON.parse(atob(globals.propertySnippet )) : '');
var propertyFor = function(){
    if(propertySnippet.propertyFor){
    	switch (propertySnippet.propertyFor.toLowerCase()) {
            case 'sale':
            case 'buy':
                propertyFor = "buy";
                break;

            case 'rent':
                propertyFor = "rent";
                break;
            case 'pg':
            case 'flatmate':
                propertyFor = "pg";
                break;

            default:
                propertyFor = '';
        }
        return propertyFor;
    }
}

if(globals.pageType == 'VAP') {
    var gapropertyFor = propertyFor();
}
export const  vapContactAbRand = ()=>{
    gaService.addEvent('RealEstate','leadForm_click','contact_init_'+gapropertyFor+'_vap_v2'); 
}

export const  vapSubmitContactAbRand = ()=>{
    gaService.addEvent('RealEstate','leadForm_click','contact_submit_'+gapropertyFor+'_vap_v2'); 
}

export const  vapClickViewAbRand = ()=>{
	gaService.addEvent('RealEstate','leadForm_click','view_init_'+gapropertyFor+'_vap_v2'); 
}

export const  vapClickViewSubmitAbRand = ()=>{
	gaService.addEvent('RealEstate','leadForm_click','view_submit_'+gapropertyFor+'_vap_v2');
}

export const  pageViewAbRandGA = ()=>{
    gaService.addPageView('VAP', 'RealEstate', 'vap_'+ gapropertyFor + '_v2');
}

export const  premiumAbRandGA = (label)=>{
    gaService.addEvent('RealEstate', 'premiumAd_click', label+'_'+gapropertyFor+'_vap_v2');
}

export const extProduct = ()=>{
    gaService.addEvent('RealEstate', 'extProduct_click', 'realDocs_'+gapropertyFor+'_vap_v2');
}
