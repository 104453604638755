import {isSet,source,getterSetter,getTime,dayDifference,getutmData,propertyType,connectionCheck,jsUcfirst,isEmpty,getUrlVars, getUnitConfigurations, getSiteVisitTimeSlots, validateEmail} from '../utils/common'
import {otpService} from '../services/otpService'
import {errorTrack} from '../utils/errorTrack'
import {login} from '../loginService'
import {updateSessionFunc} from './userconflict'
import {requirementPayload, nriRequirementPayload, homeloanAssistPayload} from './payloadBuilder'
import {viewNumberSucess,contactSucess} from './replytemplate'
import {gtmAdContactButton,eventTriggerQH,eventTriggeranalatyic, eventTriggerQproject} from '../services/gtmService'
import {getContactSuccessEvents,getEventId,getContactSubCategory} from '../services/gtmEvents'
import {getCookie,setCookieWithExpirationHours,setCookie} from '../services/cookieService'
import {requirementService} from '../services/requirementService';
import {similarListing} from '../services/contactService';
import {vapSubmitContactAbRand,vapClickViewSubmitAbRand} from '../services/vapAbRand';
import {gaLeadform} from '../services/gaLeadFormService';

require('../services/gaService')
window.replyFormController = {};

export function generateOTP() {
	if (window.location.href.indexOf('/qproject/') > 0) {
		let adDetails = getterSetter.get('adDetails');
        gaLeadform('qh_lead_submit_success');
        var gtmData = {
			'event':'form_submit_success',
			'eventCategory': 'formsubmit',
			'HL_intent': getterSetter.get('loanRequirement') ? 'Y':'N',
			'eventAction': Number(adDetails.id)
		}
        eventTriggerQproject(gtmData);
    }else{
    	if(connectionCheck()){
    		var gtmData = {
				'event':'form_submit_success',
				'eventCategory': 'formsubmit',
				'HL_intent': getterSetter.get('loanRequirement') ? 'Y':'N'
			}
        	eventTriggerQH(gtmData);
    	}
    }
	let formViewTypeWrap = '#commonpopup';
	if(getterSetter.get('formViewType') === 'static') {
		formViewTypeWrap = '#static_form_wrap';
	}

	var payload = {
		"email": (getterSetter.get('email') ? getterSetter.get('email') : ''),
		"firstName": (getterSetter.get('name') ? getterSetter.get('name') : ''),
		"mobile": (getterSetter.get('mobile') ?  getterSetter.get('mobile') : ''),
		"source": source(),
		"page": 'REPLY'
	};

	// Prepend the phone code to the mobile number
	if (getterSetter.get('isNRIMobile')) {
		payload.mobile = getterSetter.get('phonecode') + payload.mobile;
	}
	//htmlMobileValidation();
	otpService.generateOTP(payload).then(function(result) {
		otpService.setOtpVariable(result.clientId, 'otpClientId');
		result = JSON.parse(result.response);
		const otpResponse = result ? result.GenerateOTPApplicationResponse : {};
		if (otpResponse.GenerateOTPApplication) {
			let OtpId = otpResponse.GenerateOTPApplication.otpId;
			otpService.setOtpVariable(OtpId, 'otpId');

			// Show the user an alert that the OTP has been sent
			getterSetter.set('errMsg', `OTP sent on +${getterSetter.get('phonecode') + getterSetter.get('mobile')}`); 
			setAlert();

			// Unhide the OTP field
			let replyOTPShow = formViewTypeWrap + " #replyotpshow";
			document.querySelector(replyOTPShow).classList.add('otp')
		

			// Detect Spamer and Otp Not Verified Requirement..
			detectSpamUser().then((data)=> {
				var res = JSON.parse(data);
				if(res.statusCode === 200) {
					if(res.data == null ||  getterSetter.get('reqSource') === 'CLICK_TO_VIEW_IN_LISTING') {
						getterSetter.set('detectSpamUser',false);
						if(getterSetter.get('reqSource') !== 'IM_INTERESTED_IN_BUILDER'){
							if (getterSetter.get('isNRIMobile')) {
								requirementService.addNRIRequirement(nriRequirementPayload());
								
								// Push the requrement to dialer API
								replyFormController.sendNRIRequirementToDialer();

								// Trigger GA event
								gaService.addEvent('RealEstate', 'QH_leadForm_countrycode', 'QH_leadForm_countrycode_init');
							} else {
								requirementService.addRequirement(requirementPayload());
							}
						}
					} else if(getterSetter.get('reqSource') !== 'CLICK_TO_VIEW_IN_LISTING'){
						if(getterSetter.get('reqSource') !== 'IM_INTERESTED_IN_BUILDER'){
							if (getterSetter.get('isNRIMobile')) {
								requirementService.addNRIRequirement(nriRequirementPayload());
								
								// Push the requrement to dialer API
								replyFormController.sendNRIRequirementToDialer();

								// Trigger GA event
								gaService.addEvent('RealEstate', 'QH_leadForm_countrycode', 'QH_leadForm_countrycode_init');
							} else {
								requirementService.addRequirement(requirementPayload());
							}
						}
					}
					else {
						getterSetter.set('errMsg',res.data.message) 
						setAlert();
					}
				}
			});

			$(formViewTypeWrap +" #submitEnquiry").css({"display": "none"});
			$(formViewTypeWrap + " #loginSubmitEnquiry").css({"display": "block"});
		} else {
			const isMobileInvalid = otpResponse.errors && otpResponse.errors.findIndex((error) => 'MOBILE_INVALID' === error.code) >= 0;
			getterSetter.set('errMsg', isMobileInvalid ? 'Invalid mobile number' : 'Could not sent OTP'); 
			setAlert();
		}
	});

	let OTPEl = formViewTypeWrap + ' #otp';
	let otpinput = document.querySelector(OTPEl);
	otpinput.addEventListener('keyup', (event) => {
		otpService.setOtpVariable(event.target.value,'otpNumber')
	})

	let resentEL = formViewTypeWrap + ' #resend';
	document.querySelector(resentEL).addEventListener('click', (event) => {
		var resendPayload = {
	        "otpId": otpService.getOtpVariable().otpId,
	        "otpClientId":otpService.getOtpVariable().otpClientId ? otpService.getOtpVariable().otpClientId : '',
	        "source": source(),
	        "page": "REPLY"
	    }

	    otpService.resendOTP(resendPayload).then(function (result) {
	    	otpinput.value = '';
			document.querySelector('.error-container-otp').classList.add('hide');
			getterSetter.set('errMsg',`OTP resent on +${getterSetter.get('phonecode') + getterSetter.get('mobile')}`);
			setAlert();
	        if (result && result.ResendOTPApplicationResponse && result.ResendOTPApplicationResponse.ResendOTPApplication) {
	        }
	    });
	});


	//Second time mobile number Edit Handle starts here..
	document.querySelector(formViewTypeWrap + " div.isdcode input").addEventListener('keyup', function(event) {
		let submitEnqBtnId = formViewTypeWrap + ' #submitEnquiry';
		let loginSubmitEnqBtnId = formViewTypeWrap + ' #loginSubmitEnquiry';
		let submitEnqBtn = document.querySelector(submitEnqBtnId);
		let loginSubmitEnqBtn = document.querySelector(loginSubmitEnqBtnId);
		
		if(submitEnqBtn && loginSubmitEnqBtn) {
	        if(loginSubmitEnqBtn.style.display == 'block') {
	            submitEnqBtn.style.display = 'block';
	            loginSubmitEnqBtn.style.display = 'none';
	            let otpshowId = formViewTypeWrap + " #replyotpshow";
	            let alertboxmsg = formViewTypeWrap + " #alertmsgbox";
				document.querySelector(otpshowId).classList.remove('otp');
				document.querySelector(alertboxmsg).classList.add('hide');
				otpService.setOtpVariable('', 'otpId');
				getterSetter.set('mobile','');
				getterSetter.set('errMsg', '');
	        }
	    } 
	});
	
}
export function verifyOTP(){
	var otpData = otpService.getOtpVariable();
	var payload = {
			"otpId": otpData.otpId,
			"otp": otpData.otpNumber,
			"source": source(),
			"page": "REPLY",
			"otpClientId": otpData.otpClientId,
			"enquiry_phone": getterSetter.get('mobile') || '',
			"adId": getterSetter.get('adDetails').id
		}
		otpService.verifyOTP(payload).then(function (result) {
			var result = result && result.response ? JSON.parse(result.response).VerifyAndDeleteOTPApplicationResponse : {};
			if (result) {
				if (result.VerifyAndDeleteOTPApplication.success) {
					getterSetter.set('otpFlag',true);
					if (!getterSetter.get('isNRIMobile')) {
						requirementService.addRequirement(requirementPayload());
					} else {
						requirementService.addNRIRequirement(nriRequirementPayload());
					}
					setCookieWithExpirationHours('otp_verified_no', getterSetter.get('mobile'), 1);
					showOtpSuccessSection();
					gaService.addEvent('link-RealestateResponsiveUI', "VAP", 'OTP_success');
				}else {
					getterSetter.set('errMsg',result.VerifyAndDeleteOTPApplication.errors[0].code)
					setAlert()
					gaService.addEvent('link-RealestateResponsiveUI', "VAP", 'OTP_failure');
				}
			}
		})
}

export function showOtpSuccessSection() {
	let formViewTypeWrap = '#commonpopup';
	if(getterSetter.get('formViewType') === 'static') {
		formViewTypeWrap = '#static_form_wrap';
	}
	let replyContainer = formViewTypeWrap + ' #reply_Container';
	let successDiv = formViewTypeWrap + ' #showSucess';

	if(getterSetter.get('formType') == 'broker'){
		showSimilar();
	}

	// Hide tthe OTP field if there
	const otpField = document.querySelector(formViewTypeWrap + ' #replyotpshow');
	if (otpField) {
		otpField.classList.remove('otp');
	}

	if(globals.pageType === 'VAP') {
		detectSpamUser().then((data)=> {
			let flag = 0;
			if(globals.pageType == 'VAP' && getterSetter.get('reqSource') == 'CLICK_TO_VIEW_IN_LISTING') {
				flag = 1;
		    	gaLeadform('viewNumber_submit_click');
		    	vapClickViewSubmitAbRand();
		    }
			var res = JSON.parse(data);
			if(res.statusCode === 200) {
				if(res.success) {
					getCustomerType(getterSetter.get('mobile'), getterSetter.get('email')).then((customerType)=> {
						if (customerType && (customerType === 'broker' || customerType === 'builder')) {
							document.querySelector(replyContainer).classList.add('thnkup');
							var thnk_user_type = (getterSetter.get('adDetails').userType) ? getterSetter.get('adDetails').userType.toLowerCase() : 'owner';
							thnk_user_type = (thnk_user_type === 'individual') ? 'owner' : thnk_user_type;
							document.querySelector(successDiv).innerHTML = contactSucess(thnk_user_type);
							getCCRdetails('contact');
						} else {
								if(globals.pageType == 'VAP' && getterSetter.get('reqSource') == 'CLICK_TO_VIEW_IN_LISTING') {
								clickToviewNumberHandling(res.data);
								}		
								else {
									document.querySelector(replyContainer).classList.add('thnkup');
									document.querySelector(successDiv).innerHTML = viewNumberSucess(res.data);
									getCCRdetails('clicktoviewnumber');
								}	
							}
					}).catch(() => {
						getterSetter.set('errMsg', 'Something Went Wrong. Please Try Again');
						setAlert();
					})
				}else {
					getterSetter.set('errMsg',res.data.message)
					setAlert();
				}
			}
		})
	}
}


export function clickToViewNumberPaymentSuccess() {
getterSetter.set('reqSource', 'CLICK_TO_VIEW_IN_LISTING');
if(globals.pageType === 'VAP') {
	detectSpamUser().then((data)=> {
	var res = JSON.parse(data);
	if(res.statusCode === 200) {
		if(res.success) {
			if(globals.pageType == 'VAP' && getterSetter.get('reqSource') == 'CLICK_TO_VIEW_IN_LISTING') 
			{
				document.getElementById('paymentSuccessOwnerDetails').innerHTML = viewNumberSucess(res.data);
			}		
		}
	}
	});
}
}

/**
 * Capping Logic For click to View
 * @param {Object} res 
 */
function clickToviewNumberHandling(data) {
	let formViewTypeWrap = '#commonpopup';
	let replyContainer = formViewTypeWrap + ' #reply_Container';
	let successDiv = formViewTypeWrap + ' #showSucess';
	
	if (data.redirectToPayment != null && data.mobile == null) {
		document.querySelector(replyContainer).classList.add('thnkup');
		cliclToViewNumberIntiatePayment(data.redirectToPayment,data.individualViewId);
	}
	else if (data.redirectToPayment == null && data.mobile == null) {
		getterSetter.set('errMsg',data.message)
		setAlert();
	}
	else {
		document.querySelector(replyContainer).classList.add('thnkup');
		document.querySelector(successDiv).innerHTML = viewNumberSucess(data);
		getCCRdetails('clicktoviewnumber');
	}
}

/**
 * Function to intiate payment links depending upon the user requirments
 */
function cliclToViewNumberIntiatePayment(redirectionToPayment,individualViewId) {
	
	let attributesForPaymentSelect =  {
		"rentalOption":false,
		"purchaseOption":false,
	};
	let redirection = redirectionToPayment.split(",");
	if(redirection.includes("rental")) {
		attributesForPaymentSelect["rentalOption"] = true;
	}
	if(redirection.includes("purchase")) {
		attributesForPaymentSelect["purchaseOption"] = true;
	}
	$('#commonpopup').modal('hide');
	const modalPaymentTpl = _.template(document.getElementById('paymentOptionTemplate').innerHTML);
	document.getElementById('contactseeker').innerHTML = modalPaymentTpl({attributesForPaymentSelect});
	$('#contactseeker').modal('show');
	prePaymentComponent.individualViewId = individualViewId;
	prePaymentComponent.init();
	getCCRdetails('contact');
}



export function handleApiErr(replyDetails) {
	var otpData = otpService.getOtpVariable();
	let errorLabel = getterSetter.get('errorLabel');
	if (errorLabel == 'MOBILE_ALREADY_REGISTERED') {
		if (otpData.showVerifiedSuccessSection) {
			submitReply(replyDetails);
		}
	} else if (errorLabel == 'EMAIL_ALREADY_REGISTERED') {
		submitReply(replyDetails);
	} else {
		if (errorLabel == 'EXISTING_USER_EMAIL_VERIFIED_LOGIN_NEEDED') {
			var jsonData = {
				"numEmail": getterSetter.get('email'),
				"password": getterSetter.get('password'),
				"otpCheck": 0
			};
		} else {
			if (replyDetails.pwdCheck) {
				var jsonData = {
					"numEmail": getterSetter.get('mobile'),
					"password": getterSetter.get('password'),
					"otpCheck": 0
				};
			} else {
				var jsonData = {
					"GreetingName": getterSetter.get('mobile'),
					"password": '',
					"otpRequest": 1,
					"otpNumber" : otpData.otpNumber,
					"otpId": otpData.otpId,
					"page": 'REPLY',
					"source":source()
				};
			}
		}
		getterSetter.get('loginstatus') ? submitReply(replyDetails) : submitReplyAfterLogin(jsonData,replyDetails);			
	}
}
export function submitReplyAfterLogin(jsonData,replyDetails) {
	gaService.addEvent('Button-RealestateResponsiveUI', 'Mobile_As_Login', globals.pageType + "_submit_OTP");
	login(jsonData).then((result) => {
		if (result.error == false && result.status == 'AUTH_SUCCESS') {
			//$(document).trigger("loginEvent", result.data);
			if(result && result.data && result.data.user) {
				getterSetter.set('loginData', result);
				getterSetter.set('userId', result.data.user.id);
				getterSetter.set('loginstatus', true);
				getterSetter.set('otpFlag',true);
				getterSetter.set('name', setLoginName(result.data.user));
				if (!getterSetter.get('isNRIMobile')) {
					requirementService.addRequirement(requirementPayload());
				}
			} else {
				getterSetter.set( 'loginstatus', false);
			}
			if ('undefined' !== typeof PUB_SUB) {
				PUB_SUB.emit('do_login', {name: getterSetter.get('name')});
			}
			if ("userEmail" in result.data.user) {
				var emailList = result.data.user.userEmail;
				for (var i = 0; i < emailList.length; i++) {
					if (getterSetter.get('email') == emailList[i].emailId) {
						getterSetter.set('email',emailList[i].emailId)
						getterSetter.set ('emailMatchInAcc',true)
					}
				}
			}
			if (!getterSetter.get('emailMatchInAcc')) {
				let email  = emailList.length > 0 ? emailList[0].emailId : getterSetter.get('email')
				 getterSetter.set('email',email)
			}

			if ("userMobile" in result.data.user) {
				var mobileList = result.data.user.userMobile;
				for (var i = 0; i < mobileList.length; i++) {
					if (getterSetter.get('mobile') == mobileList[i].mobileNumber) {
						getterSetter.set('mobile',mobileList[i].mobileNumber) 
						getterSetter.set ('mobileMatchInAcc',true)
					}
				}
			}
			if (!getterSetter.get('mobileMatchInAcc') && mobileList) {
				let mobile = mobileList.length > 0 ? mobileList[0].mobileNumber : getterSetter.get('mobile')
				 getterSetter.set('mobile',mobile)
			}

			gaService.addEvent('link-RealestateResponsiveUI', 'Mobile_As_Login', globals.pageType + "_OTP_Success");

			submitReply(replyDetails);

		}
		else {
			errorTrack(globals.pageType,'otpVerifylogin',result.status,'LISTING_CONTACT')
			gaService.addEvent('link-RealestateResponsiveUI', 'Mobile_As_Login', globals.pageType + "_OTP_Failure");
			
			const selWrapper = (getterSetter.get('formViewType') === 'static') ? '#static_form_wrap' : '#commonpopup';
			const elContactForm = document.querySelector(selWrapper);
			const elAlertMessageBox = elContactForm.querySelector('#alertmsgbox');

			elAlertMessageBox.classList.add('hide');
			elContactForm.querySelector('.error-container-otp').classList.remove('hide');
		}
	});
}
export function submitReply(replyDetails) {
	saveAdReply();

	// GA for Lead Forms Submit
	var reqSource = getterSetter.get('reqSource');

	var contactSubmit = ['CONTACT_IN_LISTING_FLP', 'REQUIREMENT_POPUP_FLP' , 'CONTACT_INDIVIDUAL_BROKER', 'PROJECT_CONTACT_IN_BUILDER', 'IM_INTERESTED_IN_PROJECT', 'IM_INTERESTED_IN_QPROJECT'];
	var snbPageContact = ['CONTACT_IN_LISTING_SNB', 'PROJECT_CONTACT_IN_SNB' , 'CONTACT_SNB_BROKER'];
	var localityPage = ['CONTACT_IN_LISTING_LOCALITY', 'PROJECT_CONTACT_IN_LOCALITY', 'CONTACT_BROKER_IN_LOCALITY'];

	var viewNumber = 'CLICK_TO_VIEW_IN_LISTING';
	var similarContact = 'CONTACT_IN_LISTING_SIMILAR';
	var projectUnit = ['PROJECT_UNIT_CONTACT_IN_PROJECT', 'PROJECT_UNIT_CONTACT_IN_QPROJECT', 'PROJECT_CONTACT_IN_PROJECT', 'REQUIREMENT_POPUP_PROJECT', 'PROJECT_CONTACT_IN_QPROJECT'];
	var individualListing = 'CONTACT_IN_LISTING_INDIVIDUAL';

	var QAPPage = ['QAP_BOOK_VISIT_SNB', 'QAP_BOOK_VISIT_VAP'];

	if (contactSubmit.includes(reqSource) || snbPageContact.includes(reqSource) || localityPage.includes(reqSource)) {
		gaLeadform('contact_submit_click');
	}else if (reqSource == similarContact) {
		gaLeadform('similar_contact_submit_click');
	}else if(projectUnit.includes(reqSource) ){
		gaLeadform('contact_unit_submit_click');
	}else if(reqSource == individualListing){
		gaLeadform('listing_contact_submit_click');
	}else if(QAPPage.includes(reqSource)){
		gaLeadform('qapBookVisit_submit_click');
	}




	if (getterSetter.get('errorLabel') == "EMAIL_ALREADY_REGISTERED") {
		updateSessionFunc();
	}
	var gtmevent = (globals.pageType === 'LOCALITY')?getContactSuccessEvents(getterSetter.get('localityReqSource')):getContactSuccessEvents(getterSetter.get('reqSource'));
	var id_type = (globals.pageType === 'LOCALITY')?getEventId(getterSetter.get('localityReqSource')):getEventId(getterSetter.get('reqSource'));
	var gtmSubcat = (globals.pageType === 'LOCALITY')?getContactSubCategory(getterSetter.get('localityReqSource'),getterSetter.get('adDetails')):getContactSubCategory(getterSetter.get('reqSource'),getterSetter.get('adDetails'));
	var gtmData = {
		'event':gtmevent,
		'mobile': getterSetter.get('mobile'),
		'email': getterSetter.get('email'),
		'SubCategory_Name':gtmSubcat
	};
	gtmData[id_type] = getterSetter.get('adDetails').id;
	var utmResult = getutmData();
	gtmData['utm_campaign'] = utmResult['utm_campaign'];
	gtmData['utm_medium'] = utmResult['utm_medium'];
	gtmData['utm_source'] = utmResult['utm_source'];
	gtmData['HL_intent'] = getterSetter.get('loanRequirement') ? 'Y':'N';
	gtmData['user_db_id'] = getCookie('__u');
	if(typeof getterSetter.get('adDetails').price !== 'undefined') {
		gtmData['price'] = getterSetter.get('adDetails').price;
	}

	if(connectionCheck()){
        eventTriggerQH(gtmData);
    } else {
        eventTriggeranalatyic(gtmData);
    }

    //GA Event for IM INTERESTED
    if(getterSetter.get('reqSource') === 'IM_INTERESTED_IN_LISTING') {
    	let intent = 'buy';
    	if(window.location.href.indexOf("-pg-") > -1) {
    		intent = 'pg';
    	} 
    	if(window.location.href.indexOf("-rent-") > -1) {
    		intent = 'rent';
    	}
    	gaService.addEvent('RealEstate', "QH_vap_"+intent, "QH_vap_"+intent+"_leadForm_iAmInterested_submit_click");
    } 
    if(getterSetter.get('reqSource') === 'IM_INTERESTED_IN_PROJECT') {
    	gaService.addEvent('RealEstate', "QH_project_buy", "QH_vap_project_leadForm_iAmInterested_submit_click");
    } 
	// fire GA Event for the AlertMail Submit
	if (globals.pageType === 'VAP' && getterSetter.get('alertMail')) {
		var propertySnippet = JSON.parse(atob(globals.propertySnippet));
		var txnType = propertyType(propertySnippet.propertyFor || propertySnippet.availableFor);
		var action = 'QH_vap_' + txnType;
		var label = action + '_leadForm_alertMail_contact_submit_click';
		gaService.addEvent('RealEstate', action, label);
	}

	if(getterSetter.get('adDetails').isGrabHouse == 1){
		gtmAdContactButton('REPLY_SUCCESS_QHMR_'+globals.pageType, getterSetter.get('adDetails').id, {'emailId':getterSetter.get('email'),'phone':getterSetter.get('mobile')});
	}
	if(getterSetter.get('detectSpamUser') && !getterSetter.get('isNRIMobile')){
		detectSpamUser().then((data)=> {
			var res = JSON.parse(data);
				if(res.data == null || getterSetter.get('reqSource') === 'CLICK_TO_VIEW_IN_LISTING') {
					if(getterSetter.get('reqSource') !== 'IM_INTERESTED_IN_BUILDER') {
						if(replyDetails.counter == 0){
							requirementService.addRequirement(requirementPayload());
							replyDetails.counter++;
						}
					}
					saveAdReply();
					replySuccess();
				}
				else if(getterSetter.get('reqSource') !== 'CLICK_TO_VIEW_IN_LISTING'){
					if(getterSetter.get('reqSource') !== 'IM_INTERESTED_IN_BUILDER') {
						if(replyDetails.counter == 0){
							requirementService.addRequirement(requirementPayload());
							replyDetails.counter++;
						}
					}
					saveAdReply();
					replySuccess();
				}
				else {
					getterSetter.set('errMsg',res.data.message) 
					setAlert();
				}
		});
	} else if (getterSetter.get('isNRIMobile')) {
		requirementService.addNRIRequirement(nriRequirementPayload());
		replySuccess();
	} else {
		saveAdReply();
		replySuccess();
	}
}

export const replySuccess = function (respdata) {
	var thnk_user_type = (getterSetter.get('adDetails').userType)?getterSetter.get('adDetails').userType.toLowerCase():'owner';
	thnk_user_type = (thnk_user_type === 'individual') ? 'owner' : thnk_user_type;

	if (window.location.href.indexOf('/qproject/') > 0) {
		let adDetails = getterSetter.get('adDetails');
        gaLeadform('qh_otp_submit_success');
        var gtmData = {
			'event':'otp_submit_success',
			'eventCategory': 'formsubmit',
			'eventAction': Number(adDetails.id)
		}
        eventTriggerQproject(gtmData);
    }
    
	let formViewTypeWrap = '#commonpopup';
	if(getterSetter.get('formViewType') === 'static') {
		formViewTypeWrap = '#static_form_wrap';
	}

	let replyContainer = formViewTypeWrap + ' #reply_Container';
	let successDiv = formViewTypeWrap + ' #showSucess';
	let payload = {"test": "test"};

	switch (getterSetter.get('reqSource')) {
		case "CLICK_TO_VIEW_IN_LISTING": 
			showOtpSuccessSection();
			break;
		case "HP_FEATURED_PROJECT":
		case "HP_PROJECT_BACKDROP":
			document.querySelector(replyContainer).classList.add('thnkup')
			document.querySelector(successDiv).innerHTML = contactSucess(thnk_user_type);
			getCCRdetails('contact');
			break;
		case "PROJECT_CONTACT_IN_PROJECT":
		case "REQUIREMENT_POPUP_PROJECT":
		case "PROJECT_UNIT_CONTACT_IN_PROJECT":
			const elProjectContactStatic = document.querySelector('#static_form_wrap');

			// MSite doesn't have a 'Im Interested' section
			if (elProjectContactStatic) {
				// Populate the "I'm interested form" if not already
				const elDropdownMobile = elProjectContactStatic.querySelector('#selectMobile');
				if (!elDropdownMobile) {
					const elInputName = elProjectContactStatic.querySelector('[name=name]');
					const elInputEmail = elProjectContactStatic.querySelector('[name=email]');
					const elInputMobile = elProjectContactStatic.querySelector('[name=mobile]');
		
					// Populate the form with the user details
					elInputName.value = validateEmail(getterSetter.get('name')) ? '' : getterSetter.get('name');
					elInputName.parentElement.classList.add('open');
	
					elInputEmail.value = getterSetter.get('email');
					elInputEmail.parentElement.classList.add('open');
	
					elInputMobile.value = getterSetter.get('mobile');
					elInputMobile.parentElement.classList.add('open');
				}
			}
			document.querySelector(replyContainer).classList.add('thnkup')
			document.querySelector(successDiv).innerHTML = contactSucess(thnk_user_type);
			getCCRdetails('contact');
			// Don't break on this case as the next set of instructions is required

		case "CONTACT_IN_LISTING_PROJECT":
			document.querySelector(replyContainer).classList.add('thnkup')
			document.querySelector(successDiv).innerHTML = contactSucess(thnk_user_type);
			if(getterSetter.get('downloadBrochure')&& globals.brochureUrl){
				window.open(globals.brochureUrl, '_blank');
			}
			getCCRdetails('contact');
			break;
		case "QAP_BOOK_VISIT_SNB":
		case "QAP_BOOK_VISIT_VAP":
			thnk_user_type = 'Quikr Assured team';
			document.querySelector(replyContainer).classList.add('thnkup')
			document.querySelector(successDiv).innerHTML = contactSucess(thnk_user_type);
			getCCRdetails('contact');
			break;
		default:
			let homeloanAssistDiv = successDiv + ' #loanDetails';
			let elhomeLoanAssist;
			document.querySelector(replyContainer).classList.add('thnkup')
			if(getterSetter.get('formViewType') === 'static') {
				document.querySelector(successDiv).innerHTML = contactSucess(thnk_user_type);
				elhomeLoanAssist = document.querySelector(homeloanAssistDiv);
				if (getterSetter.get('loanRequirement') && elhomeLoanAssist) {
					elhomeLoanAssist.classList.remove('hide');
					document.getElementById("thanksMsg").innerHTML = "Thank You. We are here to assist you on home loan";
				}
				getCCRdetails('contact');
			} else {
				similarListing(getterSetter.get('adDetails').id,'Thankyou_page').then((data)=>{
					if(data === 'error'){
						document.querySelector(successDiv).innerHTML = contactSucess(thnk_user_type);
						getCCRdetails('contact');
					} else {
						showSimilar()
						homeloanAssistDiv = formViewTypeWrap + " #GetInTouch #loanDetails";
					}
					elhomeLoanAssist = document.querySelector(homeloanAssistDiv);
					let isListingSNB = intentMapping(getterSetter.get('adDetails')) === 'buy';
					if (getterSetter.get('loanRequirement') && elhomeLoanAssist && isListingSNB) {
						elhomeLoanAssist.classList.remove('hide');
						document.getElementById("thanksMsg").innerHTML = "Thank You. We are here to assist you on home loan";
					}
				})
				if(globals.pageType == 'VAP' || globals.pageType == 'project'){
					timeStamp();
				}
			}
	}
};

export const QrThankMsg = ()=>{
	reply_Container.classList.add('thnkup')
	showSucess.innerHTML = contactSucess('Owner');

	var gtmevent = getContactSuccessEvents(getterSetter.get('reqSource'));
	var id_type = getEventId(getterSetter.get('reqSource'));
	var gtmSubcat = getContactSubCategory(getterSetter.get('reqSource'),getterSetter.get('adDetails'));
	var gtmData = {
		'event':gtmevent,
		'mobile': getterSetter.get('mobile'),
		'email': getterSetter.get('email'),
		'SubCategory_Name':gtmSubcat
	};
	gtmData[id_type] = getterSetter.get('adDetails').id;
	var utmResult = getutmData();
	gtmData['utm_campaign'] = utmResult['utm_campaign'];
	gtmData['utm_medium'] = utmResult['utm_medium'];
	gtmData['utm_source'] = utmResult['utm_source'];
	gtmData['HL_intent'] = getterSetter.get('loanRequirement') ? 'Y':'N';
	gtmData['user_db_id'] = getCookie('__u');
	if(typeof getterSetter.get('adDetails').price !== 'undefined') {
		gtmData['price'] = getterSetter.get('adDetails').price;
	}
	if(connectionCheck()){
        eventTriggerQH(gtmData);
    } else {
        eventTriggeranalatyic(gtmData);
    }
}


export function detectSpamUser() {
	var Listing = getEntityType();
	var userPayload = {
		"entityType":Listing,
		"entityId":getterSetter.get('adDetails').id,
		"mobile":getterSetter.get('mobile'),
		"email":getterSetter.get('email'),
		"requirementType":getterSetter.get('reqSource')
	};

	// Prepend the phone code to the mobile number
	if (getterSetter.get('isNRIMobile')) {
		userPayload.mobile = `+${getterSetter.get('phonecode')}_${userPayload.mobile}`;
	}

	var promise = new Promise(function (resolve, reject) {
		$.ajax({
			url: "/homes/re2/detectSpamUser?aj=1",
			type: "POST",
			data: userPayload,
			success: function (response) {
				resolve(response);
			},
			error: function (error) {
				reject(error);
			}
		})
	});
	return promise;
}

export const timeStamp = function(){
	var getTimstamp = localStorage.getItem("timeStamp");
	if(getTimstamp == null){
		$('#commonpopup').modal('hide');
		$(document).trigger("renderRequirement",'');
		$('#myModal').modal('toggle');
		localStorage.setItem('timeStamp', getTime());
	}
	if(dayDifference(getTimstamp)>=1){
		$('#commonpopup').modal('hide');
		$('#myModal').modal('toggle');
		localStorage.setItem('timeStamp', getTime());
	}
}

export const showSimilar = ()=>{
	const elCommonPopup = document.querySelector('#commonpopup');
	const elGetInTouchBox = elCommonPopup.querySelector('#commonpopup #GetInTouch');

	const elGetInTouchUserMobile = elCommonPopup.querySelector('#contact_mobile');
	const elGetInTouchUserEmail = elCommonPopup.querySelector('#contact_email');

	elGetInTouchUserMobile.innerHTML = getterSetter.get('mobile') || '';
	elGetInTouchUserEmail.innerHTML = getterSetter.get('email') || '';
	elGetInTouchBox.classList.remove('hide');

	getCCRdetails('similar');

	$("#commonpopup .contentpopup").addClass("hide");
	$('#commonpopup #similarListing').removeClass("hide");
	$('#commonpopup').addClass("thankyou");
	$('#commonpopup #GetInTouch').removeClass("hide");
	let isMobile = source();
	if(isMobile != 'MOBILE') {
		replyFormController.initQapCarousel('#qapCarousel');
	}
}
export function setAlert() {
	const elContactPopup = (getterSetter.get('formViewType') === 'static') ? '#static_form_wrap' : '#commonpopup';
	const elCommonPopup = document.querySelector(elContactPopup);
	const elAlertMessageBox = elCommonPopup.querySelector('#alertmsgbox');

	if (getterSetter.get('errMsg')){
		elAlertMessageBox.classList.remove('hide')
		elAlertMessageBox.innerHTML = getterSetter.get('errMsg')
	}else{
		elAlertMessageBox.classList.add('hide')
		elAlertMessageBox.innerHTML = ''
	}
}
export function setLoginName(user){
	if(user.firstName){
		return user.firstName;
	}else if(user.email){
		return user.email.substring(0, user.email.indexOf("@"));
	}else{
		return '';
	}
}
export function saveAdReply() {
	if(globals.pageType == 'VAP' && (getterSetter.get('reqSource') == 'CONTACT_IN_LISTING_FLP' || getterSetter.get('reqSource') =='REQUIREMENT_POPUP_FLP')) {
        vapSubmitContactAbRand();
    }
}

export function userMapping(tilesDetails){
	var label = 'QHMR'
	if(tilesDetails.isGrabHouse == "1"){
		label = 'QHMR'
	}else if(tilesDetails.postedBy == "Broker" || tilesDetails.userType == "Broker") {
		label = 'Broker'
	}else if(tilesDetails.postedBy == "Builder" || tilesDetails.userType == "Builder"){
		label = 'Builder'
	}else{
		label = 'Owner'
	}
	return label
}

export function intentMapping(details){
	let propertyFor;
	switch (details.availableFor.toLowerCase()) {
		case "sale":
		case "sell":
		case "buy":
		  propertyFor = 'buy';
			break;
		case "rent":
			propertyFor = "rent_in";
			break;
		case "pg":
			propertyFor = "pg_in";
			break;
		case "flatmates":
			propertyFor= "flatmate";
			break;
	}
	return propertyFor;
}


export function categoryMapping(propertyType,mapicon){
	let categoryType;
	switch (propertyType.toLowerCase()) {
		case "apartment":
		case "villa":
		case "flatmates":
		case "pg":
		case "builderfloor":
		  categoryType = 'Residential';
			break;
		case "land":
			categoryType = "Agriculture";
			break;
		case "shop":
		case "complex":
		case "office":
			categoryType = "Commercial";
			break;
		case "plot":
			if( mapicon && mapicon.toLowerCase() ===  "commercial"){
				categoryType = "Commercial";
			} else {
				categoryType = 'Residential';
			}
	}
	return categoryType;
}	

// after every form page release will remove these section for the time I am adding this on window object.{Jay}
replyFormController.submitcontactSimilarAd = (contactListing,contact)=> {
    var contactValue;
    var contactClass;
	var contactListingtData = JSON.parse(atob(contactListing));
	getterSetter.set('adDetails',contactListingtData)

	if (!getterSetter.get('isNRIMobile')) {
		requirementService.addRequirement(requirementPayload(contactListingtData.reqSource)).then(function(response){
		var responseData = JSON.parse(response.response);
			if(responseData.statusCode == 200 || (contactListingtData.gHIsAvailable == true  && parseInt(response.status) === 200 ) ){
				contactValue = 'Thank You';
				contactClass = 'thbtn';
			} else{
				contactValue = 'Please Try Again Later';
				contactClass = 'erbtn';
			}
			contact.innerHTML = contactValue;
			$(contact).addClass(contactClass);
			$(contact).prop("disabled",true);
		});
	}

	var gtmevent = (globals.pageType === 'LOCALITY')?getContactSuccessEvents(getterSetter.get('localityReqSource'))+'_ty':getContactSuccessEvents(getterSetter.get('reqSource'))+'_ty';
	var id_type = (globals.pageType === 'LOCALITY')?getEventId(getterSetter.get('localityReqSource')):getEventId(getterSetter.get('reqSource'));
	var gtmSubcat = (globals.pageType === 'LOCALITY')?getContactSubCategory(getterSetter.get('localityReqSource'),getterSetter.get('adDetails')):getContactSubCategory(getterSetter.get('reqSource'),getterSetter.get('adDetails'));
	
	var gtmData = {
		'event': gtmevent,
		'mobile': getterSetter.get('mobile'),
		'email': getterSetter.get('email'),
		'SubCategory_Name':gtmSubcat
	};
	gtmData[id_type] = contactListingtData.id;
	var utmResult = getutmData();
	gtmData['utm_campaign'] = utmResult['utm_campaign'];
	gtmData['utm_medium'] = utmResult['utm_medium'];
	gtmData['utm_source'] = utmResult['utm_source'];
	gtmData['HL_intent'] = getterSetter.get('loanRequirement') ? 'Y':'N';
	gtmData['user_db_id'] = getCookie('__u');
	eventTriggerQH(gtmData);
		
}

replyFormController.gaEventContactSimilarListing = (slot) => {
	gaService.addEvent('link-RealestateResponsiveUI',globals.pageType+'_Real-Estate','contact_similar_listing_'+slot);
}

replyFormController.getTimeSlots = (val) => {
	const start = 9, until = 20, interval = 1, offset = 2;
	let currentDate = new Date();
	let newDate = new Date(val);
	let dateVal = (currentDate.getDate() == newDate.getDate()) ? currentDate : newDate;
	let siteVisitTimeSlots = getSiteVisitTimeSlots(start, until, interval, offset, dateVal);
	let html = '';
	siteVisitTimeSlots.map((val, index) => {
		html += `<li data-sitevisit-time="${val.value}">${val.display}</li>`;
	});
	document.getElementById('replySiteVisitSelectedTime').innerHTML = siteVisitTimeSlots[0].display;
	document.getElementById('replySiteVisitTimeDropdown').innerHTML = html;
}

export function getCustomerType(mobile, email) {
	var payload = {
		"mobile": mobile,
		"email": email
	};
	var user;

	var promise = new Promise(function (resolve, reject) {
		$.ajax({
			url: "/homes/re2/getCustomerType",
			type: "POST",
			data: payload,
			success: function (response) {
				if(!$.isEmptyObject(response)){
					var data = response.data;
					if (parseInt(response.statusCode) === 200 && data.bableUserId && data.userType ) {
						user = data.userType.toLowerCase();
					}
				}
				resolve(user);
			},
			error: function (error) {
				reject(error);
			}
		})
	});

	return promise;
}

export function getEntityType() {
	var entityType;
	switch(getterSetter.get('reqSource')) {
		case "CONTACT_INDIVIDUAL_BROKER":
		case "CONTACT_SNB_BROKER":
		case "CONTACT_BROKER_IN_LOCALITY":
			entityType = 'broker';
			break;
		case "HP_FEATURED_PROJECT":
		case "HP_PROJECT_BACKDROP":
		case "PROJECT_CONTACT_IN_SNB":
		case "IM_INTERESTED_IN_PROJECT":
		case "IM_INTERESTED_IN_QPROJECT":
		case "PROJECT_CONTACT_IN_BUILDER":
		case "PROJECT_CONTACT_IN_LOCALITY":
		case "PROJECT_CONTACT_IN_PROJECT":
		case "REQUIREMENT_POPUP_PROJECT":
		case "PROJECT_CONTACT_IN_QPROJECT":
		case "PROJECT_UNIT_CONTACT_IN_PROJECT":
		case "PROJECT_UNIT_CONTACT_IN_QPROJECT":
			entityType = 'project';
			break;
		default:
			entityType = 'listing';
			break;
	}
	return entityType;
}

export function getCCRdetails(type) {
	let adDetails = getterSetter.get('adDetails');
	if(typeof globals.qcashEnabled !== 'undefined' && globals.qcashEnabled!=0 && adDetails &&
	 ('undefined' == typeof adDetails.isGrabHouse || adDetails.isGrabHouse!= 1 )) {
		addQcash();
	}

	let formViewTypeWrap = '#commonpopup';
	if(getterSetter.get('formViewType') === 'static') {
		formViewTypeWrap = '#static_form_wrap';
	}
	
	if(type == 'similar') {
		//CCR for Smilar popup
		let isMobile = source();
		if(isMobile != 'MOBILE') {
			document.getElementById('cross-category-recommendation').classList.add("qg-recommended-desktop-container");
		}
		let ccrDiv = formViewTypeWrap + " #ccrdetailsimilar";
		let ccrDivRecomendation = formViewTypeWrap + " #cross-category-recommendation";
		document.querySelector(ccrDiv).innerHTML  = document.getElementById('ccr-div').innerHTML;
		document.querySelector(ccrDivRecomendation).classList.remove('hide');
	} else if(type == 'clicktoviewnumber') {
		let ccrDiv = formViewTypeWrap + " #ccrdetailviewnumber";
		let ccrDivRecomendation = formViewTypeWrap + " #cross-category-recommendation";
		document.querySelector(ccrDiv).innerHTML  = document.getElementById('ccr-div').innerHTML;
		document.querySelector(ccrDivRecomendation).classList.remove('hide');
	} else {
		let ccrDiv = formViewTypeWrap + " #ccrdetail";
		let ccrDivRecomendation = formViewTypeWrap + " #cross-category-recommendation";
		document.querySelector(ccrDiv).innerHTML  = document.getElementById('ccr-div').innerHTML;
		document.querySelector(ccrDivRecomendation).classList.remove('hide');
	}
}

export function addQcash() {
	let userId = '';
	if(getterSetter.get( 'loginstatus')){
        let user_Details = getterSetter.get('loginData');
        userId = user_Details.data.user.id;
    }
	let payload = {
		'qcashEnabled' : globals.qcashEnabled || '',
		'phone' : getterSetter.get('mobile'),
		'emailId' : getterSetter.get('email'),
		'userId': userId
	}
	$.ajax({
		url: "/homes/re2/addqcash?aj=1",
		type: "POST",
		data: payload,
		success: function (response) {
			if(response && isSet(response.status) && response.status == true) {
				$('.qcashthankyou').removeClass('hide');
				$('#qcashresp').html('You get <span>&#8377; '+response.qcash+' Qcash</span>');
			}
			if(globals.pageType == 'SNB') {
				$('.q_cash').addClass('hide');
			}
		},
		error: function (error) {
			console.log('error');
		}
	})
}

replyFormController.NRIRedirect = () =>{
	let adDetails = getterSetter.get('adDetails');
	let reqSource = getterSetter.get('reqSource');
    let pageType = globals.pageType;
	let nriCookie = {
		source : reqSource,
		entityType : jsUcfirst(getEntityType())  || ''
	};

    if( pageType == 'SNB'){
		if(snbFilterController.filter.data.type  === 'buy'){

			nriCookie.propertyType = snbFilterController.filter.data.cat || '';
			nriCookie.propertySubType = adDetails.propertyType || '';
			nriCookie.bedrooms = !_.isEmpty(adDetails.bhk) ? adDetails.bhk.split(" ")[0] : '' ;
			nriCookie.cityName = adDetails.cityName || '';
			nriCookie.cityId  = adDetails.cityId || '';
			nriCookie.localityName = adDetails.location || '';
			nriCookie.localityId = adDetails.localityId || '';
			nriCookie.listingId = Number(adDetails.id);
		}else if(snbFilterController.filter.data.type  === 'projects'){
			nriCookie.propertyType = adDetails.category !== '' ? adDetails.category.toLowerCase() : '';
			nriCookie.propertySubType = adDetails.type || '';
			nriCookie.cityName = adDetails.address.city || '';
			nriCookie.cityId  = adDetails.address.cityId || '';
			nriCookie.localityName = adDetails.address.locality || '';
			nriCookie.localityId = adDetails.address.localityId || '';
			nriCookie.priceRange = adDetails.priceRange;
			nriCookie.projectId = Number(adDetails.id);
		}

    } else if (pageType == 'VAP'){
		switch (reqSource) {
			case 'REQUIREMENT_POPUP_FLP':
			case 'CONTACT_IN_LISTING_FLP': {
				nriCookie.propertyType =  adDetails.category !== '' ? adDetails.category.toLowerCase() : '';
				nriCookie.propertySubType = adDetails.type || '';
				nriCookie.bedrooms = !_.isEmpty(adDetails.bhk) ? adDetails.bhk.split(" ")[0] : '' ;
				nriCookie.cityName = adDetails.city || '';
				nriCookie.cityId  = adDetails.cityId || '';
				nriCookie.localityName = !_.isEmpty(adDetails.locality) ? adDetails.locality[0]: '';
				nriCookie.localityId = !_.isEmpty(adDetails.localityIds) ? adDetails.localityIds[0]: '';
				nriCookie.listingId = Number(adDetails.id);
				break;
			}
			case 'CONTACT_IN_LISTING_VAP_QHMR': {
				nriCookie.propertyType =  adDetails.category !== '' ? adDetails.category.toLowerCase() : '';
				nriCookie.propertySubType = adDetails.type || '';
				nriCookie.bedrooms = !_.isEmpty(adDetails.bhk) ? adDetails.bhk.split(" ")[0] : '' ;
				nriCookie.cityName = adDetails.city || '';
				nriCookie.cityId  = adDetails.cityId || '';
				nriCookie.localityName = !_.isEmpty(adDetails.locality) ? adDetails.locality[0]: '';
				nriCookie.localityId = !_.isEmpty(adDetails.localityIds) ? adDetails.localityIds[0]: '';
				nriCookie.listingId = Number(adDetails.id);
				break;
			}
			case 'CONTACT_IN_LISTING_SIMILAR': {
				nriCookie.propertyType =  adDetails.category !== '' ? adDetails.category.toLowerCase() : '';
				nriCookie.propertySubType = adDetails.propertyType || '';
				nriCookie.bedrooms = !_.isEmpty(adDetails.bhk) ? adDetails.bhk.split(" ")[0] : '' ;
				nriCookie.cityName = adDetails.city || '';
				nriCookie.cityId  = adDetails.city_id || '';
				nriCookie.localityName = adDetails.locality_name || '';
				nriCookie.localityId = adDetails.locality_id || '';
				nriCookie.listingId = Number(adDetails.id);
				break;
			}
			default: {
				// Unrecognized sources
				break;
			}
		}

	}else if(pageType === 'project') {
		nriCookie.propertyType =  adDetails.category !== '' ? adDetails.category.toLowerCase() : '';
		nriCookie.propertySubType = adDetails.type || '';
        nriCookie.cityName = adDetails.address.city || '';
        nriCookie.cityId  = adDetails.address.cityId || '';
        nriCookie.localityName = adDetails.address.locality || '';
        nriCookie.localityId = adDetails.address.localityId || '';
		nriCookie.priceRange = adDetails.priceRange;
		nriCookie.projectId = Number(adDetails.id);
    }
	localStorage.removeItem('nriForm');
	localStorage.setItem('nriForm', JSON.stringify(nriCookie));
	let nriPage = window.location.origin + '/homes/nri-real-estate-investment-india';
	window.open( nriPage, '_blank')
};

replyFormController.pushToDialer = (request, callback) => {
	$.ajax({
		url: "/homes/re2/common/dialer?aj=1",
		type : "POST",
		dataType:"json",
		data: request,
		complete: () => {
			if (callback && 'function' === typeof callback) {
				callback();
			}
		}
	});
}

replyFormController.onQuikrServicesClicked = (hyperlink) => {
	let request = {};
	let paramObj = getUrlVars(hyperlink)
	request["NAME"] = getterSetter.get("name")||"";
	request["MOBILE"] = getterSetter.get('mobile')|| "";
	request['EMAIL'] = getterSetter.get('email')|| "";
	request['campaignName'] = "svc_organic";
	request['utm_source'] = paramObj['utm_source'] || "QH";
	request['utm_medium'] = paramObj['utm_medium'] || "internal";
	request['utm_campaign'] =paramObj['utm_campaign'] || "QH_buy";

	// Send the data to dialer API
	replyFormController.pushToDialer(request, () => {
		window.location.href = hyperlink;
	});
}

replyFormController.sendNRIRequirementToDialer = () => {
	let request = {};
	let paramObj = getUrlVars();
	request["NAME"] = getterSetter.get("name")||"";
	request["MOBILE"] = `+${getterSetter.get('phonecode')}_${getterSetter.get('mobile')}` || "";
	request['EMAIL'] = getterSetter.get('email')|| "";
	request['campaignName'] = "NRI_unverified_ff";
	request['utm_source'] = paramObj['utm_source'] || "QH";
	request['utm_medium'] = paramObj['utm_medium'] || "internal";
	request['utm_campaign'] =paramObj['utm_campaign'] || "QH_buy";

	// Send the data to the dialer API
	replyFormController.pushToDialer(request);
}

replyFormController.selectBhk = (data, val) => {
	let mainParent = data.parentElement;
	for(let i = 0; i < mainParent.getElementsByTagName('li').length; i++) {
        if(mainParent.getElementsByTagName('li')[i].classList.contains("active")) {
            mainParent.getElementsByTagName('li')[i].classList.remove("active");
        }
    }
    data.classList.add('active');
    getterSetter.set('projectBhk',val);
}

replyFormController.selectTimeline = (id, value, label) => {
	const homeloan = getterSetter.get('homeloan');
	if(id == 'selectedFinalized') {
		getterSetter.set('homeloan',  { ...homeloan, finalized: value });
	} else {
		getterSetter.set('homeloan', { ...homeloan, timeline: value });
	}
	document.getElementById(id).textContent = label;
	if(getterSetter.get('homeloan').finalized && getterSetter.get('homeloan').timeline) {
		$('.btnsbt').prop('disabled', false);
	}
}

replyFormController.submitHomeAssist = () => {
	document.getElementById("thanksMsg").innerHTML = "Submitted Successfully";
	let homeloanAssistDiv = "#loanDetails";
	document.querySelector(homeloanAssistDiv).classList.add('hide');
	requirementService.addHomeLoanAssistRequirement(homeloanAssistPayload());
}


replyFormController.renderBhkPills = (propertySubType) => {
	const adDetails = getterSetter.get('adDetails');
	const elContactBhk = document.querySelector('#contactBhk');

	// Get the unit configurations
	const unitConfig = getUnitConfigurations(adDetails.projectUnits);
	const applicableUnitTypes = unitConfig[propertySubType];

	if (applicableUnitTypes && applicableUnitTypes.length > 0) {
		getterSetter.set('projectBhk', applicableUnitTypes[0]);

		// Populate the pills for BHK
		const elContactBhkPills = elContactBhk.querySelector('#contactBhkPills');
		elContactBhkPills.innerHTML = applicableUnitTypes.reduce((html, bhk, idx) => {
			return html + `
				<li class="${(idx == 0) ? 'active' : ''}" onclick="replyFormController.selectBhk(this, '${bhk}')">${bhk}</li>
			`;
		}, '');
	}
}

replyFormController.selectProptype = (data, val) => {
	const mainParent = data.parentElement;
	let formViewTypeWrap = '#commonpopup';
	if(getterSetter.get('formViewType') === 'static') {
		formViewTypeWrap = '#static_form_wrap';
	}
	let contactBhk = formViewTypeWrap + ' #contactBhk';
	const elContactBhk = document.querySelector(contactBhk);

	if(val == 'Plot') {
		if(elContactBhk) {
			elContactBhk.classList.add('hide');
			getterSetter.set('projectBhk','');
		}
	} else {
		if(elContactBhk) {
			// Unhide the BHK pills
			elContactBhk.classList.remove('hide');

			// Render the applicable BHK pills
			replyFormController.renderBhkPills(val);
		}
	}
	for(let i = 0; i < mainParent.getElementsByTagName('li').length; i++) {
        if(mainParent.getElementsByTagName('li')[i].classList.contains("active")) {
            mainParent.getElementsByTagName('li')[i].classList.remove("active");
        }
    }
    data.classList.add('active');
    getterSetter.set('projectProptype',val);
}

replyFormController.initQapCarousel = (selector) => {
        const elCarousel = document.querySelector(selector);

        // Carousel may not be available based on the city selected
        if(!elCarousel) return;

        const elCarouselScrollPane = elCarousel.querySelector('[data-carousel-ref="scrollpane"]');

        // Calculate the total width of the carousel container by adding up
        // the width of each carousel item
        const elCarouselScrollItems = [...elCarouselScrollPane.children];
        const widthScrollItem = elCarouselScrollItems[0].scrollWidth;
        const totalWidthOfContainer = widthScrollItem * elCarouselScrollItems.length

        // Use the calculated total width to set the width of the parent
        elCarouselScrollPane.style.width = `${totalWidthOfContainer}px`;

        // Initialize the carousel navigation
        const elCarouselNavigation = elCarousel.querySelector('[data-carousel-ref="nav"]');
        
        // Navigation buttons may not be available if there are less items
        // that could easily fit the container
        if(!elCarouselNavigation) return;

        let idxCurrentScrollItem = 0;

        // Delegating events for navigation controls
        elCarouselNavigation.onclick = (evNavigationClick) => {
            let scrollNavDirection = evNavigationClick.target.getAttribute('data-carousel-nav');

            // Direction required for navigation to work
            if (!scrollNavDirection) return;

            // Just for consistency
            scrollNavDirection = scrollNavDirection.toUpperCase();

            if('LEFT' == scrollNavDirection && idxCurrentScrollItem > 0) {
                idxCurrentScrollItem -= 1;
                const translateX = idxCurrentScrollItem * widthScrollItem;
                elCarouselScrollPane.style.transform = `translateX(-${translateX}px)`;
            }
            
            // Considering the last 2 tile to be visible
            if('RIGHT' == scrollNavDirection && idxCurrentScrollItem < (elCarouselScrollItems.length - 2)) {
                idxCurrentScrollItem += 1; 
                const translateX = idxCurrentScrollItem * widthScrollItem;
                elCarouselScrollPane.style.transform = `translateX(-${translateX}px)`;
            }
        };

    };


var prePaymentComponent = {
	rentalUnlockCheck: null,
	buyUnlockCheck: null,
	delPrice:null,
	mainPrice:null,
	submitPay:null,
	individualViewId:null,
	init: () => {
		prePaymentComponent.rentalUnlockCheck = document.getElementById('UnlockRental');
		prePaymentComponent.buyUnlockCheck = document.getElementById('UnlockBuy');
		prePaymentComponent.delPrice = document.getElementById('delPrice');
		prePaymentComponent.mainPrice = document.getElementById('mainPrice');
		prePaymentComponent.submitPay = document.getElementById('clickToViewPay');
		if(window.location.href.indexOf("-pg-") > -1 || window.location.href.indexOf("-rent-") > -1 ) {
			prePaymentComponent.rentalUnlockCheck.checked = true;
		} else {
			prePaymentComponent.buyUnlockCheck.checked = true;
		}
		prePaymentComponent.rentalUnlockCheck && prePaymentComponent.rentalUnlockCheck.addEventListener('click', (ev) => prePaymentComponent.paymentCheckBoxSelection(ev));
		prePaymentComponent.buyUnlockCheck && prePaymentComponent.buyUnlockCheck.addEventListener('click', (ev) => prePaymentComponent.paymentCheckBoxSelection(ev));
		prePaymentComponent.submitPay.addEventListener('click',(ev)=> prePaymentComponent.paymentClick(ev));

	},
	paymentCheckBoxSelection: (ev) => {
		if(prePaymentComponent.rentalUnlockCheck.checked && prePaymentComponent.buyUnlockCheck.checked) {
			prePaymentComponent.delPrice.innerText = "₹1998";
			prePaymentComponent.mainPrice.innerText = "₹198";
		} else {
			prePaymentComponent.delPrice.innerText = "₹999";
			prePaymentComponent.mainPrice.innerText = "₹99";
		}
	},
	paymentClick:(ev) => {
		if((prePaymentComponent.rentalUnlockCheck && !prePaymentComponent.rentalUnlockCheck.checked) && (prePaymentComponent.buyUnlockCheck && !prePaymentComponent.buyUnlockCheck.checked)) {
			return false;
		}
		else {	
		let adDetails = getterSetter.get('adDetails');
		let price = parseInt(document.getElementById('mainPrice').innerText.slice(1));
		let initiatePaymentPayLoad = {
			'orders': [ {
				'productContext' : 'CLICK_TO_VIEW_IN_LISTING',
				'productPurchaseId' : prePaymentComponent.individualViewId,
				'cityId' : adDetails.cityId,
				'title' : "Seeker Premium",
				'description' : "Click to View Subscription",
				'categoryId' : 20,
				'subcatId' : null,
				'amount' : price,
			}],
			'amount':price,
			'userEmail':(getterSetter.get('email') ? getterSetter.get('email') : ''),
			'categoryId':20,
			'cityId':adDetails.cityId,
			'source':source(),
			'callBack':window.location.href.split('?')[0]+"?paymentSucessClickToView=true",
		}	 	
		localStorage.setItem('clickToViewNumberPaymentRedirect',btoa(Math.round(Date.now() / 1000)));	
		$.ajax({
			url: "/homes/re2/clickToViewSuccess",
			type : "POST",
			data:initiatePaymentPayLoad,
			dataType: 'json',
			success : function(response){
				window.location.href = unescape(response['paymentPageUrl']);
			}
		});
	}
	}
	
};

// (() => {
// 	let attributesForPaymentSelect =  {
// 		"rentalOption":true,
// 		"purchaseOption":true,
// 	};

// 	const modalPaymentTpl = _.template(document.getElementById('paymentOptionTemplate').innerHTML);
// 	document.getElementById('contactseeker').innerHTML = modalPaymentTpl({attributesForPaymentSelect});
// 	$('#contactseeker').modal('show');
// 	prePaymentComponent.init();

// 	$('#contactseeker').on('hide.bs.modal', function (e) {
// 		setTimeout(() => {
// 			const modalPaymentTpl = _.template(document.getElementById('paymentOptionTemplate').innerHTML);
// 			document.getElementById('contactseeker').innerHTML = modalPaymentTpl({attributesForPaymentSelect});
// 			$('#contactseeker').modal('show');
// 			prePaymentComponent.init();
// 		}, 2000);
// 	});
// })();