    import {getCookie,setCookie} from '../services/cookieService'
import {set} from '../services/localStorageService'

var spinner = {};
spinner.count = 0;

export const app = {
    source: {
        QH: 0,
        CF: 1,
        QHCF: 2,
        QHMR: 3,
        COWORK: 4
    }
};

export function isSet(val) {
    if (val != undefined && val != null && val != '') return true;
    return false
}

export function makeFormData(obj = {}) {
    let formData = new FormData();
    for (let key in obj) {
        formData.append(key, obj[key])
    }
    return formData;
}

export function openOwnerConfirmationPopup() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('unqid')) {
        const currentUrl = window.location.href;
        const currentParams = window.location.search;
        const str = currentUrl.replace(currentParams, '');
        window.history.replaceState({}, document.title, str);

        if ((currentParams !== '') && currentParams.includes('unqid')) {
            const startIdx = currentParams.indexOf('=');
            const endIdx = currentParams.indexOf('+');

            let unqId = '';
            if ((startIdx > -1) && (endIdx > -1)) {
                unqId = currentParams.substr((startIdx + 1), (endIdx - (startIdx + 1)));
                getterSetter.set('ownerPropertyAvlUnqId', unqId);

                let createdDate = currentParams.substr((endIdx + 1), 10);
                if (createdDate !== '') {
                    const [yyyy, mm, dd] = createdDate.split('-');
                    const createdDtObj = new Date(yyyy, (parseInt(mm, 10) - 1), (parseInt(dd, 10) + 1));
                    const currentDtObj = new Date();
                    const dayDiff = Math.ceil(Math.abs( currentDtObj.getTime() - createdDtObj.getTime() ) / (1000 * 60 * 60 * 24))
                    if (dayDiff < 3) {
                        $('#voting_emailpop').modal('show');
                    }
                }
            }
        }
    }
}

export function getFormObject (form) {
    var formDataObject_Tracker = {};
    var formDataObject = {};
    var formData = $(form).serializeArray();
    $.each(formData, function (idx, entry) {
        var name = entry["name"];
        var newVal = entry["value"];
        if (isSet(formDataObject_Tracker[name])){
            formDataObject[name] = formDataObject[name] + "," + newVal;
        }
        else {
            formDataObject[name] = newVal;
            formDataObject_Tracker[name] = newVal
        }
    });
    return formDataObject
}
export function isEmpty(val) {
    return _.isEmpty(val)
}

export function isValidEmail(value) {
    if (!value || 'string' !== typeof value) {
        return false;
    }

    const regex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return regex.test(value);
}

export function isValidName(value) {
    if (!value || 'string' !== typeof value) {
        return false;
    }

    const regex = /^[a-zA-Z\s.]+$/i;
    return regex.test(value);
}

export function convertMoneyFormatToNumber(money) {
    var moneyArr = money.split(" ");
    if (!isSet(moneyArr[1])) return Number(moneyArr[0]).toFixed(2);
    if (moneyArr[1] === "Cr") {
        var cr = moneyArr[0] * 1E7;
        return Number(cr).toFixed(2)
    }
    if (moneyArr[1] === "Lac") {
        var lac = moneyArr[0] * 1E5;
         return Number(lac).toFixed(2)
    }
    if (moneyArr[1] === "Th") {
        var lac = moneyArr[0] * 1E3;
        return Number(lac).toFixed(2)
    }
}
export function getShortMoneyFormat(money) {
    var numberRegex = /^[+-]?\d+(\.\d+)?([eE][+-]?\d+)?$/;
    if (numberRegex.test(money)) {
        var cr = money / 1E7;
        if (cr >= 1) return Number(cr) + " Crore";
        var lac = money / 1E5;
        if (lac >= 1) return Number(lac) + " Lakhs";
        var th = money / 1E3;
        if (th >= 1) return Number(money);
        return Number(money)
    } else return money
}

export function getShortMoneyFormatCowork(money) {
    var numberRegex = /^[+-]?\d+(\.\d+)?([eE][+-]?\d+)?$/;
    if (numberRegex.test(money)) {
        var cr = money / 1E7;
        if (cr >= 1) return Number(cr) + " Crore";
        var lac = money / 1E5;
        if (lac >= 1) return Number(lac) + " Lakh";
        var th = money / 1E3;
        if (th >= 1) return Number(th) + " Thousand";
        return Number(money)
    } else return money
}

export function getTinyMoneyFormat(money) {
    var numberRegex = /^[+-]?\d+(\.\d+)?([eE][+-]?\d+)?$/;
    if (numberRegex.test(money)) {
        var cr = money / 1E7;
        if (cr >= 1) return Number(cr) + " Cr";
        var lac = money / 1E5;
        if (lac >= 1) return Number(lac) + " L";
        var th = money / 1E3;
        if (th >= 1) return Number(th) + " K";
        return Number(money)
    } else return money
}

export function inArrayOfObjects(searchField, searchValue, objectArray) {
    var index = -1;
    for (var i =
        0; i < objectArray.length; i++)
        if (objectArray[i][searchField] === searchValue) {
            index = i;
            break
        }
    return index
}
export function capitalizeFirstLetter(string) {
    if(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    return;
}
export function currencyFilter (input) {
    input = Number(input);

    if (isNaN(input) || input < 0) {
        return "-";
    }
    if (input < 1000) {
        return input;
    }
    else if (input >= 1000 && input < 100000) {
        input += '';
        input = insertAt(input, input.length - 3, ',');
        return input;
    } else if (input >= 100000 && input < 10000000) {
        input += '';
        input = insertAt(input.substring(0, input.length - 3), input.length - 5, '.');
        return input + ' Lakh';
    }
    else if (input >= 10000000 && input < 1000000000) {
        input += '';
        input = insertAt(input.substring(0, input.length - 5), input.length - 7, '.');
        return input + ' Crore';
    }
};
export function areaFilter (area, unit){
    if ('string' == typeof area) {
        return area;
    }
    area = parseInt(area);

    if (area == -1 || !area) {
        return " - ";
    } else {
        return area + " " + unit;
    }
    return area;
}
export function ImageLoader() {
    /**
     * Load the image at the given URL.
     * @param    {String} url
     * @return   {Promise} A $q promise that resolves when the image has loaded
     *   successfully.
     * @type     {Function}
     * @name     load
     * @memberOf bootstrapLightbox.ImageLoader
     */
    this.load = function (url) {
        var deferred = $.Deferred();

        var image = new Image();

        // when the image has loaded
        image.onload = function () {
            // check image properties for possible errors
            if ((typeof this.complete === 'boolean' && this.complete === false) ||
                (typeof this.naturalWidth === 'number' && this.naturalWidth === 0)) {
                deferred.reject();
            }

            deferred.resolve(image);
        };

        // when the image fails to load
        image.onerror = function () {
            deferred.reject();
        };

        // start loading the image
        image.src = url;

        return deferred.promise();
    };
};
function insertAt(input, index, key) {
    if (index > 0) {
        return input.substring(0, index) + key + input.substring(index, input.length);
    }
    else
        return key + input;
};
function filtering(Url, replaceVar) {
    if (Url != undefined) {
        return Url.toLowerCase().trim().replace(/[^\w\s-]+/gi, ' ').replace(/ /g, replaceVar);
    }
}
export function seoUrl(Url) {
    return filtering(Url, '-');
};
export const getterSetter = {
    get : function( name ){ 
        return this[name]; 
    },
    set : function( name, value ){ 
        if(name == 'mobile' || name == 'email' || name == 'name') {
            if(name == 'mobile') { set('autofill_no', value); }
            if(name == 'email') { set('autofill_email', value); }
            if(name == 'name') { set('autofill_contactname', value); }
        } 
        this[name] = value; 
    }
};
export function WonboShow() {
    var abRand = getCookie("abRand") ? parseInt(getCookie("abRand")) : parseInt(getCookie("abRandMobile"));
    if(abRand >= 1 && abRand <= 80){
        return true;
    }else{
        return false;
    }
}

export function toCamelCase(str) {
    return str.replace(/([-_][a-z])/ig, (part) => {
        return part.toUpperCase()
            .replace('-', '')
            .replace('_', '');
        });
}

export function isNavigationKeyPressed(ev) {
    const evt = window.event ? event : ev;
    const arrowKeys = [37, 38, 39, 40, 13, 9];
    const keyCode = (evt.which) ? evt.which : evt.keyCode;

    // Prevent all other than special keys and numbers
    return arrowKeys.indexOf(keyCode) != -1;
}

export function isNumberOrSpecialKeyPressed(ev) {
    const evt = window.event ? event : ev;
    const specialKeys = [8, 9, 13, 16, 37, 38, 39, 40];
    const keyCode = (evt.which) ? evt.which : evt.keyCode;

    // Prevent keyboard trap for ctrl+A or meta+A (Select All)
    if ((evt.ctrlKey || evt.metaKey) && keyCode == 65) {
        return true;
    }

    // Prevent keyboard trap for ctrl+v or meta+v (Paste)
    if ((evt.ctrlKey || evt.metaKey) && keyCode == 86) {
        return true;
    }

    // Prevent all other than special keys and numbers
    return ((keyCode >= 48 && keyCode <= 57) || specialKeys.indexOf(keyCode) != -1);
}

export function source() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent) ? 'MOBILE' : 'DESK';
}

export function isAndroid() {
    return /Android/i.test(navigator.userAgent) ? true : false;
}

export function isIos() {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent) ? true : false;
}

export function dayDifference(past){
    if(!past) return;
    var date1 = new Date(past);
    var date2 = new Date(getTime());
    var timeDiff = Math.abs(date2.getTime() - date1.getTime());
    return Math.ceil(timeDiff / (1000 * 3600 * 24)); 
}
export const showSpinner = function () {
    ++spinner.count;
    spinner.id = document.getElementById("spinner");
    if(spinner.id) {
        spinner.id.className = "";
    }
}

export const hideSpinner = function (obj) {
    --spinner.count;
    if(spinner.count == 0) {
        spinner.id = document.getElementById("spinner");
        if(spinner.id) {
            spinner.id.className = "hidden";
        }
    }
}
export const getTime = ()=>{
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth()+1; //January is 0!
    var yyyy = today.getFullYear();
    if(dd<10) {
        dd = '0'+dd
    } 
    if(mm<10) {
        mm = '0'+mm
    } 
    var date = mm + '/' + dd + '/' + yyyy;
    return date;
}

export const replaceSpace = function (obj) {
    return  obj.replace(/ /gi, "-").toLowerCase()
}

export const replaceSpacetounderscore = function (obj) {
    return  obj.replace(/ /gi, "_").toLowerCase()
} 
export function _tplRender(templateName,callback) {
    let template = {'templatename' : templateName};
    if(!getterSetter.get(templateName)){
        $.ajax({
            url: '/homes/re2/templaterender?aj=1',
            method: 'POST',
            data: template,
            async: false,
            cache: true,
            success: function (data) {
                getterSetter.set(templateName,data);            
                callback(data);
            }
        });
    }else{
        callback(getterSetter.get(templateName));
    }
}
export function _tplRenderPromiss(templateName) {
    var promise = new Promise(function(resolve, reject) {
        let template = {'templatename' : templateName};
        if(!getterSetter.get(templateName)){
            $.ajax({
                url: '/homes/re2/templaterender?aj=1',
                method: 'POST',
                data: template,
                async: false,
                cache: true,
                success: function (data) {
                    getterSetter.set(templateName,data); 
                    resolve(data);           
                }
            });
        }else{
            resolve(getterSetter.get(templateName));
        }
    });
    return promise;
}

export function _vapTplRenderPromiss(templateName) {
    var promise = new Promise(function(resolve, reject) {
        let template = {'templatename' : templateName};
        if(!getterSetter.get(templateName)){
            $.ajax({
                url: '/homes/re2/vaptemplaterender?aj=1',
                method: 'POST',
                data: template,
                async: false,
                cache: true,
                success: function (data) {
                    getterSetter.set(templateName,data); 
                    resolve(data);           
                }
            });
        }else{
            resolve(getterSetter.get(templateName));
        }
    });
    return promise;
}

export function getQueryStringValue (key) {  
  return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));  
}

export function setCity(cityName,cityId){
    let getCityName = getCookie("new_prefer_city");
    let getCityId = getCookie("prefer_city_id");
    setCookie('new_prefer_city', cityName.toLowerCase());
    setCookie('prefer_city_id', cityId);
    var entity_city=cityName.charAt(0).toUpperCase() + cityName.slice(1);
    if(!isMobile){
        document.getElementById('hSelectCity').innerHTML = `<i class="icon-location"></i><span>${entity_city}</span><i class="icon-arrow"></i>`
    }
}
export function isMobile(){
    var condition = false;
    return window.innerWidth < 768 ? !condition : condition;
}
export function resumeAd() {
    if (localStorage.getItem("qh_resume_ad") !== null) {
        var resumeAdDetail = JSON.parse(localStorage.getItem('qh_resume_ad'));
        var title = '';
        var adType = "Property";
        var intent = ' for sale in ';
        if(globals.pageType === 'SNB' || globals.pageType === 'Home') {
            $("#resumeAd").removeClass('hide');
            $("#home-nri-tile").addClass('hide');
            if(resumeAdDetail.defaultDetails.AdDescriptionSection.subsections.Ad_Title.value!=='') {
                title += resumeAdDetail.defaultDetails.AdDescriptionSection.subsections.Ad_Title.value;
            } else {
                if(resumeAdDetail.defaultDetails.AdOutline.ad_type == 5 || resumeAdDetail.defaultDetails.AdOutline.ad_type == 6) {
                    intent = " for rent in ";
                    adType = "PG/Flatmates";
                } else if (resumeAdDetail.defaultDetails.AdOutline.ad_type == 2) {
                    intent = " for rent in ";
                    adType = "Property";
                }
                if(resumeAdDetail.defaultDetails.AboutPropertySection.subsections.Property_Type.value !== '') {
                    adType ="";
                    intent = resumeAdDetail.defaultDetails.AboutPropertySection.subsections.Property_Type.value + intent;
                    if(resumeAdDetail.defaultDetails.AboutPropertySection.subsections.Property_Details.subsections.bhk.value !== '') {
                        intent = " " + resumeAdDetail.defaultDetails.AboutPropertySection.subsections.Property_Details.subsections.bhk.value + intent;
                    }
                }
                title += intent;
                if(resumeAdDetail.defaultDetails.LocalitySection.subsections.Locality.value !=='') {
                    title += resumeAdDetail.defaultDetails.LocalitySection.subsections.Locality.value+", ";
                } 
                title += resumeAdDetail.defaultDetails.AdOutline.city;
            }
            if(resumeAdDetail.defaultDetails.AdOutline.city )
            $("#resume-ad-title").html(title);
            $(".resume-ad-score").html(resumeAdDetail.score + "%");
            $(".resume-ad").css('width',resumeAdDetail.score + "%");
        }
    }
}

/**
 * confirm Pop up 
 * have to include commomConfirmPopUp to access this service
 * @param {succes call back function} succesCallBack 
 */
var callBackActionfunction;
export function confirmPopUP(callBackfunction) {
    $('#forumConfirmPopUp').modal('show');
    if (typeof callBackActionfunction === "undefined") {
        callBackActionfunction = callBackfunction;
        confirmPopUpDeleteEvent(callBackActionfunction);
    }
}

function confirmPopUpDeleteEvent (callBackfunction) {
    let yesForumCommentDelete = document.getElementById('confirmDelete');
    if (yesForumCommentDelete) {
        yesForumCommentDelete.addEventListener('click', (event) => {
            $('#forumConfirmPopUp').modal('hide');
            callBackfunction();
        });
    }
};

export function intentType(type) {
    let intent;
    switch(type){
        case 'buy':
            intent = 'sell';
            break;
        case 'rent':
            intent = 'rent';
            break;
        case 'pg':
            intent = 'PG';
    }
    return intent;
}

export function getUrlVars(url) {
    var vars = {};
    var parts = (url || window.location.href).replace(/[?&]+([^=&]+)=([^&]*)/gi,
        function(m,key,value) {
            vars[key] = value;
        });
    return vars;
}


export function getutmData() {
    var trafficCookie = getCookie("utmztrack") ? getCookie("utmztrack") : getCookie('__utmz')
    var trafficSource = "none";
    var trafficMedium = "none";
    var trafficCamp = "none";
    var utmData = {};
    if(trafficCookie){
        var cookie = decodeURIComponent(trafficCookie);
        var indexOfSrc = cookie.indexOf("utmcsr");
        if(indexOfSrc > -1){
            cookie = cookie.substring(indexOfSrc);
            var cookieArr = cookie.split("|");
            for(var i=0;i<cookieArr.length;i++){
                var arr = cookieArr[i].split("=");
                if(arr[0] == "utmcsr"){
                    trafficSource = arr[1];
                }else if(arr[0] == "utmcmd"){
                    trafficMedium = arr[1];
                }else if(arr[0] == "utmccn"){
                    trafficCamp = arr[1];
                }
            }
        }
    }
    else{
        var paramObj = getUrlVars();
        trafficSource = paramObj['utm_source'] || trafficSource;
        trafficMedium = paramObj['utm_medium'] || trafficMedium;
        trafficCamp = paramObj['campaign'] || trafficCamp;
    }

    utmData['utm_source'] = trafficSource;
    utmData['utm_medium'] = trafficMedium;
    utmData['utm_campaign'] = trafficCamp;
    return utmData;
}

export function numberCheck(input){
    var counter = 0;
    for(var i = 0;i<input.length;i++){
        if ($.isNumeric(input[i])) {
        counter++;
      }
    }
    if(counter > 5){
        input = "Anonymous";
    }
    return input;
}

export function propertyType(type) {
    let property_type;
    var _type = type ? type.toLowerCase() : null;
    switch(_type){
        case 'sell':
        case 'sale':
        case 'buy':
            property_type = 'buy';
            break;
        case 'rent':
            property_type = 'rent';
            break;
        case 'pg':
        case 'flatmates':
        case 'flatmate':
            property_type = 'pg';
            break;
        default :
            property_type = null;
    }
    return property_type ? property_type : '';
}

export function jsUcfirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
export function scrollElmVert(el,num){
    var re=/html$/i;
    while(!re.test(el.tagName) && (1 > el.scrollTop)) el=el.parentNode;
    if(0 < el.scrollTop) el.scrollTop += num;
}

export  function elmYPosition(el) {
    var elm = 'string' === typeof el ? document.querySelector(el) : el;
    var y = elm.offsetTop;
    var node = elm;
    while (node.offsetParent && node.offsetParent != document.body) {
        node = node.offsetParent;
        y += node.offsetTop;
    }
    return y;
}

export function smoothScroll(elementY, duration) { 
    const startingY = window.pageYOffset;
    const diff = elementY - startingY;
    let start;

    window.requestAnimationFrame(function step(timestamp) {
      if (!start) start = timestamp;
      var time = timestamp - start;
      var percent = Math.min(time / duration, 1);
      window.scrollTo(0, startingY + diff * percent);

      if (time < duration) {
        window.requestAnimationFrame(step);
      }
    });
  }

export function convertspace(data) {
    data = data.replace(/ /g, "_");
    return data;
}
export function loadMapJs() {
    return new Promise(function(resolve, reject) {
        if (!document.getElementById('mapApi')) {
            var script = document.createElement('script');
            script.setAttribute('type', 'text/javascript');
            script.setAttribute('id', 'mapApi');
            script.setAttribute('src', '//maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyB2BN1HLjbZVKRXNmjJ6fhIyY1r4f_y1rc');
            document.head.appendChild(script);
            script.onload = function() {
              return resolve('sucess');
            };
            script.onerror = function() {
              return reject('errors');
            };
        }else{
            return resolve('sucess');
        }
    });
}

export function validateEmail(email){
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function connectionCheck(){
    if('connection' in navigator && navigator.connection.effectiveType === '4g'){
        return true;
    }
    return false;
}

export function trim(str) {
    str = str.replace(/\s+/g, ' ');
    str = str.replace(/^\s|\s$/g, '');
    return str;
}

export function cloneObj(obj, deep=false){
  var result = {};
  for(let key in obj){
    if(deep && obj[key] instanceof Object){
       if(obj[key] instanceof Array){
         result[key] = [];
         obj[key].forEach(function(item){
            if(item instanceof Object){
               result[key].push(cloneObj(item, true));
            } else {
               result[key].push(item);
            }
         });
       } else {
         result[key] = cloneObj(obj[key]);
       }
    } else {
       result[key] = obj[key];
    }
  }
  return result
}

export function analyticeventMapping(gtmData){
    var newGtmData = {};
    newGtmData['event_name'] = (gtmData.hasOwnProperty('event'))?gtmData['event']:'';
    newGtmData['ad_id'] = (gtmData.hasOwnProperty('Ad_id'))?gtmData['Ad_id']:'';
    newGtmData['subcat_name'] = (gtmData.hasOwnProperty('SubCategory_Name'))?gtmData['SubCategory_Name']:'';
    newGtmData['broker_id'] = (gtmData.hasOwnProperty('Broker_id'))?gtmData['Broker_id']:'';
    newGtmData['project_id'] = (gtmData.hasOwnProperty('Project_id'))?gtmData['Project_id']:'';
    newGtmData['subcat_id'] = (gtmData.hasOwnProperty('SubCategory_id'))?gtmData['SubCategory_id']:'';

    newGtmData['intent'] = (gtmData.hasOwnProperty('Intent'))?gtmData['Intent']:'';
    newGtmData['property_type'] = (gtmData.hasOwnProperty('Property_type'))?gtmData['Property_type']:'';
    newGtmData['locality_id'] = (gtmData.hasOwnProperty('Locality_id'))?gtmData['Locality_id']:'';
    newGtmData['locality_name'] = (gtmData.hasOwnProperty('Locality_name'))?gtmData['Locality_name']:'';
    newGtmData['bhk'] = (gtmData.hasOwnProperty('Bhk'))?gtmData['Bhk']:'';
    newGtmData['price_max'] = (gtmData.hasOwnProperty('Price_max'))?gtmData['Price_max']:'';
    newGtmData['price_min'] = (gtmData.hasOwnProperty('Price_min'))?gtmData['Price_min']:'';

    newGtmData['user_db_id'] = (gtmData.hasOwnProperty('user_db_id'))?gtmData['user_db_id']:'';
    newGtmData['HL_intent'] = (gtmData.hasOwnProperty('HL_intent'))?gtmData['HL_intent']:'';
    newGtmData['mobile'] = (gtmData.hasOwnProperty('mobile'))?gtmData['mobile']:'';
    newGtmData['email'] = (gtmData.hasOwnProperty('email'))?gtmData['email']:'';
    newGtmData['price'] = (gtmData.hasOwnProperty('price'))?gtmData['price']:'';

    newGtmData['filter_value'] = (gtmData.hasOwnProperty('Filter_value'))?gtmData['Filter_value']:'';
    newGtmData['filter_name'] = (gtmData.hasOwnProperty('Filter_name'))?gtmData['Filter_name']:'';

    newGtmData['cat_id'] = 20;
    newGtmData['cat_name'] = "Real-Estate";
    newGtmData['city_id'] = getCookie("prefer_city_id");
    newGtmData['city_name'] = getCookie("new_prefer_city");

    return newGtmData;
}
export function headerDropDown(){
    let categoryName = localStorage.getItem("category") || "res"
    let subcat = localStorage.getItem("type") || "buy"
    if (subcat == "pg") {
            $("#searchedCat").html("PG" + '<i class="icon-arrow"></i>');
    }
    else if (categoryName == 'com' && subcat == "buy") {
        $("#searchedCat").html("Commercial" + '<i class="icon-arrow"></i>');
    }
    else {
        $("#searchedCat").html(capitalizeFirstLetter(subcat) + '<i class="icon-arrow"></i>');
    }
}
export function analyticeutmMapping(gtmData) {
    var newUtmData = {};
    newUtmData['source'] = (gtmData.hasOwnProperty('utm_source'))?gtmData['utm_source']:'';
    newUtmData['name'] = (gtmData.hasOwnProperty('utm_campaign'))?gtmData['utm_campaign']:'';
    newUtmData['medium'] = (gtmData.hasOwnProperty('utm_medium'))?gtmData['utm_medium']:'';
    return newUtmData;
}

export function getNextDates(noOfDates, formatted = false, inclusive = false) {
	const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	return [...Array(noOfDates).keys()].map(i => {
		const date = new Date();
		date.setDate(date.getDate() + (inclusive ? i : (i + 1)));
		return formatted ? `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}` : date;
	});
}

/**
 * Site visit time slots
 *
 * @param start the slot start hour
 * @param until the slot end hour
 * @param interval the slot interval in hours
 * @param offset the slot start hour offset in hours
 * @returns {Array} the time slots
 */
export function getSiteVisitTimeSlots(start = 9, until = 20, interval = 1, offset = 2, date = new Date()) {

    const slots = [];
    const now = date;
    const hoursNow = now.getHours();
    const minutesNow = now.getMinutes();

    let scheduledStartHours = hoursNow + offset;

    // If the minutes is past the hour, step up the offset
    if(minutesNow > 0) {
        scheduledStartHours += 1;
    }

    // The scheduled start should be within the range
    if(scheduledStartHours >= start && scheduledStartHours < until) {
        start = scheduledStartHours;
    }

    for(let hour = start; hour < until; hour++) {
        let startHour = `${hour > 12 ? hour - 12 : hour} ${hour > 12 ? 'PM' : 'AM'}`;
        let untilHour = `${(hour + interval) > 12 ? (hour + interval) - 12 : (hour + interval)} ${(hour + interval) > 12 ? 'PM' : 'AM' }`;

        slots.push({
            value: hour,
            display: `${startHour} - ${untilHour}`,
        });
    }

    return slots;
}

export function highlightMatches(text, highlightText) {
    highlightText = unescape(highlightText);
    text = unescape(text);
    var regex = new RegExp(highlightText, 'gi');
    return text.replace(regex, function(str) {
        return "<strong>" + str + "</strong>"
    });
}

export function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
}

/**
 * Service to store the searched text
 * @param page Page Name
 * @param searchKey Searched Text by the user
 * @param searchParameter Search Parameter eg. Locality,Project,Zone,FreeText etc
 * @param redirectFlag Flag for Redirection by default is true
 * @returns Promise
 */
export function storeSearchText(page, searchKey, searchParameter, redirectFlag = true) {
    var storeData = {
        page,
        searchText: searchKey,
        searchParameter,
        channel: isMobile() ? 'MSITE' : 'Website',
        cityId: getCookie('prefer_city_id')
    };

    var promise = new Promise(function (resolve, reject) {
        if (searchKey !== '' && page !== '' && searchParameter !== '') {
            $.ajax({
                url: "/homes/storeSearchText",
                method: "POST",
                data: storeData
                , success: function (res) {
                    if (res.statusCode === 200 && res.message === 'success') {
                        if (redirectFlag) {
                            resolve('success');
                        } else {
                            reject('noRedirection')
                        }
                    } else {
                        reject('error')
                    }
                },
                error: function () {
                    reject('error')
                }
            });
        } else {
            reject('error');
        }
    });

    return promise;
}

/**
 * Store the search text and redirect. 
 * We redirect to url given irrespective of the search is stored or not.
 * @param page Page Name
 * @param searchText Searched Text by the user
 * @param linkType Search Parameter eg. Locality,Project,Zone,FreeText etc
 * @param redirectionURL URL on which we want to redirect
 * @param redirectionFlag Flag for Redirection by default is true
 */
export function storeSearchAndRedirect(page, searchText, linkType, redirectionURL, redirectionFlag = true) {
    storeSearchText(page, searchText, linkType, redirectionFlag).then(() => {
        window.location.href = redirectionURL
    }).catch(() => {
        window.location.href = redirectionURL
    })
}

export function convert(string) {
   var multiple = {
    '&' : '&amp;',
    '<' : '&lt;',
    '>' : '&gt;',
    '"' : '&quot;',
    '`' : '&apos;'
  };
  for(var char in multiple) {
    var before = char;
    var after= multiple[char]; 
    var pattern = new RegExp(before, 'g');
    string = string.replace(pattern,after);    
  }
  return string;
}

export function parseQueryParams() {
    const url = new URL(window.location.href);
    return url.searchParams;
}

export function isValidProjectBannerFormfillSource(source) {
    return source && ['HP_PROJECT_BACKDROP', 'HP_FEATURED_PROJECT']
        .indexOf(source.toUpperCase()) >= 0;
}

export function getUnitConfigurations(projectUnits = []) {
    return projectUnits ? projectUnits.reduce((types, unit) => {
        if (unit.subType in types) {
            if (-1 === types[unit.subType].indexOf(unit.type)) {
                types[unit.subType].push(unit.type);
            }
        } else {
            types[unit.subType] = [unit.type];
        }

        return types;
    }, {}) : {};
}

export function debounce(func, wait, immediate) {
    var timeout, args, context, timestamp, result;

    var later = function () {
        var last = _.now() - timestamp;

        if (last < wait && last >= 0) {
            timeout = setTimeout(later, wait - last);
        } else {
            timeout = null;
            if (!immediate) {
                result = func.apply(context, args);
                if (!timeout) context = args = null;
            }
        }
    };

    return function () {
        context = this;
        args = arguments;
        timestamp = _.now();
        var callNow = immediate && !timeout;
        if (!timeout) timeout = setTimeout(later, wait);
        if (callNow) {
            result = func.apply(context, args);
            context = args = null;
        }

        return result;
    };
}

export const detectLocations = (successCalback = () => {}, failureCallback = () => {}) => {
	navigator.permissions.query({ name: 'geolocation' }).then((result) => {
		if (result.state === 'prompt' || result.state === 'granted') {
			navigator.geolocation.getCurrentPosition((res) => {
				const { coords: { latitude, longitude } } = res;
				successCalback(latitude, longitude);
			}, (err) => {
				failureCallback();
			});
		} else{
			failureCallback();
		}
	});
};

export function removeUrl(text) {
    let urlRegex =/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
    return text.replace(urlRegex,"");
}

export function delegate(el, evt, sel, handler) {
    el.addEventListener(evt, function(event) {
        var t = event.target;
        while (t && t !== this) {
            if (t.matches(sel)) {
                handler.call(t, event);
            }
            t = t.parentNode;
        }
    });
};

export let quickLinks = {
    tabsData: null,
    htmlTpl: null,
    targetElm: document.getElementById('qlinkbox'),
    currentTabData: {
        slug: 'TopProjects',
        qlTypes: [
            { slug: 'TopProjects', name: 'TOP PROJECTS' },
            { slug: 'TopLocalities', name: 'TOP LOCALITIES' },
            { slug: 'TopBrokers', name: 'TOP BROKERS' },
            { slug: 'Blogs', name: 'BLOGS' }
        ],
        subTypes: [],
        currentSubType: '',
        currentSubTypeScrollPos: 0,
        propertyLinks: []
    },
    init: function() {
        document.getElementById('qlButtonLnks').onclick = function() {
            document.getElementById("quick_links_main").classList.toggle('active');
        }
        document.getElementById('quick_links_main').addEventListener('click', function(ev) {
            if (ev.target.id === 'quick_links_main') {
                document.getElementById("quick_links_main").classList.remove('active');
            }
        });
        if (document.getElementById('quick_links_main').classList.contains('hide')) {
            document.getElementById('quick_links_main').classList.remove('hide');
        }
        [...document.getElementsByClassName('js-tab-qlinks')].forEach(element => {
            element.addEventListener('click', (ev)=> {
                this.generateCurrentTab(ev.target.dataset.key);
                this.renderTemplate();
            });
        });
        let stabElm = document.getElementById('stabln');
        [...document.getElementsByClassName('js-subtab-qlinks')].forEach(element => {
            element.addEventListener('click', (ev)=> {
                this.currentTabData.currentSubTypeScrollPos = (stabElm) ? stabElm.scrollLeft : 0;
                this.generateCurrentTab(this.currentTabData.slug, ev.target.dataset.subkey);
                this.renderTemplate();
            });
        });

        if (stabElm) {
            stabElm.scrollLeft = this.currentTabData.currentSubTypeScrollPos;
            if (this.currentTabData.currentSubType === 'BUILDERFLOOR') {
                stabElm.scrollLeft = stabElm.clientWidth;
            }
        }
    },
    renderInitialQuickLinks: function() {
        this.getQuickLinks().then((resp) => {
            this.tabsData = resp;
            if (this.tabsData) {
                this.generateCurrentTab('TopProjects', 'COMPLETED');
                this.renderTemplate();
            }
        }).catch((err) => {
            console.error(err);
        });
    },
    renderTemplate: function() {
        const innerTplStr = document.getElementById('quickLinksBoxTpl').innerHTML;
        this.htmlTpl = _.template(innerTplStr);
        this.targetElm.innerHTML = this.htmlTpl(this.currentTabData);
        this.init();
    },
    generateCurrentTab: function(typeKey, subTypekey = '') {
        this.currentTabData.slug = typeKey;
        this.currentTabData.currentSubType = subTypekey.toUpperCase();

        this.currentTabData.subTypes = [];
        this.tabsData[typeKey].forEach((el) => {
            if (el.subType) {
                this.currentTabData.subTypes.push(el.subType.toUpperCase());
            }
        });
        if (this.currentTabData.currentSubType !== '') {
            const elm = this.tabsData[typeKey].find((el)=> {
                return el.subType.toUpperCase() === this.currentTabData.currentSubType;
            });
            if (elm) {
                this.currentTabData.propertyLinks = elm.data;
            }
        } else {
            this.currentTabData.propertyLinks = (this.tabsData[typeKey].length > 0) ? this.tabsData[typeKey][0].data : [];

            if (this.tabsData[typeKey].length > 0) {
                if (this.tabsData[typeKey][0].subType) {
                    this.currentTabData.currentSubType = this.tabsData[typeKey][0].subType.toUpperCase();
                } else {
                    this.currentTabData.currentSubType = '';
                }
            } else {
                this.currentTabData.currentSubType = '';
            }
        }
    },

    getQuickLinks: function() {
        const getCityName = getCookie("new_prefer_city");
        const getCityId = getCookie("prefer_city_id");
    
        const promise = new Promise((res, rej) => {
            $.ajax({
                type: 'POST',
                url: '/homes/re2/quickLinks',
                data: { 'cid': getCityId, 'cname': getCityName},
                dataType: 'json',
                success: function(response) {
                    response = JSON.parse(response);
                    if ('data'  in response) {
                        res(response.data);
                    } else {
                        res([]);
                    }
                },
                error: function(err) {
                    rej(err);
                }
            });
        });
        return promise;
    }
};
