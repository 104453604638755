import {getterSetter} from '../utils/common'
import {addEmail,updateSession} from '../loginService'
import {generateOTP,setAlert} from './replyservices'
require('../services/gaService')
export const replyError  = (respdata)=> {
	var alertMessage='';
	var conflict;
	var data = JSON.parse(respdata);
	if (data.DetectEmailMobileConflictApplicationResponse.errors){
		var errObj = data.DetectEmailMobileConflictApplicationResponse.errors || [];
		if (errObj.length > 0) {
			getterSetter.set('errorLabel',errObj[0].code)
			var errMsg;
			gaService.addEvent('link-RealestateResponsiveUI', 'Mobile_As_Login', globals.pageType + "_" + errObj[0].code);
			switch (errObj[0].code) {

				// 1. Non Login :-  Email exist in acc a verified and any mobile
				case "EXISTING_USER_EMAIL_VERIFIED_LOGIN_NEEDED" :
					errMsg = "This Email is registered with us. Please login or use a different email";
					break;

				// 2. Non Login :- Email exist in acc a unverified and any mobile which doesn't belongs to in account
				case "MOBILE_NUMBER_CONFLICT" :
					errMsg = "OTP sent on +" + getterSetter.get('phonecode') +  getterSetter.get('mobile') + ".";
					generateOTP()
					break;

				// 3. Non Login :- Email doesn't exist and Mobile verified with acc a
				case "EXISTING_USER_MOBILE_VERIFIED_LOGIN_NEEDED" :
					errMsg = "OTP sent on +"+ getterSetter.get('phonecode') + getterSetter.get('mobile') + ".";
					generateOTP()
					break;

				// 4. Non Login :- Email don't exist in db and mobile unverified
				case "EMAIL_ID_CONFLICT" :
					errMsg = "OTP sent on +" + getterSetter.get('phonecode')+ getterSetter.get('mobile') + ".";
					generateOTP()
					break;

				// 5. Login :- Email Exist in logged in acc and Mobile exist in acc b verified or unverified
				case "MOBILE_ALREADY_REGISTERED" :
					errMsg = "OTP sent on +" + getterSetter.get('phonecode')+ getterSetter.get('mobile') + ".";
					generateOTP()
					break;

				// 6. Login :- Mobile Exist in logged in acc and Email exist in acc b verified or unverified
				case "EMAIL_ALREADY_REGISTERED" :

					var evPayload = {
						"userEmail": getterSetter.get('mobile'),
						"userLoggedIn": true,
						"loggedInUserId": getterSetter.get('loggedInUserId') || ''
					};
					errMsg = "Please Wait...Sending Verification link in given Email.";
					addEmail(evPayload).then(function (evResult) {
						evResult = JSON.parse(evResult);
						if (evResult != 'API_CALL_FAILED') {
							evResult = evResult.AddEmailToUserAccountApplicationResponse;
							if (!("errors" in evResult)) {
								alertMessage = "Verification link has been sent. please verify or change email and submit";
								alertClass = 'alert-success';
							} else if (evResult.errors[0].code == "EMAIL_PENDING_VERIFICATION") {
								alertMessage = "Verification link has been sent. please verify or change email and submit";
								alertClass = 'alert-success';
							} else {
								alertMessage = evResult.errors[0].code;
								alertClass = 'alert-danger';
							}
						} else {
							alertClass = 'alert-success';
							alertMessage = evResult;
						}
					});
					break;

				default:
					break;
			}
			alertMessage = errMsg;
			getterSetter.set('errMsg',alertMessage)
			
		}
	}else{
		if(!conflict){
			generateOTP()
		}
	}
	setAlert();
}
export function updateSessionFunc() {
	var pushEmailData = {
		'emailId': getterSetter.get('email'),
		'isVerified': 1
	};
	var userdata = {
		"userEmail": pushEmailData
	}
		updateSession(userdata).then(function (response) {
	});
}