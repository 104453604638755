export function getContactInitiateEvents(reqSource){
	let eventType;
	switch (reqSource) {
		case "CONTACT_IN_LISTING_FLP":
			eventType = 'contact_on_vap_initiate';
			break;
		case "CLICK_TO_VIEW_IN_LISTING":
		  eventType = 'view_number_on_vap_initiate';
			break;
		case "CONTACT_IN_LISTING_SNB":
			eventType = "contact_on_snb_page_initiate";
			break;
		case "PROJECT_CONTACT_IN_SNB":
			eventType = "contact_on_project_snb_page_initiate";
			break;
		case "CONTACT_SNB_BROKER":
			eventType = "contact_on_broker_snb_page_initiate";
			break;
		case "PROJECT_CONTACT_IN_BUILDER":
			eventType = "contact_on_builder_page_initiate";
			break;
		case "PROJECT_CONTACT_IN_PROJECT":
			eventType = "download_brochure_on_project_page_initiate";
			break;
		case "PROJECT_UNIT_CONTACT_IN_PROJECT":
			eventType = "contact_on_unit_project_page_initiate";
			break;
		case "CONTACT_IN_LISTING_PROJECT":
			eventType = "contact_on_listing_project_page_initiate";
			break;
		case "CONTACT_IN_LISTING_INDIVIDUAL":
			eventType = "contact_on_listing_individual_page_initiate";
			break;
		case "CLICK_TO_VIEW_IN_SNB_BROKER":
			eventType = "click_to_view_number_on_broker_snb_initiate";
			break;
		case "REQUIREMENT_POPUP_PROJECT":
		    eventType = "pop_ups_project";
			break;
		case "REQUIREMENT_POPUP_FLP":
		    eventType = "pop_ups_vap";
			break;
		case "PROJECT_CONTACT_IN_LOCALITY":
		    eventType = "contact_on_project_snb_page_on_locality_initiate";
			break;
		case "CONTACT_BROKER_IN_LOCALITY":
			eventType = "contact_on_broker_snb_page_on_locality_initiate";
			break;
		case "CONTACT_IN_LISTING_RENT_LOCALITY":
			eventType = "contact_on_rent_snb_page_on_locality_initiate";
			break;
		case "CONTACT_IN_LISTING_SELL_LOCALITY":
			eventType = "contact_on_buy_snb_page_on_locality_initiate";
			break;
		case "IM_INTERESTED_IN_PROJECT":
			eventType = "contact_on_im_interested_in_project_initiate";
			break;
		case "CONTACT_INDIVIDUAL_BROKER":
			eventType = "contact_on_individual_broker_initiate";
			break;
		case "CONTACT_IN_LISTING_SIMILAR":
			eventType = "contact_on_listing_similar_initiate"
	}
	return eventType;
}

export function getContactSuccessEvents(reqSource){
	let eventType;
	switch (reqSource) {
		case "CONTACT_IN_LISTING_FLP":
			eventType = 'contact_on_vap_success';
			break;
		case "CLICK_TO_VIEW_IN_LISTING":
		  eventType = 'view_number_on_vap_success';
			break;
		case "CONTACT_IN_LISTING_SNB":
			eventType = "contact_on_snb_page_success";
			break;
		case "PROJECT_CONTACT_IN_SNB":
			eventType = "contact_on_project_snb_page_success";
			break;
		case "CONTACT_SNB_BROKER":
			eventType = "contact_on_broker_snb_page_success";
			break;
		case "PROJECT_CONTACT_IN_BUILDER":
			eventType = "contact_on_builder_page_success";
			break;
		case "PROJECT_CONTACT_IN_PROJECT":
			eventType = "download_brochure_on_project_page_success";
			break;
		case "REQUIREMENT_POPUP_PROJECT":
			eventType = "pop_ups_project_page_success";
			break;
		case "REQUIREMENT_POPUP_FLP":
			eventType = "pop_ups_vap_page_success";
			break;
		case "PROJECT_UNIT_CONTACT_IN_PROJECT":
			eventType = "contact_on_unit_project_page_success";
			break;
		case "CONTACT_IN_LISTING_PROJECT":
			eventType = "contact_on_listing_project_page_success";
			break;
		case "CONTACT_IN_LISTING_INDIVIDUAL":
			eventType = "contact_on_listing_individual_page_success";
			break;
		case "CLICK_TO_VIEW_IN_SNB_BROKER":
			eventType = "click_to_view_number_on_broker_snb_success";
			break;
		case "PROJECT_CONTACT_IN_LOCALITY":
			eventType = "contact_on_project_snb_page_on_locality_success";
			break;
		case "CONTACT_BROKER_IN_LOCALITY":
			eventType = "contact_on_broker_snb_page_on_locality_success";
			break;
		case "CONTACT_IN_LISTING_RENT_LOCALITY":
			eventType = "contact_on_rent_snb_page_on_locality_success";
			break;
		case "CONTACT_IN_LISTING_SELL_LOCALITY":
			eventType = "contact_on_buy_snb_page_on_locality_success";
			break;
		case "IM_INTERESTED_IN_PROJECT":
			eventType = "contact_on_im_interested_in_project_success";
			break;
		case "CONTACT_INDIVIDUAL_BROKER":
			eventType = "contact_on_individual_broker_success";
			break;
		case "CONTACT_IN_LISTING_SIMILAR":
			eventType = "contact_on_listing_similar_success"
	}
	return eventType;
}

export function getEventId(reqSource){
	let eventId;
	switch(reqSource) {
		case "CONTACT_IN_LISTING_FLP":
		case "REQUIREMENT_POPUP_FLP":
		case "CLICK_TO_VIEW_IN_LISTING":
		case "CONTACT_IN_LISTING_SNB":
		case "CONTACT_IN_LISTING_INDIVIDUAL":
		case "CONTACT_IN_LISTING_PROJECT":
		case "CONTACT_IN_LISTING_RENT_LOCALITY":
		case "CONTACT_IN_LISTING_SELL_LOCALITY":
		case "CONTACT_IN_LISTING_SIMILAR":
			eventId = 'Ad_id';
			break;
		case "CLICK_TO_VIEW_IN_SNB_BROKER":
		case "CONTACT_SNB_BROKER":
		case "CONTACT_BROKER_IN_LOCALITY":
		case "CONTACT_INDIVIDUAL_BROKER":
			eventId = "Broker_id";
			break;
		case "PROJECT_CONTACT_IN_SNB":
		case "PROJECT_CONTACT_IN_BUILDER":
		case "PROJECT_CONTACT_IN_PROJECT":
		case "REQUIREMENT_POPUP_PROJECT":
		case "PROJECT_UNIT_CONTACT_IN_PROJECT":
		case "PROJECT_CONTACT_IN_LOCALITY":
		case "IM_INTERESTED_IN_PROJECT":
			eventId = "Project_id";
			break;
	}
	return eventId;
}

export function getContactSubCategory(reqSource,adDetails){
	let subCategory;
	adDetails.category = (adDetails.category)?adDetails.category:'Residential';
	switch(reqSource) {
		case "CONTACT_IN_LISTING_FLP":
		case "REQUIREMENT_POPUP_FLP":
		case "CLICK_TO_VIEW_IN_LISTING":
		case "CONTACT_IN_LISTING_SIMILAR":
			subCategory = adDetails.type+ ' For '+ getAvailableFor(adDetails.availableFor.toLowerCase()) ;
			break;
		case "CONTACT_IN_LISTING_SNB":
		case "CONTACT_IN_LISTING_INDIVIDUAL":
		case "CONTACT_IN_LISTING_RENT_LOCALITY":
		case "CONTACT_IN_LISTING_SELL_LOCALITY":
			subCategory = adDetails.propertyType+ ' For '+ getAvailableFor(adDetails.availableFor.toLowerCase()) ;
			break;
		case "CONTACT_IN_LISTING_PROJECT":
			subCategory =  adDetails.category + ' ' + adDetails.propertyType+ ' For '+ getAvailableFor(adDetails.availableFor.toLowerCase()) ;
			break;
		case "CLICK_TO_VIEW_IN_SNB_BROKER":
		case "CONTACT_SNB_BROKER":
		case "CONTACT_BROKER_IN_LOCALITY":
		case "CONTACT_INDIVIDUAL_BROKER":
			subCategory = '' ;
			break;
		case "PROJECT_CONTACT_IN_SNB":
		case "PROJECT_CONTACT_IN_BUILDER":
		case "PROJECT_CONTACT_IN_PROJECT":
		case "REQUIREMENT_POPUP_PROJECT":
		case "PROJECT_UNIT_CONTACT_IN_PROJECT":
		case "PROJECT_CONTACT_IN_LOCALITY":
		case "IM_INTERESTED_IN_PROJECT":
			subCategory =  adDetails.category + ' ' + adDetails.type+ ' For Sale';
			break;
	}
	return subCategory;
}

export function getAvailableFor(chkAvailabel) {
	let availabel;
	switch(chkAvailabel){
		case "sell":
		case "sale":
			availabel = 'Sale';
			break;
		case "rent":
			availabel = 'Rent';
			break;
		case "pg":
			availabel = "Pg";
			break;
		case "flatmates":
		case "flatmate":
			availabel= "Flatmate";
			break;
	}
	return availabel;
}
