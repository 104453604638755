export const actBroadbandCities = [23, 28, 1116463];

export const homeloan = {
    finalized : [
        {
            value : 'IMMEDIATELY',
            label : 'Yes'
        },
        {
            value : 'WITHIN_1_MONTH',
            label : 'No, but would finalize in next 1 month'
        },
        {
            value : 'WITHIN_2_TO_3_MONTHS',
            label : 'No, but would finalize in next 2-3 months'
        },
        {
            value : 'WITHIN_3_TO_6_MONTHS',
            label : 'No, but would finalize in next 3-6 months'
        }
    ],
    timeline : [
        {
            value : 'IMMEDIATELY',
            label : 'Immediately'
        },
        {
            value : 'WITHIN_1_MONTH',
            label : 'Within next 1 month'
        },
        {
            value : 'WITHIN_2_TO_3_MONTHS',
            label : 'In the next 2-3months'
        },
        {
            value : 'WITHIN_3_TO_6_MONTHS',
            label : 'In next 3-6 months'
        }
    ]
};