import {getCookie} from '../services/cookieService'
import {searchActionService} from '../services/searchActionService'
import {seoUrl,isSet,intentType,isMobile,highlightMatches,isEmpty,storeSearchAndRedirect} from '../utils/common'
require('../services/gaService.js')
var search_term = '';
var cityId = getCookie('prefer_city_id');
cityId = cityId || 31;
var projectCities = ['27', '132222' , '201301' , '201002' , '31', '400701', '400601'];
var citySelectFlag = true;
var isCityCollapsed = true;
var headsearch = "form-inline hidden-xs hidden-sm";
var searchicon = "icon-search";
var agricultureList;
var searchform = {
	locality: null
};
var homeCategorySelected ='';
var adTypeKey='';
var defaultUrlCate = false;
var showPresearchSuggest;
var finalSearchArr=[];
var defaultAdTypeSelect;
var prefer_city = getCookie('new_prefer_city');
$("#citySelectFlag").html('');
var adType={
	"res":[{"key":"buy","displayVal":"Buy"},{"key":"rent","displayVal":"Rent"},{"key":"pg","displayVal":"PG/Hostels"}],
	"com":[{"key":"buy","displayVal":"Buy"},{"key":"rent","displayVal":"Rent"}],
	"agr":[{"key":"buy","displayVal":"Buy"}]
};

$('#searchHomes').on('click', function(event){
	if(Search.isMobile())$('html, body').animate({scrollTop:134}, 300);
	showPresearchsuggest(event);
});

$(".viewdls").click(function() {
    $(this).siblings(".callmead").show(300);
});

$(".closeqbox").click(function() {
	$(this).parent(".callmead").hide(300);
});

window. Search = {
	init:function() {
		$( ".quikr-search" ).prepend('<div class="back-icon icon-left" id="m_site_in" onclick="removeInnerIcon()"></div>');
		if(location.search) {
			var splitSearchKey = location.search.split("?");
			if(splitSearchKey[1] == 'cat=commercial') {
				var defaultUrlCate = true;
				homeCategorySelected = 'com';
				localStorage.setItem('type', 'buy');
				localStorage.setItem('category', 'com');
			} else {
				defaultUrlCate = false;
			}
		}
	},
	isMobile :function () {
        var condition = false;
        return window.innerWidth < 992 ? !condition : condition;
    }
}

$(function(){
	Search.init();
	if(!cityId){
		citySelectFlag = false;
		$("#citySelectFlag").html('Please select the city to proceed');
	}
	setPreSearchSuggestFlags();
	defaultAdTypeSelect = defaultType();
	tabSelect();
	adTypeKey = localStorage.getItem('type') || 'buy';
	sethomeCategorySelected();
});

function debounce(func, wait, immediate) {
    var timeout, args, context, timestamp, result;

    var later = function () {
        var last = _.now() - timestamp;

        if (last < wait && last >= 0) {
            timeout = setTimeout(later, wait - last);
        } else {
            timeout = null;
            if (!immediate) {
                result = func.apply(context, args);
                if (!timeout) context = args = null;
            }
        }
    };

    return function () {
        context = this;
        args = arguments;
        timestamp = _.now();
        var callNow = immediate && !timeout;
        if (!timeout) timeout = setTimeout(later, wait);
        if (callNow) {
            result = func.apply(context, args);
            context = args = null;
        }

        return result;
    };
};

function debouncedKeypress() {
    var e = document.getElementById('searchHomes');
    if (isSet(e)) {
        key = e.value;
        getSuggestedLocalityNew(e.value,1);
    }
}

var handleKeypress = debounce(function () {
    debouncedKeypress();
}, 500);

function sethomeCategorySelected(){
	$("#defaultAdTypeSelect").html(defaultAdTypeSelect);
}

function setPreSearchSuggestFlags(){
	showPresearchSuggest = true;
}

function showPresearchsuggest(e){
	if(Search.isMobile()){
		$("#m_site_search").addClass('m_site');
	}
}
document.addEventListener("click", function(event) {
	if (event.target.closest("#searchHomes")){
		$('#showPresearchSuggest').toggleClass('hide');
		return;
	} 	
    $("#showPresearchSuggest").addClass("hide");
});
$("#m_site_rmv").on('click',function(){
	$("#m_site_search").removeClass('m_site');
	$("#showPresearchSuggest").addClass("hide");
})

window.removeInnerIcon = ()=>{
	$(".quikr-search").removeClass('m_site');
}

function setPreSearchSuggestFlagsFalse(){
	showPresearchSuggest = false;
}

function setDisplayOrder(data){
	var SortArray = [];
	var temparray = [];

	for (var c in data){
		SortArray.push({type: c, data: data[c]});

	}
	for ( var i = 0; i< SortArray.length; i++ ){
		var temp = [];
		for (var j = 1; j< SortArray[i].data.length; j++){
			temp.push(SortArray[i].data[j]);
		}
		temp.sort(function(a, b){
			return b.count-a.count;
		});
		temparray.push({type: SortArray[i].type, data:temp});
		temp = [];
		temparray[i].data.splice(0, 0, SortArray[i].data[0]);
	}
	finalSearchArr = temparray;
	var sumOfFirstTwo = 0;
}

function mappedata(obj) {
	obj.type = 'projects';
	obj.groupHeaderName = 'Projects';
	obj.resultFlag = 1
	return obj;
}
function mappedataBuilder(obj) {
	obj.type = 'builders';
	obj.groupHeaderName = 'Builders';
	obj.resultFlag = 1
	return obj;
}
function mappedataLocality(obj) {
	obj.type = 'localities';
	obj.groupHeaderName = 'Localities';
	obj.resultFlag = 1
	return obj;
}

function defaultType() {
	var select_city = getCookie('prefer_city_id');
	let type;
	if(projectCities.includes(select_city)){
		type = 'project'
	}else{
		type = localStorage.getItem('type') || 'buy';
	}

	switch(type) {
		case 'buy' :
			defaultAdTypeSelect = 'Buy';
			break;
		case 'rent' :
			defaultAdTypeSelect = 'Rent';
			break;
		case 'pg' :
			defaultAdTypeSelect = 'PG/Hostels';
			break;
		case 'project' :
			defaultAdTypeSelect = 'Projects';
			break;
		default:
			defaultAdTypeSelect = 'Buy';
	}
	return defaultAdTypeSelect;
}

function tabSelect(){
	var select_city = getCookie('prefer_city_id');
	let type;
	if(projectCities.includes(select_city)){
		type = 'project'
	}else{
		type = localStorage.getItem('type') || 'buy';
		let category = localStorage.getItem('category')
		if(category == 'agr' || category == 'com'){
			type = category;
		}
	}

	switch(type) {
		case 'buy' :
			 $("#buytab").addClass("active");
			break;
		case 'rent' :
			$("#renttab").addClass("active");
			break;
		case 'pg' :
			$("#pgtab").addClass("active");
			break;
		case 'agr' :
			$("#agrtab").addClass("active");
			break;
		case 'com' :
			$("#comtab").addClass("active");
			$('#bsrBtn').addClass('showdw');
			break;
		case 'project':
			$('#protab').addClass('active');
			break;
		default:
			$("#buytab").addClass("active");
	}
}

window.selectAdType = (adTypeObj)=>{
	defaultAdTypeSelect = adTypeObj.displayVal;
	adTypeKey = adTypeObj.key;
	localStorage.setItem('type',adTypeObj.key);
	sethomeCategorySelected();
	defaultType();
}

window.selectCategoryType = (adTypeObj)=>{
    defaultAdTypeSelect = adTypeObj.displayVal;
    adTypeKey = adTypeObj.key;
    localStorage.setItem('type',adTypeObj.key);
}

window.setSearchgaType = (type)=>{
	localStorage.setItem('searchgaType',type);
}

window.searchGa = (position)=>{
	var searchgaType =  localStorage.getItem("searchgaType") || 'buy';
	var page_type = globals.pageType.toLowerCase();
	switch(position){
		case "tabclick":
			gaService.addEvent('RealEstate', 'QH_'+page_type , 'QH_'+page_type+'_search_'+searchgaType+'_click');
			break;
		case "searchBox":
			gaService.addEvent('RealEstate', 'QH_'+page_type , 'QH_'+page_type+'_search_'+searchgaType+'_searchBox_click');
			break;
		case "searchButton":
			gaService.addEvent('RealEstate', 'QH_'+page_type , 'QH_'+page_type+'_search_'+searchgaType+'_searchButton_click');
			break;
		case "searchcity":
			gaService.addEvent('RealEstate', 'QH_'+page_type , 'QH_'+page_type+'_search_'+searchgaType+'_city_click');
			break;
		case "searchBox":
			gaService.addEvent('RealEstate', 'QH_'+page_type , 'QH_'+page_type+'_search_'+searchgaType+'_searchBox_click');
			break;
	}
}

function getSuggestedLocalityNew(key,homeFlag) {
	var cityId = getCookie('prefer_city_id');
	var    resp = [];
	$('#showPresearchSuggest').addClass('hide');
	cityId = cityId || 31;
	if(key.length > 2)
	{
		$.ajax({
			url: "/homes/re2/search/" + cityId + "/" + key,
			method: "GET",
			success: function (data) {
				data = JSON.parse(data);
				var data = data.data;
				var localitycount = 0;
				var buildercount = 0;
				var projectcount = 0;
				var limit =2;
				if(agricultureList == "agr")
				{
					data.builders = {};
				}
				setDisplayOrder(data);
				var finalArr = finalSearchArr,
					lengthArr = finalArr.length,
					returnResp = [];
				for (var c = 0; c < lengthArr; c++){
					var obj = finalArr[c],
						valArr = obj.data;

					for (var d = 0; d < limit; d++) {
						if ("object" == typeof valArr && "undefined" != typeof valArr[d]) {
							var type = obj.type;
							valArr[d]["groupHeaderName"] = (d >= 0) ? type[0].toUpperCase() + type.substring(1, type.length) : "";
							valArr[d]["type"] = obj.type;
							valArr[d]["resultFlag"] = 1;
							returnResp.push(valArr[d]);
							if(obj.type == 'builders'){
								buildercount++;
							}
							if(obj.type == 'localities'){
								localitycount++;
							}
							if(obj.type == 'projects'){
								projectcount++;
							}
						}
					}
				}

				if(localitycount < 2 && finalSearchArr[2].data.length > 2){
					switch(localitycount){
						case 1:
							returnResp.push(mappedata(finalSearchArr[2].data[2]));
							finalSearchArr[2].data.splice(2,1);
							break;
						case 0:
							returnResp.push(mappedata(finalSearchArr[2].data[2]));
							finalSearchArr[2].data.splice(2,1);
							if(finalSearchArr[2].data[3])
								returnResp.push(mappedata(finalSearchArr[2].data[3]));
							finalSearchArr[2].data.splice(2,1);
							break;
					}
				} else{
					if(localitycount < 2 && finalSearchArr[1].data.length > 2){
						switch(localitycount){
							case 1:
								returnResp.push(mappedataBuilder(finalSearchArr[1].data[2]));
								finalSearchArr[2].data.splice(2,1);
								break;
							case 0:
								returnResp.push(mappedataBuilder(finalSearchArr[1].data[2]));
								finalSearchArr[1].data.splice(2,1);
								if(finalSearchArr[1].data[3])
									returnResp.push(mappedata(finalSearchArr[1].data[3]));
								finalSearchArr[1].data.splice(3,1);
								break;
						}
					}
				}

				if(buildercount < 2 && finalSearchArr[2].data.length > 2){
					switch(buildercount){
						case 1:
							returnResp.push(mappedata(finalSearchArr[2].data[2]));
							finalSearchArr[2].data.splice(2,1);
							break;
						case 0:
							returnResp.push(mappedata(finalSearchArr[2].data[2]));
							finalSearchArr[2].data.splice(2,1);
							if(finalSearchArr[2].data[3])
								returnResp.push(mappedata(finalSearchArr[2].data[3]));
							finalSearchArr[2].data.splice(3,1);
							break;
					}
				} else{
					if(buildercount < 2 && finalSearchArr[0].data.length > 2){
						switch(buildercount){
							case 1:
								returnResp.push(mappedataLocality(finalSearchArr[0].data[2]));
								finalSearchArr[0].data.splice(2,1);
								break;
							case 0:
								returnResp.push(mappedataLocality(finalSearchArr[0].data[2]));
								finalSearchArr[0].data.splice(2,1);
								if(finalSearchArr[0].data[3])
									returnResp.push(mappedataLocality(finalSearchArr[0].data[3]));
								finalSearchArr[0].data.splice(3,1);
								break;
						}
					}
				}

				if(projectcount < 2 && finalSearchArr[0].data.length > 2){
					switch(projectcount){
						case 1:
							returnResp.push(mappedataLocality(finalSearchArr[0].data[2]));
							finalSearchArr[0].data.splice(2,1);
							break;
						case 0:
							returnResp.push(mappedataLocality(finalSearchArr[0].data[2]));
							finalSearchArr[0].data.splice(2,1);
							if(finalSearchArr[0].data[3])
								returnResp.push(mappedataLocality(finalSearchArr[0].data[3]));
							finalSearchArr[0].data.splice(3,1);
							break;
					}
				} else{
					if(projectcount < 2 && finalSearchArr[1].data.length > 2){
						switch(projectcount){
							case 1:
								returnResp.push(mappedataBuilder(finalSearchArr[1].data[2]));
								finalSearchArr[1].data.splice(2,1);
								break;
							case 0:
								returnResp.push(mappedataBuilder(finalSearchArr[1].data[2]));
								finalSearchArr[1].data.splice(2,1);
								if(finalSearchArr[1].data[3])
									returnResp.push(mappedataBuilder(finalSearchArr[1].data[3]));
								finalSearchArr[1].data.splice(3,1);
								break;
						}
					}
				}
				var preference = ["localities","builders","projects"];
				returnResp.sort(function(a, b){
					if(preference.indexOf(b.type) < preference.indexOf(a.type)){
						return 1;
					}
					else {
						return 0;
					}
				});

				returnRespDatahtml='';
				for (var i = 0; i < returnResp.length; i++) {
					var returnRespData = returnResp[i];
					if(returnRespData.groupHeaderName=='Localities'){
						var urlpayload=JSON.stringify({'type':'localities', 'name' : returnRespData.name.replace(/ /g, "/"),'id' : returnRespData.id});
					}
					if(returnRespData.groupHeaderName=='Builders'){
						var urlpayload=JSON.stringify({'type':'builders', 'name' : returnRespData.name.replace(/ /g, "/"),'id' : returnRespData.id});
					}
					if(returnRespData.groupHeaderName=='Projects'){
						var urlpayload=JSON.stringify({'type':'projects', 'name' : returnRespData.name.replace(/ /g, "/"),'id' : returnRespData.id,'location' : returnRespData.locality.replace(/ /g, "/")});
					}
					select = 'selectMatchurl(' + urlpayload + ')';
					returnRespDatahtml += '<li onclick=' + select + '>';
					returnRespDatahtml += '<a>';
					returnRespDatahtml += '<div class="projectwidthadd">';
					returnRespDatahtml += '<span>'+returnRespData.name+'</span>';
					if(returnRespData.count && returnRespData.groupHeaderName!= 'Projects'){
						returnRespDatahtml += '<span><b>('+returnRespData.count+')</b></span>';
					}
					returnRespDatahtml += '</div>';
					returnRespDatahtml += '<span>'+returnRespData.groupHeaderName+'</span>';
					returnRespDatahtml += '</a>';
					returnRespDatahtml += '</li>';
				}
				$("#showautosuggest").show('fast');
				$("#showautosuggest").html(returnRespDatahtml);
			}
		});
	} else {
		$("#showautosuggest").html('');
		showPresearchsuggest();
		return [{"resultFlag":'2'}];
	}
}

function selectMatchurl(obj_url){
	var cityName = getCookie("new_prefer_city"),
		cityId =  getCookie("prefer_city_id"),
		catNameMap = {
			"res" : "residential",
			"com" : "commercial",
			"agr" : "agricultural"
		};
	if(obj_url.type=="projects"){
		obj_url.name=(obj_url.name).replace(/\/+/g, ' ');
		var project = (obj_url.name).replace(/\s+/g, '-').toLowerCase();
		obj_url.location=(obj_url.location).replace(/\/+/g, ' ');
		var location = (obj_url.location).replace(/\s+/g, '-').toLowerCase();
		cityName = cityName.replace(/\s+/g, '-').toLowerCase();
		window.location.href = "/homes/project/"+project+"+"+location+"+"+cityName+"+"+obj_url.id;
	}
	else  if(obj_url.type=="builders"){
		obj_url.name=(obj_url.name).replace(/\/+/g, ' ');
		var builder = (obj_url.name).replace(/\s+/g, '-').toLowerCase();
		window.location.href = "/homes/builder/"+builder+"+"+obj_url.id;
	}
	else  if(obj_url.type=="localities"){
		var search_term = {
			"resultFlag":0,
			"searchKey":$("#searchHomes").val(),
			"categoryName":localStorage.getItem("category") || "",
			"adType":localStorage.getItem("type") || "",
			"type":"freetxt"
		};
		obj_url.name=(obj_url.name).replace(/\/+/g, ' ');
		var location = (obj_url.name).replace(/\s+/g, '-').toLowerCase();
		cityName = cityName.replace(/\s+/g, '-').toLowerCase();

		var homeUrl = 'property/';
		homeUrl += catNameMap[search_term.categoryName ? search_term.categoryName : 'res'];
		if ('pg' == search_term.adType) {
			homeUrl += '-for-pg_flatmates';
		} else {
			homeUrl += '-for';
		}
		if (search_term.adType) {
			switch (search_term.adType) {
				case 'buy' :
					homeUrl += '-sale';
					break;

				case 'pg' :
					homeUrl += '';
					break;

				default:
					homeUrl += '-' + search_term.adType;

			}
		} else {
			homeUrl += '-sale';
		}

		homeUrl += '-in-';

		homeUrl += location+"-";
		homeUrl += cityName+"-";

		homeUrl += 'cid_' + cityId;
		homeUrl += '-lid_' + obj_url.id;

		homeUrl = homeUrl.toLowerCase();
		homeUrl = homeUrl.replace(/[ ]/g, "-");
		var locSnburl = "/homes/"+homeUrl;
		window.location.href = locSnburl;
	}
	else{
		var search_term = {
			"resultFlag":0,
			"searchKey":$("#searchHomes").val(),
			"categoryName":localStorage.getItem("category") || "",
			"adType":localStorage.getItem("type") || "",
			"type":"freetxt"};
		submitSearch(search_term);
	}
}

window.selectCategory = (categoryName,obj)=>{
	$('.srchtab').removeClass('active');
	$(obj).children('.srchtab').addClass('active');
	searchActionService.setAction('Browse');
	let adTypeValues = adType[categoryName];
	adTypeKey = adType[categoryName][0].key;
	homeCategorySelected = categoryName;
	agricultureList = categoryName;
	localStorage.setItem('category',categoryName);
	if(categoryName == 'com'){
		$("#defaultAdTypeSelect").html('Lease');
		$('#comtab').addClass('active');
		$('#bsrBtn').addClass('showdw');

	}else{
		$('#bsrBtn').removeClass('showdw');
	}
}

function clear() {
	searchform.locality="";
}

function clearSearch() {
	search = {
		name:  ""
	}
}

function getHomeUrl(city_name,cityId,catNameMap,search_term){
	var homeUrl = '/homes/';
	homeUrl += 'property/';
	homeUrl += catNameMap[search_term.categoryName ? search_term.categoryName : 'res'];
	if ('pg' == search_term.adType) {
		homeUrl += '-for-pg_flatmates';
	} else if('projects' == search_term.adType){
        homeUrl += '-projects-for';
    } else {
		homeUrl += '-for';
	}
	if (search_term.adType) {
		switch (search_term.adType) {
			case 'buy' :
				homeUrl += '-sale';
				break;
			case 'projects' :
                homeUrl += '-sale';
                break;
			case 'pg' :
				homeUrl += '';
				break;

			default:
				homeUrl += '-' + search_term.adType;

		}
	} else {
		homeUrl += '-sale';
	}

	homeUrl += '-in-';

	homeUrl += search_term.name ? (search_term.name.split(',')[0].trim().replace(/ /g, "_") + '-') : '';
	homeUrl += city_name.replace(/ /g, "_") + '-';
	homeUrl += 'cid_' + cityId;
	homeUrl += search_term.id ? ('-lid_' + search_term.id) : '';
	
	homeUrl = homeUrl.toLowerCase();
	homeUrl = homeUrl.replace(/[ ]/g, "-");
	
	if(search_term.type.toLowerCase() == "freetxt"){
		var searchKeyval = search_term.searchKey;
		if(searchKeyval.indexOf("@") !== -1) {
			searchKeyval = searchKeyval.substring(0, searchKeyval.lastIndexOf("@"));
		}
		homeUrl += '?text=' + searchKeyval.replace(/ /g, "-");
	}

	return homeUrl;
}

window.newSearch = (search_term, isTypeAheadSource)=> {
	if(!isTypeAheadSource) {
		searchActionService.setAction('Browse');
	}
	var homeCategorySelected=localStorage.getItem("category");
	var adTypeKey=localStorage.getItem("type");
	var city_name = getCookie("new_prefer_city"),
		cityId =  getCookie("prefer_city_id"),
		catNameMap = {
			"res" : "residential",
			"com" : "commercial",
			"agr" : "agricultural"
		};

	if (!city_name) {
		if (search_term != null && search_term != 'undefined' && search_term.hasOwnProperty('preSearchSuggest') && search_term.preSearchSuggest) {
			city_name = city_name || 'mumbai';
			cityId = cityId || 31;
		} else {
			var noCitySelectSearch = true;
			return true;
		}
	}

	if(search_term != null && search_term != 'undefined' && search_term.hasOwnProperty('type')) {
		var homeUrl = '/homes/';
		var redirectFlag = true;

		if(!search_term.adType) {
            search_term.adType = adTypeKey;
        }

        if(!search_term.categoryName) {
            search_term.categoryName = homeCategorySelected;
        }

		switch (search_term.type.toLowerCase()) {
			case 'localities' :
				homeUrl = getHomeUrl(city_name,cityId,catNameMap,search_term);
				break;
			case 'freetxt':
				if(search_term.searchKey!=''){
					var category_name = catNameMap[search_term.categoryName ? search_term.categoryName : 'res'];
					var intent_type = intentType(search_term.adType);
					$.ajax({
						url: "/homes/re2/attributeDetect",
						dataType: "json",
						method : "POST",
						async: false,
						data :{keywords:search_term.searchKey,categoryPgid:20,category:category_name,intent:intent_type},
						success: function(data) {
							if(typeof data=='object' && data.statusCode==200 && data.data != null){
								homeUrl=data.data.finalAbsoluteUrl;
								homeUrl += '?text=' + data.data.keyword.replace(/ /g, "-");
							}else {
								homeUrl = getHomeUrl(city_name,cityId,catNameMap,search_term);
							}   
						}
					});
				}
				else{
					homeUrl = getHomeUrl(city_name,cityId,catNameMap,search_term);
				}
				break;

			case 'projects' :
				if(!isSet(search_term.id)){
					redirectFlag = false;
					break;
				}
				homeUrl += 'project/' + seoUrl(search_term.name) + '+' + seoUrl(isSet(search_term.locality) ? search_term.locality : '') + '+' + seoUrl(isSet(city_name) ? city_name : '') + '+' + search_term.id;
				break;

			case 'builders' :
				homeUrl += 'builder/' + seoUrl(search_term.name) + '+' + search_term.id;
				break;

			default:

		}
		storeSearchAndRedirect(globals.pageType,search_term.searchKey,search_term.type.toLowerCase(),homeUrl,redirectFlag)
	}
}

function searchInLocality(search_term) {
	var city_name = getCookie('new_prefer_city');
	city_name = city_name || 'mumbai';
	if(search_term != null && search_term != 'undefined' && search_term.hasOwnProperty('type'))
	{
		var homeUrl = '/homes/';

		if(search_term.type == "locality") {
			var searchObj = search_term,
				search_term = search_term.name,
				search_terms = search_term.split(','),
				city_id = getCookie('prefer_city_id'),
				additionalStr = "";
			city_id = city_id || 31;
			if(searchObj.homeSearchFlag){
				additionalStr = '&category_name=' + searchObj.categoryName + "&ad_type=" + searchObj.adType;
			}
			homeUrl = homeUrl + 'listings?locality=' + search_terms[0] + '&city_name=' + city_name + '&city_id=' + city_id + additionalStr ;
		} else if(search_term.type == "projects") {
			homeUrl = homeUrl + 'project/' + $filter('seoUrlFilter')(search_term.name) + '+' + $filter('seoUrlFilter')(search_term.locality) + '+' + $filter('seoUrlFilter')(city_name) + '+' + search_term.id;
		} else if(search_term.type == "builder") {
			homeUrl = homeUrl + 'builder/' + search_term.id;
		} else if(search_term.type == "freeTXT") {
			$scope.searchform.locality = search_term.searchKey;
			var city_id = getCookie('prefer_city_id'),
				additionalStr = '&category_name=' + $rootScope.homeCategorySelected + "&ad_type=" + $scope.adTypeKey + "&free_txt=" + $filter('seoUrlFilter')(search_term.searchKey);
			city_id = city_id || 31;
			homeUrl = homeUrl + 'listings?locality=&city_name=' + city_name + '&city_id=' + city_id + additionalStr;
		}
		$window.location = homeUrl;
	}
}


function homeLink(url) {
	var homeUrl = "http://" + $location.host() + '/';
	homeUrl = homeUrl+url;
	return homeUrl;
}

window.onKeyDown = (pageName)=> {
	searchActionService.setAction('Search');
	var term = null != searchform.locality && "object" == typeof searchform.locality ? searchform.locality : {"resultFlag" : 0, "searchKey": document.getElementsByName('searchHomes')[0].value, "type":"freeTXT"};
	if ("freeTXT" == term.type) {
		gaService.addEvent('HP_Real-Estate' == pageName?'link-RealestateResponsiveUI':'Real-Estate_Header_Responsive', pageName, 'search_freetext_'+ prefer_city + (term.searchKey));
	}

	if(document.querySelector('#protab').classList.contains('active') && term.searchKey === ''){
		window.location = $('#protab').attr('href');
	}else {
		newSearch(term);
	}
};

window.addEvent = (cat, action, label)=> {
	gaService.addEvent(cat, action, label);
}

function typeAheadSelectSearch(searchObj, pageName) {
	searchActionService.setAction('Search');
	if (searchObj.hasOwnProperty('type')) {
		var searchType = ("s" == searchObj.type.charAt(searchObj.type.length - 1))?searchObj.type.substr(0, (searchObj.type.length - 1)):searchObj.type;
		gaService.addEvent('HP_Real-Estate' == pageName?'link-RealestateResponsiveUI':'Real-Estate_Header_Responsive', pageName, 'select_autosearch_' + searchType);
	}
	searchObj.categoryName = homeCategorySelected;
	searchObj.adType = adTypeKey;
	newSearch(searchObj, true);
}
function showTopList() {
	$("#showHeadersearchSuggest").toggle();
	if(Search.isMobile()){
        $(".quikr-search").addClass('m_site');
    }
}
window.setHeaderSearchSuggestFlags = function(){
	showTopList();
}

//overlapping css

$(window).on('click', function(e){
	var searchBar = document.getElementById('searchautosuggestnew');
	var homeSearch = document.getElementById('showautosuggest');
	if((searchBar && searchBar.style.display === 'block') || 
		(homeSearch && homeSearch.style.display === 'block')) {
		$("#showautosuggest").css('display','none');
		$("#searchautosuggestnew").css('display','none');
	}
});

$('.bsrproperty').on('click', function(e){
	var search_container = $("#searchHomes"); // Give you class or ID
	if (!search_container.is(e.target) && search_container.has(e.target).length === 0) // ... nor a descendant-child of the container
	{
		$('#showPresearchSuggest').addClass('hide');
		$("#showautosuggest").css('display','none');
	}
});

$('#enquiry_city').on('click', function(e){
	var search_container = $("#searchHomes"); // Give you class or ID
	if (!search_container.is(e.target) && search_container.has(e.target).length === 0) // ... nor a descendant-child of the container
	{
		$('#showPresearchSuggest').addClass('hide');
		$("#showautosuggest").css('display','none');
	}
});

$('#searchHomes').on("keypress", function(e) {
	if (e.keyCode == 13) {
		onKeyDown('HP_Real-Estate');
		return false; // prevent the button click from happening
	}
});

window.homeSearchGa = (type)=>{
	var page_type = globals.pageType.toLowerCase();
	gaService.addEvent('RealEstate', 'QH_'+page_type , 'QH_'+page_type+'_browse_'+type+'_click');
}

window.homeTopSearchGa = (type,name)=>{
	name = unescape(name);
	var page_type = globals.pageType.toLowerCase();
	gaService.addEvent('RealEstate', 'QH_'+page_type , 'QH_'+page_type+'_search_'+type+'_'+name+'_click');
}

window.homeRecentSearch = ()=>{
	var page_type = globals.pageType.toLowerCase();
	gaService.addEvent('RealEstate', 'QH_'+page_type , 'QH_'+page_type+'_search_recentSearch_click');
}

/* Search Bar Improvement Start */
$("#searchHomes").on('keyup keypress', function(e) {
	var cityId = getCookie('prefer_city_id');
	var q = $("#searchHomes").val();
	var cityIds = '';
	var isClusterSet = getCookie('isCluster');
	if(isSet(isClusterSet) && isClusterSet == 'true'){
		var clusterCityList = JSON.parse(localStorage.getItem('clusterCities'));
	    if(clusterCityList && Object.keys(clusterCityList).length > 0){
	        var clusterCity = [];
	        for(let i = 0; i < clusterCityList.length; i++){
			  	clusterCity.push(clusterCityList[i].id);
			}
	        cityIds = clusterCity.toString();
	    }
	}
	var projectUl = '';
	var localityUl = '';
	var builderUl = '';
	var regionsUl = '';
    var zonesUl = '';
    var poisUl = '';
	var autosuggest = '';
	if(q.length >= 3) {
		$('#showPresearchSuggest').addClass('hide');
		$.ajax({
			url: "/homes/re2/autosuggest",
			method: "POST",
			data: {cityid: cityId, text: q, cityIds: cityIds || ''},
			success: function (data) {
				if(!isEmpty(data)){
					$.map(data, (value, key) => {
						var labelvalue = "";
						if (value.count >= 1 && value.category == 'LocalityProjects') {
							labelvalue = value.label + "  (" + value.count + " Projects)";
						} else {
							labelvalue = value.label;
						}
						if(value.category === 'Project' || value.category === 'LocalityProjects') {
							projectUl = `${projectUl}<li onclick="selectAutoSuggest(${value.cityId}, '${value.category}', '${escape(value.label)}', '${value.locality}', '${value.id}'); homeTopSearchGa('projects', '${escape(value.label)}');">${highlightMatches(escape(labelvalue), escape(q))}, ${value.cityName} <span>Project</span></li>`;
						} else if(value.category === 'Builder') {
							builderUl = builderUl + '<li onclick="selectAutoSuggest(\'' + cityId + '\',\'' + value.category + '\',\'' + escape(value.label) + '\',\'' + value.locality + '\',\'' + value.id + '\');homeTopSearchGa(\' builders \',\'' + escape(value.label) + '\');">' + highlightMatches(escape(labelvalue), escape(q)) + ' <span>Builder</span></li>';
						} else if(value.category === 'Location'){
							localityUl = localityUl + '<li onclick="selectAutoSuggest(\'' + value.cityId + '\',\'' + value.category + '\',\'' + escape(value.label) + '\',\'' + value.locality + '\',\'' + value.id + '\');homeTopSearchGa(\' locality \',\'' + escape(value.label) + '\');">' + highlightMatches(escape(labelvalue), escape(q)) + ', ' + value.cityName + '<span>Locality</span></li>';
						} else if(value.category === 'Regions'){
							regionsUl = regionsUl + '<li onclick="selectAutoSuggest(\'' + value.cityId + '\',\'' + value.category + '\',\'' + escape(value.label) + '\',\'' + value.locality + '\',\'' + value.id + '\');homeTopSearchGa(\' region \',\'' + escape(value.label) + '\');">' + highlightMatches(escape(labelvalue), escape(q)) + ', ' + value.cityName + ' <span>Region</span></li>';
						} else if(value.category === 'Pois'){
							poisUl = poisUl + '<li onclick="selectAutoSuggest(\'' + cityId + '\',\'' + value.category + '\',\'' + escape(value.label) + '\',\'' + value.locality + '\',\'' + value.id + '\');homeTopSearchGa(\' poi \',\'' + escape(value.label) + '\');">' + highlightMatches(escape(labelvalue), escape(q)) + ' <span>Nearby</span></li>';
						} else {
							zonesUl = zonesUl + '<li onclick="selectAutoSuggest(\'' + cityId + '\',\'' + value.category + '\',\'' + escape(value.label) + '\',\'' + value.locality + '\',\'' + value.id + '\');homeTopSearchGa(\' zone \',\'' + escape(value.label) + '\');">' + highlightMatches(escape(labelvalue), escape(q)) + ' <span>Zone</span></li>';
						}
					});
					if(zonesUl != '') {
						autosuggest = autosuggest + "<ul>" + zonesUl + "</ul>";
					}
					if(regionsUl != '') {
						autosuggest = autosuggest + "<ul>" + regionsUl + "</ul>";
					}
					if(localityUl != '') {
						autosuggest = autosuggest + "<ul>" + localityUl + "</ul>";
					}
					if(projectUl != '') {
						autosuggest = autosuggest + "<ul>" + projectUl + "</ul>";
					}
					if(builderUl != '') {
						autosuggest = autosuggest + "<ul>" + builderUl + "</ul>";
					}
					if(poisUl != '') {
						autosuggest = autosuggest + "<ul>" + poisUl + "</ul>";
					}
					$("#showautosuggest").html(autosuggest);
					$("#showautosuggest").css('display','block');
				} else{
					$("#showautosuggest").html('');
					$("#showautosuggest").css('display','none');
				}
			}
		});
	} else {
		$("#showautosuggest").html('');
		$("#showautosuggest").css('display','none');
	}
});
/* Search Bar Improvement End */
