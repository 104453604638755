/**
 * Created by nikhilchauhan on 17/07/18.
 * Lead Form GA tracking for VAP and SNB pages
 */
import {getterSetter, propertyType} from '../utils/common';
require('../services/gaService');

/**
 * GA Lead Form Tracking function for SNB and VAP pages
 * @param event
 */
export function gaLeadform(event) {
    // Get Page Type
    var page = globals.pageType.toLowerCase();
    var txnType, label, action, form, type;

    // Get txnType (buy/rent/pg) for SNB/VAP
    if (page === 'snb') {
        txnType = snbFilterController.filter.data.type;
    } else if (page === 'vap') {
        var propertySnippet = JSON.parse(atob(globals.propertySnippet));
        txnType = propertyType(propertySnippet.propertyFor);
    }

    // Create Action eg. QH_vap_buy or QH_project
    if (page === 'snb' || page === 'vap') {
        action = 'QH_' + page + '_' + txnType;
    } else {
        action = 'QH_' + page;
    }

    // Create Label
    label = action + '_leadForm_' + getterSetter.get('isQHMR') + event;

    // label for Listing in Individual Page
    if (page === 'individual') {
        form = getterSetter.get('reqSource');
        if (form === 'CONTACT_IN_LISTING_INDIVIDUAL') {
            type = propertyType(getterSetter.get('adDetails').availableFor);
            label = action + '_leadForm_' + type + '_' + event;
        }
    }

    // label for Listing in Locality Page
    if (page === 'locality') {
        form = getterSetter.get('reqSource');
        if (form === 'CONTACT_IN_LISTING_LOCALITY') {
            type = propertyType(getterSetter.get('adDetails').availableFor);
            label = action + '_leadForm_' + type + '_' + event;
        } else if (form === 'PROJECT_CONTACT_IN_LOCALITY') {
            label = action + '_leadForm_project_' + event;
        } else if (form === 'CONTACT_BROKER_IN_LOCALITY') {
            label = action + '_leadForm_broker_' + event;
        }
    }

    if ( (page === 'snb' || page === 'vap') && event ==='qapBookVisit_submit_click'){
        gaService.addEvent('RealEstate', action +'_qap', action+'_qap_qapBookVisit_submit_click');
    }

    if ( (page === 'snb' || page === 'vap') && event ==='qapBookVisit_init_click'){
        gaService.addEvent('RealEstate', action +'_qap', action+'_qap_qapBookVisit_init_click');
    }


    if (page === 'vap' && event ==='similar_contact_init_click'){
        gaService.addEvent('RealEstate', action, action+'_similarProperties_contact_init_click');
    }

    if (page === 'vap' && event ==='similar_contact_submit_click'){
        gaService.addEvent('RealEstate', action, action+'_similarProperties_contact_submit_click');
    }

    gaService.addEvent('RealEstate', action, label);
}

/**
 * GA Lead Form Tracking for HomeLoan checkbox for SNB Buy/Projects, Builder Page and Project Page
 * @param value
 */
export function gaHomeLoan(value) {
    var page = globals.pageType.toLowerCase();
    var action, label_snippet, form;
    if (page) {

        // if Project Page QH_project else QH_page_buy
        if (page === 'snb' || page === 'vap') {
            action = 'QH_' + page + '_buy';
        } else {
            action = 'QH_' + page;
        }

        label_snippet = '_leadForm_contact_homeLoan_';

        // Check if Form is from Project Unit
        if (page === 'project') {
            form = getterSetter.get('reqSource');
            if (form === 'PROJECT_UNIT_CONTACT_IN_PROJECT' || form === 'PROJECT_CONTACT_IN_PROJECT' || form === 'REQUIREMENT_POPUP_PROJECT') {
                label_snippet = '_leadForm_contact_unit_homeLoan_';
            }
        }
        // Check if Form is for Listing in Individual Page
        if (page === 'individual') {
            form = getterSetter.get('reqSource');
            if (form === 'CONTACT_IN_LISTING_INDIVIDUAL') {
                label_snippet = '_leadForm_buy_listing_contact_homeLoan_';
            }
        }

        if (page === 'locality') {
            form = getterSetter.get('reqSource');
            if (form === 'CONTACT_IN_LISTING_LOCALITY') {
                label_snippet = '_leadForm_buy_contact_homeLoan_';
            } else if (form === 'PROJECT_CONTACT_IN_LOCALITY') {
                label_snippet = '_leadForm_project_contact_homeLoan_';
            }
        }

        // Create Label
        var label = action + label_snippet + (value ? 'select' : 'deselect');
        gaService.addEvent('RealEstate', action, label);
    }

}

/**
 * GA Lead Form Tracking for HomeLoan checkbox for SNB Buy/Projects, Builder Page and Project Page
 * @param value
 */
export function gaActBroadband(action, label) {
    gaService.addEvent('rentOptin', action, label);
}

/**
 * GA Lead Form Tracking for share lead to brokers checkbox for SNB Buy/Rent, listing VAP Page
 * @param value
 */
export function gaIsMultiplierAllowed(value, details){
    var intent = details.availableFor.toLowerCase();
    var page = globals.pageType.toLowerCase();
    var action;
    if(intent === 'sale' || intent === 'sell')
    {
        intent = 'buy';
    }
    if(page)
    {
        if ((page === 'snb' || page === 'vap') && (intent === 'rent' || intent === 'buy' )) {
            action = 'QH_leadForm_' + page + '_' + intent + '_contactedbyothers';
        }
        // Create Label
        var label = action + '_' + (value ? 'select' : 'deselect');
        gaService.addEvent('RealEstate', action, label);
    }

}

