import {getterSetter, capitalizeFirstLetter} from '../utils/common'
import {windowWidthService} from '../services/windowWidthService'
import {getCookie} from '../services/cookieService'

export const replyPayload = function createPostData() {
	let adDetails = getterSetter.get('adDetails')
	var postData = {
		"adId": adDetails.id,
		"loggedInUserId": getterSetter.get('loggedInUserId') || '',
		"loggedin": getterSetter.get('loggedInUserId')? true : false,
		"replyEmailId": getterSetter.get('email') || '',
		"replyContent": 'Hey, I am interested.Please contact me at the earliest.',
		"replyMobile": getterSetter.get('mobile'),//"9916348063",
		"userAgent": window.navigator.userAgent.substring(0, 90),
		"attributeData": "",
		"replyReferer": "CONTACT_IN_LISTING_" + (globals.pageType ? globals.pageType.toUpperCase() : ""),
		"adReplyType": "SMS",
		"makeAnOfferPrice": "",
		"serverName": window.location.hostname,
		"adReplyLevel": "ALERT_NEEDED_LEVEL",
		"v2": true,
		"alertNeeded":true,
		"emailToAdPosterReqd": false,
		"ncaDetailsReqdInResponse": true,
		"replyUserDetailsReqdInResponse": true,
		"smsToAdPosterReqd": false,
		"promoEmailerToReplierReqd": false,
		"ncaCreationReqd": true,
		"skipReplyStatusUpdate": true,
		"source": "desktop"
	};
	return (postData);
};

export function requirementPayload(source) {
	let leadPayload;
	switch (source ? source : getterSetter.get('reqSource')) {
		case "PROJECT_CONTACT_IN_SNB":
		case "PROJECT_CONTACT_IN_PROJECT":
		case "REQUIREMENT_POPUP_PROJECT":
		case "PROJECT_CONTACT_IN_QPROJECT":
		  leadPayload = projectPayloadV2();
			break;
		case "CONTACT_SNB_BROKER":
		case "CLICK_TO_VIEW_BROKER":
		case "CONTACT_BROKER_IN_LOCALITY":
			leadPayload = brokerPayload();
			break;
		case "HP_FEATURED_PROJECT":
		case "HP_PROJECT_BACKDROP":
		case "PROJECT_CONTACT_IN_LOCALITY":
		case "SNB_PROJECT_BACKDROP":
		  	leadPayload = projectPayload();
			break;
		case "PROJECT_UNIT_CONTACT_IN_PROJECT":
		case "PROJECT_UNIT_CONTACT_IN_QPROJECT":
		case "PROJECT_CONTACT_IN_BUILDER":
			leadPayload = projectUnitload();
			break;
		case "IM_INTERESTED_IN_PROJECT":
		case "IM_INTERESTED_IN_QPROJECT":
			leadPayload = sendEnquiryPayloadV2();
			break;
		case "QAP_BOOK_VISIT_SNB":
		case "QAP_BOOK_VISIT_VAP":
		case "CONTACT_IN_LISTING_FLP":
			leadPayload = qapListingPayload(source);
			break;
		default:
			leadPayload = listingPayload(source);
	}
	leadPayload.whatsappOptIn = getterSetter.get('optInCheck') ? "YES" : "NO";
	return leadPayload;
	
}

export function nriRequirementPayload(source = getterSetter.get('reqSource')) {
	const adDetails = getterSetter.get('adDetails');

	const payload = {
		category: 'unknown',
		property_type: '',
		bedrooms: '',
		cityId: '',
		cityName: '',
		name: getterSetter.get('name') || 'User',
		country_code: `+${getterSetter.get('phonecode')}`,
		mobile: getterSetter.get('mobile'),
		userId: getterSetter.get('userId') || '',
		verifiedStatus: getterSetter.get('otpFlag') ? 'OTP_VERIFIED':'NOT_VERIFIED',
		email:  getterSetter.get('email'),
		communication: 'Call',
		entityType: '',
		source: getterSetter.get('reqSource'),
		listingIds: '',
		localityId: adDetails && adDetails.localityId || null,
		projectIds: '',
		projectUnitIds: '',
		brokerIds: '',
		min_budget: adDetails && adDetails.priceRange && adDetails.priceRange.low || null,
		max_budget: adDetails && adDetails.priceRange && adDetails.priceRange.high || null,
		isAssisted: false,
		comments: ''
	};

	switch(source) {
		// Banners and Featured Projects
		case "HP_FEATURED_PROJECT":
		case "HP_PROJECT_BACKDROP":
		case "PROJECT_CONTACT_IN_LOCALITY":
		case "SNB_PROJECT_BACKDROP": {
			payload.cityId = adDetails.cityId;
			payload.cityName = adDetails.city ? capitalizeFirstLetter(adDetails.city) : '';
			payload.projectIds = adDetails.id;
			payload.entityType = 'PROJECT';
			break;
		}

		// Listing SNB
		case "CONTACT_IN_LISTING_SNB": {
			payload.cityId = adDetails.cityId;
			payload.cityName = adDetails.cityName;
			payload.listingIds = adDetails.id;
			payload.bedrooms = adDetails.bhk ? adDetails.bhk.split(' ')[0] : '';
			payload.property_type = adDetails.propertyType;
			payload.entityType = 'LISTING';
			break;
		}
		case "QAP_BOOK_VISIT_SNB":
		case "QAP_BOOK_VISIT_VAP": {
			const siteVisitDate = new Date(getterSetter.get('selectedSiteVisitDate'));
			const siteVisitStartTime = new Date(getterSetter.get('selectedSiteVisitStartTime'));
			const siteVisitEndTime = new Date(getterSetter.get('selectedSiteVisitEndTime'));

			siteVisitStartTime.setFullYear(siteVisitDate.getFullYear(), siteVisitDate.getMonth(), siteVisitDate.getDate());
			siteVisitEndTime.setFullYear(siteVisitDate.getFullYear(), siteVisitDate.getMonth(), siteVisitDate.getDate());
			
			payload.siteVisitStartTime = siteVisitStartTime.getTime();
			payload.siteVisitEndTime = siteVisitEndTime.getTime();
		}

		// Project SnB
		case "PROJECT_CONTACT_IN_SNB":

		// Project VAP
		case "PROJECT_CONTACT_IN_PROJECT":
		case "REQUIREMENT_POPUP_PROJECT":
		case "PROJECT_UNIT_CONTACT_IN_QPROJECT":
		case "PROJECT_CONTACT_IN_QPROJECT": {
			const bedrooms = (getterSetter.get('projectBhk')) ? getterSetter.get('projectBhk').replace(' BHK','') : '';
			const propertyType = (getterSetter.get('projectProptype')) ? getterSetter.get('projectProptype') : '';
			payload.bedrooms = bedrooms;
			payload.property_type = propertyType;
		}
		
		case "PROJECT_CONTACT_IN_BUILDER": {
			payload.cityId = adDetails.address.cityId;
			payload.cityName = adDetails.address.city;
			payload.projectIds = adDetails.id;
			payload.property_type = payload.property_type || adDetails.type;
			payload.entityType = 'PROJECT';
			break;
		}

		// Broker SnB
		case "CONTACT_SNB_BROKER":
			
		// Broker VAP
		case "CONTACT_INDIVIDUAL_BROKER": {
			payload.brokerIds = adDetails.id;
			payload.cityId = adDetails.cityId;
			payload.property_type = getterSetter.get('brokerUnitType') || '';
			payload.min_budget = $('#seekerMinPrice').val();
			payload.max_budget = $('#seekerMaxPrice').val();
			payload.entityType = 'BROKER';
			break;
		}

		//INDIVIDUAL LISTING
		case "CONTACT_IN_LISTING_INDIVIDUAL": {
			payload.cityId = adDetails.cityId;
			payload.cityName = adDetails.cityName;
			payload.listingIds = adDetails.id;
			payload.bedrooms = adDetails.bhk ? adDetails.bhk.split(' ')[0] : '';
			payload.property_type = adDetails.type;
			payload.entityType = 'LISTING';
			break;
		}

		// Listing VAP
		case "CONTACT_IN_LISTING_FLP":
		case "IM_INTERESTED_IN_LISTING":
		case "REQUIREMENT_POPUP_FLP":
		case "CONTACT_IN_LISTING_PROJECT":
		case "CLICK_TO_VIEW_IN_LISTING": {
			payload.cityId = adDetails.cityId;
			payload.cityName = adDetails.city;
			payload.listingIds = adDetails.id;
			payload.bedrooms = adDetails.bhk ? adDetails.bhk.split(' ')[0] : '';
			payload.property_type = adDetails.type;
			payload.entityType = 'LISTING';
			break;
		}
		case "CONTACT_IN_LISTING_SIMILAR": {
			payload.cityId = adDetails.city_id;
			payload.cityName = adDetails.cityName;
			payload.listingIds = adDetails.id;
			payload.bedrooms = adDetails.bhk ? adDetails.bhk.split(' ')[0] : '';
			payload.property_type = adDetails.propertyType || adDetails.property_type;
			payload.entityType = 'LISTING';
			break;
		}

		// Project VAP
		case "IM_INTERESTED_IN_PROJECT":
		case "IM_INTERESTED_IN_QPROJECT": {
			const bedrooms = (getterSetter.get('projectBhk')) ? getterSetter.get('projectBhk').replace(' BHK','') : '';
			const propertyType = (getterSetter.get('projectProptype')) ? getterSetter.get('projectProptype') : '';
			payload.bedrooms = bedrooms;
			payload.property_type = propertyType;
		}
		case "PROJECT_UNIT_CONTACT_IN_PROJECT": {
			payload.cityId = adDetails.address.cityId;
			payload.cityName = adDetails.address.city;
			payload.projectIds = adDetails.id;
			payload.property_type = payload.property_type || adDetails.type;
			payload.entityType = 'PROJECT';
			break;
		}

		default: {
			break;
		}
	}

	return payload;
}

export function nriAssistedRequirementPayload(source = getterSetter.get('reqSource')) {
	return {
		cityId: getterSetter.get('assistedCityId') || getCookie('prefer_city_id') || 0,
		cityName: getterSetter.get('assistedCityName'),
		name: getterSetter.get('name') || 'User',
		country_code: getterSetter.get('phonecode') ? `+${getterSetter.get('phonecode')}` : '91',
		mobile: getterSetter.get('mobile'),
		userId: getterSetter.get('userId') || '',
		verifiedStatus: getterSetter.get('otpFlag') ? 'OTP_VERIFIED':'NOT_VERIFIED',
		email:  getterSetter.get('email'),
		communication: 'Call',
		source: source,
		isAssisted: getterSetter.get('isAssisted'),
		assistedPaymentStatus: getterSetter.get('assistedPaymentStatus'),
		comments: getterSetter.get('comments'),
		channel: windowWidthService.channel,
		countrytype: getterSetter.get('countrytype') || 1,
	};

}

export function listingPayload(source){
	var payload = {
		"userName": getterSetter.get('name')|| '',
		"phone": getterSetter.get('mobile'),
		"emailId": getterSetter.get('email'),
		"listingId": getterSetter.get('adDetails').id,
		"channel": windowWidthService.channel,
		"source": source ? source : getterSetter.get('reqSource'),
		"requestPic": getterSetter.get('requestPic') ? "YES" : "NO",
		"requestPrice": getterSetter.get('requestPrice') ? "YES" : "NO",
		"isGrabhouse" : getterSetter.get('adDetails').isGrabHouse,
		"loanRequirement" : getterSetter.get('loanRequirement') ? 'YES':'NO',
		"isMultiplierAllowed" : getterSetter.get('isMultiplierAllowed') ? 1:0,
		"verifiedStatus" : getterSetter.get('otpFlag') ? 'OTP_VERIFIED':'NOT_VERIFIED',
		"referral":location.hostname,
		"loggedInUserId": getCookie('__u') || '',
		"loggedin": getCookie('__u') ? true : false
	};
	return payload;
}

export function qapListingPayload(source){
	var payload = listingPayload(source);
	const siteVisitDate = new Date(getterSetter.get('selectedSiteVisitDate'));
	const siteVisitStartTime = new Date(getterSetter.get('selectedSiteVisitStartTime'));
	const siteVisitEndTime = new Date(getterSetter.get('selectedSiteVisitEndTime'));

	siteVisitStartTime.setFullYear(siteVisitDate.getFullYear(), siteVisitDate.getMonth(), siteVisitDate.getDate());
	siteVisitEndTime.setFullYear(siteVisitDate.getFullYear(), siteVisitDate.getMonth(), siteVisitDate.getDate());
	
	payload.siteVisitStartTime = siteVisitStartTime.getTime();
	payload.siteVisitEndTime = siteVisitEndTime.getTime();
	return payload;
}

export function projectPayload(){
	var payload = {
		"userName": getterSetter.get('name')|| '',
		"emailId": getterSetter.get('email'),
		"phone": getterSetter.get('mobile'),
		'projectIds': getterSetter.get('adDetails').id,
		'projectId': getterSetter.get('adDetails').id,
		"channel": windowWidthService.channel,
		"source": getterSetter.get('reqSource'),
		"loanRequirement" : getterSetter.get('loanRequirement') ? 'YES':'NO',
		"verifiedStatus" : getterSetter.get('otpFlag') ? 'OTP_VERIFIED':'NOT_VERIFIED',
		"referral":location.hostname,
		"loggedInUserId": getCookie('__u') || '',
		"loggedin": getCookie('__u') ? true : false
	};
	return payload;
}

export function projectPayloadV2(){
	let bhks = (getterSetter.get('projectBhk')) ? getterSetter.get('projectBhk').replace(' BHK','') : '';
	let propertyTypes = (getterSetter.get('projectProptype')) ? getterSetter.get('projectProptype') : '';
	var payload = {
		"userName": getterSetter.get('name')|| '',
		"emailId": getterSetter.get('email'),
		"phone": getterSetter.get('mobile'),
		'projectIds': getterSetter.get('adDetails').id,
		'projectId': getterSetter.get('adDetails').id,
		"channel": windowWidthService.channel,
		"source": getterSetter.get('reqSource'),
		"loanRequirement" : getterSetter.get('loanRequirement') ? 'YES':'NO',
		"verifiedStatus" : getterSetter.get('otpFlag') ? 'OTP_VERIFIED':'NOT_VERIFIED',
		"referral":location.hostname,
		"loggedInUserId": getCookie('__u') || '',
		"loggedin": getCookie('__u') ? true : false,
		"bhks": bhks,
		"propertyTypes": propertyTypes
	};
	return payload;
}

export function projectUnitload(){
	var payload = {
		"userName": getterSetter.get('name')|| '',
		"phone": getterSetter.get('mobile'),
		"emailId": getterSetter.get('email'),
		'projectIds': getterSetter.get('adDetails').id,
		'projectId': getterSetter.get('adDetails').id,
		'projectUnitId': getterSetter.get('adDetails').unitId,
		"channel": windowWidthService.channel,
		"source": getterSetter.get('reqSource'),
		"loanRequirement" : getterSetter.get('loanRequirement') ? 'YES':'NO',
		"cityId":getterSetter.get('adDetails').address.cityId,
		"localityIds":getterSetter.get('adDetails').localityId,
		"verifiedStatus" : getterSetter.get('otpFlag') ? 'OTP_VERIFIED':'NOT_VERIFIED',
		"referral":location.hostname,
		"loggedInUserId": getCookie('__u') || '',
		"loggedin": getCookie('__u') ? true : false
	};
	return payload;
}

export function brokerPayload(){
	var payload = {
		"userName": getterSetter.get('name')|| '',
		"phone": getterSetter.get('mobile'),
		"emailId": getterSetter.get('email'),
		"listingId": getterSetter.get('adDetails').id,
		"channel": windowWidthService.channel,
		"source": getterSetter.get('reqSource'),
		"message": (getterSetter.get('requestPic')?'Hey I am interested in your property. Please share/upload some images to my email/mobile.':'Hey, I am interested.Please contact me at the earliest.'),
		"isGrabhouse" : getterSetter.get('adDetails').isGrabHouse,
		"loanRequirement" : getterSetter.get('loanRequirement') ? 'YES':'NO',
		"brokerId" : getterSetter.get('adDetails').brokerid,
		"postedBy" : 'BROKER',
        "priceMin" : $('#seekerMinPrice').val(),
        "priceMax" : $('#seekerMaxPrice').val(),
        "categoryType" : getterSetter.get('brokerCategory'),
        "propertyType" : getterSetter.get('brokerUnitType'),
        "adType" : getterSetter.get('brokerAdType'),
        "cityId" : getterSetter.get('adDetails').cityId,
        "verifiedStatus" : getterSetter.get('otpFlag') ? 'OTP_VERIFIED':'NOT_VERIFIED',
        "referral":location.hostname,
       	"loggedInUserId": getCookie('__u') || '',
		"loggedin": getCookie('__u') ? true : false,
	};
	return payload;
}
export function sendEnquiryPayloadV2() {
	let bhks = (getterSetter.get('projectBhk')) ? getterSetter.get('projectBhk').replace(' BHK','') : '';
	let propertyTypes = (getterSetter.get('projectProptype')) ? getterSetter.get('projectProptype') : '';
	var leadPayload = {
		"userName": getterSetter.get('name')|| '',
		"phone": getterSetter.get('mobile'),
		"emailId": getterSetter.get('email'),
		"projectId": getterSetter.get('adDetails').id,
		"channel": windowWidthService.channel,
		"pageType" : getterSetter.get('adDetails').pageType,
		"projectName" : getterSetter.get('adDetails').projectName,
		"locality":getterSetter.get('adDetails').locality,
		"cityId":getterSetter.get('adDetails').cityId,
		"city":getterSetter.get('adDetails').city,
		"source":getterSetter.get('reqSource'),
		"verifiedStatus" : getterSetter.get('otpFlag') ? 'OTP_VERIFIED':'NOT_VERIFIED',
		"referral":location.hostname,
		"loggedInUserId": getCookie('__u') || '',
		"loggedin": getCookie('__u') ? true : false,
		"bhks": bhks,
		"propertyTypes": propertyTypes
	};
	return leadPayload;
}

export function homeloanAssistPayload() {
	var payload = {
		"userId" : getCookie('__u') || '',
		"projectId" : globals.pageType === 'project' ? getterSetter.get('adDetails').id : '',
		"adId": globals.pageType === 'SNB' ? getterSetter.get('adDetails').id : '',
		"source": "QH",
		"channel": windowWidthService.channel === 'Website' ? 'desktop' : 'msite',
		"page": globals.pageType,
		"loanRequiredIn": getterSetter.get('homeloan').timeline,
		"homeFinalizedIn": getterSetter.get('homeloan').finalized
	}
	return payload;
}