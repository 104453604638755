import {getterSetter} from '../utils/common'
import { homeloan } from '../constants/contactForm';
export function viewNumberSucess (res){
  return `<div class="s_thankup">
            <div class="modal-header">
              <button type="button" class="close icon-reset" data-dismiss="modal"></button>
              <div class="co_img">
                  <div class="imgbox">
                      <img width="70"  src="https://teja10.kuikr.com//r1/20151127/ak_689_102486863-1448600592_300x300.jpeg">
                      <span></span>
                  </div>
                  <div class="co_content">
                      <div class="three_c">
                          <div class="tellme">Owner</div>
                          ${res.name?`<h4>${res.name}</h4>`:''  }
                      </div>
                  </div>
              </div>
              <div class="user_de">
                  ${res.mobile?`<div class="pn_no"><i class="icon-phone"></i> &nbsp; ${res.mobile}</div>`: ''}
                  ${res.email?`<div class="em_id"><i class="icon-mail"></i> &nbsp; ${res.email}</div>`:''  }
              </div> 
              ${((typeof globals.qcashEnabled !== 'undefined') && globals.qcashEnabled !=0) ? `
                    <div class="q_cash_po qcashthankyou clearfix hide">
                        <img src="${staticImageHost}restatic/build/image/QCash_Icon.png"/>
                        <div class="price_q">
                            <b id="qcashresp">You get <span>&#8377;50 QCash</span></b>
                            <p>Use now to buy premium Ad packs</p>
                        </div>
                    </div>` : ``}
            </div>
            <div class="services_de" id="ccrdetailviewnumber"></div>
            <button type="button" class="button button-secondary" data-dismiss="modal">
              <span>OK</span>
            </button> 
          </div>`;
}

function getHLAssistForm() {
    if (globals.pageType === 'SNB' || globals.pageType === 'project') {
        return `<div class="loanDetails hide" id="loanDetails">
                    <div class="dropdown-menu-open" dropdown>
                        <a class="hLAnchor" href="javascript:;" data-toggle="dropdown">
                            <span id = "selectedTimeline">By when do you need the Home Loan</span>
                            <i class="icon-down-open-mini"></i>
                        </a>
                        <ul class="dropdown-open-onclick dropdown-menu">
                            ${getHLAssistList("timeline")}
                        </ul>
                    </div>
                    <div class="dropdown-menu-open" dropdown>
                        <a class="hLAnchor" href="javascript:;" data-toggle="dropdown">
                            <span id = "selectedFinalized">Have you finalized your Dream Home</span>
                            <i class="icon icon-down-open-mini"></i>
                        </a>
                        <ul class="dropdown-open-onclick dropdown-menu">
                            ${getHLAssistList("finalized")}
                        </ul>
                    </div>
                    <button disabled type="button" class="button button-secondary btnsbt" id="replysubmit2" onclick = "replyFormController.submitHomeAssist()">
                        <span id="submitLoanAssist">Submit</span>
                    </button>
                </div>`;
    }
    return "";
}

function getHLAssistList (type) {
    const dd = homeloan[type];
    let listString = "";
    const id = (type === "finalized") ? "selectedFinalized" : "selectedTimeline";
    dd.map((d) => {
        listString += `<li class="selectFinal" onclick = "replyFormController.selectTimeline('${id}', '${d.value}' , '${d.label}')">${d.label}</li>`;
    });
    return listString;
}

export function contactSucess (usertype){
    return `<div class="s_thankup">
            <div class="modal-header simi_thankup">
              <button type="button" class="close icon-reset ${getterSetter.get('formViewType') === 'static' ? 'hide' : ''}" data-dismiss="modal"></button>
              <div class="coimg">
                 <strong id="thanksMsg">THANK YOU!</strong>
                 ${getHLAssistForm()}
                 <p>The ${usertype} will get in touch with you on </p>
              </div>
              <div class="user_de">
                    <div class="pn_no"><i class="icon-phone"></i><span id="contact_mobile">&nbsp; ${getterSetter.get('mobile')}</span></div>
                    <div class="em_id"><i class="icon-mail"></i><span id="contact_email">&nbsp; ${getterSetter.get('email')}</span></div>
              </div> 
              ${((typeof globals.qcashEnabled !== 'undefined') && globals.qcashEnabled !=0) ? `
                  <div class="q_cash_po qcashthankyou clearfix hide">
                      <img src="${staticImageHost}restatic/build/image/QCash_Icon.png"/>
                      <div class="price_q">
                          <b id="qcashresp">You get <span>&#8377;50 QCash</span></b>
                          <p>Use now to buy premium Ad packs</p>
                      </div>
                  </div>` : ``}
            </div>
            <div class="services_de" id="ccrdetail">
            </div>
            ${getterSetter.get('formViewType') !== 'static' ? '<button type="button" class="button button-secondary" data-dismiss="modal"><span>OK</span></button>' : ''}
          </div>`;
}