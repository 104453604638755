import {getCookie} from './cookieService'
import {errorTrack} from '../utils/errorTrack'
require('../services/gaService.js')
export const requirementService = function() {

    var factory = {};
    factory.ADD_REQUIREMENT_API = '/homes/re2/addRequirement?aj=1';
    factory.CREATE_ALERT_API = '/homes/re2/createAlert?aj=1';
    factory.HOME_LOAN_REQUIREMENT_API = '/homes/re2/addHomeLoanReq?aj=1';
    factory.ADD_NRI_REQUIREMENT_API = '/homes/re2/save_nri_data?aj=1';
    factory.ADD_NRI_ASSISTED_REQUIREMENT_API = '/homes/property-assistance/requirements?aj=1';
    factory.COWORK_REQUIREMENT_API = '/homes/re2/cowork/requirements?aj=1';
    factory.COWORK_LEADENRICH_API = '/homes/re2/cowork/leadenrich?aj=1';
    factory.ADD_HOME_LOAN_ASSIST = '/homes/re2/addHomeLoanAssist?aj=1'

    function getUrlVars() {
        var vars = {};
        var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi,
            function(m,key,value) {
                vars[key] = value;
            });
        return vars;
    }

    function setTrafficGoalConversionParameters(data){
        if(data && typeof data === "object"){
            var trafficCookie = getCookie("utmztrack") ? getCookie("utmztrack") : getCookie('__utmz')
            var trafficSource = "none";
            var trafficMedium = "none";
            var trafficCamp = "none";
            if(trafficCookie){
                var cookie = decodeURIComponent(trafficCookie);
                var indexOfSrc = cookie.indexOf("utmcsr");
                if(indexOfSrc > -1){
                    cookie = cookie.substring(indexOfSrc);
                    var cookieArr = cookie.split("|");
                    for(var i=0;i<cookieArr.length;i++){
                        var arr = cookieArr[i].split("=");
                        if(arr[0] == "utmcsr"){
                            trafficSource = arr[1];
                        }else if(arr[0] == "utmcmd"){
                            trafficMedium = arr[1];
                        }else if(arr[0] == "utmccn"){
                            trafficCamp = arr[1];
                        }
                    }
                }
            }
            else{
                var paramObj = getUrlVars();
                trafficSource = paramObj['utm_source'] || trafficSource;
                trafficMedium = paramObj['utm_medium'] || trafficMedium;
                trafficCamp = paramObj['campaign'] || trafficCamp;
            }
            data['goalType'] = data['source'];
            data['trafficSource'] = trafficSource;
            data['medium'] = trafficMedium;
            data['campaignId'] = trafficCamp;
        }
        return data;
    }

    factory.setTrafficGoalConversionParameters = function(d){
        d = d || {};
        return setTrafficGoalConversionParameters(d);
    }

    factory.addRequirement = function(data) {
        var deferred = $.Deferred();
        var d = setTrafficGoalConversionParameters(data);
        $.ajax({
            url: factory.ADD_REQUIREMENT_API,
            type: "POST",
            data : d,
            success: function(data) {
                let response = JSON.parse(data.response)
                if(response.statusCode == 200 || ( parseInt(d.isGrabhouse) === 1 && parseInt(data.status) === 200 ) ){
                    gaService.addEvent('RealEstate', 'success', `Alerts_contact_submit_success_${globals.pageType}`);
                    deferred.resolve(data); 
                }else{
                    errorTrack(globals.pageType,'addRequirement',response.error,d.source)
                }
                
            },
            error: function(err) {
                errorType(globals.pageType,'addRequirement','apiError',err)
                deferred.reject(err);
            }
        });

        return deferred.promise();
    };

    factory.addNRIRequirement = function(data) {
        const deferred = $.Deferred();
        const d = setTrafficGoalConversionParameters(data);
        $.ajax({
            url: factory.ADD_NRI_REQUIREMENT_API,
            type: "POST",
            data : d,
            success: function(data) {
                let response = data;
                if(response.statusCode == 200) {
                    deferred.resolve(data); 
                }
            }
        });

        return deferred.promise();
    };

    factory.addNRIAssistedRequirement = function(data) {
        const deferred = $.Deferred();
        const d = setTrafficGoalConversionParameters(data);
        $.ajax({
            url: factory.ADD_NRI_ASSISTED_REQUIREMENT_API,
            type: "POST",
            data : d,
            success: function(data) {
                let response = data;
                if(response.statusCode == 200) {
                    deferred.resolve(data); 
                }
            }
        });

        return deferred.promise();
    };

    factory.addCoworkRequirement = function(data) {
        const deferred = $.Deferred();
        //const d = setTrafficGoalConversionParameters(data);
        $.ajax({
            url: factory.COWORK_REQUIREMENT_API,
            type: "POST",
            data : data,
            success: function(data) {
                let response = data;
                if(response.code == 200) {
                    deferred.resolve(data); 
                }
            }
        });

        return deferred.promise();
    };

    factory.addHomeLoanRequirement = function(data) {
        var deferred = $.Deferred();
        var d = setTrafficGoalConversionParameters(data);
        $.ajax({
            url: factory.HOME_LOAN_REQUIREMENT_API,
            type: "POST",
            data : d,
            success: function(data) {
                let response = JSON.parse(data.response)
                if(response.statusCode == 200) {
                    deferred.resolve(data); 
                } else {
                    errorTrack(globals.pageType,'homeLoanAddRequirement',response.error.description,d.source)
                }
            }
        });

        return deferred.promise();
    };

    factory.addHomeLoanAssistRequirement = function(data) {
        var deferred = $.Deferred();
        $.ajax({
            url: factory.ADD_HOME_LOAN_ASSIST,
            type: "POST",
            data : data,
            success: function(data) {
                let response = JSON.parse(data.response)
                if(response.statusCode == 200) {
                    deferred.resolve(data); 
                } else {
                    errorTrack(globals.pageType,'homeLoanAssistRequirement',response.error.description,d.source)
                }
            }
        });
    }

    factory.saveLeadEnrichment = function(data) {
        var deferred = $.Deferred();
        $.ajax({
            url: factory.COWORK_LEADENRICH_API,
            type: "POST",
            data : data,
            success: function(data) {
                let response = data;

                if(response) {
                    deferred.resolve(response); 
                } else {
                    errorTrack(globals.pageType,'leadEnrichment',response.error.description,d.source)
                }
            },
            error: function(error) {
                deferred.rejected(error);
            }
        });

        return deferred.promise();
    };

    return factory;
}();
