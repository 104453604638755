var error = {};
		error['otpsubmit'] = "otpSubmit";
		error['otpVerify'] = "otpVerify";
		error['otpVerifylogin'] = "otpVerifylogin";
		error['otpResend'] = "otpResend";
		error['anonymousUser'] = "anonymousUser";
		error['formType'] = "auto";

window.onerror = function(message, file, line, column, error) {
				//stackTrace()
			error = error || {};
			var data = {
					message: message,
					file: file,
					line: line,
					column: column,
					
				}
				if(window.page){
					data.error =  {
						name: error.name,
						message: error.message,
						stack: error.stack
					}  
				}
				let jsonString = JSON.stringify(data)
}
export const errorTrack = (page,errorLevel,apiError,reqSource='')=>{
	if (typeof gtag !== "undefined") {
		const config = {
			event_category: "QH-Errors",
			event_label: page,
			value: 0,
			non_interaction: true
		};
		if(page === 'PapMsite'){
			gtag("event", `${reqSource}_${errorLevel}_${apiError}`, config);
		} else {
			gtag("event", `${error.formType}_${reqSource}_${errorLevel}`, config);
		}
	}
	//apiError = JSON.stringify(apiError)
}