import '../../assets/sass/web/modules/_replyad.scss'
import {isSet,getterSetter,getFormObject,getTime, parseQueryParams,getUnitConfigurations,validateEmail} from '../utils/common'
import {formValidator} from '../services/formValidator'
import {otpService} from '../services/otpService'
import {generateOTP,submitReply,handleApiErr,verifyOTP,showOtpSuccessSection,categoryMapping,QrThankMsg,setAlert} from '../reply/replyservices'
import {isValidProjectBannerFormfillSource} from '../utils/common'
import {requirementPayload} from '../reply/payloadBuilder'
import {replyError} from '../reply/userconflict'
import {getCookie} from '../services/cookieService'
import {dataService} from '../services/dataService';
import {requirementService} from '../services/requirementService';
import {gaHomeLoan, gaActBroadband, gaIsMultiplierAllowed} from '../services/gaLeadFormService';
import {getLoggedInUser} from '../loginService';
import COUNTRIES from '../nri/country_code';


const PROJECT_SOURCES = [
	'PROJECT_CONTACT_IN_SNB',
	'IM_INTERESTED_IN_PROJECT',
	'HP_FEATURED_PROJECT',
	'REQUIREMENT_POPUP_PROJECT',
	'PROJECT_CONTACT_IN_PROJECT',
	'HP_PROJECT_BACKDROP',
];

var replyAd = {};
var clickedElement;
replyAd.counter = 0
var formDetails = {}
getterSetter.set('downloadBrochure',false)
export const replyInit = (entityMapping,template)=> {
	formDetails = {};
	formDetails.defaultLogo = window.CDNURL+'/image/vap-broker-d.png';
	getterSetter.set('formTemplate', template)
	replyAd.counter = 0;
	clickedElement = '';
	getterSetter.set('errMsg','');
	getterSetter.set('countryCode','IN');
	entityMapping.imageLogo = entityMapping.imageLogo ? entityMapping.imageLogo : formDetails.defaultLogo;
	formDetails = Object.assign(formDetails, entityMapping);
	replyAd.getLoggedInUser();
	otpService.setOtpVariable('', 'otpId');
}
replyAd.getLoggedInUser = ()=> {
	formDetails.emailList = []
	formDetails.mobileList = []
	if(getterSetter.get('formViewType') !== 'static') {
		var details = getterSetter.get('loginData');
		if (details && details.data && details.data.user) {
			if (!details.error) {
				replyAd.loginStatus = true;
				getterSetter.set('otpFlag', true);
				replyAd.setLoggedInData(details.data.user);
				replyAd.renderForm()
			} else {
				replyAd.loginStatus = false;
				replyAd.renderForm()
			}
		} else {
			replyAd.loginStatus = false;
			replyAd.renderForm()
		}
	} else {
		// for static forms get Logged In User by promise
		getLoggedInUser.then(response => {
			let details = response;
			if (details && details.data && details.data.user) {
				if (!details.error) {
					replyAd.loginStatus = true;
					getterSetter.set('otpFlag', true);
					replyAd.setLoggedInData(details.data.user);
					replyAd.renderForm()

				} else {
					replyAd.loginStatus = false;
					replyAd.renderForm()
				}
			} else {
				replyAd.loginStatus = false;
				replyAd.renderForm()
			}
		}).catch(() => {
			replyAd.loginStatus = false;
			replyAd.renderForm()
		});
	}
}


replyAd.renderForm = () => {
	let formViewTypeWrap = '#commonpopup';
	if(getterSetter.get('formViewType') === 'static') {
		formViewTypeWrap = '#static_form_wrap';
	}

	let replyContainer = formViewTypeWrap + ' #reply_Container';
	let template = getterSetter.get('formTemplate');
	formDetails.source = getterSetter.get('reqSource');
	formDetails.cityId = getCookie('prefer_city_id') || '';
	if(PROJECT_SOURCES.includes(getterSetter.get('reqSource'))) {
		// Create a map of the unit configurations
		let unitConfig = [];
		if(globals.pageType == 'SNB') {
			unitConfig = getUnitConfigurations(formDetails.tilesDetails.units);
		} else {
			unitConfig = getUnitConfigurations(formDetails.tilesDetails.projectUnits);
		}
		const propertyTypes = Object.keys(unitConfig);
		
		if(propertyTypes.length > 0) {
			const defaultPropertySubType = propertyTypes[0];
			getterSetter.set('projectProptype', defaultPropertySubType);

			// Find all the applicable unit types
			const applicableUnitTypes = unitConfig[defaultPropertySubType];

			if(applicableUnitTypes.length > 0) {
				getterSetter.set('projectBhk', applicableUnitTypes[0]);
			}
		}
	}

	$(replyContainer).html(template({'formData':formDetails}));

	if (formDetails.showbroker) {
		let minElm = document.getElementById('seekerMinPrice');
		let maxElm = document.getElementById('seekerMaxPrice');
		if (minElm) {
			minElm.onblur = function(ev) {
				if (ev.target.value !== '') {
					if (!this.parentElement.classList.contains('open')) {
						this.parentElement.classList.add('open');
					}
				} else {
					this.parentElement.classList.remove('open');
				}
			}
		}
		if (maxElm) {
			maxElm.onblur = function(ev) {
				if (ev.target.value !== '') {
					if (!this.parentElement.classList.contains('open')) {
						this.parentElement.classList.add('open');
					}
				} else {
					this.parentElement.classList.remove('open');
				}
			}
		}
	}

	if(formDetails.chkCommercial){
		$(formViewTypeWrap + " #hlcont").html('Yes, I need commercial premises loan');
	}

	// Initialize the country code dropdown
	replyAd.initCountryCodeDropdown(formViewTypeWrap);


		let replySubmit = formViewTypeWrap + ' #replysubmit';
		if(document.querySelector(replySubmit)){
			document.querySelector(replySubmit).addEventListener('click', (event) => {
				clickedElement = event.currentTarget;
				if(clickedElement.innerText == 'I am Interested') {
					replyAd.counter = 0;
					getterSetter.set('sendEnquiry', true);
					getterSetter.set('formViewType','static');
					
					// Parse the query params to check the source
					const queryParams = parseQueryParams();
					if (queryParams.has('qh_source')) {
						const source = queryParams.get('qh_source').toUpperCase();
						getterSetter.set('reqSource', isValidProjectBannerFormfillSource(source) ? source : "IM_INTERESTED_IN_PROJECT");
					}
				}
				replyAd.submit(formViewTypeWrap)
			})
		}

		let selectEmail = formViewTypeWrap +' #selectEmail';
		if(document.querySelector(selectEmail)){
			document.querySelector(selectEmail).addEventListener('click', (event) => {
				let email = event.target.getAttribute('data-email')
				$( formViewTypeWrap + ' #selectedEmail').html(email);
				$( formViewTypeWrap + " form[name='replyForm'] :input[name='email']").val(email);

				formDetails.email = email
				getterSetter.set('email',email)
			})
		}

		let selectMobile = formViewTypeWrap + ' #selectMobile';
		if(document.querySelector(selectMobile)){
			document.querySelector(selectMobile).addEventListener('click', (event) => {
				let mobile = event.target.getAttribute('data-mobile')
				$( formViewTypeWrap + ' #selectedMobileNo').html(mobile);
				$( formViewTypeWrap + " form[name='replyForm'] :input[name='mobile']").val(mobile);
				formDetails.mobile = mobile
				getterSetter.set('mobile',mobile)
			})
		}

		let showHideBank = formViewTypeWrap + ' #showHideBank';
		if(document.querySelector(showHideBank)) {
			document.querySelector(showHideBank).addEventListener('click', (event) =>{
				gaHomeLoan(event.target.checked)
			})
		}

		let isMultiplierAllowed = formViewTypeWrap + ' #isMultiplierAllowed';
		if(document.querySelector(isMultiplierAllowed)) {
			document.querySelector(isMultiplierAllowed).addEventListener('click', (event) =>{
				gaIsMultiplierAllowed(event.target.checked, getterSetter.get('adDetails'))
			})
		}
		if(getterSetter.get('formType') == 'broker'){
			getterSetter.set('brokerAdType','Buy');
			getterSetter.set('brokerUnitType','Apartment');
			getterSetter.set('brokerCategory','Residential');
			let selectAdType = formViewTypeWrap + ' #selectAdType'
			if(document.querySelector(selectAdType)) {
				document.querySelector(selectAdType).addEventListener('click', (event) => {
					let adType = event.target.getAttribute('data-adtype')
					$(formViewTypeWrap +" #slectedAdType").text(adType);
					getterSetter.set('brokerAdType',adType)
					$("#brokerAdType").val(adType);
				})
			}

			let selectedCategory = formViewTypeWrap + ' #selectedCategory';
			if(document.querySelector(selectedCategory)) {
				document.querySelector(selectedCategory).addEventListener('click', (event) => {
					let cat = event.target.getAttribute('data-cat')
					$(formViewTypeWrap + " #slectedUnitType").text(cat);
					getterSetter.set('brokerUnitType',cat);
					let category_type = categoryMapping(cat);
					getterSetter.set('brokerCategory',category_type);
				})
			}
		}

	if(['QAP_BOOK_VISIT_SNB', 'QAP_BOOK_VISIT_VAP'].includes(getterSetter.get('reqSource'))) {
		replyAd.initSiteVisitFields();
	}

}

// Renders the country suggestions
replyAd.renderCountrySuggestions = (form, suggestions = []) => {
	const elContactForm = document.querySelector(form);
	const elCountrySuggestions = elContactForm.querySelector('[data-ref=contact--country-suggestions]');

	elCountrySuggestions.innerHTML = suggestions.reduce((html, country) => html + `
		<li tabindex="0" data-value="${country.dial_code}" data-country="${country.code}">${country.dial_code} (${country.name})</li>
	`, '');
};

// Initializes the countries dropdown
replyAd.initCountryCodeDropdown = (form) => {
	// Event listener for country phone code change
	const elContactForm = document.querySelector(form);
	const elPhoneWrapper = elContactForm.querySelector('#replyotpshow');
	const elPhoneCodeWrapper = elContactForm.querySelector('.country_c');
	const elMobileNumber = elContactForm.querySelector('input[name=mobile]');
	const elMobileDropdown = elContactForm.querySelector('#selectMobile')
	const elPhoneCodeHidden = elContactForm.querySelector('input[name=phonecode]');
	const elPhoneCodeLabel = elContactForm.querySelector('[data-ref=contact--phone-code]');
	const elCountrySearchInput = elContactForm.querySelector('[data-ref=contact--country-search]');
	const elCountrySuggestions = elContactForm.querySelector('[data-ref=contact--country-suggestions]');
	const elHomeLoanWrapper = elContactForm.querySelector('[data-ref=contact--homeloan-wrapper]');
	const elHomeLoanCheckbox = elHomeLoanWrapper && elHomeLoanWrapper.querySelector('#showHideBank');
	// Remove the country code dropdown if the user doesn't
	// have any mobile associated
	if (elMobileDropdown) {
		elPhoneCodeWrapper.remove();
	} else {
		let cursor = -1;
		let elLastCursorOnSuggestion = null;

		// Render the country suggestions
		replyAd.renderCountrySuggestions(form, COUNTRIES);

		// Country auto suggest
		elCountrySearchInput.addEventListener('input', ({target: {value}}) => {
			const matchingCountries = COUNTRIES.filter((country) => {
				return country.dial_code.includes(value) || 
					(country.name.toLowerCase().startsWith(value.toLowerCase()));
			});

			// Re-render the country suggestions
			cursor = -1;
			replyAd.renderCountrySuggestions(form, matchingCountries);
		});

		// Keyboard navigation for country suggestions
		elCountrySearchInput.addEventListener('keydown', (ev) => {
			if (38 === ev.keyCode || 40 === ev.keyCode || 13 === ev.keyCode) {
				const suggestions = elCountrySuggestions.children;
				const max = elCountrySuggestions.childElementCount;

				if (elLastCursorOnSuggestion) {
					elLastCursorOnSuggestion.classList.remove('active');
				}

				// Update the cursor
				if (40 === ev.keyCode && cursor < (max - 1)) {
					cursor += 1;
					elLastCursorOnSuggestion = suggestions.item(cursor);
					elLastCursorOnSuggestion.classList.add('active');

					// Scroll down
					if (elLastCursorOnSuggestion.offsetTop > elCountrySuggestions.clientHeight) {
						elCountrySuggestions.scrollTop = elCountrySuggestions.scrollTop + elLastCursorOnSuggestion.scrollHeight + 2;
					}
				} else if (38 === ev.keyCode && cursor > 0) {
					cursor -= 1;
					elLastCursorOnSuggestion = suggestions.item(cursor);
					elLastCursorOnSuggestion.classList.add('active');

					// Scroll down
					if (elLastCursorOnSuggestion.offsetTop > elCountrySuggestions.clientHeight) {
						elCountrySuggestions.scrollTop = elCountrySuggestions.scrollTop - elLastCursorOnSuggestion.scrollHeight - 2;
					}
				} else if (13 === ev.keyCode) {
					ev.preventDefault();
					suggestions.item(cursor).click();
				}
			}
		});

		// Country selection from suggestions
		elCountrySuggestions.addEventListener('click', ({target: {dataset: {value, country}}}) => {
			if (!value) return;

			elPhoneCodeHidden.value = value;
			elPhoneCodeLabel.innerText = value;
			getterSetter.set('countryCode', country);

			if (elHomeLoanCheckbox) {
				elHomeLoanCheckbox.checked = false;
			}
			
			// Hide the "Homeloan" option if the user is an NRI
			if ('+91' !== value) {
				getterSetter.set('isNRIMobile', true);
				elPhoneWrapper.querySelector('.error-container').classList.add('hide');
			} else {
				getterSetter.set('isNRIMobile', false);
			}

			// Validate the phone number based on the country selected
			formValidator.isMobileNoValid.call(elMobileNumber);
		});
	}
}

replyAd.setLoggedInData = (data)=> {
	data.userMobile = (typeof data.userMobile === 'undefined') ? [] : data.userMobile;
	data.userEmail = (typeof data.userEmail === 'undefined') ? [] : data.userEmail;
	data.userEmail.length > 0 ? getterSetter.set('email',data.userEmail[0].emailId) : getterSetter.set('email','');
	data.userMobile.length > 0 ? getterSetter.set('mobile',data.userMobile[0].mobileNumber) : getterSetter.set('mobile','');
	(data.firstName && data.firstName != '') ? getterSetter.set('name',data.firstName) : getterSetter.set('name','');
	getterSetter.set('loggedInUserId',data.id)
	formDetails.emailList = data.userEmail;
	formDetails.mobileList = data.userMobile;
	formDetails.email = getterSetter.get('email');
	formDetails.mobile = getterSetter.get('mobile');
	formDetails.name = validateEmail(getterSetter.get('name')) ? '' : getterSetter.get('name');
}

replyAd.setLoginName = (user)=>{
    if(user.firstName){
        return user.firstName;
    }else if(user.email){
        return user.email.substring(0, user.email.indexOf("@"));
    }else{
        return '';
    }
}
replyAd.valid = function () {
	var $form = $(clickedElement.closest("form[name='replyForm']"));
	var nameElement = $form.find(":input[name='name']");
	var mobileElement = $form.find(":input[name='mobile']");
	var emailElement = $form.find(":input[name='email']");
	var nameValid = formValidator.isNameValid.call(nameElement);
	var emailValid = formValidator.isEmailValid.call(emailElement);
	var mobileValid = formValidator.isMobileNoValid.call(mobileElement);

	getterSetter.set('name',username.value)
	
	// If Quikr Assured property, validate the visit slots
	if(['QAP_BOOK_VISIT_SNB', 'QAP_BOOK_VISIT_VAP'].includes(getterSetter.get('reqSource'))) {
		if(!getterSetter.get('selectedSiteVisitDate') || !getterSetter.get('selectedSiteVisitStartTime' || !getterSetter.get('selectedSiteVisitEndTime'))) {
			return false;
		}
	}

	if(getterSetter.get('formType') == 'broker'){
	    var pricevalid = replyAd.priceValid();
		return (nameValid && mobileValid && emailValid && pricevalid)|| (formDetails.name && formDetails.mobile && formDetails.email && pricevalid);
	} else{
		return (nameValid && mobileValid && emailValid);
	}
};

replyAd.priceValid = function() {
	var $form = $(clickedElement.closest("form[name='replyForm']"));
	var $minprice = $form.find(".pricemin");
	var minPriceElement = $form.find(":input[name='minPrice']");
	var maxPriceElement = $form.find(":input[name='maxPrice']");
	if(minPriceElement[0].value != '' || maxPriceElement[0].value != '') {
		if(parseInt(minPriceElement[0].value) > parseInt(maxPriceElement[0].value)) {
			$minprice.find('.error-container').removeClass('hide');
            $minprice.find('.min_max_invalid').siblings().hide().end().show();
            return false;
        } else {
        	$minprice.find('.error-container').addClass('hide');
        	return true;
        }
	} 
	return true;
}

replyAd.siteVisitDateClickHandler = (evSiteVisitDateClick) => {
	const elSelectedDate = evSiteVisitDateClick.target;
	const selectedDate = new Date(elSelectedDate.getAttribute('data-sitevisit-date'));
	getterSetter.set('selectedSiteVisitDate', selectedDate.getTime());

	// Finally reflect the dropdown value
	document.querySelector('#replySiteVisitSelectedDate').innerText = elSelectedDate.innerText;
};

replyAd.siteVisitTimeClickHandler = (evSiteVisitTimeClick) => {
	const elSelectedTime = evSiteVisitTimeClick.target;
	const selectedStartTime = new Date(1970, 0, 1, 0, 0, 0, 0);
	const selectedEndTime = new Date(1970, 0, 1, 0, 0, 0, 0);

	// Get the starting hour value and add 1 hour to the start time to get the end time
	selectedStartTime.setHours(Number(elSelectedTime.getAttribute('data-sitevisit-time')));
	selectedEndTime.setHours(selectedStartTime.getHours() + 1);

	getterSetter.set('selectedSiteVisitStartTime', selectedStartTime.getTime());
	getterSetter.set('selectedSiteVisitEndTime', selectedEndTime.getTime());

	// Finally reflect the dropdown value
	document.querySelector('#replySiteVisitSelectedTime').innerText = elSelectedTime.innerText;
};

replyAd.initSiteVisitFields = () => {
	const elSiteVisitDateDropdown = document.querySelector('#replySiteVisitDateDropdown');
	const elSiteVisitTimeDropdown = document.querySelector('#replySiteVisitTimeDropdown');

	// Remove the event handlers, if already attached
	elSiteVisitDateDropdown.removeEventListener('click', replyAd.siteVisitDateClickHandler);
	elSiteVisitTimeDropdown.removeEventListener('click', replyAd.siteVisitTimeClickHandler);

	elSiteVisitDateDropdown.addEventListener('click', replyAd.siteVisitDateClickHandler);
	elSiteVisitTimeDropdown.addEventListener('click', replyAd.siteVisitTimeClickHandler);
};

replyAd.modalRegister = ()=>{
	replysubmit.addEventListener('click', (event) => {
		clickedElement = event.currentTarget
		replyAd.submit()
	})
	if(replyAd.loginStatus){
		if(document.querySelector('#selectEmail')){
			document.querySelector('#selectEmail').addEventListener('click', (event) => {
				let email = event.target.getAttribute('data-email')
				$('#selectedEmail').html(email);
				formDetails.email = email
				getterSetter.set('email',email)
			})
		}

		if(document.querySelector('#selectMobile')){
			document.querySelector('#selectMobile').addEventListener('click', (event) => {
				let mobile = event.target.getAttribute('data-mobile')
				$('#selectedMobileNo').html(mobile);
				formDetails.mobile = mobile
				getterSetter.set('mobile',mobile)
			})
		}
	}

	if(getterSetter.get('formType') == 'broker'){
		getterSetter.set('brokerAdType','Buy');
		getterSetter.set('brokerUnitType','Apartment');
		getterSetter.set('brokerCategory','Residential');
		if(document.querySelector('#selectAdType')) {
			document.querySelector('#selectAdType').addEventListener('click', (event) => {
				let adType = event.target.getAttribute('data-adtype')
				$("#slectedAdType").text(adType);
				getterSetter.set('brokerAdType',adType)
				$("#brokerAdType").val(adType);
			})
		}

		if(document.querySelector('#selectedCategory')) {
			document.querySelector('#selectedCategory').addEventListener('click', (event) => {
				let cat = event.target.getAttribute('data-cat')
				$("#slectedUnitType").text(cat);
				getterSetter.set('brokerUnitType',cat);
				let category_type = categoryMapping(cat);
				getterSetter.set('brokerCategory',category_type);
			})	
		}
	}
	
	let clickEventsSingleValue = [readMore,readLess];
	Array.from(clickEventsSingleValue).forEach((getElement) => {
		getElement.addEventListener('click', (event) => {
			var readMoreLess = $(event.target).parents().find('.readMoreLess');
        readMoreLess.toggleClass('hide');
        var readMore = $('.ReadMore');
        var ReadLess = $('.ReadLess');
        if($(event.target).html() == 'Read More'){
        	readMore.addClass('hide');
        	ReadLess.removeClass('hide');
        } else {
        	ReadLess.addClass('hide');
        	readMore.removeClass('hide');
        }
		})
	})

	if(document.querySelector('#showHideBank')) {
		document.querySelector('#showHideBank').addEventListener('click', (event) =>{
			gaHomeLoan(event.target.checked)
		})
	}
}

replyAd.getFormValue = ()=>{
	let form = getFormObject(clickedElement.closest("form[name='replyForm']"));
	formDetails.email = form.email ? form.email : formDetails.email;
	formDetails.mobile = form.mobile ? form.mobile : formDetails.mobile;
	formDetails.phonecode = form.phonecode ? form.phonecode : formDetails.phonecode;
	formDetails.name= form.name ? form.name : formDetails.name;

	getterSetter.set('email',formDetails.email);
	getterSetter.set('mobile',formDetails.mobile);
	getterSetter.set('phonecode',formDetails.phonecode.replace('+', ''));
	getterSetter.set('name',formDetails.name);
}
replyAd.viewad = ()=>{
	getterSetter.set('loanRequirement',false)
	if(!replyAd.loginStatus){
		replyAd.getFormValue()
		var otpVerifiedNo = getCookie('otp_verified_no');
        if(isSet(otpService.getOtpVariable().otpNumber) && isSet(otpService.getOtpVariable().otpId)){
			verifyOTP()
		}else if(otpVerifiedNo == getterSetter.get('mobile')){
			showOtpSuccessSection();
		}
		else {
			generateOTP();
		}
	}else{
		showOtpSuccessSection();
	}
}
replyAd.contact = ()=>{
	formDetails.showBank ? getterSetter.set('loanRequirement',showHideBank.checked): getterSetter.set('loanRequirement',false)
	typeof isMultiplierAllowed !== 'undefined' && isSet(isMultiplierAllowed) ? getterSetter.set('isMultiplierAllowed',isMultiplierAllowed.checked): getterSetter.set('isMultiplierAllowed',false)
	if(replyAd.loginStatus){
		let details = getterSetter.get('loginData');

		if (details.data.user.userEmail.length > 0) {
			getterSetter.set('email', details.data.user.userEmail[0].emailId);
		} else {
			getterSetter.set('email', formDetails.email);
		}
		getterSetter.set('detectSpamUser',true)
		submitReply(replyAd);
	}

	if(!replyAd.loginStatus){
		replyAd.getFormValue()
		var obj = {
            "emailId": formDetails.email || '',
            "mobileNumber":formDetails.mobile || '',
            "userDetailsNeeded": true
        };

        if (isSet(otpService.getOtpVariable().otpNumber) && isSet(otpService.getOtpVariable().otpId)){
        	handleApiErr(replyAd);
		} else {
			// Avoid email mobile conflict detection for NRI requirments
			if (getterSetter.get('isNRIMobile')) {
				generateOTP();
			} else {
				dataService.detectEmailMobileConflict(obj).then((response) => {
					replyError(response)
				});
			}
		}

	}
}
replyAd.submit = function (formWrapper) {
	if(formWrapper && formWrapper == '#static_form_wrap' && isSet(globals.pageType)) {
		if(globals.pageType == 'VAP') {
			getterSetter.set('reqSource','IM_INTERESTED_IN_LISTING');
		} else {
			getterSetter.set('reqSource','IM_INTERESTED_IN_PROJECT');
		}
	}
	if (!replyAd.valid()) {
		return false;
	} 

	const elFormWrapper = formWrapper ? document.querySelector(formWrapper) : null;
	const elOtpField = elFormWrapper ? elFormWrapper.querySelector('#replyotpshow') : document.getElementById("replyotpshow");

	// Trigger Act broadband ga
	const actBroadbandCheckbox = document.querySelector(formWrapper + ' #js-act-broadband');
	if (formDetails.showActBroadband && actBroadbandCheckbox && actBroadbandCheckbox.checked) {
		if (!formDetails.name || !formDetails.email || !formDetails.mobile) {
			replyAd.getFormValue();
		}

		const { name, email, mobile, tilesDetails: { id, city, cityName } } = formDetails;
		const action = `${city || cityName}-${id}`;
		const label = `${name},${email},${mobile}`;

		if (elOtpField && !elOtpField.classList.contains('otp')) {
			gaActBroadband(action, label);
		}
	}

	formDetails.email = formWrapper ? $(formWrapper + ' :input[name="email"]').val() : '';
	if(elOtpField && elOtpField.classList.contains('otp')) {
		let contactotp = elFormWrapper ? elFormWrapper.querySelector('#otp') : document.querySelector('#commonpopup #otp');
		if(contactotp && contactotp.value == '') {
			getterSetter.set('errMsg', "OTP required");
			setAlert();
			return false;
		}
	}

	if(location.hostname.includes("www.quikrrealty.com")){
		replyAd.getFormValue()
		requirementService.addRequirement(requirementPayload());
		QrThankMsg();
		return;
	}
	const optinEl = document.querySelector(formWrapper + ' #whatsAppNoti');
	getterSetter.set('optInCheck',optinEl.checked);
	if(getterSetter.get('isOtpverify')){
		replyAd.viewad()
	} else {
		replyAd.contact()
	}
	localStorage.setItem('contactTimeStamp', getTime());	
};

window.readMoreLess = (el) => {
	el ? $(el).closest('.qcontent').find('.readMoreLess , .ReadMore, .ReadLess').toggleClass('hide'): '';
}
