var loginSvc = {};
import {getterSetter} from './utils/common'
export let getLoggedInUser = new Promise((resolve, reject) => {

    return fetch('/homes/re2/auth?aj=1',{
        method: 'GET',
        credentials: 'include'
    }).then((res) => {
        return res.json()
    }).then((data) => {
        return resolve(data);
    }).catch((reason) => {
        return reject(reason);
    });
})


export let login = function (payload) {

    return new Promise((resolve, reject) => {
        let postData = new FormData();
        postData.append("jsonData", JSON.stringify( payload ))
        fetch('/homes/re2/login?aj=1', {
            method : 'POST',
            credentials: 'include',
            body : postData
        }).then((res) => {
            return res.json();
        }).then((data) => {
            getterSetter.set( 'loginData', data);
            return resolve(data);
        }).catch((reason) => {
            return reject(reason);
        });
    })
}

export let addEmail = function (payload) {

    return new Promise((resolve, reject) => {
        let postData = new FormData();
        postData.append("jsonData", JSON.stringify( payload ))
        fetch('/homes/re2/addEmail?aj=1', {
            method : 'POST',
            credentials: 'include',
            body : postData
        }).then((res) => {
            return res.json();
        }).then((data) => {
            return resolve(data);
        }).catch((reason) => {
            return reject(reason);
        });
    })
}

export let detectEmailMobileConflict = function (payload) {

    return new Promise((resolve, reject) => {
        let postData = new FormData();
        postData.append("jsonData", JSON.stringify( payload ))
        fetch('/homes/re2/detectEmailMobileConflict', {
            method : 'POST',
            body : postData
        }).then((res) => {
            return res.json();
        }).then((data) => {
            return resolve(data);
        }).catch((reason) => {
            return reject(reason);
        });
    })
}

export let updateSession = function (jsonData) {
    var deferred = $.Deferred();
    $.ajax({
        url: "/homes/re2/updateSession",
        type: "POST",
        data : jsonData,
    }).success(function (data) {
            deferred.resolve(data);
        })
        .error(function (err) {
            deferred.reject(err);
        });

    return deferred.promise;
}
