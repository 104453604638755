module.exports = {
    
        "data" : {
            "topCities": [
                {
                    "id": "22",
                    "name": "Ahmedabad",
                    "siblingCities": {
                        "382010": "Gandhinagar"
                    }
                },
                {
                    "id": "23",
                    "name": "Bangalore",
                    "siblingCities": {
                        "1047522": "Chikkaballapur",
                        "1047882": "Chintamani",
                        "1048602": "Doddaballapur"
                    }
                },
                {
                    "id": "24",
                    "name": "Chandigarh",
                    "siblingCities": {
                        "134102": "Panchkula",
                        "140110": "Mohali",
                        "160104": "Zirakpur",
                        "1138783": "Kharar"
                    }
                },
                {
                    "id": "25",
                    "name": "Chennai",
                    "siblingCities": {
                        "1099723": "Kanchipuram"
                    }
                },
                {
                    "id": "26",
                    "name": "Coimbatore",
                    "siblingCities": {
                        "100236": "Udumalpet"
                    }
                },
                {
                    "id": "27",
                    "name": "Delhi",
                    "siblingCities": {
                        "121001": "Faridabad",
                        "132222": "Gurgaon",
                        "201002": "Ghaziabad",
                        "201301": "Noida",
                        "201310": "GreaterNoida",
                        "301019": "Bhiwadi"
                    }
                },
                {
                    "id": "28",
                    "name": "Hyderabad",
                    "siblingCities": {
                        "1012061": "Secunderabad"
                    }
                },
                {
                    "id": "29",
                    "name": "Kochi",
                    "siblingCities": {
                        "682301": "Tripunithura",
                        "1054902": "Edathala"
                    }
                },
                {
                    "id": "30",
                    "name": "Kolkata",
                    "siblingCities": {
                        "1127443": "Bally",
                        "1127983": "Bansberia",
                        "1128163": "Baranagar",
                        "1128343": "Barasat",
                        "1128703": "Barrackpore",
                        "1129603": "Bhatpara",
                        "1129783": "Bidhannagar",
                        "1133383": "Dumdum",
                        "1134283": "Garulia",
                        "1135723": "Howrah",
                        "1135903": "HugliChuchura",
                        "1137883": "Kalyani",
                        "1139323": "Konnagar",
                        "1140223": "Maheshtala",
                        "1142203": "NewBarrackpur",
                        "1144543": "Serampore",
                        "1146163": "Titagarh",
                        "1146703": "Uttarpara"
                    }
                },
                {
                    "id": "31",
                    "name": "Mumbai",
                    "siblingCities": {
                        "400601": "Thane",
                        "400612": "Mumbra",
                        "400701": "NaviMumbai",
                        "402305": "Raigad",
                        "421201": "Dombivli",
                        "421301": "Kalyan",
                        "421501": "Ambernath",
                        "1067863": "Badlapur",
                        "1074523": "Palghar",
                        "1074883": "Panvel",
                        "1077403": "VasaiVirar"
                    }
                },
                {
                    "id": "33",
                    "name": "Pune",
                    "siblingCities": {
                        "1075603": "PimpriChinchwad"
                    }
                },
                {
                    "id": "132222",
                    "name": "Gurgaon",
                    "siblingCities": {
                        "27": "Delhi",
                        "121001": "Faridabad",
                        "201002": "Ghaziabad",
                        "201301": "Noida",
                        "201310": "GreaterNoida",
                        "301019": "Bhiwadi"
                    }
                },
                {
                    "id": "152222",
                    "name": "Jaipur",
                    "siblingCities": {}
                },
                {
                    "id": "162222",
                    "name": "Lucknow",
                    "siblingCities": {}
                },
                {
                    "id": "201301",
                    "name": "Noida",
                    "siblingCities": {
                        "27": "Delhi",
                        "121001": "Faridabad",
                        "132222": "Gurgaon",
                        "201002": "Ghaziabad",
                        "201310": "GreaterNoida",
                        "301019": "Bhiwadi"
                    }
                },
                {
                    "id": "400701",
                    "name": "NaviMumbai",
                    "siblingCities": {
                        "31": "Mumbai",
                        "400601": "Thane",
                        "400612": "Mumbra",
                        "402305": "Raigad",
                        "421201": "Dombivli",
                        "421301": "Kalyan",
                        "421501": "Ambernath",
                        "1067863": "Badlapur",
                        "1074523": "Palghar",
                        "1074883": "Panvel",
                        "1077403": "VasaiVirar"
                    }
                },
                {
                    "id": "695001",
                    "name": "Trivandrum",
                    "siblingCities": {
                        "1056522": "Nedumangad",
                        "1056702": "Neyyattinkara"
                    }
                }
            ],
            "allCities": [
                {
                    "id": "32",
                    "name": "Mysore",
                    "siblingCities": {
                        "1051302": "Mandya"
                    }
                },
                {
                    "id": "100001",
                    "name": "Changlang",
                    "siblingCities": {}
                },
                {
                    "id": "100002",
                    "name": "Namsai",
                    "siblingCities": {}
                },
                {
                    "id": "100003",
                    "name": "Barpeta",
                    "siblingCities": {}
                },
                {
                    "id": "100004",
                    "name": "Cachar",
                    "siblingCities": {}
                },
                {
                    "id": "100005",
                    "name": "Darrang",
                    "siblingCities": {}
                },
                {
                    "id": "100006",
                    "name": "Dhemaji",
                    "siblingCities": {}
                },
                {
                    "id": "100007",
                    "name": "Goalpara",
                    "siblingCities": {}
                },
                {
                    "id": "100008",
                    "name": "Golaghat",
                    "siblingCities": {}
                },
                {
                    "id": "100009",
                    "name": "Hailakandi",
                    "siblingCities": {}
                },
                {
                    "id": "100010",
                    "name": "Kamrup",
                    "siblingCities": {}
                },
                {
                    "id": "100011",
                    "name": "Kokrajhar",
                    "siblingCities": {}
                },
                {
                    "id": "100012",
                    "name": "Marigaon",
                    "siblingCities": {}
                },
                {
                    "id": "100013",
                    "name": "Nalbari",
                    "siblingCities": {}
                },
                {
                    "id": "100014",
                    "name": "Sonitpur",
                    "siblingCities": {}
                },
                {
                    "id": "100015",
                    "name": "Banka",
                    "siblingCities": {}
                },
                {
                    "id": "100016",
                    "name": "Bhojpur",
                    "siblingCities": {}
                },
                {
                    "id": "100017",
                    "name": "Godda",
                    "siblingCities": {}
                },
                {
                    "id": "100018",
                    "name": "Kaimur",
                    "siblingCities": {}
                },
                {
                    "id": "100019",
                    "name": "Khagaria",
                    "siblingCities": {}
                },
                {
                    "id": "100020",
                    "name": "Madhepura",
                    "siblingCities": {}
                },
                {
                    "id": "100021",
                    "name": "Nalanda",
                    "siblingCities": {}
                },
                {
                    "id": "100022",
                    "name": "Rohtas",
                    "siblingCities": {}
                },
                {
                    "id": "100023",
                    "name": "Saran",
                    "siblingCities": {}
                },
                {
                    "id": "100024",
                    "name": "Sheohar",
                    "siblingCities": {}
                },
                {
                    "id": "100025",
                    "name": "Siwan",
                    "siblingCities": {}
                },
                {
                    "id": "100026",
                    "name": "Bastar",
                    "siblingCities": {}
                },
                {
                    "id": "100027",
                    "name": "Jashpur",
                    "siblingCities": {}
                },
                {
                    "id": "100028",
                    "name": "Kanker",
                    "siblingCities": {}
                },
                {
                    "id": "100029",
                    "name": "Mahasamund",
                    "siblingCities": {}
                },
                {
                    "id": "100030",
                    "name": "Surguja",
                    "siblingCities": {}
                },
                {
                    "id": "100031",
                    "name": "Kutch",
                    "siblingCities": {}
                },
                {
                    "id": "100032",
                    "name": "Panchmahal",
                    "siblingCities": {}
                },
                {
                    "id": "100033",
                    "name": "Dang",
                    "siblingCities": {}
                },
                {
                    "id": "100034",
                    "name": "CharkhiDadri",
                    "siblingCities": {}
                },
                {
                    "id": "100036",
                    "name": "Hamirpur",
                    "siblingCities": {}
                },
                {
                    "id": "100037",
                    "name": "Kinnaur",
                    "siblingCities": {}
                },
                {
                    "id": "100038",
                    "name": "LahaulSpiti",
                    "siblingCities": {}
                },
                {
                    "id": "100039",
                    "name": "Mandi",
                    "siblingCities": {}
                },
                {
                    "id": "100040",
                    "name": "Nahan",
                    "siblingCities": {}
                },
                {
                    "id": "100041",
                    "name": "Sirmaur",
                    "siblingCities": {}
                },
                {
                    "id": "100042",
                    "name": "Budgam",
                    "siblingCities": {}
                },
                {
                    "id": "100043",
                    "name": "Doda",
                    "siblingCities": {}
                },
                {
                    "id": "100044",
                    "name": "Kargil",
                    "siblingCities": {}
                },
                {
                    "id": "100045",
                    "name": "Kupwara",
                    "siblingCities": {}
                },
                {
                    "id": "100046",
                    "name": "Poonch",
                    "siblingCities": {}
                },
                {
                    "id": "100047",
                    "name": "Pulwama",
                    "siblingCities": {}
                },
                {
                    "id": "100048",
                    "name": "Rajouri",
                    "siblingCities": {}
                },
                {
                    "id": "100049",
                    "name": "Samba",
                    "siblingCities": {}
                },
                {
                    "id": "100050",
                    "name": "Chatra",
                    "siblingCities": {}
                },
                {
                    "id": "100051",
                    "name": "Dumka",
                    "siblingCities": {}
                },
                {
                    "id": "100052",
                    "name": "Garhwa",
                    "siblingCities": {}
                },
                {
                    "id": "100053",
                    "name": "Gumla",
                    "siblingCities": {}
                },
                {
                    "id": "100054",
                    "name": "Hazaribagh",
                    "siblingCities": {}
                },
                {
                    "id": "100055",
                    "name": "Jamtara",
                    "siblingCities": {}
                },
                {
                    "id": "100056",
                    "name": "Koderma",
                    "siblingCities": {}
                },
                {
                    "id": "100057",
                    "name": "Latehar",
                    "siblingCities": {}
                },
                {
                    "id": "100058",
                    "name": "Lohardaga",
                    "siblingCities": {}
                },
                {
                    "id": "100059",
                    "name": "Palamu",
                    "siblingCities": {}
                },
                {
                    "id": "100060",
                    "name": "WestSinghbhum",
                    "siblingCities": {}
                },
                {
                    "id": "100061",
                    "name": "Sahibganj",
                    "siblingCities": {}
                },
                {
                    "id": "100062",
                    "name": "SeraikelaKharsawan",
                    "siblingCities": {}
                },
                {
                    "id": "100063",
                    "name": "Simdega",
                    "siblingCities": {}
                },
                {
                    "id": "100064",
                    "name": "Coorg",
                    "siblingCities": {}
                },
                {
                    "id": "100065",
                    "name": "UttaraKannada",
                    "siblingCities": {}
                },
                {
                    "id": "100066",
                    "name": "Idukki",
                    "siblingCities": {
                        "685584": "Thodupuzha",
                        "1054362": "Changanacherry",
                        "1055982": "Kottayam"
                    }
                },
                {
                    "id": "100067",
                    "name": "Pathanamthitta",
                    "siblingCities": {
                        "689101": "Thiruvalla",
                        "1055802": "Kollam"
                    }
                },
                {
                    "id": "100069",
                    "name": "Alirajpur",
                    "siblingCities": {}
                },
                {
                    "id": "100070",
                    "name": "Anuppur",
                    "siblingCities": {}
                },
                {
                    "id": "100071",
                    "name": "Barwani",
                    "siblingCities": {}
                },
                {
                    "id": "100072",
                    "name": "Dindori",
                    "siblingCities": {}
                },
                {
                    "id": "100073",
                    "name": "Jhabua",
                    "siblingCities": {}
                },
                {
                    "id": "100076",
                    "name": "Neemuch",
                    "siblingCities": {}
                },
                {
                    "id": "100077",
                    "name": "Raisen",
                    "siblingCities": {}
                },
                {
                    "id": "100078",
                    "name": "Sidhi",
                    "siblingCities": {}
                },
                {
                    "id": "100079",
                    "name": "Umaria",
                    "siblingCities": {}
                },
                {
                    "id": "100080",
                    "name": "Gadchiroli",
                    "siblingCities": {}
                },
                {
                    "id": "100081",
                    "name": "Sindhudurg",
                    "siblingCities": {}
                },
                {
                    "id": "100083",
                    "name": "Chandel",
                    "siblingCities": {}
                },
                {
                    "id": "100084",
                    "name": "Churachandpur",
                    "siblingCities": {}
                },
                {
                    "id": "100085",
                    "name": "Senapati",
                    "siblingCities": {}
                },
                {
                    "id": "100086",
                    "name": "Tamenglong",
                    "siblingCities": {}
                },
                {
                    "id": "100087",
                    "name": "Thoubal",
                    "siblingCities": {}
                },
                {
                    "id": "100088",
                    "name": "EastKhasiHills",
                    "siblingCities": {}
                },
                {
                    "id": "100089",
                    "name": "Kolasib",
                    "siblingCities": {}
                },
                {
                    "id": "100090",
                    "name": "Lawngtlai",
                    "siblingCities": {}
                },
                {
                    "id": "100091",
                    "name": "Lunglei",
                    "siblingCities": {}
                },
                {
                    "id": "100092",
                    "name": "Mamit",
                    "siblingCities": {}
                },
                {
                    "id": "100093",
                    "name": "Saiha",
                    "siblingCities": {}
                },
                {
                    "id": "100094",
                    "name": "Serchhip",
                    "siblingCities": {}
                },
                {
                    "id": "100095",
                    "name": "Mokokchung",
                    "siblingCities": {}
                },
                {
                    "id": "100096",
                    "name": "Mon",
                    "siblingCities": {}
                },
                {
                    "id": "100097",
                    "name": "Phek",
                    "siblingCities": {}
                },
                {
                    "id": "100098",
                    "name": "Tuensang",
                    "siblingCities": {}
                },
                {
                    "id": "100099",
                    "name": "Wokha",
                    "siblingCities": {}
                },
                {
                    "id": "100100",
                    "name": "Zunheboto",
                    "siblingCities": {}
                },
                {
                    "id": "100101",
                    "name": "Angul",
                    "siblingCities": {}
                },
                {
                    "id": "100102",
                    "name": "Boudh",
                    "siblingCities": {}
                },
                {
                    "id": "100105",
                    "name": "Deogarh",
                    "siblingCities": {}
                },
                {
                    "id": "100106",
                    "name": "Ganjam",
                    "siblingCities": {}
                },
                {
                    "id": "100107",
                    "name": "Jagatsinghpur",
                    "siblingCities": {}
                },
                {
                    "id": "100108",
                    "name": "Jajpur",
                    "siblingCities": {}
                },
                {
                    "id": "100109",
                    "name": "Kalahandi",
                    "siblingCities": {}
                },
                {
                    "id": "100110",
                    "name": "Kandhamal",
                    "siblingCities": {}
                },
                {
                    "id": "100111",
                    "name": "Kendrapara",
                    "siblingCities": {}
                },
                {
                    "id": "100112",
                    "name": "Koraput",
                    "siblingCities": {}
                },
                {
                    "id": "100113",
                    "name": "Khordha",
                    "siblingCities": {}
                },
                {
                    "id": "100114",
                    "name": "Malkangiri",
                    "siblingCities": {}
                },
                {
                    "id": "100115",
                    "name": "Mayurbhanj",
                    "siblingCities": {}
                },
                {
                    "id": "100116",
                    "name": "Nabarangpur",
                    "siblingCities": {}
                },
                {
                    "id": "100117",
                    "name": "Nayagarh",
                    "siblingCities": {}
                },
                {
                    "id": "100118",
                    "name": "Subarnapur",
                    "siblingCities": {}
                },
                {
                    "id": "100119",
                    "name": "Sundargarh",
                    "siblingCities": {}
                },
                {
                    "id": "100120",
                    "name": "FatehgarhSahib",
                    "siblingCities": {}
                },
                {
                    "id": "100121",
                    "name": "Rupnagar",
                    "siblingCities": {}
                },
                {
                    "id": "100122",
                    "name": "Dungarpur",
                    "siblingCities": {}
                },
                {
                    "id": "100123",
                    "name": "Jalore",
                    "siblingCities": {}
                },
                {
                    "id": "100124",
                    "name": "Jhalawar",
                    "siblingCities": {}
                },
                {
                    "id": "100125",
                    "name": "Sirohi",
                    "siblingCities": {}
                },
                {
                    "id": "100126",
                    "name": "Ariyalur",
                    "siblingCities": {}
                },
                {
                    "id": "100127",
                    "name": "Perambalur",
                    "siblingCities": {}
                },
                {
                    "id": "100128",
                    "name": "Sivaganga",
                    "siblingCities": {}
                },
                {
                    "id": "100129",
                    "name": "Medak",
                    "siblingCities": {}
                },
                {
                    "id": "100130",
                    "name": "RangaReddy",
                    "siblingCities": {}
                },
                {
                    "id": "100131",
                    "name": "Dhalai",
                    "siblingCities": {}
                },
                {
                    "id": "100132",
                    "name": "NorthTripura",
                    "siblingCities": {}
                },
                {
                    "id": "100133",
                    "name": "SouthTripura",
                    "siblingCities": {}
                },
                {
                    "id": "100134",
                    "name": "WestTripura",
                    "siblingCities": {}
                },
                {
                    "id": "100135",
                    "name": "AmbedkarNagar",
                    "siblingCities": {}
                },
                {
                    "id": "100136",
                    "name": "Amethi",
                    "siblingCities": {}
                },
                {
                    "id": "100137",
                    "name": "Bagpat",
                    "siblingCities": {}
                },
                {
                    "id": "100138",
                    "name": "Chandauli",
                    "siblingCities": {}
                },
                {
                    "id": "100139",
                    "name": "Chitrakoot",
                    "siblingCities": {}
                },
                {
                    "id": "100141",
                    "name": "Kaushambi",
                    "siblingCities": {}
                },
                {
                    "id": "100142",
                    "name": "LakhimpurKheri",
                    "siblingCities": {}
                },
                {
                    "id": "100143",
                    "name": "Kushinagar",
                    "siblingCities": {}
                },
                {
                    "id": "100144",
                    "name": "Maharajganj",
                    "siblingCities": {}
                },
                {
                    "id": "100145",
                    "name": "Pratapgarh",
                    "siblingCities": {}
                },
                {
                    "id": "100146",
                    "name": "SantKabirNagar",
                    "siblingCities": {}
                },
                {
                    "id": "100147",
                    "name": "Shrawasti",
                    "siblingCities": {}
                },
                {
                    "id": "100148",
                    "name": "Siddharthnagar",
                    "siblingCities": {}
                },
                {
                    "id": "100149",
                    "name": "Sonbhadra",
                    "siblingCities": {}
                },
                {
                    "id": "100150",
                    "name": "Bageshwar",
                    "siblingCities": {}
                },
                {
                    "id": "100151",
                    "name": "Chamoli",
                    "siblingCities": {}
                },
                {
                    "id": "100152",
                    "name": "Champawat",
                    "siblingCities": {}
                },
                {
                    "id": "100153",
                    "name": "PauriGarhwal",
                    "siblingCities": {}
                },
                {
                    "id": "100154",
                    "name": "Pithoragarh",
                    "siblingCities": {}
                },
                {
                    "id": "100155",
                    "name": "Rudraprayag",
                    "siblingCities": {}
                },
                {
                    "id": "100156",
                    "name": "TehriGarhwal",
                    "siblingCities": {}
                },
                {
                    "id": "100157",
                    "name": "UdhamSinghNagar",
                    "siblingCities": {}
                },
                {
                    "id": "100158",
                    "name": "Uttarkashi",
                    "siblingCities": {}
                },
                {
                    "id": "100159",
                    "name": "Malda",
                    "siblingCities": {}
                },
                {
                    "id": "100160",
                    "name": "North24Parganas",
                    "siblingCities": {}
                },
                {
                    "id": "100161",
                    "name": "PurbaMedinipur",
                    "siblingCities": {}
                },
                {
                    "id": "100162",
                    "name": "South24Parganas",
                    "siblingCities": {}
                },
                {
                    "id": "100163",
                    "name": "DakshinDinajpur",
                    "siblingCities": {}
                },
                {
                    "id": "100164",
                    "name": "WestDinajpur",
                    "siblingCities": {}
                },
                {
                    "id": "100165",
                    "name": "PaschimMedinipur",
                    "siblingCities": {}
                },
                {
                    "id": "100167",
                    "name": "Akbarpur",
                    "siblingCities": {}
                },
                {
                    "id": "100168",
                    "name": "Krishna",
                    "siblingCities": {}
                },
                {
                    "id": "100169",
                    "name": "Prakasam",
                    "siblingCities": {}
                },
                {
                    "id": "100170",
                    "name": "Bansi",
                    "siblingCities": {}
                },
                {
                    "id": "100171",
                    "name": "Berhampore",
                    "siblingCities": {}
                },
                {
                    "id": "100194",
                    "name": "EastGodavari",
                    "siblingCities": {
                        "1004861": "Kakinada",
                        "1010441": "Rajahmundry"
                    }
                },
                {
                    "id": "100195",
                    "name": "Biharsharif",
                    "siblingCities": {}
                },
                {
                    "id": "100196",
                    "name": "Challakere",
                    "siblingCities": {}
                },
                {
                    "id": "100197",
                    "name": "Dharamshala",
                    "siblingCities": {}
                },
                {
                    "id": "100198",
                    "name": "Jalaun",
                    "siblingCities": {}
                },
                {
                    "id": "100199",
                    "name": "Jangipur",
                    "siblingCities": {}
                },
                {
                    "id": "100200",
                    "name": "Medinipur",
                    "siblingCities": {}
                },
                {
                    "id": "100201",
                    "name": "Rajpipla",
                    "siblingCities": {}
                },
                {
                    "id": "100202",
                    "name": "Tawang",
                    "siblingCities": {}
                },
                {
                    "id": "100203",
                    "name": "Tirap",
                    "siblingCities": {}
                },
                {
                    "id": "100204",
                    "name": "UpperSiang",
                    "siblingCities": {}
                },
                {
                    "id": "100205",
                    "name": "WestKameng",
                    "siblingCities": {}
                },
                {
                    "id": "100206",
                    "name": "WestSiang",
                    "siblingCities": {}
                },
                {
                    "id": "100207",
                    "name": "Devakottai",
                    "siblingCities": {}
                },
                {
                    "id": "100209",
                    "name": "DimaHasao",
                    "siblingCities": {}
                },
                {
                    "id": "100210",
                    "name": "Kadur",
                    "siblingCities": {
                        "1047702": "Chikmagalur"
                    }
                },
                {
                    "id": "100211",
                    "name": "EastChamparan",
                    "siblingCities": {}
                },
                {
                    "id": "100213",
                    "name": "Sheikhpura",
                    "siblingCities": {}
                },
                {
                    "id": "100214",
                    "name": "Pandalur",
                    "siblingCities": {}
                },
                {
                    "id": "100215",
                    "name": "JanjgirChampa",
                    "siblingCities": {}
                },
                {
                    "id": "100217",
                    "name": "Kawardha",
                    "siblingCities": {}
                },
                {
                    "id": "100218",
                    "name": "Raghunathganj",
                    "siblingCities": {}
                },
                {
                    "id": "100221",
                    "name": "Tirupattur",
                    "siblingCities": {}
                },
                {
                    "id": "100222",
                    "name": "Arsikere",
                    "siblingCities": {}
                },
                {
                    "id": "100223",
                    "name": "Avinashi",
                    "siblingCities": {}
                },
                {
                    "id": "100224",
                    "name": "Brahiya",
                    "siblingCities": {}
                },
                {
                    "id": "100225",
                    "name": "Chatrapur",
                    "siblingCities": {}
                },
                {
                    "id": "100226",
                    "name": "Didwana",
                    "siblingCities": {}
                },
                {
                    "id": "100227",
                    "name": "Gopeshwar",
                    "siblingCities": {}
                },
                {
                    "id": "100228",
                    "name": "Hiriyur",
                    "siblingCities": {}
                },
                {
                    "id": "100229",
                    "name": "Jamkhandi",
                    "siblingCities": {}
                },
                {
                    "id": "100230",
                    "name": "Kotdwara",
                    "siblingCities": {}
                },
                {
                    "id": "100231",
                    "name": "Mudhol",
                    "siblingCities": {}
                },
                {
                    "id": "100232",
                    "name": "Paralakhemundi",
                    "siblingCities": {}
                },
                {
                    "id": "100233",
                    "name": "Rasipuram",
                    "siblingCities": {}
                },
                {
                    "id": "100234",
                    "name": "Sindagi",
                    "siblingCities": {}
                },
                {
                    "id": "100236",
                    "name": "Udumalpet",
                    "siblingCities": {
                        "26": "Coimbatore"
                    }
                },
                {
                    "id": "100239",
                    "name": "Bhatkal",
                    "siblingCities": {}
                },
                {
                    "id": "100248",
                    "name": "Nakodar",
                    "siblingCities": {}
                },
                {
                    "id": "121001",
                    "name": "Faridabad",
                    "siblingCities": {
                        "27": "Delhi",
                        "132222": "Gurgaon",
                        "201002": "Ghaziabad",
                        "201301": "Noida",
                        "201310": "GreaterNoida",
                        "301019": "Bhiwadi"
                    }
                },
                {
                    "id": "122103",
                    "name": "Mewat",
                    "siblingCities": {}
                },
                {
                    "id": "123001",
                    "name": "Mahendragarh",
                    "siblingCities": {}
                },
                {
                    "id": "123106",
                    "name": "Dharuhera",
                    "siblingCities": {}
                },
                {
                    "id": "124103",
                    "name": "Jhajjar",
                    "siblingCities": {}
                },
                {
                    "id": "125001",
                    "name": "Hisar",
                    "siblingCities": {}
                },
                {
                    "id": "125055",
                    "name": "Sirsa",
                    "siblingCities": {}
                },
                {
                    "id": "134102",
                    "name": "Panchkula",
                    "siblingCities": {
                        "24": "Chandigarh",
                        "140110": "Mohali",
                        "160104": "Zirakpur",
                        "1138783": "Kharar"
                    }
                },
                {
                    "id": "136118",
                    "name": "Kurukshetra",
                    "siblingCities": {}
                },
                {
                    "id": "140110",
                    "name": "Mohali",
                    "siblingCities": {
                        "24": "Chandigarh",
                        "134102": "Panchkula",
                        "160104": "Zirakpur",
                        "1138783": "Kharar"
                    }
                },
                {
                    "id": "141001",
                    "name": "Ludhiana",
                    "siblingCities": {}
                },
                {
                    "id": "142222",
                    "name": "Indore",
                    "siblingCities": {}
                },
                {
                    "id": "143104",
                    "name": "Amritsar",
                    "siblingCities": {}
                },
                {
                    "id": "144001",
                    "name": "Jalandhar",
                    "siblingCities": {}
                },
                {
                    "id": "144514",
                    "name": "Nawanshahr",
                    "siblingCities": {}
                },
                {
                    "id": "160104",
                    "name": "Zirakpur",
                    "siblingCities": {
                        "24": "Chandigarh",
                        "134102": "Panchkula",
                        "140110": "Mohali",
                        "1138783": "Kharar"
                    }
                },
                {
                    "id": "171206",
                    "name": "Shimla",
                    "siblingCities": {}
                },
                {
                    "id": "172222",
                    "name": "Nagpur",
                    "siblingCities": {}
                },
                {
                    "id": "173204",
                    "name": "Kasauli",
                    "siblingCities": {}
                },
                {
                    "id": "173209",
                    "name": "Dharampur",
                    "siblingCities": {}
                },
                {
                    "id": "173215",
                    "name": "Solan",
                    "siblingCities": {}
                },
                {
                    "id": "174001",
                    "name": "BilaspurHP",
                    "siblingCities": {}
                },
                {
                    "id": "175101",
                    "name": "Kullu",
                    "siblingCities": {}
                },
                {
                    "id": "175131",
                    "name": "Manali",
                    "siblingCities": {}
                },
                {
                    "id": "176036",
                    "name": "Kangra",
                    "siblingCities": {}
                },
                {
                    "id": "176215",
                    "name": "Dharamsala",
                    "siblingCities": {}
                },
                {
                    "id": "176304",
                    "name": "Dalhousie",
                    "siblingCities": {}
                },
                {
                    "id": "176316",
                    "name": "Chamba",
                    "siblingCities": {}
                },
                {
                    "id": "180001",
                    "name": "Jammu",
                    "siblingCities": {}
                },
                {
                    "id": "182144",
                    "name": "Ramban",
                    "siblingCities": {}
                },
                {
                    "id": "182204",
                    "name": "Kishtwar",
                    "siblingCities": {}
                },
                {
                    "id": "182222",
                    "name": "Surat",
                    "siblingCities": {}
                },
                {
                    "id": "182311",
                    "name": "Reasi",
                    "siblingCities": {}
                },
                {
                    "id": "190001",
                    "name": "Srinagar",
                    "siblingCities": {}
                },
                {
                    "id": "191201",
                    "name": "Ganderbal",
                    "siblingCities": {}
                },
                {
                    "id": "192231",
                    "name": "Kulgam",
                    "siblingCities": {}
                },
                {
                    "id": "192305",
                    "name": "Shopian",
                    "siblingCities": {}
                },
                {
                    "id": "193502",
                    "name": "Bandipora",
                    "siblingCities": {}
                },
                {
                    "id": "194101",
                    "name": "Leh",
                    "siblingCities": {}
                },
                {
                    "id": "201002",
                    "name": "Ghaziabad",
                    "siblingCities": {
                        "27": "Delhi",
                        "121001": "Faridabad",
                        "132222": "Gurgaon",
                        "201301": "Noida",
                        "201310": "GreaterNoida",
                        "301019": "Bhiwadi"
                    }
                },
                {
                    "id": "201310",
                    "name": "GreaterNoida",
                    "siblingCities": {
                        "27": "Delhi",
                        "121001": "Faridabad",
                        "132222": "Gurgaon",
                        "201002": "Ghaziabad",
                        "201301": "Noida",
                        "301019": "Bhiwadi"
                    }
                },
                {
                    "id": "208001",
                    "name": "Kanpur",
                    "siblingCities": {}
                },
                {
                    "id": "210301",
                    "name": "HamirpurHP",
                    "siblingCities": {}
                },
                {
                    "id": "211001",
                    "name": "Allahabad",
                    "siblingCities": {}
                },
                {
                    "id": "221002",
                    "name": "Varanasi",
                    "siblingCities": {}
                },
                {
                    "id": "221304",
                    "name": "SantRavidasNagar",
                    "siblingCities": {}
                },
                {
                    "id": "222136",
                    "name": "Jalalpur",
                    "siblingCities": {}
                },
                {
                    "id": "225402",
                    "name": "Barabanki",
                    "siblingCities": {}
                },
                {
                    "id": "243001",
                    "name": "Bareilly",
                    "siblingCities": {}
                },
                {
                    "id": "244302",
                    "name": "Moradabad",
                    "siblingCities": {}
                },
                {
                    "id": "246746",
                    "name": "Seohara",
                    "siblingCities": {}
                },
                {
                    "id": "246761",
                    "name": "Dhampur",
                    "siblingCities": {}
                },
                {
                    "id": "248003",
                    "name": "Dehradun",
                    "siblingCities": {}
                },
                {
                    "id": "248179",
                    "name": "Mussoorie",
                    "siblingCities": {}
                },
                {
                    "id": "250002",
                    "name": "Meerut",
                    "siblingCities": {}
                },
                {
                    "id": "263001",
                    "name": "Nainital",
                    "siblingCities": {}
                },
                {
                    "id": "263601",
                    "name": "Almora",
                    "siblingCities": {}
                },
                {
                    "id": "281001",
                    "name": "Mathura",
                    "siblingCities": {}
                },
                {
                    "id": "282002",
                    "name": "Agra",
                    "siblingCities": {}
                },
                {
                    "id": "301019",
                    "name": "Bhiwadi",
                    "siblingCities": {
                        "27": "Delhi",
                        "121001": "Faridabad",
                        "132222": "Gurgaon",
                        "201002": "Ghaziabad",
                        "201301": "Noida",
                        "201310": "GreaterNoida"
                    }
                },
                {
                    "id": "305001",
                    "name": "Ajmer",
                    "siblingCities": {}
                },
                {
                    "id": "305002",
                    "name": "Pushkar",
                    "siblingCities": {}
                },
                {
                    "id": "313001",
                    "name": "Udaipur",
                    "siblingCities": {}
                },
                {
                    "id": "334001",
                    "name": "Bikaner",
                    "siblingCities": {}
                },
                {
                    "id": "342001",
                    "name": "Jodhpur",
                    "siblingCities": {}
                },
                {
                    "id": "360001",
                    "name": "Rajkot",
                    "siblingCities": {}
                },
                {
                    "id": "361001",
                    "name": "Jamnagar",
                    "siblingCities": {}
                },
                {
                    "id": "361335",
                    "name": "Dwarka",
                    "siblingCities": {}
                },
                {
                    "id": "362150",
                    "name": "GirSomnath",
                    "siblingCities": {}
                },
                {
                    "id": "370210",
                    "name": "Kandla",
                    "siblingCities": {}
                },
                {
                    "id": "382010",
                    "name": "Gandhinagar",
                    "siblingCities": {
                        "22": "Ahmedabad"
                    }
                },
                {
                    "id": "383001",
                    "name": "Sabarkantha",
                    "siblingCities": {}
                },
                {
                    "id": "383350",
                    "name": "Aravalli",
                    "siblingCities": {}
                },
                {
                    "id": "385001",
                    "name": "Banaskantha",
                    "siblingCities": {}
                },
                {
                    "id": "387411",
                    "name": "Kheda",
                    "siblingCities": {}
                },
                {
                    "id": "388255",
                    "name": "Mahisagar",
                    "siblingCities": {}
                },
                {
                    "id": "390001",
                    "name": "Vadodara",
                    "siblingCities": {}
                },
                {
                    "id": "391165",
                    "name": "ChhotaUdaipur",
                    "siblingCities": {}
                },
                {
                    "id": "393110",
                    "name": "Bharuch",
                    "siblingCities": {}
                },
                {
                    "id": "393145",
                    "name": "Narmada",
                    "siblingCities": {}
                },
                {
                    "id": "394635",
                    "name": "Tapi",
                    "siblingCities": {}
                },
                {
                    "id": "396193",
                    "name": "DadraandNagarHaveli",
                    "siblingCities": {}
                },
                {
                    "id": "400601",
                    "name": "Thane",
                    "siblingCities": {
                        "31": "Mumbai",
                        "400612": "Mumbra",
                        "400701": "NaviMumbai",
                        "402305": "Raigad",
                        "421201": "Dombivli",
                        "421301": "Kalyan",
                        "421501": "Ambernath",
                        "1067863": "Badlapur",
                        "1074523": "Palghar",
                        "1074883": "Panvel",
                        "1077403": "VasaiVirar"
                    }
                },
                {
                    "id": "400605",
                    "name": "Kalwa",
                    "siblingCities": {}
                },
                {
                    "id": "400612",
                    "name": "Mumbra",
                    "siblingCities": {
                        "31": "Mumbai",
                        "400601": "Thane",
                        "400701": "NaviMumbai",
                        "402305": "Raigad",
                        "421201": "Dombivli",
                        "421301": "Kalyan",
                        "421501": "Ambernath",
                        "1067863": "Badlapur",
                        "1074523": "Palghar",
                        "1074883": "Panvel",
                        "1077403": "VasaiVirar"
                    }
                },
                {
                    "id": "402305",
                    "name": "Raigad",
                    "siblingCities": {
                        "31": "Mumbai",
                        "400601": "Thane",
                        "400612": "Mumbra",
                        "400701": "NaviMumbai",
                        "421201": "Dombivli",
                        "421301": "Kalyan",
                        "421501": "Ambernath",
                        "1067863": "Badlapur",
                        "1074523": "Palghar",
                        "1074883": "Panvel",
                        "1077403": "VasaiVirar"
                    }
                },
                {
                    "id": "403108",
                    "name": "Goa",
                    "siblingCities": {
                        "1027001": "Madgaon",
                        "1027181": "Mormugoa",
                        "1027361": "Panaji"
                    }
                },
                {
                    "id": "413001",
                    "name": "Solapur",
                    "siblingCities": {}
                },
                {
                    "id": "415002",
                    "name": "Satara",
                    "siblingCities": {}
                },
                {
                    "id": "416001",
                    "name": "Kolhapur",
                    "siblingCities": {}
                },
                {
                    "id": "421201",
                    "name": "Dombivli",
                    "siblingCities": {
                        "31": "Mumbai",
                        "400601": "Thane",
                        "400612": "Mumbra",
                        "400701": "NaviMumbai",
                        "402305": "Raigad",
                        "421301": "Kalyan",
                        "421501": "Ambernath",
                        "1067863": "Badlapur",
                        "1074523": "Palghar",
                        "1074883": "Panvel",
                        "1077403": "VasaiVirar"
                    }
                },
                {
                    "id": "421301",
                    "name": "Kalyan",
                    "siblingCities": {
                        "31": "Mumbai",
                        "400601": "Thane",
                        "400612": "Mumbra",
                        "400701": "NaviMumbai",
                        "402305": "Raigad",
                        "421201": "Dombivli",
                        "421501": "Ambernath",
                        "1067863": "Badlapur",
                        "1074523": "Palghar",
                        "1074883": "Panvel",
                        "1077403": "VasaiVirar"
                    }
                },
                {
                    "id": "421501",
                    "name": "Ambernath",
                    "siblingCities": {
                        "31": "Mumbai",
                        "400601": "Thane",
                        "400612": "Mumbra",
                        "400701": "NaviMumbai",
                        "402305": "Raigad",
                        "421201": "Dombivli",
                        "421301": "Kalyan",
                        "1067863": "Badlapur",
                        "1074523": "Palghar",
                        "1074883": "Panvel",
                        "1077403": "VasaiVirar"
                    }
                },
                {
                    "id": "422001",
                    "name": "Nashik",
                    "siblingCities": {}
                },
                {
                    "id": "431001",
                    "name": "Aurangabad",
                    "siblingCities": {}
                },
                {
                    "id": "444001",
                    "name": "Akola",
                    "siblingCities": {}
                },
                {
                    "id": "454116",
                    "name": "RajgarhMadhyaPradesh",
                    "siblingCities": {}
                },
                {
                    "id": "462001",
                    "name": "Bhopal",
                    "siblingCities": {}
                },
                {
                    "id": "465441",
                    "name": "AgarMalwa",
                    "siblingCities": {}
                },
                {
                    "id": "474003",
                    "name": "Gwalior",
                    "siblingCities": {}
                },
                {
                    "id": "482004",
                    "name": "Jabalpur",
                    "siblingCities": {}
                },
                {
                    "id": "483775",
                    "name": "Katni",
                    "siblingCities": {}
                },
                {
                    "id": "491226",
                    "name": "Balod",
                    "siblingCities": {}
                },
                {
                    "id": "491335",
                    "name": "Bemetara",
                    "siblingCities": {}
                },
                {
                    "id": "491995",
                    "name": "Kabirdham",
                    "siblingCities": {}
                },
                {
                    "id": "493332",
                    "name": "BalodaBazarBhatapara",
                    "siblingCities": {}
                },
                {
                    "id": "493889",
                    "name": "Gariaband",
                    "siblingCities": {}
                },
                {
                    "id": "494111",
                    "name": "Sukma",
                    "siblingCities": {}
                },
                {
                    "id": "494226",
                    "name": "Kondagaon",
                    "siblingCities": {}
                },
                {
                    "id": "494449",
                    "name": "Dantewada",
                    "siblingCities": {}
                },
                {
                    "id": "494450",
                    "name": "BijapurCGH",
                    "siblingCities": {}
                },
                {
                    "id": "494661",
                    "name": "Narayanpur",
                    "siblingCities": {}
                },
                {
                    "id": "495001",
                    "name": "Bilaspur",
                    "siblingCities": {}
                },
                {
                    "id": "495334",
                    "name": "Mungeli",
                    "siblingCities": {}
                },
                {
                    "id": "497229",
                    "name": "Surajpur",
                    "siblingCities": {}
                },
                {
                    "id": "497335",
                    "name": "Koriya",
                    "siblingCities": {}
                },
                {
                    "id": "501101",
                    "name": "Vikarabad",
                    "siblingCities": {}
                },
                {
                    "id": "503111",
                    "name": "Kamareddy",
                    "siblingCities": {}
                },
                {
                    "id": "504293",
                    "name": "Asifabad",
                    "siblingCities": {}
                },
                {
                    "id": "505172",
                    "name": "Peddapalli",
                    "siblingCities": {}
                },
                {
                    "id": "505301",
                    "name": "RajannaSircilla",
                    "siblingCities": {}
                },
                {
                    "id": "506002",
                    "name": "Warangal",
                    "siblingCities": {}
                },
                {
                    "id": "506101",
                    "name": "Mahabubabad",
                    "siblingCities": {}
                },
                {
                    "id": "506167",
                    "name": "Jangaon",
                    "siblingCities": {}
                },
                {
                    "id": "507101",
                    "name": "BhadradriKothagudem",
                    "siblingCities": {}
                },
                {
                    "id": "509125",
                    "name": "JogulambaGadwal",
                    "siblingCities": {}
                },
                {
                    "id": "509209",
                    "name": "Nagarkurnool",
                    "siblingCities": {}
                },
                {
                    "id": "520001",
                    "name": "Vijayawada",
                    "siblingCities": {
                        "1000901": "Bapatla",
                        "1003961": "Guntur",
                        "1008281": "Narasaraopet",
                        "1014041": "Tenali"
                    }
                },
                {
                    "id": "524101",
                    "name": "Nellore",
                    "siblingCities": {}
                },
                {
                    "id": "531001",
                    "name": "Vizag",
                    "siblingCities": {}
                },
                {
                    "id": "534001",
                    "name": "WestGodavari",
                    "siblingCities": {
                        "1002881": "Eluru"
                    }
                },
                {
                    "id": "575001",
                    "name": "Mangalore",
                    "siblingCities": {}
                },
                {
                    "id": "580020",
                    "name": "Hubli",
                    "siblingCities": {}
                },
                {
                    "id": "590001",
                    "name": "Belgaum",
                    "siblingCities": {}
                },
                {
                    "id": "605001",
                    "name": "Pondicherry",
                    "siblingCities": {}
                },
                {
                    "id": "620015",
                    "name": "Trichy",
                    "siblingCities": {}
                },
                {
                    "id": "625001",
                    "name": "Madurai",
                    "siblingCities": {}
                },
                {
                    "id": "629161",
                    "name": "Kanyakumari",
                    "siblingCities": {}
                },
                {
                    "id": "632001",
                    "name": "Vellore",
                    "siblingCities": {}
                },
                {
                    "id": "636001",
                    "name": "Salem",
                    "siblingCities": {}
                },
                {
                    "id": "643001",
                    "name": "Nilgiris",
                    "siblingCities": {}
                },
                {
                    "id": "673003",
                    "name": "Kozhikode",
                    "siblingCities": {
                        "673121": "Wayanad",
                        "1056162": "Malappuram",
                        "1056342": "Manjeri",
                        "1057242": "Ponnani",
                        "1057782": "Tirur"
                    }
                },
                {
                    "id": "673121",
                    "name": "Wayanad",
                    "siblingCities": {
                        "673003": "Kozhikode",
                        "1056162": "Malappuram",
                        "1056342": "Manjeri",
                        "1057242": "Ponnani",
                        "1057782": "Tirur"
                    }
                },
                {
                    "id": "673310",
                    "name": "Mahe",
                    "siblingCities": {}
                },
                {
                    "id": "680001",
                    "name": "Thrissur",
                    "siblingCities": {}
                },
                {
                    "id": "682301",
                    "name": "Tripunithura",
                    "siblingCities": {
                        "29": "Kochi",
                        "1054902": "Edathala"
                    }
                },
                {
                    "id": "685584",
                    "name": "Thodupuzha",
                    "siblingCities": {
                        "100066": "Idukki",
                        "1054362": "Changanacherry",
                        "1055982": "Kottayam"
                    }
                },
                {
                    "id": "689101",
                    "name": "Thiruvalla",
                    "siblingCities": {
                        "100067": "Pathanamthitta",
                        "1055802": "Kollam"
                    }
                },
                {
                    "id": "721301",
                    "name": "Kharagpur",
                    "siblingCities": {}
                },
                {
                    "id": "731101",
                    "name": "Birbhum",
                    "siblingCities": {}
                },
                {
                    "id": "733134",
                    "name": "UttarDinajpur",
                    "siblingCities": {}
                },
                {
                    "id": "735101",
                    "name": "Dooars",
                    "siblingCities": {}
                },
                {
                    "id": "737101",
                    "name": "EastSikkim",
                    "siblingCities": {}
                },
                {
                    "id": "737111",
                    "name": "WestSikkim",
                    "siblingCities": {}
                },
                {
                    "id": "737116",
                    "name": "NorthSikkim",
                    "siblingCities": {}
                },
                {
                    "id": "737126",
                    "name": "SouthSikkim",
                    "siblingCities": {}
                },
                {
                    "id": "741101",
                    "name": "Nadia",
                    "siblingCities": {}
                },
                {
                    "id": "744201",
                    "name": "Andaman",
                    "siblingCities": {}
                },
                {
                    "id": "744303",
                    "name": "Nicobar",
                    "siblingCities": {}
                },
                {
                    "id": "751001",
                    "name": "Bhubaneswar",
                    "siblingCities": {
                        "1081543": "Puri"
                    }
                },
                {
                    "id": "753001",
                    "name": "Cuttack",
                    "siblingCities": {}
                },
                {
                    "id": "761200",
                    "name": "Gajapati",
                    "siblingCities": {}
                },
                {
                    "id": "766104",
                    "name": "Nuapada",
                    "siblingCities": {}
                },
                {
                    "id": "781001",
                    "name": "Guwahati",
                    "siblingCities": {
                        "1016381": "Dispur"
                    }
                },
                {
                    "id": "781360",
                    "name": "Baksa",
                    "siblingCities": {}
                },
                {
                    "id": "782105",
                    "name": "Morigaon",
                    "siblingCities": {}
                },
                {
                    "id": "782435",
                    "name": "Hojai",
                    "siblingCities": {}
                },
                {
                    "id": "782460",
                    "name": "EastKarbiAnglong",
                    "siblingCities": {}
                },
                {
                    "id": "782486",
                    "name": "WestKarbiAnglong",
                    "siblingCities": {}
                },
                {
                    "id": "783385",
                    "name": "Chirang",
                    "siblingCities": {}
                },
                {
                    "id": "784176",
                    "name": "Biswanath",
                    "siblingCities": {}
                },
                {
                    "id": "784509",
                    "name": "Udalguri",
                    "siblingCities": {}
                },
                {
                    "id": "785102",
                    "name": "Majuli",
                    "siblingCities": {}
                },
                {
                    "id": "785686",
                    "name": "Charaideo",
                    "siblingCities": {}
                },
                {
                    "id": "786631",
                    "name": "Longding",
                    "siblingCities": {}
                },
                {
                    "id": "790102",
                    "name": "EastKameng",
                    "siblingCities": {}
                },
                {
                    "id": "791001",
                    "name": "KraDaadi",
                    "siblingCities": {}
                },
                {
                    "id": "791102",
                    "name": "EastSiang",
                    "siblingCities": {}
                },
                {
                    "id": "791111",
                    "name": "PapumPare",
                    "siblingCities": {}
                },
                {
                    "id": "791118",
                    "name": "KurungKumey",
                    "siblingCities": {}
                },
                {
                    "id": "791120",
                    "name": "LowerSubansiri",
                    "siblingCities": {}
                },
                {
                    "id": "791122",
                    "name": "UpperSubansiri",
                    "siblingCities": {}
                },
                {
                    "id": "791125",
                    "name": "Siang",
                    "siblingCities": {}
                },
                {
                    "id": "792001",
                    "name": "Lohit",
                    "siblingCities": {}
                },
                {
                    "id": "792101",
                    "name": "DibangValley",
                    "siblingCities": {}
                },
                {
                    "id": "792104",
                    "name": "Anjaw",
                    "siblingCities": {}
                },
                {
                    "id": "792110",
                    "name": "LowerDibangValley",
                    "siblingCities": {}
                },
                {
                    "id": "793001",
                    "name": "Shillong",
                    "siblingCities": {}
                },
                {
                    "id": "793102",
                    "name": "RiBhoi",
                    "siblingCities": {}
                },
                {
                    "id": "793109",
                    "name": "EastJaintiaHills",
                    "siblingCities": {}
                },
                {
                    "id": "793114",
                    "name": "SouthWestKhasiHills",
                    "siblingCities": {}
                },
                {
                    "id": "793119",
                    "name": "WestKhasiHills",
                    "siblingCities": {}
                },
                {
                    "id": "793150",
                    "name": "WestJaintiaHills",
                    "siblingCities": {}
                },
                {
                    "id": "794001",
                    "name": "WestGaroHills",
                    "siblingCities": {}
                },
                {
                    "id": "794102",
                    "name": "SouthGaroHills",
                    "siblingCities": {}
                },
                {
                    "id": "794108",
                    "name": "NorthGaroHills",
                    "siblingCities": {}
                },
                {
                    "id": "794110",
                    "name": "EastGaroHills",
                    "siblingCities": {}
                },
                {
                    "id": "794115",
                    "name": "SouthWestGaroHills",
                    "siblingCities": {}
                },
                {
                    "id": "795004",
                    "name": "ImphalWest",
                    "siblingCities": {}
                },
                {
                    "id": "795005",
                    "name": "ImphalEast",
                    "siblingCities": {}
                },
                {
                    "id": "795142",
                    "name": "Ukhrul",
                    "siblingCities": {}
                },
                {
                    "id": "796001",
                    "name": "Aizawl",
                    "siblingCities": {}
                },
                {
                    "id": "796321",
                    "name": "Champhai",
                    "siblingCities": {}
                },
                {
                    "id": "797001",
                    "name": "Kohima",
                    "siblingCities": {}
                },
                {
                    "id": "797101",
                    "name": "Peren",
                    "siblingCities": {}
                },
                {
                    "id": "798611",
                    "name": "Kiphire",
                    "siblingCities": {}
                },
                {
                    "id": "798625",
                    "name": "Longleng",
                    "siblingCities": {}
                },
                {
                    "id": "799101",
                    "name": "Gomati",
                    "siblingCities": {}
                },
                {
                    "id": "799102",
                    "name": "Sipahijala",
                    "siblingCities": {}
                },
                {
                    "id": "799201",
                    "name": "Khowai",
                    "siblingCities": {}
                },
                {
                    "id": "799290",
                    "name": "Unakoti",
                    "siblingCities": {}
                },
                {
                    "id": "800001",
                    "name": "Patna",
                    "siblingCities": {}
                },
                {
                    "id": "804401",
                    "name": "Arwal",
                    "siblingCities": {}
                },
                {
                    "id": "816107",
                    "name": "Pakur",
                    "siblingCities": {}
                },
                {
                    "id": "824101",
                    "name": "AurangabadBH",
                    "siblingCities": {}
                },
                {
                    "id": "826001",
                    "name": "Dhanbad",
                    "siblingCities": {}
                },
                {
                    "id": "831001",
                    "name": "Jamshedpur",
                    "siblingCities": {}
                },
                {
                    "id": "832102",
                    "name": "PurbiSinghbhum",
                    "siblingCities": {}
                },
                {
                    "id": "832404",
                    "name": "Khunti",
                    "siblingCities": {}
                },
                {
                    "id": "834002",
                    "name": "Ranchi",
                    "siblingCities": {}
                },
                {
                    "id": "844101",
                    "name": "Vaishali",
                    "siblingCities": {}
                },
                {
                    "id": "845438",
                    "name": "WestChamparan",
                    "siblingCities": {}
                },
                {
                    "id": "1000001",
                    "name": "Adilabad",
                    "siblingCities": {}
                },
                {
                    "id": "1000181",
                    "name": "Adoni",
                    "siblingCities": {}
                },
                {
                    "id": "1000361",
                    "name": "Amalapuram",
                    "siblingCities": {}
                },
                {
                    "id": "1000541",
                    "name": "Anakapalle",
                    "siblingCities": {}
                },
                {
                    "id": "1000721",
                    "name": "Anantapur",
                    "siblingCities": {}
                },
                {
                    "id": "1000901",
                    "name": "Bapatla",
                    "siblingCities": {
                        "520001": "Vijayawada",
                        "1003961": "Guntur",
                        "1008281": "Narasaraopet",
                        "1014041": "Tenali"
                    }
                },
                {
                    "id": "1001081",
                    "name": "Bellampalle",
                    "siblingCities": {}
                },
                {
                    "id": "1001261",
                    "name": "Bhimavaram",
                    "siblingCities": {}
                },
                {
                    "id": "1001441",
                    "name": "Bobbili",
                    "siblingCities": {}
                },
                {
                    "id": "1001621",
                    "name": "Bodhan",
                    "siblingCities": {}
                },
                {
                    "id": "1001801",
                    "name": "Chilakalurupet",
                    "siblingCities": {}
                },
                {
                    "id": "1001981",
                    "name": "Chinnachowk",
                    "siblingCities": {}
                },
                {
                    "id": "1002161",
                    "name": "Chirala",
                    "siblingCities": {}
                },
                {
                    "id": "1002341",
                    "name": "Chittoor",
                    "siblingCities": {}
                },
                {
                    "id": "1002521",
                    "name": "Cuddapah",
                    "siblingCities": {}
                },
                {
                    "id": "1002701",
                    "name": "Dharmavaram",
                    "siblingCities": {}
                },
                {
                    "id": "1002881",
                    "name": "Eluru",
                    "siblingCities": {
                        "534001": "WestGodavari"
                    }
                },
                {
                    "id": "1003061",
                    "name": "GaddiAnnaram",
                    "siblingCities": {}
                },
                {
                    "id": "1003241",
                    "name": "Gadwal",
                    "siblingCities": {}
                },
                {
                    "id": "1003421",
                    "name": "Gudivada",
                    "siblingCities": {}
                },
                {
                    "id": "1003601",
                    "name": "Gudur",
                    "siblingCities": {}
                },
                {
                    "id": "1003781",
                    "name": "Guntakal",
                    "siblingCities": {}
                },
                {
                    "id": "1003961",
                    "name": "Guntur",
                    "siblingCities": {
                        "520001": "Vijayawada",
                        "1000901": "Bapatla",
                        "1008281": "Narasaraopet",
                        "1014041": "Tenali"
                    }
                },
                {
                    "id": "1004141",
                    "name": "Hindupur",
                    "siblingCities": {}
                },
                {
                    "id": "1004321",
                    "name": "Jagtial",
                    "siblingCities": {}
                },
                {
                    "id": "1004501",
                    "name": "Kadiri",
                    "siblingCities": {}
                },
                {
                    "id": "1004681",
                    "name": "Kagaznagar",
                    "siblingCities": {}
                },
                {
                    "id": "1004861",
                    "name": "Kakinada",
                    "siblingCities": {
                        "100194": "EastGodavari",
                        "1010441": "Rajahmundry"
                    }
                },
                {
                    "id": "1005041",
                    "name": "Kallur",
                    "siblingCities": {}
                },
                {
                    "id": "1005221",
                    "name": "Kapra",
                    "siblingCities": {}
                },
                {
                    "id": "1005401",
                    "name": "Karimnagar",
                    "siblingCities": {}
                },
                {
                    "id": "1005581",
                    "name": "Kavali",
                    "siblingCities": {}
                },
                {
                    "id": "1005761",
                    "name": "Khamman",
                    "siblingCities": {}
                },
                {
                    "id": "1005941",
                    "name": "Koratla",
                    "siblingCities": {}
                },
                {
                    "id": "1006121",
                    "name": "Kottagudem",
                    "siblingCities": {}
                },
                {
                    "id": "1006301",
                    "name": "Kurnool",
                    "siblingCities": {}
                },
                {
                    "id": "1006481",
                    "name": "Machilipatnam",
                    "siblingCities": {}
                },
                {
                    "id": "1006661",
                    "name": "Madanapalle",
                    "siblingCities": {}
                },
                {
                    "id": "1006841",
                    "name": "Mahbubnagar",
                    "siblingCities": {}
                },
                {
                    "id": "1007021",
                    "name": "Mancherial",
                    "siblingCities": {}
                },
                {
                    "id": "1007201",
                    "name": "Mandamarri",
                    "siblingCities": {}
                },
                {
                    "id": "1007381",
                    "name": "Mangalagiri",
                    "siblingCities": {}
                },
                {
                    "id": "1007561",
                    "name": "Markapur",
                    "siblingCities": {}
                },
                {
                    "id": "1007741",
                    "name": "Miryalguda",
                    "siblingCities": {}
                },
                {
                    "id": "1007921",
                    "name": "Nalgonda",
                    "siblingCities": {}
                },
                {
                    "id": "1008101",
                    "name": "Nandyal",
                    "siblingCities": {}
                },
                {
                    "id": "1008281",
                    "name": "Narasaraopet",
                    "siblingCities": {
                        "520001": "Vijayawada",
                        "1000901": "Bapatla",
                        "1003961": "Guntur",
                        "1014041": "Tenali"
                    }
                },
                {
                    "id": "1008461",
                    "name": "Narsapur",
                    "siblingCities": {}
                },
                {
                    "id": "1008641",
                    "name": "Nirmal",
                    "siblingCities": {}
                },
                {
                    "id": "1008821",
                    "name": "Nizamabad",
                    "siblingCities": {}
                },
                {
                    "id": "1009001",
                    "name": "Nuzvid",
                    "siblingCities": {}
                },
                {
                    "id": "1009181",
                    "name": "Ongole",
                    "siblingCities": {}
                },
                {
                    "id": "1009361",
                    "name": "Palakol",
                    "siblingCities": {}
                },
                {
                    "id": "1009541",
                    "name": "Palwancha",
                    "siblingCities": {}
                },
                {
                    "id": "1009721",
                    "name": "Pitapuram",
                    "siblingCities": {}
                },
                {
                    "id": "1009901",
                    "name": "Ponnur",
                    "siblingCities": {}
                },
                {
                    "id": "1010081",
                    "name": "Proddatur",
                    "siblingCities": {}
                },
                {
                    "id": "1010261",
                    "name": "Qutubullapur",
                    "siblingCities": {}
                },
                {
                    "id": "1010441",
                    "name": "Rajahmundry",
                    "siblingCities": {
                        "100194": "EastGodavari",
                        "1004861": "Kakinada"
                    }
                },
                {
                    "id": "1010621",
                    "name": "Rajendranagar",
                    "siblingCities": {}
                },
                {
                    "id": "1010801",
                    "name": "Ramachandrapuram",
                    "siblingCities": {}
                },
                {
                    "id": "1010981",
                    "name": "Ramagundam",
                    "siblingCities": {}
                },
                {
                    "id": "1011161",
                    "name": "Rayachoti",
                    "siblingCities": {}
                },
                {
                    "id": "1011341",
                    "name": "Rayadurg",
                    "siblingCities": {}
                },
                {
                    "id": "1011521",
                    "name": "Samalkota",
                    "siblingCities": {}
                },
                {
                    "id": "1011701",
                    "name": "Sangareddy",
                    "siblingCities": {}
                },
                {
                    "id": "1011881",
                    "name": "Sattenapalle",
                    "siblingCities": {}
                },
                {
                    "id": "1012061",
                    "name": "Secunderabad",
                    "siblingCities": {
                        "28": "Hyderabad"
                    }
                },
                {
                    "id": "1012241",
                    "name": "Serilingampally",
                    "siblingCities": {}
                },
                {
                    "id": "1012421",
                    "name": "Siddipet",
                    "siblingCities": {}
                },
                {
                    "id": "1012601",
                    "name": "Sirsilla",
                    "siblingCities": {}
                },
                {
                    "id": "1012781",
                    "name": "Srikakulam",
                    "siblingCities": {}
                },
                {
                    "id": "1012961",
                    "name": "Srikalahasti",
                    "siblingCities": {}
                },
                {
                    "id": "1013141",
                    "name": "Suryapet",
                    "siblingCities": {}
                },
                {
                    "id": "1013321",
                    "name": "Tadepalligudem",
                    "siblingCities": {}
                },
                {
                    "id": "1013501",
                    "name": "Tadpatri",
                    "siblingCities": {}
                },
                {
                    "id": "1013681",
                    "name": "Tandur",
                    "siblingCities": {}
                },
                {
                    "id": "1013861",
                    "name": "Tanuku",
                    "siblingCities": {}
                },
                {
                    "id": "1014041",
                    "name": "Tenali",
                    "siblingCities": {
                        "520001": "Vijayawada",
                        "1000901": "Bapatla",
                        "1003961": "Guntur",
                        "1008281": "Narasaraopet"
                    }
                },
                {
                    "id": "1014221",
                    "name": "Tirupati",
                    "siblingCities": {}
                },
                {
                    "id": "1014401",
                    "name": "Tuni",
                    "siblingCities": {}
                },
                {
                    "id": "1014581",
                    "name": "Uppal",
                    "siblingCities": {}
                },
                {
                    "id": "1014761",
                    "name": "Vinukonda",
                    "siblingCities": {}
                },
                {
                    "id": "1014941",
                    "name": "Vizianagaram",
                    "siblingCities": {}
                },
                {
                    "id": "1015121",
                    "name": "Wanaparthi",
                    "siblingCities": {}
                },
                {
                    "id": "1015301",
                    "name": "Yemmiganur",
                    "siblingCities": {}
                },
                {
                    "id": "1015481",
                    "name": "Itanagar",
                    "siblingCities": {}
                },
                {
                    "id": "1015661",
                    "name": "Bongaigaon",
                    "siblingCities": {}
                },
                {
                    "id": "1015841",
                    "name": "Dhubri",
                    "siblingCities": {}
                },
                {
                    "id": "1016021",
                    "name": "Dibrugarh",
                    "siblingCities": {}
                },
                {
                    "id": "1016201",
                    "name": "Diphu",
                    "siblingCities": {}
                },
                {
                    "id": "1016381",
                    "name": "Dispur",
                    "siblingCities": {
                        "781001": "Guwahati"
                    }
                },
                {
                    "id": "1016561",
                    "name": "Jorhat",
                    "siblingCities": {}
                },
                {
                    "id": "1016741",
                    "name": "Karimganj",
                    "siblingCities": {}
                },
                {
                    "id": "1016921",
                    "name": "Lumding",
                    "siblingCities": {}
                },
                {
                    "id": "1017101",
                    "name": "Nagaon",
                    "siblingCities": {}
                },
                {
                    "id": "1017281",
                    "name": "NorthLakhimpur",
                    "siblingCities": {}
                },
                {
                    "id": "1017461",
                    "name": "Silchar",
                    "siblingCities": {}
                },
                {
                    "id": "1017641",
                    "name": "Sivasagar",
                    "siblingCities": {}
                },
                {
                    "id": "1017821",
                    "name": "Tezpur",
                    "siblingCities": {}
                },
                {
                    "id": "1018001",
                    "name": "Tinsukia",
                    "siblingCities": {}
                },
                {
                    "id": "1018181",
                    "name": "Araria",
                    "siblingCities": {}
                },
                {
                    "id": "1018361",
                    "name": "Arrah",
                    "siblingCities": {}
                },
                {
                    "id": "1018541",
                    "name": "Bagaha",
                    "siblingCities": {}
                },
                {
                    "id": "1018721",
                    "name": "Begusarai",
                    "siblingCities": {}
                },
                {
                    "id": "1018901",
                    "name": "Bettiah",
                    "siblingCities": {}
                },
                {
                    "id": "1019081",
                    "name": "Bhagalpur",
                    "siblingCities": {}
                },
                {
                    "id": "1019261",
                    "name": "Buxar",
                    "siblingCities": {}
                },
                {
                    "id": "1019441",
                    "name": "Chapra",
                    "siblingCities": {}
                },
                {
                    "id": "1019621",
                    "name": "Darbhanga",
                    "siblingCities": {}
                },
                {
                    "id": "1019801",
                    "name": "DehrionSone",
                    "siblingCities": {}
                },
                {
                    "id": "1019981",
                    "name": "DinapurNizamat",
                    "siblingCities": {}
                },
                {
                    "id": "1020161",
                    "name": "Gaya",
                    "siblingCities": {}
                },
                {
                    "id": "1020341",
                    "name": "Gopalganj",
                    "siblingCities": {}
                },
                {
                    "id": "1020521",
                    "name": "Hajipur",
                    "siblingCities": {}
                },
                {
                    "id": "1020701",
                    "name": "Jamalpur",
                    "siblingCities": {}
                },
                {
                    "id": "1020881",
                    "name": "Jamui",
                    "siblingCities": {}
                },
                {
                    "id": "1021061",
                    "name": "Jehanabad",
                    "siblingCities": {}
                },
                {
                    "id": "1021241",
                    "name": "Katihar",
                    "siblingCities": {}
                },
                {
                    "id": "1021421",
                    "name": "Kishanganj",
                    "siblingCities": {}
                },
                {
                    "id": "1021601",
                    "name": "Lakhisarai",
                    "siblingCities": {}
                },
                {
                    "id": "1021781",
                    "name": "Madhubani",
                    "siblingCities": {}
                },
                {
                    "id": "1021961",
                    "name": "Mokama",
                    "siblingCities": {}
                },
                {
                    "id": "1022141",
                    "name": "Motihari",
                    "siblingCities": {}
                },
                {
                    "id": "1022321",
                    "name": "Munger",
                    "siblingCities": {}
                },
                {
                    "id": "1022501",
                    "name": "Muzaffarpur",
                    "siblingCities": {}
                },
                {
                    "id": "1022681",
                    "name": "Nawada",
                    "siblingCities": {}
                },
                {
                    "id": "1022861",
                    "name": "PhulwariSharif",
                    "siblingCities": {}
                },
                {
                    "id": "1023041",
                    "name": "Purnia",
                    "siblingCities": {}
                },
                {
                    "id": "1023221",
                    "name": "Saharsa",
                    "siblingCities": {}
                },
                {
                    "id": "1023401",
                    "name": "Samastipur",
                    "siblingCities": {}
                },
                {
                    "id": "1023581",
                    "name": "Sasaram",
                    "siblingCities": {}
                },
                {
                    "id": "1023761",
                    "name": "Sitamarhi",
                    "siblingCities": {}
                },
                {
                    "id": "1023941",
                    "name": "Supaul",
                    "siblingCities": {}
                },
                {
                    "id": "1024121",
                    "name": "Ambikapur",
                    "siblingCities": {}
                },
                {
                    "id": "1024301",
                    "name": "Bhatapara",
                    "siblingCities": {}
                },
                {
                    "id": "1024481",
                    "name": "Bhilai",
                    "siblingCities": {}
                },
                {
                    "id": "1024661",
                    "name": "BhilaiCharoda",
                    "siblingCities": {}
                },
                {
                    "id": "1024841",
                    "name": "Chirmiri",
                    "siblingCities": {}
                },
                {
                    "id": "1025021",
                    "name": "DalliRajhara",
                    "siblingCities": {}
                },
                {
                    "id": "1025201",
                    "name": "Dhamtari",
                    "siblingCities": {}
                },
                {
                    "id": "1025381",
                    "name": "Durg",
                    "siblingCities": {}
                },
                {
                    "id": "1025561",
                    "name": "Jagdalpur",
                    "siblingCities": {}
                },
                {
                    "id": "1025741",
                    "name": "Korba",
                    "siblingCities": {}
                },
                {
                    "id": "1025921",
                    "name": "Raigarh",
                    "siblingCities": {}
                },
                {
                    "id": "1026101",
                    "name": "Raipur",
                    "siblingCities": {}
                },
                {
                    "id": "1026281",
                    "name": "RajNandgaon",
                    "siblingCities": {}
                },
                {
                    "id": "1026461",
                    "name": "Silvassa",
                    "siblingCities": {
                        "1026641": "Daman",
                        "1146891": "DamanDiu"
                    }
                },
                {
                    "id": "1026641",
                    "name": "Daman",
                    "siblingCities": {
                        "1026461": "Silvassa",
                        "1146891": "DamanDiu"
                    }
                },
                {
                    "id": "1026821",
                    "name": "Diu",
                    "siblingCities": {}
                },
                {
                    "id": "1027001",
                    "name": "Madgaon",
                    "siblingCities": {
                        "403108": "Goa",
                        "1027181": "Mormugoa",
                        "1027361": "Panaji"
                    }
                },
                {
                    "id": "1027181",
                    "name": "Mormugoa",
                    "siblingCities": {
                        "403108": "Goa",
                        "1027001": "Madgaon",
                        "1027361": "Panaji"
                    }
                },
                {
                    "id": "1027361",
                    "name": "Panaji",
                    "siblingCities": {
                        "403108": "Goa",
                        "1027001": "Madgaon",
                        "1027181": "Mormugoa"
                    }
                },
                {
                    "id": "1027541",
                    "name": "Amreli",
                    "siblingCities": {}
                },
                {
                    "id": "1027721",
                    "name": "Anand",
                    "siblingCities": {}
                },
                {
                    "id": "1027901",
                    "name": "Anjar",
                    "siblingCities": {}
                },
                {
                    "id": "1028081",
                    "name": "Ankleshwar",
                    "siblingCities": {}
                },
                {
                    "id": "1028261",
                    "name": "Bardoli",
                    "siblingCities": {}
                },
                {
                    "id": "1028441",
                    "name": "Bhavnagar",
                    "siblingCities": {}
                },
                {
                    "id": "1028621",
                    "name": "Bhuj",
                    "siblingCities": {}
                },
                {
                    "id": "1028801",
                    "name": "Bilimora",
                    "siblingCities": {}
                },
                {
                    "id": "1028981",
                    "name": "Borsad",
                    "siblingCities": {}
                },
                {
                    "id": "1029161",
                    "name": "Botad",
                    "siblingCities": {}
                },
                {
                    "id": "1029341",
                    "name": "Chandkheda",
                    "siblingCities": {}
                },
                {
                    "id": "1029521",
                    "name": "Chandlodiya",
                    "siblingCities": {}
                },
                {
                    "id": "1029701",
                    "name": "Dabhoi",
                    "siblingCities": {}
                },
                {
                    "id": "1029881",
                    "name": "Dahod",
                    "siblingCities": {}
                },
                {
                    "id": "1030061",
                    "name": "Deesa",
                    "siblingCities": {}
                },
                {
                    "id": "1030241",
                    "name": "Dholka",
                    "siblingCities": {}
                },
                {
                    "id": "1030421",
                    "name": "Dhoraji",
                    "siblingCities": {}
                },
                {
                    "id": "1030601",
                    "name": "Dhrangadhra",
                    "siblingCities": {}
                },
                {
                    "id": "1030781",
                    "name": "Gandhidham",
                    "siblingCities": {}
                },
                {
                    "id": "1030961",
                    "name": "Ghatlodiya",
                    "siblingCities": {}
                },
                {
                    "id": "1031141",
                    "name": "Godhra",
                    "siblingCities": {}
                },
                {
                    "id": "1031321",
                    "name": "Gondal",
                    "siblingCities": {}
                },
                {
                    "id": "1031501",
                    "name": "Himatnagar",
                    "siblingCities": {}
                },
                {
                    "id": "1031681",
                    "name": "Jetpur",
                    "siblingCities": {}
                },
                {
                    "id": "1031861",
                    "name": "Junagadh",
                    "siblingCities": {}
                },
                {
                    "id": "1032041",
                    "name": "Kadi",
                    "siblingCities": {}
                },
                {
                    "id": "1032221",
                    "name": "Kalol",
                    "siblingCities": {}
                },
                {
                    "id": "1032401",
                    "name": "Keshod",
                    "siblingCities": {}
                },
                {
                    "id": "1032581",
                    "name": "Khambhat",
                    "siblingCities": {}
                },
                {
                    "id": "1032761",
                    "name": "Mahuva",
                    "siblingCities": {}
                },
                {
                    "id": "1032941",
                    "name": "Mandvi",
                    "siblingCities": {}
                },
                {
                    "id": "1033121",
                    "name": "Mangrol",
                    "siblingCities": {}
                },
                {
                    "id": "1033301",
                    "name": "Mehsana",
                    "siblingCities": {}
                },
                {
                    "id": "1033481",
                    "name": "Modasa",
                    "siblingCities": {}
                },
                {
                    "id": "1033661",
                    "name": "Morbi",
                    "siblingCities": {}
                },
                {
                    "id": "1033841",
                    "name": "Nadiad",
                    "siblingCities": {}
                },
                {
                    "id": "1034021",
                    "name": "Navsari",
                    "siblingCities": {}
                },
                {
                    "id": "1034201",
                    "name": "Palanpur",
                    "siblingCities": {}
                },
                {
                    "id": "1034381",
                    "name": "Palitana",
                    "siblingCities": {}
                },
                {
                    "id": "1034561",
                    "name": "Patan",
                    "siblingCities": {}
                },
                {
                    "id": "1034741",
                    "name": "Petlad",
                    "siblingCities": {}
                },
                {
                    "id": "1034921",
                    "name": "Porbandar",
                    "siblingCities": {}
                },
                {
                    "id": "1035101",
                    "name": "Ranip",
                    "siblingCities": {}
                },
                {
                    "id": "1035281",
                    "name": "Savarkundla",
                    "siblingCities": {}
                },
                {
                    "id": "1035461",
                    "name": "Sidhpur",
                    "siblingCities": {}
                },
                {
                    "id": "1035641",
                    "name": "Surendranagar",
                    "siblingCities": {}
                },
                {
                    "id": "1035821",
                    "name": "Una",
                    "siblingCities": {}
                },
                {
                    "id": "1036001",
                    "name": "Unjha",
                    "siblingCities": {}
                },
                {
                    "id": "1036181",
                    "name": "Upleta",
                    "siblingCities": {}
                },
                {
                    "id": "1036361",
                    "name": "Valsad",
                    "siblingCities": {}
                },
                {
                    "id": "1036541",
                    "name": "Vapi",
                    "siblingCities": {}
                },
                {
                    "id": "1036721",
                    "name": "Vejalpur",
                    "siblingCities": {}
                },
                {
                    "id": "1036901",
                    "name": "Veraval",
                    "siblingCities": {}
                },
                {
                    "id": "1037081",
                    "name": "Vijalpor",
                    "siblingCities": {}
                },
                {
                    "id": "1037261",
                    "name": "Viramgam",
                    "siblingCities": {}
                },
                {
                    "id": "1037441",
                    "name": "Visnagar",
                    "siblingCities": {}
                },
                {
                    "id": "1037621",
                    "name": "Ambala",
                    "siblingCities": {}
                },
                {
                    "id": "1037801",
                    "name": "AmbalaSadar",
                    "siblingCities": {}
                },
                {
                    "id": "1037981",
                    "name": "Bahadurgarh",
                    "siblingCities": {}
                },
                {
                    "id": "1038161",
                    "name": "Bhiwani",
                    "siblingCities": {}
                },
                {
                    "id": "1038341",
                    "name": "Fatehabad",
                    "siblingCities": {}
                },
                {
                    "id": "1038521",
                    "name": "Hansi",
                    "siblingCities": {}
                },
                {
                    "id": "1038701",
                    "name": "Jagadhri",
                    "siblingCities": {}
                },
                {
                    "id": "1038881",
                    "name": "Jind",
                    "siblingCities": {}
                },
                {
                    "id": "1039061",
                    "name": "Kaithal",
                    "siblingCities": {}
                },
                {
                    "id": "1039241",
                    "name": "Karnal",
                    "siblingCities": {}
                },
                {
                    "id": "1039421",
                    "name": "MandiDabwali",
                    "siblingCities": {}
                },
                {
                    "id": "1039601",
                    "name": "Narnaul",
                    "siblingCities": {}
                },
                {
                    "id": "1039781",
                    "name": "Narwana",
                    "siblingCities": {}
                },
                {
                    "id": "1039961",
                    "name": "Palwal",
                    "siblingCities": {}
                },
                {
                    "id": "1040142",
                    "name": "Panipat",
                    "siblingCities": {}
                },
                {
                    "id": "1040322",
                    "name": "Rewari",
                    "siblingCities": {}
                },
                {
                    "id": "1040502",
                    "name": "Rohtak",
                    "siblingCities": {}
                },
                {
                    "id": "1040682",
                    "name": "Sonipat",
                    "siblingCities": {}
                },
                {
                    "id": "1040862",
                    "name": "Thanesar",
                    "siblingCities": {}
                },
                {
                    "id": "1041042",
                    "name": "Tohana",
                    "siblingCities": {}
                },
                {
                    "id": "1041222",
                    "name": "Yamunanagar",
                    "siblingCities": {}
                },
                {
                    "id": "1041402",
                    "name": "Anantnag",
                    "siblingCities": {}
                },
                {
                    "id": "1041582",
                    "name": "Baramulla",
                    "siblingCities": {}
                },
                {
                    "id": "1041762",
                    "name": "Kathua",
                    "siblingCities": {}
                },
                {
                    "id": "1041942",
                    "name": "Sopore",
                    "siblingCities": {}
                },
                {
                    "id": "1042122",
                    "name": "Udhampur",
                    "siblingCities": {}
                },
                {
                    "id": "1042302",
                    "name": "Adityapur",
                    "siblingCities": {}
                },
                {
                    "id": "1042482",
                    "name": "Bagbera",
                    "siblingCities": {}
                },
                {
                    "id": "1042662",
                    "name": "Bhuli",
                    "siblingCities": {}
                },
                {
                    "id": "1042842",
                    "name": "BokaroSteelCity",
                    "siblingCities": {}
                },
                {
                    "id": "1043022",
                    "name": "Chaibasa",
                    "siblingCities": {}
                },
                {
                    "id": "1043202",
                    "name": "Chakradharpur",
                    "siblingCities": {}
                },
                {
                    "id": "1043382",
                    "name": "Chirkunda",
                    "siblingCities": {}
                },
                {
                    "id": "1043562",
                    "name": "Daltonganj",
                    "siblingCities": {}
                },
                {
                    "id": "1043742",
                    "name": "Deoghar",
                    "siblingCities": {}
                },
                {
                    "id": "1043922",
                    "name": "Giridih",
                    "siblingCities": {}
                },
                {
                    "id": "1044102",
                    "name": "Hazaribag",
                    "siblingCities": {}
                },
                {
                    "id": "1044282",
                    "name": "Jharia",
                    "siblingCities": {}
                },
                {
                    "id": "1044462",
                    "name": "JhumriTelaiya",
                    "siblingCities": {}
                },
                {
                    "id": "1044642",
                    "name": "Jorapokhar",
                    "siblingCities": {}
                },
                {
                    "id": "1044822",
                    "name": "Katras",
                    "siblingCities": {}
                },
                {
                    "id": "1045002",
                    "name": "Phusro",
                    "siblingCities": {}
                },
                {
                    "id": "1045182",
                    "name": "Ramgarh",
                    "siblingCities": {}
                },
                {
                    "id": "1045362",
                    "name": "Sahebganj",
                    "siblingCities": {}
                },
                {
                    "id": "1045542",
                    "name": "Saunda",
                    "siblingCities": {}
                },
                {
                    "id": "1045722",
                    "name": "Sindri",
                    "siblingCities": {}
                },
                {
                    "id": "1045902",
                    "name": "Tisra",
                    "siblingCities": {}
                },
                {
                    "id": "1046082",
                    "name": "Bagalkot",
                    "siblingCities": {}
                },
                {
                    "id": "1046262",
                    "name": "Basavakalyan",
                    "siblingCities": {}
                },
                {
                    "id": "1046442",
                    "name": "Bellary",
                    "siblingCities": {}
                },
                {
                    "id": "1046622",
                    "name": "Bhadravathi",
                    "siblingCities": {}
                },
                {
                    "id": "1046802",
                    "name": "Bidar",
                    "siblingCities": {}
                },
                {
                    "id": "1046982",
                    "name": "Bijapur",
                    "siblingCities": {}
                },
                {
                    "id": "1047162",
                    "name": "Chamrajnagar",
                    "siblingCities": {}
                },
                {
                    "id": "1047342",
                    "name": "Channapatna",
                    "siblingCities": {}
                },
                {
                    "id": "1047522",
                    "name": "Chikkaballapur",
                    "siblingCities": {
                        "23": "Bangalore",
                        "1047882": "Chintamani",
                        "1048602": "Doddaballapur"
                    }
                },
                {
                    "id": "1047702",
                    "name": "Chikmagalur",
                    "siblingCities": {
                        "100210": "Kadur"
                    }
                },
                {
                    "id": "1047882",
                    "name": "Chintamani",
                    "siblingCities": {
                        "23": "Bangalore",
                        "1047522": "Chikkaballapur",
                        "1048602": "Doddaballapur"
                    }
                },
                {
                    "id": "1048062",
                    "name": "Chitradurga",
                    "siblingCities": {}
                },
                {
                    "id": "1048242",
                    "name": "Dandeli",
                    "siblingCities": {}
                },
                {
                    "id": "1048422",
                    "name": "Davanagere",
                    "siblingCities": {}
                },
                {
                    "id": "1048602",
                    "name": "Doddaballapur",
                    "siblingCities": {
                        "23": "Bangalore",
                        "1047522": "Chikkaballapur",
                        "1047882": "Chintamani"
                    }
                },
                {
                    "id": "1048782",
                    "name": "Gadag",
                    "siblingCities": {}
                },
                {
                    "id": "1048962",
                    "name": "Gangavathi",
                    "siblingCities": {}
                },
                {
                    "id": "1049142",
                    "name": "Gokak",
                    "siblingCities": {}
                },
                {
                    "id": "1049322",
                    "name": "Gulbarga",
                    "siblingCities": {}
                },
                {
                    "id": "1049502",
                    "name": "Harihar",
                    "siblingCities": {}
                },
                {
                    "id": "1049682",
                    "name": "Hassan",
                    "siblingCities": {}
                },
                {
                    "id": "1049862",
                    "name": "Haveri",
                    "siblingCities": {}
                },
                {
                    "id": "1050042",
                    "name": "Hospet",
                    "siblingCities": {}
                },
                {
                    "id": "1050222",
                    "name": "Ilkal",
                    "siblingCities": {}
                },
                {
                    "id": "1050402",
                    "name": "Jamakhandi",
                    "siblingCities": {}
                },
                {
                    "id": "1050582",
                    "name": "Karwar",
                    "siblingCities": {}
                },
                {
                    "id": "1050762",
                    "name": "Kolar",
                    "siblingCities": {}
                },
                {
                    "id": "1050942",
                    "name": "Kollegal",
                    "siblingCities": {}
                },
                {
                    "id": "1051122",
                    "name": "Koppal",
                    "siblingCities": {}
                },
                {
                    "id": "1051302",
                    "name": "Mandya",
                    "siblingCities": {
                        "32": "Mysore"
                    }
                },
                {
                    "id": "1051482",
                    "name": "Nipani",
                    "siblingCities": {}
                },
                {
                    "id": "1051662",
                    "name": "RabkaviBanhatti",
                    "siblingCities": {}
                },
                {
                    "id": "1051842",
                    "name": "Raichur",
                    "siblingCities": {}
                },
                {
                    "id": "1052022",
                    "name": "Ramanagaram",
                    "siblingCities": {}
                },
                {
                    "id": "1052202",
                    "name": "Ranebennur",
                    "siblingCities": {}
                },
                {
                    "id": "1052382",
                    "name": "Robertsonpet",
                    "siblingCities": {}
                },
                {
                    "id": "1052562",
                    "name": "Sagara",
                    "siblingCities": {}
                },
                {
                    "id": "1052742",
                    "name": "Shimoga",
                    "siblingCities": {}
                },
                {
                    "id": "1052922",
                    "name": "Sindhnur",
                    "siblingCities": {}
                },
                {
                    "id": "1053102",
                    "name": "Sira",
                    "siblingCities": {}
                },
                {
                    "id": "1053282",
                    "name": "Sirsi",
                    "siblingCities": {}
                },
                {
                    "id": "1053462",
                    "name": "Tiptur",
                    "siblingCities": {}
                },
                {
                    "id": "1053642",
                    "name": "Tumkur",
                    "siblingCities": {}
                },
                {
                    "id": "1053822",
                    "name": "Udupi",
                    "siblingCities": {}
                },
                {
                    "id": "1054002",
                    "name": "Yadgir",
                    "siblingCities": {}
                },
                {
                    "id": "1054182",
                    "name": "Alappuzha",
                    "siblingCities": {
                        "1054542": "Cherthala",
                        "1055622": "Kayamkulam"
                    }
                },
                {
                    "id": "1054362",
                    "name": "Changanacherry",
                    "siblingCities": {
                        "100066": "Idukki",
                        "685584": "Thodupuzha",
                        "1055982": "Kottayam"
                    }
                },
                {
                    "id": "1054542",
                    "name": "Cherthala",
                    "siblingCities": {
                        "1054182": "Alappuzha",
                        "1055622": "Kayamkulam"
                    }
                },
                {
                    "id": "1054722",
                    "name": "Chittur",
                    "siblingCities": {
                        "1056882": "Palakkad"
                    }
                },
                {
                    "id": "1054902",
                    "name": "Edathala",
                    "siblingCities": {
                        "29": "Kochi",
                        "682301": "Tripunithura"
                    }
                },
                {
                    "id": "1055082",
                    "name": "Kanhangad",
                    "siblingCities": {
                        "1055442": "Kasaragod"
                    }
                },
                {
                    "id": "1055262",
                    "name": "Kannur",
                    "siblingCities": {
                        "1057062": "Payyannur",
                        "1057422": "Taliparamba",
                        "1057602": "Thalassery"
                    }
                },
                {
                    "id": "1055442",
                    "name": "Kasaragod",
                    "siblingCities": {
                        "1055082": "Kanhangad"
                    }
                },
                {
                    "id": "1055622",
                    "name": "Kayamkulam",
                    "siblingCities": {
                        "1054182": "Alappuzha",
                        "1054542": "Cherthala"
                    }
                },
                {
                    "id": "1055802",
                    "name": "Kollam",
                    "siblingCities": {
                        "100067": "Pathanamthitta",
                        "689101": "Thiruvalla"
                    }
                },
                {
                    "id": "1055982",
                    "name": "Kottayam",
                    "siblingCities": {
                        "100066": "Idukki",
                        "685584": "Thodupuzha",
                        "1054362": "Changanacherry"
                    }
                },
                {
                    "id": "1056162",
                    "name": "Malappuram",
                    "siblingCities": {
                        "673003": "Kozhikode",
                        "673121": "Wayanad",
                        "1056342": "Manjeri",
                        "1057242": "Ponnani",
                        "1057782": "Tirur"
                    }
                },
                {
                    "id": "1056342",
                    "name": "Manjeri",
                    "siblingCities": {
                        "673003": "Kozhikode",
                        "673121": "Wayanad",
                        "1056162": "Malappuram",
                        "1057242": "Ponnani",
                        "1057782": "Tirur"
                    }
                },
                {
                    "id": "1056522",
                    "name": "Nedumangad",
                    "siblingCities": {
                        "695001": "Trivandrum",
                        "1056702": "Neyyattinkara"
                    }
                },
                {
                    "id": "1056702",
                    "name": "Neyyattinkara",
                    "siblingCities": {
                        "695001": "Trivandrum",
                        "1056522": "Nedumangad"
                    }
                },
                {
                    "id": "1056882",
                    "name": "Palakkad",
                    "siblingCities": {
                        "1054722": "Chittur"
                    }
                },
                {
                    "id": "1057062",
                    "name": "Payyannur",
                    "siblingCities": {
                        "1055262": "Kannur",
                        "1057422": "Taliparamba",
                        "1057602": "Thalassery"
                    }
                },
                {
                    "id": "1057242",
                    "name": "Ponnani",
                    "siblingCities": {
                        "673003": "Kozhikode",
                        "673121": "Wayanad",
                        "1056162": "Malappuram",
                        "1056342": "Manjeri",
                        "1057782": "Tirur"
                    }
                },
                {
                    "id": "1057422",
                    "name": "Taliparamba",
                    "siblingCities": {
                        "1055262": "Kannur",
                        "1057062": "Payyannur",
                        "1057602": "Thalassery"
                    }
                },
                {
                    "id": "1057602",
                    "name": "Thalassery",
                    "siblingCities": {
                        "1055262": "Kannur",
                        "1057062": "Payyannur",
                        "1057422": "Taliparamba"
                    }
                },
                {
                    "id": "1057782",
                    "name": "Tirur",
                    "siblingCities": {
                        "673003": "Kozhikode",
                        "673121": "Wayanad",
                        "1056162": "Malappuram",
                        "1056342": "Manjeri",
                        "1057242": "Ponnani"
                    }
                },
                {
                    "id": "1057962",
                    "name": "Tiruvalla",
                    "siblingCities": {}
                },
                {
                    "id": "1058142",
                    "name": "Kavaratti",
                    "siblingCities": {}
                },
                {
                    "id": "1058322",
                    "name": "Ashoknagar",
                    "siblingCities": {}
                },
                {
                    "id": "1058502",
                    "name": "Balaghat",
                    "siblingCities": {}
                },
                {
                    "id": "1058682",
                    "name": "Betul",
                    "siblingCities": {}
                },
                {
                    "id": "1058862",
                    "name": "Bhind",
                    "siblingCities": {}
                },
                {
                    "id": "1059042",
                    "name": "BinaEtawa",
                    "siblingCities": {}
                },
                {
                    "id": "1059222",
                    "name": "Burhanpur",
                    "siblingCities": {}
                },
                {
                    "id": "1059402",
                    "name": "Chhatarpur",
                    "siblingCities": {}
                },
                {
                    "id": "1059582",
                    "name": "Chhindwara",
                    "siblingCities": {}
                },
                {
                    "id": "1059762",
                    "name": "Dabra",
                    "siblingCities": {}
                },
                {
                    "id": "1059942",
                    "name": "Damoh",
                    "siblingCities": {}
                },
                {
                    "id": "1060122",
                    "name": "Datia",
                    "siblingCities": {}
                },
                {
                    "id": "1060302",
                    "name": "Dewas",
                    "siblingCities": {}
                },
                {
                    "id": "1060482",
                    "name": "Dhanpuri",
                    "siblingCities": {}
                },
                {
                    "id": "1060662",
                    "name": "Dhar",
                    "siblingCities": {}
                },
                {
                    "id": "1060842",
                    "name": "Guna",
                    "siblingCities": {}
                },
                {
                    "id": "1061022",
                    "name": "Harda",
                    "siblingCities": {}
                },
                {
                    "id": "1061202",
                    "name": "Hoshangabad",
                    "siblingCities": {}
                },
                {
                    "id": "1061382",
                    "name": "Itarsi",
                    "siblingCities": {}
                },
                {
                    "id": "1061562",
                    "name": "Jaora",
                    "siblingCities": {}
                },
                {
                    "id": "1061743",
                    "name": "Khandwa",
                    "siblingCities": {}
                },
                {
                    "id": "1061923",
                    "name": "Khargone",
                    "siblingCities": {}
                },
                {
                    "id": "1062103",
                    "name": "Mandla",
                    "siblingCities": {}
                },
                {
                    "id": "1062283",
                    "name": "Mandsaur",
                    "siblingCities": {}
                },
                {
                    "id": "1062463",
                    "name": "Mhow",
                    "siblingCities": {}
                },
                {
                    "id": "1062643",
                    "name": "Morena",
                    "siblingCities": {}
                },
                {
                    "id": "1062823",
                    "name": "Nagda",
                    "siblingCities": {}
                },
                {
                    "id": "1063003",
                    "name": "Narsinghpur",
                    "siblingCities": {}
                },
                {
                    "id": "1063183",
                    "name": "Nimach",
                    "siblingCities": {}
                },
                {
                    "id": "1063363",
                    "name": "Panna",
                    "siblingCities": {}
                },
                {
                    "id": "1063543",
                    "name": "Parasia",
                    "siblingCities": {}
                },
                {
                    "id": "1063723",
                    "name": "Pithampur",
                    "siblingCities": {}
                },
                {
                    "id": "1063903",
                    "name": "Ratlam",
                    "siblingCities": {}
                },
                {
                    "id": "1064083",
                    "name": "Rewa",
                    "siblingCities": {}
                },
                {
                    "id": "1064263",
                    "name": "Sagar",
                    "siblingCities": {}
                },
                {
                    "id": "1064443",
                    "name": "Sarni",
                    "siblingCities": {}
                },
                {
                    "id": "1064623",
                    "name": "Satna",
                    "siblingCities": {}
                },
                {
                    "id": "1064803",
                    "name": "Sehore",
                    "siblingCities": {}
                },
                {
                    "id": "1064983",
                    "name": "Seoni",
                    "siblingCities": {}
                },
                {
                    "id": "1065163",
                    "name": "Shahdol",
                    "siblingCities": {}
                },
                {
                    "id": "1065343",
                    "name": "Shajapur",
                    "siblingCities": {}
                },
                {
                    "id": "1065523",
                    "name": "Sheopur",
                    "siblingCities": {}
                },
                {
                    "id": "1065703",
                    "name": "Shivpuri",
                    "siblingCities": {}
                },
                {
                    "id": "1065883",
                    "name": "Singrauli",
                    "siblingCities": {}
                },
                {
                    "id": "1066063",
                    "name": "Tikamgarh",
                    "siblingCities": {}
                },
                {
                    "id": "1066243",
                    "name": "Ujjain",
                    "siblingCities": {}
                },
                {
                    "id": "1066423",
                    "name": "Vidisha",
                    "siblingCities": {}
                },
                {
                    "id": "1066603",
                    "name": "Achalpur",
                    "siblingCities": {}
                },
                {
                    "id": "1066783",
                    "name": "Ahmednagar",
                    "siblingCities": {}
                },
                {
                    "id": "1066963",
                    "name": "Akot",
                    "siblingCities": {}
                },
                {
                    "id": "1067143",
                    "name": "Amalner",
                    "siblingCities": {}
                },
                {
                    "id": "1067323",
                    "name": "Ambajogai",
                    "siblingCities": {}
                },
                {
                    "id": "1067503",
                    "name": "Amravati",
                    "siblingCities": {}
                },
                {
                    "id": "1067683",
                    "name": "Anjangaon",
                    "siblingCities": {}
                },
                {
                    "id": "1067863",
                    "name": "Badlapur",
                    "siblingCities": {
                        "31": "Mumbai",
                        "400601": "Thane",
                        "400612": "Mumbra",
                        "400701": "NaviMumbai",
                        "402305": "Raigad",
                        "421201": "Dombivli",
                        "421301": "Kalyan",
                        "421501": "Ambernath",
                        "1074523": "Palghar",
                        "1074883": "Panvel",
                        "1077403": "VasaiVirar"
                    }
                },
                {
                    "id": "1068043",
                    "name": "Ballarpur",
                    "siblingCities": {}
                },
                {
                    "id": "1068223",
                    "name": "Baramati",
                    "siblingCities": {}
                },
                {
                    "id": "1068403",
                    "name": "Barshi",
                    "siblingCities": {}
                },
                {
                    "id": "1068583",
                    "name": "Basmath",
                    "siblingCities": {}
                },
                {
                    "id": "1068763",
                    "name": "Beed",
                    "siblingCities": {}
                },
                {
                    "id": "1068943",
                    "name": "Bhadravati",
                    "siblingCities": {}
                },
                {
                    "id": "1069123",
                    "name": "Bhandara",
                    "siblingCities": {}
                },
                {
                    "id": "1069303",
                    "name": "Bhusawal",
                    "siblingCities": {}
                },
                {
                    "id": "1069483",
                    "name": "Buldhana",
                    "siblingCities": {}
                },
                {
                    "id": "1069663",
                    "name": "Chalisgaon",
                    "siblingCities": {}
                },
                {
                    "id": "1069843",
                    "name": "Chandrapur",
                    "siblingCities": {}
                },
                {
                    "id": "1070023",
                    "name": "Chopda",
                    "siblingCities": {}
                },
                {
                    "id": "1070203",
                    "name": "Deolali",
                    "siblingCities": {}
                },
                {
                    "id": "1070383",
                    "name": "Dhule",
                    "siblingCities": {}
                },
                {
                    "id": "1070563",
                    "name": "Gondia",
                    "siblingCities": {}
                },
                {
                    "id": "1070743",
                    "name": "Hinganghat",
                    "siblingCities": {}
                },
                {
                    "id": "1070923",
                    "name": "Hingoli",
                    "siblingCities": {}
                },
                {
                    "id": "1071103",
                    "name": "Ichalkaranji",
                    "siblingCities": {}
                },
                {
                    "id": "1071283",
                    "name": "Jalgaon",
                    "siblingCities": {}
                },
                {
                    "id": "1071463",
                    "name": "Jalna",
                    "siblingCities": {}
                },
                {
                    "id": "1071643",
                    "name": "Kamthi",
                    "siblingCities": {}
                },
                {
                    "id": "1071823",
                    "name": "Karad",
                    "siblingCities": {}
                },
                {
                    "id": "1072003",
                    "name": "Karanja",
                    "siblingCities": {}
                },
                {
                    "id": "1072183",
                    "name": "Khamgaon",
                    "siblingCities": {}
                },
                {
                    "id": "1072363",
                    "name": "Khopoli",
                    "siblingCities": {}
                },
                {
                    "id": "1072543",
                    "name": "Kopargaon",
                    "siblingCities": {}
                },
                {
                    "id": "1072723",
                    "name": "Latur",
                    "siblingCities": {}
                },
                {
                    "id": "1072903",
                    "name": "Lonavla",
                    "siblingCities": {}
                },
                {
                    "id": "1073083",
                    "name": "Malegaon",
                    "siblingCities": {}
                },
                {
                    "id": "1073263",
                    "name": "Malkapur",
                    "siblingCities": {}
                },
                {
                    "id": "1073443",
                    "name": "Manmad",
                    "siblingCities": {}
                },
                {
                    "id": "1073623",
                    "name": "Miraj",
                    "siblingCities": {}
                },
                {
                    "id": "1073803",
                    "name": "Nanded",
                    "siblingCities": {}
                },
                {
                    "id": "1073983",
                    "name": "Nandurbar",
                    "siblingCities": {}
                },
                {
                    "id": "1074163",
                    "name": "NavgharManikpur",
                    "siblingCities": {}
                },
                {
                    "id": "1074343",
                    "name": "Osmanabad",
                    "siblingCities": {}
                },
                {
                    "id": "1074523",
                    "name": "Palghar",
                    "siblingCities": {
                        "31": "Mumbai",
                        "400601": "Thane",
                        "400612": "Mumbra",
                        "400701": "NaviMumbai",
                        "402305": "Raigad",
                        "421201": "Dombivli",
                        "421301": "Kalyan",
                        "421501": "Ambernath",
                        "1067863": "Badlapur",
                        "1074883": "Panvel",
                        "1077403": "VasaiVirar"
                    }
                },
                {
                    "id": "1074703",
                    "name": "Pandharpur",
                    "siblingCities": {}
                },
                {
                    "id": "1074883",
                    "name": "Panvel",
                    "siblingCities": {
                        "31": "Mumbai",
                        "400601": "Thane",
                        "400612": "Mumbra",
                        "400701": "NaviMumbai",
                        "402305": "Raigad",
                        "421201": "Dombivli",
                        "421301": "Kalyan",
                        "421501": "Ambernath",
                        "1067863": "Badlapur",
                        "1074523": "Palghar",
                        "1077403": "VasaiVirar"
                    }
                },
                {
                    "id": "1075063",
                    "name": "Parbhani",
                    "siblingCities": {}
                },
                {
                    "id": "1075243",
                    "name": "Parli",
                    "siblingCities": {}
                },
                {
                    "id": "1075423",
                    "name": "Phaltan",
                    "siblingCities": {}
                },
                {
                    "id": "1075603",
                    "name": "PimpriChinchwad",
                    "siblingCities": {
                        "33": "Pune"
                    }
                },
                {
                    "id": "1075783",
                    "name": "Pusad",
                    "siblingCities": {}
                },
                {
                    "id": "1075963",
                    "name": "Ratnagiri",
                    "siblingCities": {}
                },
                {
                    "id": "1076143",
                    "name": "Sangamner",
                    "siblingCities": {}
                },
                {
                    "id": "1076323",
                    "name": "Sangli",
                    "siblingCities": {}
                },
                {
                    "id": "1076503",
                    "name": "Shegaon",
                    "siblingCities": {}
                },
                {
                    "id": "1076683",
                    "name": "Shirpur",
                    "siblingCities": {}
                },
                {
                    "id": "1076863",
                    "name": "Shrirampur",
                    "siblingCities": {}
                },
                {
                    "id": "1077043",
                    "name": "Udgir",
                    "siblingCities": {}
                },
                {
                    "id": "1077223",
                    "name": "UranIslampur",
                    "siblingCities": {}
                },
                {
                    "id": "1077403",
                    "name": "VasaiVirar",
                    "siblingCities": {
                        "31": "Mumbai",
                        "400601": "Thane",
                        "400612": "Mumbra",
                        "400701": "NaviMumbai",
                        "402305": "Raigad",
                        "421201": "Dombivli",
                        "421301": "Kalyan",
                        "421501": "Ambernath",
                        "1067863": "Badlapur",
                        "1074523": "Palghar",
                        "1074883": "Panvel"
                    }
                },
                {
                    "id": "1077583",
                    "name": "Wani",
                    "siblingCities": {}
                },
                {
                    "id": "1077763",
                    "name": "Wardha",
                    "siblingCities": {}
                },
                {
                    "id": "1077943",
                    "name": "Washim",
                    "siblingCities": {}
                },
                {
                    "id": "1078123",
                    "name": "Yavatmal",
                    "siblingCities": {}
                },
                {
                    "id": "1078303",
                    "name": "Imphal",
                    "siblingCities": {}
                },
                {
                    "id": "1078483",
                    "name": "Tura",
                    "siblingCities": {}
                },
                {
                    "id": "1078663",
                    "name": "Dimapur",
                    "siblingCities": {}
                },
                {
                    "id": "1078843",
                    "name": "Balangir",
                    "siblingCities": {}
                },
                {
                    "id": "1079023",
                    "name": "Balasore",
                    "siblingCities": {}
                },
                {
                    "id": "1079203",
                    "name": "Barbil",
                    "siblingCities": {}
                },
                {
                    "id": "1079383",
                    "name": "Bargarh",
                    "siblingCities": {}
                },
                {
                    "id": "1079563",
                    "name": "Baripada",
                    "siblingCities": {}
                },
                {
                    "id": "1079743",
                    "name": "Bhadrak",
                    "siblingCities": {}
                },
                {
                    "id": "1079923",
                    "name": "Bhawanipatna",
                    "siblingCities": {}
                },
                {
                    "id": "1080103",
                    "name": "Brahmapur",
                    "siblingCities": {}
                },
                {
                    "id": "1080283",
                    "name": "Brajarajnagar",
                    "siblingCities": {}
                },
                {
                    "id": "1080463",
                    "name": "Dhenkanal",
                    "siblingCities": {}
                },
                {
                    "id": "1080643",
                    "name": "Jatani",
                    "siblingCities": {}
                },
                {
                    "id": "1080823",
                    "name": "Jeypore",
                    "siblingCities": {}
                },
                {
                    "id": "1081003",
                    "name": "Jharsuguda",
                    "siblingCities": {}
                },
                {
                    "id": "1081183",
                    "name": "Kendujhar",
                    "siblingCities": {}
                },
                {
                    "id": "1081363",
                    "name": "Paradip",
                    "siblingCities": {}
                },
                {
                    "id": "1081543",
                    "name": "Puri",
                    "siblingCities": {
                        "751001": "Bhubaneswar"
                    }
                },
                {
                    "id": "1081723",
                    "name": "Rayagada",
                    "siblingCities": {}
                },
                {
                    "id": "1081903",
                    "name": "Rourkela",
                    "siblingCities": {}
                },
                {
                    "id": "1082083",
                    "name": "Sambalpur",
                    "siblingCities": {}
                },
                {
                    "id": "1082263",
                    "name": "Sunabeda",
                    "siblingCities": {}
                },
                {
                    "id": "1082443",
                    "name": "Karaikal",
                    "siblingCities": {}
                },
                {
                    "id": "1082623",
                    "name": "Ozhukarai",
                    "siblingCities": {}
                },
                {
                    "id": "1082803",
                    "name": "Abohar",
                    "siblingCities": {}
                },
                {
                    "id": "1082983",
                    "name": "Barnala",
                    "siblingCities": {}
                },
                {
                    "id": "1083163",
                    "name": "Batala",
                    "siblingCities": {}
                },
                {
                    "id": "1083343",
                    "name": "Bathinda",
                    "siblingCities": {}
                },
                {
                    "id": "1083523",
                    "name": "Faridkot",
                    "siblingCities": {}
                },
                {
                    "id": "1083703",
                    "name": "Fazilka",
                    "siblingCities": {}
                },
                {
                    "id": "1083883",
                    "name": "Firozpur",
                    "siblingCities": {}
                },
                {
                    "id": "1084063",
                    "name": "FirozpurCantonment",
                    "siblingCities": {}
                },
                {
                    "id": "1084243",
                    "name": "Gurdaspur",
                    "siblingCities": {}
                },
                {
                    "id": "1084423",
                    "name": "Hoshiarpur",
                    "siblingCities": {}
                },
                {
                    "id": "1084603",
                    "name": "Jagraon",
                    "siblingCities": {}
                },
                {
                    "id": "1084783",
                    "name": "Kapurthala",
                    "siblingCities": {}
                },
                {
                    "id": "1084963",
                    "name": "Khanna",
                    "siblingCities": {}
                },
                {
                    "id": "1085143",
                    "name": "Kotkapura",
                    "siblingCities": {}
                },
                {
                    "id": "1085323",
                    "name": "Malerkotla",
                    "siblingCities": {}
                },
                {
                    "id": "1085503",
                    "name": "Malout",
                    "siblingCities": {}
                },
                {
                    "id": "1085683",
                    "name": "MandiGobindgarh",
                    "siblingCities": {}
                },
                {
                    "id": "1085863",
                    "name": "Mansa",
                    "siblingCities": {}
                },
                {
                    "id": "1086043",
                    "name": "Moga",
                    "siblingCities": {}
                },
                {
                    "id": "1086223",
                    "name": "Nabha",
                    "siblingCities": {}
                },
                {
                    "id": "1086403",
                    "name": "Pathankot",
                    "siblingCities": {}
                },
                {
                    "id": "1086583",
                    "name": "Patiala",
                    "siblingCities": {}
                },
                {
                    "id": "1086763",
                    "name": "Phagwara",
                    "siblingCities": {}
                },
                {
                    "id": "1086943",
                    "name": "Rajpura",
                    "siblingCities": {}
                },
                {
                    "id": "1087123",
                    "name": "Sangrur",
                    "siblingCities": {}
                },
                {
                    "id": "1087303",
                    "name": "Sirhind",
                    "siblingCities": {}
                },
                {
                    "id": "1087483",
                    "name": "SriMuktsarSahib",
                    "siblingCities": {}
                },
                {
                    "id": "1087663",
                    "name": "Sunam",
                    "siblingCities": {}
                },
                {
                    "id": "1087843",
                    "name": "TarnTaranSahib",
                    "siblingCities": {}
                },
                {
                    "id": "1088023",
                    "name": "Alwar",
                    "siblingCities": {}
                },
                {
                    "id": "1088203",
                    "name": "Balotra",
                    "siblingCities": {}
                },
                {
                    "id": "1088383",
                    "name": "Banswara",
                    "siblingCities": {}
                },
                {
                    "id": "1088563",
                    "name": "Baran",
                    "siblingCities": {}
                },
                {
                    "id": "1088743",
                    "name": "Bari",
                    "siblingCities": {}
                },
                {
                    "id": "1088923",
                    "name": "Barmer",
                    "siblingCities": {}
                },
                {
                    "id": "1089103",
                    "name": "Beawar",
                    "siblingCities": {}
                },
                {
                    "id": "1089283",
                    "name": "Bharatpur",
                    "siblingCities": {}
                },
                {
                    "id": "1089463",
                    "name": "Bhilwara",
                    "siblingCities": {}
                },
                {
                    "id": "1089643",
                    "name": "Bundi",
                    "siblingCities": {}
                },
                {
                    "id": "1089823",
                    "name": "Chittorgarh",
                    "siblingCities": {}
                },
                {
                    "id": "1090003",
                    "name": "Chomu",
                    "siblingCities": {}
                },
                {
                    "id": "1090183",
                    "name": "Churu",
                    "siblingCities": {}
                },
                {
                    "id": "1090363",
                    "name": "Dausa",
                    "siblingCities": {}
                },
                {
                    "id": "1090543",
                    "name": "Dholpur",
                    "siblingCities": {}
                },
                {
                    "id": "1090723",
                    "name": "Ganganagar",
                    "siblingCities": {}
                },
                {
                    "id": "1090903",
                    "name": "GangapurCity",
                    "siblingCities": {}
                },
                {
                    "id": "1091083",
                    "name": "Hanumangarh",
                    "siblingCities": {}
                },
                {
                    "id": "1091263",
                    "name": "Hindaun",
                    "siblingCities": {}
                },
                {
                    "id": "1091443",
                    "name": "Jaisalmer",
                    "siblingCities": {}
                },
                {
                    "id": "1091623",
                    "name": "Jhunjhunu",
                    "siblingCities": {}
                },
                {
                    "id": "1091803",
                    "name": "Karauli",
                    "siblingCities": {}
                },
                {
                    "id": "1091983",
                    "name": "Kishangarh",
                    "siblingCities": {}
                },
                {
                    "id": "1092163",
                    "name": "Kota",
                    "siblingCities": {}
                },
                {
                    "id": "1092343",
                    "name": "Kuchaman",
                    "siblingCities": {}
                },
                {
                    "id": "1092523",
                    "name": "Ladnun",
                    "siblingCities": {}
                },
                {
                    "id": "1092703",
                    "name": "Makrana",
                    "siblingCities": {}
                },
                {
                    "id": "1092883",
                    "name": "Nagaur",
                    "siblingCities": {}
                },
                {
                    "id": "1093063",
                    "name": "Nawalgarh",
                    "siblingCities": {}
                },
                {
                    "id": "1093243",
                    "name": "Nimbahera",
                    "siblingCities": {}
                },
                {
                    "id": "1093423",
                    "name": "Pali",
                    "siblingCities": {}
                },
                {
                    "id": "1093603",
                    "name": "Rajgarh",
                    "siblingCities": {}
                },
                {
                    "id": "1093783",
                    "name": "Rajsamand",
                    "siblingCities": {}
                },
                {
                    "id": "1093963",
                    "name": "Ratangarh",
                    "siblingCities": {}
                },
                {
                    "id": "1094143",
                    "name": "Sardarshahar",
                    "siblingCities": {}
                },
                {
                    "id": "1094323",
                    "name": "SawaiMadhopur",
                    "siblingCities": {}
                },
                {
                    "id": "1094503",
                    "name": "Sikar",
                    "siblingCities": {}
                },
                {
                    "id": "1094683",
                    "name": "Sujangarh",
                    "siblingCities": {}
                },
                {
                    "id": "1094863",
                    "name": "Suratgarh",
                    "siblingCities": {}
                },
                {
                    "id": "1095043",
                    "name": "Tonk",
                    "siblingCities": {}
                },
                {
                    "id": "1095223",
                    "name": "Gangtok",
                    "siblingCities": {}
                },
                {
                    "id": "1095403",
                    "name": "Aarani",
                    "siblingCities": {}
                },
                {
                    "id": "1095583",
                    "name": "Ambasamudram",
                    "siblingCities": {}
                },
                {
                    "id": "1095763",
                    "name": "Ambur",
                    "siblingCities": {}
                },
                {
                    "id": "1095943",
                    "name": "Arakkonam",
                    "siblingCities": {}
                },
                {
                    "id": "1096123",
                    "name": "Arcot",
                    "siblingCities": {}
                },
                {
                    "id": "1096303",
                    "name": "Aruppukkottai",
                    "siblingCities": {}
                },
                {
                    "id": "1096483",
                    "name": "Attur",
                    "siblingCities": {}
                },
                {
                    "id": "1096663",
                    "name": "Avaniapuram",
                    "siblingCities": {}
                },
                {
                    "id": "1096843",
                    "name": "Bhavani",
                    "siblingCities": {}
                },
                {
                    "id": "1097023",
                    "name": "Bodinayakkanur",
                    "siblingCities": {}
                },
                {
                    "id": "1097203",
                    "name": "Chengalpattu",
                    "siblingCities": {}
                },
                {
                    "id": "1097383",
                    "name": "Chidambaram",
                    "siblingCities": {}
                },
                {
                    "id": "1097563",
                    "name": "Coonoor",
                    "siblingCities": {}
                },
                {
                    "id": "1097743",
                    "name": "Cuddalore",
                    "siblingCities": {}
                },
                {
                    "id": "1097923",
                    "name": "Devarshola",
                    "siblingCities": {}
                },
                {
                    "id": "1098103",
                    "name": "Dharapuram",
                    "siblingCities": {}
                },
                {
                    "id": "1098283",
                    "name": "Dharmapuri",
                    "siblingCities": {}
                },
                {
                    "id": "1098463",
                    "name": "Dindigul",
                    "siblingCities": {}
                },
                {
                    "id": "1098643",
                    "name": "Erode",
                    "siblingCities": {}
                },
                {
                    "id": "1098823",
                    "name": "Gobichettipalayam",
                    "siblingCities": {}
                },
                {
                    "id": "1099003",
                    "name": "Gudiyatham",
                    "siblingCities": {}
                },
                {
                    "id": "1099183",
                    "name": "Hosur",
                    "siblingCities": {
                        "1100623": "Krishnagiri"
                    }
                },
                {
                    "id": "1099363",
                    "name": "Kadayanallur",
                    "siblingCities": {}
                },
                {
                    "id": "1099543",
                    "name": "Kambam",
                    "siblingCities": {}
                },
                {
                    "id": "1099723",
                    "name": "Kanchipuram",
                    "siblingCities": {
                        "25": "Chennai"
                    }
                },
                {
                    "id": "1099903",
                    "name": "Karaikudi",
                    "siblingCities": {}
                },
                {
                    "id": "1100083",
                    "name": "Karur",
                    "siblingCities": {}
                },
                {
                    "id": "1100263",
                    "name": "Komarapalayam",
                    "siblingCities": {}
                },
                {
                    "id": "1100443",
                    "name": "Kovilpatti",
                    "siblingCities": {}
                },
                {
                    "id": "1100623",
                    "name": "Krishnagiri",
                    "siblingCities": {
                        "1099183": "Hosur"
                    }
                },
                {
                    "id": "1100803",
                    "name": "Kumbakonam",
                    "siblingCities": {}
                },
                {
                    "id": "1100983",
                    "name": "Mannargudi",
                    "siblingCities": {}
                },
                {
                    "id": "1101163",
                    "name": "Mayiladuthurai",
                    "siblingCities": {}
                },
                {
                    "id": "1101343",
                    "name": "Mettupalayam",
                    "siblingCities": {}
                },
                {
                    "id": "1101523",
                    "name": "Mettur",
                    "siblingCities": {}
                },
                {
                    "id": "1101703",
                    "name": "Nagapattinam",
                    "siblingCities": {}
                },
                {
                    "id": "1101883",
                    "name": "Nagercoil",
                    "siblingCities": {}
                },
                {
                    "id": "1102063",
                    "name": "Namakkal",
                    "siblingCities": {}
                },
                {
                    "id": "1102243",
                    "name": "Neyveli",
                    "siblingCities": {}
                },
                {
                    "id": "1102423",
                    "name": "Ooty",
                    "siblingCities": {}
                },
                {
                    "id": "1102603",
                    "name": "Palani",
                    "siblingCities": {}
                },
                {
                    "id": "1102783",
                    "name": "Pallavaram",
                    "siblingCities": {}
                },
                {
                    "id": "1102963",
                    "name": "Panruti",
                    "siblingCities": {}
                },
                {
                    "id": "1103143",
                    "name": "Paramakudi",
                    "siblingCities": {}
                },
                {
                    "id": "1103323",
                    "name": "Pattukkottai",
                    "siblingCities": {}
                },
                {
                    "id": "1103503",
                    "name": "Pollachi",
                    "siblingCities": {}
                },
                {
                    "id": "1103683",
                    "name": "Pudukkottai",
                    "siblingCities": {}
                },
                {
                    "id": "1103863",
                    "name": "Puliyankudi",
                    "siblingCities": {}
                },
                {
                    "id": "1104043",
                    "name": "Rajapalayam",
                    "siblingCities": {}
                },
                {
                    "id": "1104223",
                    "name": "Ramanathapuram",
                    "siblingCities": {}
                },
                {
                    "id": "1104403",
                    "name": "Sankarankoil",
                    "siblingCities": {}
                },
                {
                    "id": "1104583",
                    "name": "Sivakasi",
                    "siblingCities": {}
                },
                {
                    "id": "1104763",
                    "name": "Srivilliputhur",
                    "siblingCities": {}
                },
                {
                    "id": "1104943",
                    "name": "Tenkasi",
                    "siblingCities": {}
                },
                {
                    "id": "1105123",
                    "name": "Thanjavur",
                    "siblingCities": {}
                },
                {
                    "id": "1105303",
                    "name": "TheniAllinagaram",
                    "siblingCities": {}
                },
                {
                    "id": "1105483",
                    "name": "Thiruvarur",
                    "siblingCities": {}
                },
                {
                    "id": "1105663",
                    "name": "Thoothukudi",
                    "siblingCities": {}
                },
                {
                    "id": "1105843",
                    "name": "Tindivanam",
                    "siblingCities": {}
                },
                {
                    "id": "1106023",
                    "name": "Tiruchendur",
                    "siblingCities": {}
                },
                {
                    "id": "1106203",
                    "name": "Tiruchengode",
                    "siblingCities": {}
                },
                {
                    "id": "1106383",
                    "name": "Tirunelveli",
                    "siblingCities": {}
                },
                {
                    "id": "1106563",
                    "name": "Tirupathur",
                    "siblingCities": {}
                },
                {
                    "id": "1106743",
                    "name": "Tirupur",
                    "siblingCities": {}
                },
                {
                    "id": "1106923",
                    "name": "Tiruvannamalai",
                    "siblingCities": {}
                },
                {
                    "id": "1107103",
                    "name": "Udumalaipettai",
                    "siblingCities": {}
                },
                {
                    "id": "1107283",
                    "name": "Valparai",
                    "siblingCities": {}
                },
                {
                    "id": "1107463",
                    "name": "Vaniyambadi",
                    "siblingCities": {}
                },
                {
                    "id": "1107643",
                    "name": "Viluppuram",
                    "siblingCities": {}
                },
                {
                    "id": "1107823",
                    "name": "Virudhachalam",
                    "siblingCities": {}
                },
                {
                    "id": "1108003",
                    "name": "Virudhunagar",
                    "siblingCities": {}
                },
                {
                    "id": "1108183",
                    "name": "Agartala",
                    "siblingCities": {}
                },
                {
                    "id": "1108363",
                    "name": "Aligarh",
                    "siblingCities": {}
                },
                {
                    "id": "1108543",
                    "name": "Amroha",
                    "siblingCities": {}
                },
                {
                    "id": "1108723",
                    "name": "Auraiya",
                    "siblingCities": {}
                },
                {
                    "id": "1108903",
                    "name": "Azamgarh",
                    "siblingCities": {}
                },
                {
                    "id": "1109083",
                    "name": "Baheri",
                    "siblingCities": {}
                },
                {
                    "id": "1109263",
                    "name": "Bahraich",
                    "siblingCities": {}
                },
                {
                    "id": "1109443",
                    "name": "Ballia",
                    "siblingCities": {}
                },
                {
                    "id": "1109623",
                    "name": "Balrampur",
                    "siblingCities": {}
                },
                {
                    "id": "1109803",
                    "name": "Banda",
                    "siblingCities": {}
                },
                {
                    "id": "1109983",
                    "name": "Baraut",
                    "siblingCities": {}
                },
                {
                    "id": "1110163",
                    "name": "Basti",
                    "siblingCities": {}
                },
                {
                    "id": "1110343",
                    "name": "BehtaHajipur",
                    "siblingCities": {}
                },
                {
                    "id": "1110523",
                    "name": "BelaPratapgarh",
                    "siblingCities": {}
                },
                {
                    "id": "1110703",
                    "name": "Bhadohi",
                    "siblingCities": {}
                },
                {
                    "id": "1110883",
                    "name": "Bijnor",
                    "siblingCities": {}
                },
                {
                    "id": "1111063",
                    "name": "Bisalpur",
                    "siblingCities": {}
                },
                {
                    "id": "1111243",
                    "name": "Budaun",
                    "siblingCities": {}
                },
                {
                    "id": "1111423",
                    "name": "Bulandshahr",
                    "siblingCities": {}
                },
                {
                    "id": "1111603",
                    "name": "Chandausi",
                    "siblingCities": {}
                },
                {
                    "id": "1111783",
                    "name": "Chandpur",
                    "siblingCities": {}
                },
                {
                    "id": "1111963",
                    "name": "Chhibramau",
                    "siblingCities": {}
                },
                {
                    "id": "1112143",
                    "name": "Dadri",
                    "siblingCities": {}
                },
                {
                    "id": "1112323",
                    "name": "Deoband",
                    "siblingCities": {}
                },
                {
                    "id": "1112503",
                    "name": "Deoria",
                    "siblingCities": {}
                },
                {
                    "id": "1112683",
                    "name": "Etah",
                    "siblingCities": {}
                },
                {
                    "id": "1112863",
                    "name": "Etawah",
                    "siblingCities": {}
                },
                {
                    "id": "1113043",
                    "name": "Faizabad",
                    "siblingCities": {}
                },
                {
                    "id": "1113223",
                    "name": "Faridpur",
                    "siblingCities": {}
                },
                {
                    "id": "1113403",
                    "name": "Farrukhabad",
                    "siblingCities": {}
                },
                {
                    "id": "1113583",
                    "name": "Fatehpur",
                    "siblingCities": {}
                },
                {
                    "id": "1113763",
                    "name": "Firozabad",
                    "siblingCities": {}
                },
                {
                    "id": "1113943",
                    "name": "Gangaghat",
                    "siblingCities": {}
                },
                {
                    "id": "1114123",
                    "name": "Gangoh",
                    "siblingCities": {}
                },
                {
                    "id": "1114303",
                    "name": "Ghazipur",
                    "siblingCities": {}
                },
                {
                    "id": "1114483",
                    "name": "GolaGokarannath",
                    "siblingCities": {}
                },
                {
                    "id": "1114663",
                    "name": "Gonda",
                    "siblingCities": {}
                },
                {
                    "id": "1114843",
                    "name": "Gorakhpur",
                    "siblingCities": {}
                },
                {
                    "id": "1115023",
                    "name": "Hapur",
                    "siblingCities": {}
                },
                {
                    "id": "1115203",
                    "name": "Hardoi",
                    "siblingCities": {}
                },
                {
                    "id": "1115383",
                    "name": "Hasanpur",
                    "siblingCities": {}
                },
                {
                    "id": "1115563",
                    "name": "Hathras",
                    "siblingCities": {}
                },
                {
                    "id": "1115743",
                    "name": "Jahangirabad",
                    "siblingCities": {}
                },
                {
                    "id": "1115923",
                    "name": "Jaunpur",
                    "siblingCities": {}
                },
                {
                    "id": "1116103",
                    "name": "Jhansi",
                    "siblingCities": {}
                },
                {
                    "id": "1116283",
                    "name": "Kairana",
                    "siblingCities": {}
                },
                {
                    "id": "1116463",
                    "name": "Kannauj",
                    "siblingCities": {}
                },
                {
                    "id": "1116643",
                    "name": "Kasganj",
                    "siblingCities": {}
                },
                {
                    "id": "1116823",
                    "name": "Khatauli",
                    "siblingCities": {}
                },
                {
                    "id": "1117003",
                    "name": "Khurja",
                    "siblingCities": {}
                },
                {
                    "id": "1117183",
                    "name": "Kiratpur",
                    "siblingCities": {}
                },
                {
                    "id": "1117363",
                    "name": "Konch",
                    "siblingCities": {}
                },
                {
                    "id": "1117543",
                    "name": "Laharpur",
                    "siblingCities": {}
                },
                {
                    "id": "1117723",
                    "name": "Lakhimpur",
                    "siblingCities": {}
                },
                {
                    "id": "1117903",
                    "name": "Lalitpur",
                    "siblingCities": {}
                },
                {
                    "id": "1118083",
                    "name": "Loni",
                    "siblingCities": {}
                },
                {
                    "id": "1118263",
                    "name": "Mahoba",
                    "siblingCities": {}
                },
                {
                    "id": "1118443",
                    "name": "Mainpuri",
                    "siblingCities": {}
                },
                {
                    "id": "1118623",
                    "name": "Mau",
                    "siblingCities": {}
                },
                {
                    "id": "1118803",
                    "name": "Mauranipur",
                    "siblingCities": {}
                },
                {
                    "id": "1118983",
                    "name": "Mawana",
                    "siblingCities": {}
                },
                {
                    "id": "1119163",
                    "name": "Mirzapur",
                    "siblingCities": {}
                },
                {
                    "id": "1119343",
                    "name": "Modinagar",
                    "siblingCities": {}
                },
                {
                    "id": "1119523",
                    "name": "Mubarakpur",
                    "siblingCities": {}
                },
                {
                    "id": "1119703",
                    "name": "Mughalsarai",
                    "siblingCities": {}
                },
                {
                    "id": "1119883",
                    "name": "Muradnagar",
                    "siblingCities": {}
                },
                {
                    "id": "1120063",
                    "name": "Muzaffarnagar",
                    "siblingCities": {}
                },
                {
                    "id": "1120243",
                    "name": "Nagina",
                    "siblingCities": {}
                },
                {
                    "id": "1120423",
                    "name": "Najibabad",
                    "siblingCities": {}
                },
                {
                    "id": "1120603",
                    "name": "Nawabganj",
                    "siblingCities": {}
                },
                {
                    "id": "1120783",
                    "name": "Obra",
                    "siblingCities": {}
                },
                {
                    "id": "1120963",
                    "name": "Orai",
                    "siblingCities": {}
                },
                {
                    "id": "1121143",
                    "name": "Pilibhit",
                    "siblingCities": {}
                },
                {
                    "id": "1121323",
                    "name": "Pilkhuwa",
                    "siblingCities": {}
                },
                {
                    "id": "1121503",
                    "name": "Raebareli",
                    "siblingCities": {}
                },
                {
                    "id": "1121683",
                    "name": "Rampur",
                    "siblingCities": {}
                },
                {
                    "id": "1121863",
                    "name": "Rath",
                    "siblingCities": {}
                },
                {
                    "id": "1122043",
                    "name": "Renukoot",
                    "siblingCities": {}
                },
                {
                    "id": "1122223",
                    "name": "Saharanpur",
                    "siblingCities": {}
                },
                {
                    "id": "1122403",
                    "name": "Sahaswan",
                    "siblingCities": {}
                },
                {
                    "id": "1122583",
                    "name": "Sambhal",
                    "siblingCities": {}
                },
                {
                    "id": "1122763",
                    "name": "Shahabad",
                    "siblingCities": {}
                },
                {
                    "id": "1122943",
                    "name": "Shahjahanpur",
                    "siblingCities": {}
                },
                {
                    "id": "1123123",
                    "name": "Shamli",
                    "siblingCities": {}
                },
                {
                    "id": "1123303",
                    "name": "Sherkot",
                    "siblingCities": {}
                },
                {
                    "id": "1123483",
                    "name": "Shikohabad",
                    "siblingCities": {}
                },
                {
                    "id": "1123663",
                    "name": "Sikandrabad",
                    "siblingCities": {}
                },
                {
                    "id": "1123843",
                    "name": "Sitapur",
                    "siblingCities": {}
                },
                {
                    "id": "1124023",
                    "name": "Sultanpur",
                    "siblingCities": {}
                },
                {
                    "id": "1124203",
                    "name": "Tanda",
                    "siblingCities": {}
                },
                {
                    "id": "1124383",
                    "name": "Tilhar",
                    "siblingCities": {}
                },
                {
                    "id": "1124563",
                    "name": "Tundla",
                    "siblingCities": {}
                },
                {
                    "id": "1124743",
                    "name": "Ujhani",
                    "siblingCities": {}
                },
                {
                    "id": "1124923",
                    "name": "Unnao",
                    "siblingCities": {}
                },
                {
                    "id": "1125103",
                    "name": "Vrindavan",
                    "siblingCities": {}
                },
                {
                    "id": "1125283",
                    "name": "Haldwani",
                    "siblingCities": {}
                },
                {
                    "id": "1125463",
                    "name": "Hardwar",
                    "siblingCities": {}
                },
                {
                    "id": "1125643",
                    "name": "Kashipur",
                    "siblingCities": {}
                },
                {
                    "id": "1125823",
                    "name": "Rishikesh",
                    "siblingCities": {}
                },
                {
                    "id": "1126003",
                    "name": "Roorkee",
                    "siblingCities": {}
                },
                {
                    "id": "1126183",
                    "name": "Rudrapur",
                    "siblingCities": {}
                },
                {
                    "id": "1126363",
                    "name": "Alipurduar",
                    "siblingCities": {}
                },
                {
                    "id": "1126543",
                    "name": "Arambag",
                    "siblingCities": {}
                },
                {
                    "id": "1126723",
                    "name": "Asansol",
                    "siblingCities": {}
                },
                {
                    "id": "1126903",
                    "name": "AshoknagarKalyangarh",
                    "siblingCities": {}
                },
                {
                    "id": "1127083",
                    "name": "Baharampur",
                    "siblingCities": {}
                },
                {
                    "id": "1127263",
                    "name": "Baidyabati",
                    "siblingCities": {}
                },
                {
                    "id": "1127443",
                    "name": "Bally",
                    "siblingCities": {
                        "30": "Kolkata",
                        "1127983": "Bansberia",
                        "1128163": "Baranagar",
                        "1128343": "Barasat",
                        "1128703": "Barrackpore",
                        "1129603": "Bhatpara",
                        "1129783": "Bidhannagar",
                        "1133383": "Dumdum",
                        "1134283": "Garulia",
                        "1135723": "Howrah",
                        "1135903": "HugliChuchura",
                        "1137883": "Kalyani",
                        "1139323": "Konnagar",
                        "1140223": "Maheshtala",
                        "1142203": "NewBarrackpur",
                        "1144543": "Serampore",
                        "1146163": "Titagarh",
                        "1146703": "Uttarpara"
                    }
                },
                {
                    "id": "1127623",
                    "name": "Balurghat",
                    "siblingCities": {}
                },
                {
                    "id": "1127803",
                    "name": "Bankura",
                    "siblingCities": {}
                },
                {
                    "id": "1127983",
                    "name": "Bansberia",
                    "siblingCities": {
                        "30": "Kolkata",
                        "1127443": "Bally",
                        "1128163": "Baranagar",
                        "1128343": "Barasat",
                        "1128703": "Barrackpore",
                        "1129603": "Bhatpara",
                        "1129783": "Bidhannagar",
                        "1133383": "Dumdum",
                        "1134283": "Garulia",
                        "1135723": "Howrah",
                        "1135903": "HugliChuchura",
                        "1137883": "Kalyani",
                        "1139323": "Konnagar",
                        "1140223": "Maheshtala",
                        "1142203": "NewBarrackpur",
                        "1144543": "Serampore",
                        "1146163": "Titagarh",
                        "1146703": "Uttarpara"
                    }
                },
                {
                    "id": "1128163",
                    "name": "Baranagar",
                    "siblingCities": {
                        "30": "Kolkata",
                        "1127443": "Bally",
                        "1127983": "Bansberia",
                        "1128343": "Barasat",
                        "1128703": "Barrackpore",
                        "1129603": "Bhatpara",
                        "1129783": "Bidhannagar",
                        "1133383": "Dumdum",
                        "1134283": "Garulia",
                        "1135723": "Howrah",
                        "1135903": "HugliChuchura",
                        "1137883": "Kalyani",
                        "1139323": "Konnagar",
                        "1140223": "Maheshtala",
                        "1142203": "NewBarrackpur",
                        "1144543": "Serampore",
                        "1146163": "Titagarh",
                        "1146703": "Uttarpara"
                    }
                },
                {
                    "id": "1128343",
                    "name": "Barasat",
                    "siblingCities": {
                        "30": "Kolkata",
                        "1127443": "Bally",
                        "1127983": "Bansberia",
                        "1128163": "Baranagar",
                        "1128703": "Barrackpore",
                        "1129603": "Bhatpara",
                        "1129783": "Bidhannagar",
                        "1133383": "Dumdum",
                        "1134283": "Garulia",
                        "1135723": "Howrah",
                        "1135903": "HugliChuchura",
                        "1137883": "Kalyani",
                        "1139323": "Konnagar",
                        "1140223": "Maheshtala",
                        "1142203": "NewBarrackpur",
                        "1144543": "Serampore",
                        "1146163": "Titagarh",
                        "1146703": "Uttarpara"
                    }
                },
                {
                    "id": "1128523",
                    "name": "Bardhaman",
                    "siblingCities": {}
                },
                {
                    "id": "1128703",
                    "name": "Barrackpore",
                    "siblingCities": {
                        "30": "Kolkata",
                        "1127443": "Bally",
                        "1127983": "Bansberia",
                        "1128163": "Baranagar",
                        "1128343": "Barasat",
                        "1129603": "Bhatpara",
                        "1129783": "Bidhannagar",
                        "1133383": "Dumdum",
                        "1134283": "Garulia",
                        "1135723": "Howrah",
                        "1135903": "HugliChuchura",
                        "1137883": "Kalyani",
                        "1139323": "Konnagar",
                        "1140223": "Maheshtala",
                        "1142203": "NewBarrackpur",
                        "1144543": "Serampore",
                        "1146163": "Titagarh",
                        "1146703": "Uttarpara"
                    }
                },
                {
                    "id": "1128883",
                    "name": "Baruipur",
                    "siblingCities": {}
                },
                {
                    "id": "1129063",
                    "name": "Basirhat",
                    "siblingCities": {}
                },
                {
                    "id": "1129243",
                    "name": "Beldanga",
                    "siblingCities": {}
                },
                {
                    "id": "1129423",
                    "name": "Bhadreswar",
                    "siblingCities": {}
                },
                {
                    "id": "1129603",
                    "name": "Bhatpara",
                    "siblingCities": {
                        "30": "Kolkata",
                        "1127443": "Bally",
                        "1127983": "Bansberia",
                        "1128163": "Baranagar",
                        "1128343": "Barasat",
                        "1128703": "Barrackpore",
                        "1129783": "Bidhannagar",
                        "1133383": "Dumdum",
                        "1134283": "Garulia",
                        "1135723": "Howrah",
                        "1135903": "HugliChuchura",
                        "1137883": "Kalyani",
                        "1139323": "Konnagar",
                        "1140223": "Maheshtala",
                        "1142203": "NewBarrackpur",
                        "1144543": "Serampore",
                        "1146163": "Titagarh",
                        "1146703": "Uttarpara"
                    }
                },
                {
                    "id": "1129783",
                    "name": "Bidhannagar",
                    "siblingCities": {
                        "30": "Kolkata",
                        "1127443": "Bally",
                        "1127983": "Bansberia",
                        "1128163": "Baranagar",
                        "1128343": "Barasat",
                        "1128703": "Barrackpore",
                        "1129603": "Bhatpara",
                        "1133383": "Dumdum",
                        "1134283": "Garulia",
                        "1135723": "Howrah",
                        "1135903": "HugliChuchura",
                        "1137883": "Kalyani",
                        "1139323": "Konnagar",
                        "1140223": "Maheshtala",
                        "1142203": "NewBarrackpur",
                        "1144543": "Serampore",
                        "1146163": "Titagarh",
                        "1146703": "Uttarpara"
                    }
                },
                {
                    "id": "1129963",
                    "name": "Birnagar",
                    "siblingCities": {}
                },
                {
                    "id": "1130143",
                    "name": "Bishnupur",
                    "siblingCities": {}
                },
                {
                    "id": "1130323",
                    "name": "BolpurSantiniketan",
                    "siblingCities": {}
                },
                {
                    "id": "1130503",
                    "name": "Bongaon",
                    "siblingCities": {}
                },
                {
                    "id": "1130683",
                    "name": "BudgeBudge",
                    "siblingCities": {}
                },
                {
                    "id": "1130863",
                    "name": "Chakdaha",
                    "siblingCities": {}
                },
                {
                    "id": "1131043",
                    "name": "Champdani",
                    "siblingCities": {}
                },
                {
                    "id": "1131223",
                    "name": "Chandannagar",
                    "siblingCities": {}
                },
                {
                    "id": "1131403",
                    "name": "Chandrokona",
                    "siblingCities": {}
                },
                {
                    "id": "1131583",
                    "name": "Contai",
                    "siblingCities": {}
                },
                {
                    "id": "1131763",
                    "name": "CoochBehar",
                    "siblingCities": {}
                },
                {
                    "id": "1131943",
                    "name": "CoopersCamp",
                    "siblingCities": {}
                },
                {
                    "id": "1132123",
                    "name": "Dainhat",
                    "siblingCities": {}
                },
                {
                    "id": "1132303",
                    "name": "Dalkhola",
                    "siblingCities": {}
                },
                {
                    "id": "1132483",
                    "name": "Darjeeling",
                    "siblingCities": {}
                },
                {
                    "id": "1132663",
                    "name": "Dhulian",
                    "siblingCities": {}
                },
                {
                    "id": "1132843",
                    "name": "Dhupguri",
                    "siblingCities": {}
                },
                {
                    "id": "1133023",
                    "name": "DiamondHarbour",
                    "siblingCities": {}
                },
                {
                    "id": "1133203",
                    "name": "Dubrajpur",
                    "siblingCities": {}
                },
                {
                    "id": "1133383",
                    "name": "Dumdum",
                    "siblingCities": {
                        "30": "Kolkata",
                        "1127443": "Bally",
                        "1127983": "Bansberia",
                        "1128163": "Baranagar",
                        "1128343": "Barasat",
                        "1128703": "Barrackpore",
                        "1129603": "Bhatpara",
                        "1129783": "Bidhannagar",
                        "1134283": "Garulia",
                        "1135723": "Howrah",
                        "1135903": "HugliChuchura",
                        "1137883": "Kalyani",
                        "1139323": "Konnagar",
                        "1140223": "Maheshtala",
                        "1142203": "NewBarrackpur",
                        "1144543": "Serampore",
                        "1146163": "Titagarh",
                        "1146703": "Uttarpara"
                    }
                },
                {
                    "id": "1133563",
                    "name": "Durgapur",
                    "siblingCities": {}
                },
                {
                    "id": "1133743",
                    "name": "Egra",
                    "siblingCities": {}
                },
                {
                    "id": "1133923",
                    "name": "EnglishBazar",
                    "siblingCities": {}
                },
                {
                    "id": "1134103",
                    "name": "Gangarampur",
                    "siblingCities": {}
                },
                {
                    "id": "1134283",
                    "name": "Garulia",
                    "siblingCities": {
                        "30": "Kolkata",
                        "1127443": "Bally",
                        "1127983": "Bansberia",
                        "1128163": "Baranagar",
                        "1128343": "Barasat",
                        "1128703": "Barrackpore",
                        "1129603": "Bhatpara",
                        "1129783": "Bidhannagar",
                        "1133383": "Dumdum",
                        "1135723": "Howrah",
                        "1135903": "HugliChuchura",
                        "1137883": "Kalyani",
                        "1139323": "Konnagar",
                        "1140223": "Maheshtala",
                        "1142203": "NewBarrackpur",
                        "1144543": "Serampore",
                        "1146163": "Titagarh",
                        "1146703": "Uttarpara"
                    }
                },
                {
                    "id": "1134463",
                    "name": "Ghatal",
                    "siblingCities": {}
                },
                {
                    "id": "1134643",
                    "name": "Gobardanga",
                    "siblingCities": {}
                },
                {
                    "id": "1134823",
                    "name": "Guskara",
                    "siblingCities": {}
                },
                {
                    "id": "1135003",
                    "name": "Habra",
                    "siblingCities": {}
                },
                {
                    "id": "1135183",
                    "name": "Haldia",
                    "siblingCities": {}
                },
                {
                    "id": "1135363",
                    "name": "Haldibari",
                    "siblingCities": {}
                },
                {
                    "id": "1135543",
                    "name": "Halisahar",
                    "siblingCities": {}
                },
                {
                    "id": "1135723",
                    "name": "Howrah",
                    "siblingCities": {
                        "30": "Kolkata",
                        "1127443": "Bally",
                        "1127983": "Bansberia",
                        "1128163": "Baranagar",
                        "1128343": "Barasat",
                        "1128703": "Barrackpore",
                        "1129603": "Bhatpara",
                        "1129783": "Bidhannagar",
                        "1133383": "Dumdum",
                        "1134283": "Garulia",
                        "1135903": "HugliChuchura",
                        "1137883": "Kalyani",
                        "1139323": "Konnagar",
                        "1140223": "Maheshtala",
                        "1142203": "NewBarrackpur",
                        "1144543": "Serampore",
                        "1146163": "Titagarh",
                        "1146703": "Uttarpara"
                    }
                },
                {
                    "id": "1135903",
                    "name": "HugliChuchura",
                    "siblingCities": {
                        "30": "Kolkata",
                        "1127443": "Bally",
                        "1127983": "Bansberia",
                        "1128163": "Baranagar",
                        "1128343": "Barasat",
                        "1128703": "Barrackpore",
                        "1129603": "Bhatpara",
                        "1129783": "Bidhannagar",
                        "1133383": "Dumdum",
                        "1134283": "Garulia",
                        "1135723": "Howrah",
                        "1137883": "Kalyani",
                        "1139323": "Konnagar",
                        "1140223": "Maheshtala",
                        "1142203": "NewBarrackpur",
                        "1144543": "Serampore",
                        "1146163": "Titagarh",
                        "1146703": "Uttarpara"
                    }
                },
                {
                    "id": "1136083",
                    "name": "Islampur",
                    "siblingCities": {}
                },
                {
                    "id": "1136263",
                    "name": "Jalpaiguri",
                    "siblingCities": {}
                },
                {
                    "id": "1136443",
                    "name": "Jamuria",
                    "siblingCities": {}
                },
                {
                    "id": "1136623",
                    "name": "JaynagarMazilpur",
                    "siblingCities": {}
                },
                {
                    "id": "1136803",
                    "name": "Jhalda",
                    "siblingCities": {}
                },
                {
                    "id": "1136983",
                    "name": "Jhargram",
                    "siblingCities": {}
                },
                {
                    "id": "1137163",
                    "name": "JiaganjAzimganj",
                    "siblingCities": {}
                },
                {
                    "id": "1137343",
                    "name": "Kaliaganj",
                    "siblingCities": {}
                },
                {
                    "id": "1137523",
                    "name": "Kalimpong",
                    "siblingCities": {}
                },
                {
                    "id": "1137703",
                    "name": "Kalna",
                    "siblingCities": {}
                },
                {
                    "id": "1137883",
                    "name": "Kalyani",
                    "siblingCities": {
                        "30": "Kolkata",
                        "1127443": "Bally",
                        "1127983": "Bansberia",
                        "1128163": "Baranagar",
                        "1128343": "Barasat",
                        "1128703": "Barrackpore",
                        "1129603": "Bhatpara",
                        "1129783": "Bidhannagar",
                        "1133383": "Dumdum",
                        "1134283": "Garulia",
                        "1135723": "Howrah",
                        "1135903": "HugliChuchura",
                        "1139323": "Konnagar",
                        "1140223": "Maheshtala",
                        "1142203": "NewBarrackpur",
                        "1144543": "Serampore",
                        "1146163": "Titagarh",
                        "1146703": "Uttarpara"
                    }
                },
                {
                    "id": "1138063",
                    "name": "Kamarhati",
                    "siblingCities": {}
                },
                {
                    "id": "1138243",
                    "name": "Kanchrapara",
                    "siblingCities": {}
                },
                {
                    "id": "1138423",
                    "name": "Kandi",
                    "siblingCities": {}
                },
                {
                    "id": "1138603",
                    "name": "Katwa",
                    "siblingCities": {}
                },
                {
                    "id": "1138783",
                    "name": "Kharar",
                    "siblingCities": {
                        "24": "Chandigarh",
                        "134102": "Panchkula",
                        "140110": "Mohali",
                        "160104": "Zirakpur"
                    }
                },
                {
                    "id": "1138963",
                    "name": "Khardaha",
                    "siblingCities": {}
                },
                {
                    "id": "1139143",
                    "name": "Khirpai",
                    "siblingCities": {}
                },
                {
                    "id": "1139323",
                    "name": "Konnagar",
                    "siblingCities": {
                        "30": "Kolkata",
                        "1127443": "Bally",
                        "1127983": "Bansberia",
                        "1128163": "Baranagar",
                        "1128343": "Barasat",
                        "1128703": "Barrackpore",
                        "1129603": "Bhatpara",
                        "1129783": "Bidhannagar",
                        "1133383": "Dumdum",
                        "1134283": "Garulia",
                        "1135723": "Howrah",
                        "1135903": "HugliChuchura",
                        "1137883": "Kalyani",
                        "1140223": "Maheshtala",
                        "1142203": "NewBarrackpur",
                        "1144543": "Serampore",
                        "1146163": "Titagarh",
                        "1146703": "Uttarpara"
                    }
                },
                {
                    "id": "1139503",
                    "name": "Krishnanagar",
                    "siblingCities": {}
                },
                {
                    "id": "1139683",
                    "name": "Kulti",
                    "siblingCities": {}
                },
                {
                    "id": "1139863",
                    "name": "Kurseong",
                    "siblingCities": {}
                },
                {
                    "id": "1140043",
                    "name": "Madhyamgram",
                    "siblingCities": {}
                },
                {
                    "id": "1140223",
                    "name": "Maheshtala",
                    "siblingCities": {
                        "30": "Kolkata",
                        "1127443": "Bally",
                        "1127983": "Bansberia",
                        "1128163": "Baranagar",
                        "1128343": "Barasat",
                        "1128703": "Barrackpore",
                        "1129603": "Bhatpara",
                        "1129783": "Bidhannagar",
                        "1133383": "Dumdum",
                        "1134283": "Garulia",
                        "1135723": "Howrah",
                        "1135903": "HugliChuchura",
                        "1137883": "Kalyani",
                        "1139323": "Konnagar",
                        "1142203": "NewBarrackpur",
                        "1144543": "Serampore",
                        "1146163": "Titagarh",
                        "1146703": "Uttarpara"
                    }
                },
                {
                    "id": "1140403",
                    "name": "Malbazar",
                    "siblingCities": {}
                },
                {
                    "id": "1140583",
                    "name": "Mathabhanga",
                    "siblingCities": {}
                },
                {
                    "id": "1140763",
                    "name": "Mekliganj",
                    "siblingCities": {}
                },
                {
                    "id": "1140943",
                    "name": "Memari",
                    "siblingCities": {}
                },
                {
                    "id": "1141123",
                    "name": "Midnapore",
                    "siblingCities": {}
                },
                {
                    "id": "1141303",
                    "name": "Mirik",
                    "siblingCities": {}
                },
                {
                    "id": "1141483",
                    "name": "Murshidabad",
                    "siblingCities": {}
                },
                {
                    "id": "1141663",
                    "name": "Nabadwip",
                    "siblingCities": {}
                },
                {
                    "id": "1141843",
                    "name": "Naihati",
                    "siblingCities": {}
                },
                {
                    "id": "1142023",
                    "name": "Nalhati",
                    "siblingCities": {}
                },
                {
                    "id": "1142203",
                    "name": "NewBarrackpur",
                    "siblingCities": {
                        "30": "Kolkata",
                        "1127443": "Bally",
                        "1127983": "Bansberia",
                        "1128163": "Baranagar",
                        "1128343": "Barasat",
                        "1128703": "Barrackpore",
                        "1129603": "Bhatpara",
                        "1129783": "Bidhannagar",
                        "1133383": "Dumdum",
                        "1134283": "Garulia",
                        "1135723": "Howrah",
                        "1135903": "HugliChuchura",
                        "1137883": "Kalyani",
                        "1139323": "Konnagar",
                        "1140223": "Maheshtala",
                        "1144543": "Serampore",
                        "1146163": "Titagarh",
                        "1146703": "Uttarpara"
                    }
                },
                {
                    "id": "1142383",
                    "name": "Panihati",
                    "siblingCities": {}
                },
                {
                    "id": "1142563",
                    "name": "Panskura",
                    "siblingCities": {}
                },
                {
                    "id": "1142743",
                    "name": "Pujali",
                    "siblingCities": {}
                },
                {
                    "id": "1142923",
                    "name": "Purulia",
                    "siblingCities": {}
                },
                {
                    "id": "1143103",
                    "name": "Raghunathpur",
                    "siblingCities": {}
                },
                {
                    "id": "1143283",
                    "name": "Raiganj",
                    "siblingCities": {}
                },
                {
                    "id": "1143463",
                    "name": "RajpurSonarpur",
                    "siblingCities": {}
                },
                {
                    "id": "1143643",
                    "name": "Rampurhat",
                    "siblingCities": {}
                },
                {
                    "id": "1143823",
                    "name": "Ranaghat",
                    "siblingCities": {}
                },
                {
                    "id": "1144003",
                    "name": "Raniganj",
                    "siblingCities": {}
                },
                {
                    "id": "1144183",
                    "name": "Rishra",
                    "siblingCities": {}
                },
                {
                    "id": "1144363",
                    "name": "Sainthia",
                    "siblingCities": {}
                },
                {
                    "id": "1144543",
                    "name": "Serampore",
                    "siblingCities": {
                        "30": "Kolkata",
                        "1127443": "Bally",
                        "1127983": "Bansberia",
                        "1128163": "Baranagar",
                        "1128343": "Barasat",
                        "1128703": "Barrackpore",
                        "1129603": "Bhatpara",
                        "1129783": "Bidhannagar",
                        "1133383": "Dumdum",
                        "1134283": "Garulia",
                        "1135723": "Howrah",
                        "1135903": "HugliChuchura",
                        "1137883": "Kalyani",
                        "1139323": "Konnagar",
                        "1140223": "Maheshtala",
                        "1142203": "NewBarrackpur",
                        "1146163": "Titagarh",
                        "1146703": "Uttarpara"
                    }
                },
                {
                    "id": "1144723",
                    "name": "Shantipur",
                    "siblingCities": {}
                },
                {
                    "id": "1144903",
                    "name": "Siliguri",
                    "siblingCities": {}
                },
                {
                    "id": "1145083",
                    "name": "Sonamukhi",
                    "siblingCities": {}
                },
                {
                    "id": "1145263",
                    "name": "Suri",
                    "siblingCities": {}
                },
                {
                    "id": "1145443",
                    "name": "Taherpur",
                    "siblingCities": {}
                },
                {
                    "id": "1145623",
                    "name": "Taki",
                    "siblingCities": {}
                },
                {
                    "id": "1145803",
                    "name": "Tamluk",
                    "siblingCities": {}
                },
                {
                    "id": "1145983",
                    "name": "Tarakeswar",
                    "siblingCities": {}
                },
                {
                    "id": "1146163",
                    "name": "Titagarh",
                    "siblingCities": {
                        "30": "Kolkata",
                        "1127443": "Bally",
                        "1127983": "Bansberia",
                        "1128163": "Baranagar",
                        "1128343": "Barasat",
                        "1128703": "Barrackpore",
                        "1129603": "Bhatpara",
                        "1129783": "Bidhannagar",
                        "1133383": "Dumdum",
                        "1134283": "Garulia",
                        "1135723": "Howrah",
                        "1135903": "HugliChuchura",
                        "1137883": "Kalyani",
                        "1139323": "Konnagar",
                        "1140223": "Maheshtala",
                        "1142203": "NewBarrackpur",
                        "1144543": "Serampore",
                        "1146703": "Uttarpara"
                    }
                },
                {
                    "id": "1146343",
                    "name": "Tufanganj",
                    "siblingCities": {}
                },
                {
                    "id": "1146523",
                    "name": "Uluberia",
                    "siblingCities": {}
                },
                {
                    "id": "1146703",
                    "name": "Uttarpara",
                    "siblingCities": {
                        "30": "Kolkata",
                        "1127443": "Bally",
                        "1127983": "Bansberia",
                        "1128163": "Baranagar",
                        "1128343": "Barasat",
                        "1128703": "Barrackpore",
                        "1129603": "Bhatpara",
                        "1129783": "Bidhannagar",
                        "1133383": "Dumdum",
                        "1134283": "Garulia",
                        "1135723": "Howrah",
                        "1135903": "HugliChuchura",
                        "1137883": "Kalyani",
                        "1139323": "Konnagar",
                        "1140223": "Maheshtala",
                        "1142203": "NewBarrackpur",
                        "1144543": "Serampore",
                        "1146163": "Titagarh"
                    }
                },
                {
                    "id": "1146883",
                    "name": "PortBlair",
                    "siblingCities": {}
                }
            ],
            "cityClusters": [
                {
                    "id": "27",
                    "name": "Delhi",
                    "childCities": {
                        "27": "Delhi",
                        "121001": "Faridabad",
                        "132222": "Gurgaon",
                        "201002": "Ghaziabad",
                        "201301": "Noida",
                        "201310": "GreaterNoida",
                        "301019": "Bhiwadi"
                    },
                    "clusterName": "Delhi NCR"
                },
                {
                    "id": "31",
                    "name": "Mumbai",
                    "childCities": {
                        "31": "Mumbai",
                        "400601": "Thane",
                        "400612": "Mumbra",
                        "400701": "NaviMumbai",
                        "402305": "Raigad",
                        "421201": "Dombivli",
                        "421301": "Kalyan",
                        "421501": "Ambernath",
                        "1067863": "Badlapur",
                        "1074523": "Palghar",
                        "1074883": "Panvel",
                        "1077403": "VasaiVirar"
                    },
                    "clusterName": "Mumbai MMR"
                },
                {
                    "id": "22",
                    "name": "Ahmedabad",
                    "childCities": {
                        "22": "Ahmedabad",
                        "382010": "Gandhinagar"
                    },
                    "clusterName": "Ahmedabad -  Gandhinagar"
                },
                {
                    "id": "30",
                    "name": "Kolkata",
                    "childCities": {
                        "30": "Kolkata",
                        "1127443": "Bally",
                        "1127983": "Bansberia",
                        "1128163": "Baranagar",
                        "1128343": "Barasat",
                        "1128703": "Barrackpore",
                        "1129603": "Bhatpara",
                        "1129783": "Bidhannagar",
                        "1133383": "Dumdum",
                        "1134283": "Garulia",
                        "1135723": "Howrah",
                        "1135903": "HugliChuchura",
                        "1137883": "Kalyani",
                        "1139323": "Konnagar",
                        "1140223": "Maheshtala",
                        "1142203": "NewBarrackpur",
                        "1144543": "Serampore",
                        "1146163": "Titagarh",
                        "1146703": "Uttarpara"
                    },
                    "clusterName": "Kolkata - Howrah"
                },
                {
                    "id": "28",
                    "name": "Hyderabad",
                    "childCities": {
                        "28": "Hyderabad",
                        "1012061": "Secunderabad"
                    },
                    "clusterName": "Hyderabad - Secunderabad"
                },
                {
                    "id": "33",
                    "name": "Pune",
                    "childCities": {
                        "33": "Pune",
                        "1075603": "PimpriChinchwad"
                    },
                    "clusterName": "Pune"
                },
                {
                    "id": "24",
                    "name": "Chandigarh",
                    "childCities": {
                        "24": "Chandigarh",
                        "134102": "Panchkula",
                        "140110": "Mohali",
                        "160104": "Zirakpur",
                        "1138783": "Kharar"
                    },
                    "clusterName": "Chandigarh Capital Region"
                },
                {
                    "id": "403108",
                    "name": "Goa",
                    "childCities": {
                        "403108": "Goa",
                        "1027001": "Madgaon",
                        "1027181": "Mormugoa",
                        "1027361": "Panaji"
                    },
                    "clusterName": "Goa"
                },
                {
                    "id": "751001",
                    "name": "Bhubaneswar",
                    "childCities": {
                        "751001": "Bhubaneswar",
                        "1081543": "Puri"
                    },
                    "clusterName": "Bhubaneswar - Puri"
                },
                {
                    "id": "781001",
                    "name": "Guwahati",
                    "childCities": {
                        "781001": "Guwahati",
                        "1016381": "Dispur"
                    },
                    "clusterName": "Guwahati - Dispur"
                },
                {
                    "id": "1026461",
                    "name": "Silvassa",
                    "childCities": {
                        "1026461": "Silvassa",
                        "1026641": "Daman",
                        "1146891": "DamanDiu"
                    },
                    "clusterName": "Daman and Diu"
                },
                {
                    "id": "25",
                    "name": "Chennai",
                    "childCities": {
                        "25": "Chennai",
                        "1099723": "Kanchipuram"
                    },
                    "clusterName": "Chennai - Kanchipuram"
                },
                {
                    "id": "23",
                    "name": "Bangalore",
                    "childCities": {
                        "23": "Bangalore",
                        "1047522": "Chikkaballapur",
                        "1047882": "Chintamani",
                        "1048602": "Doddaballapur"
                    },
                    "clusterName": "Bangalore Division"
                },
                {
                    "id": "32",
                    "name": "Mysore",
                    "childCities": {
                        "32": "Mysore",
                        "1051302": "Mandya"
                    },
                    "clusterName": "Mysore"
                },
                {
                    "id": "100210",
                    "name": "Kadur",
                    "childCities": {
                        "100210": "Kadur",
                        "1047702": "Chikmagalur"
                    },
                    "clusterName": "Chikmagalur"
                },
                {
                    "id": "1054182",
                    "name": "Alappuzha",
                    "childCities": {
                        "1054182": "Alappuzha",
                        "1054542": "Cherthala",
                        "1055622": "Kayamkulam"
                    },
                    "clusterName": "Alappuzha"
                },
                {
                    "id": "29",
                    "name": "Kochi",
                    "childCities": {
                        "29": "Kochi",
                        "682301": "Tripunithura",
                        "1054902": "Edathala"
                    },
                    "clusterName": "Ernakulam"
                },
                {
                    "id": "1055262",
                    "name": "Kannur",
                    "childCities": {
                        "1055262": "Kannur",
                        "1057062": "Payyannur",
                        "1057422": "Taliparamba",
                        "1057602": "Thalassery"
                    },
                    "clusterName": "Kannur"
                },
                {
                    "id": "1055082",
                    "name": "Kanhangad",
                    "childCities": {
                        "1055082": "Kanhangad",
                        "1055442": "Kasaragod"
                    },
                    "clusterName": "Kasargod"
                },
                {
                    "id": "100067",
                    "name": "Pathanamthitta",
                    "childCities": {
                        "100067": "Pathanamthitta",
                        "689101": "Thiruvalla",
                        "1055802": "Kollam"
                    },
                    "clusterName": "Kollam"
                },
                {
                    "id": "100066",
                    "name": "Idukki",
                    "childCities": {
                        "100066": "Idukki",
                        "685584": "Thodupuzha",
                        "1054362": "Changanacherry",
                        "1055982": "Kottayam"
                    },
                    "clusterName": "Kottayam"
                },
                {
                    "id": "673003",
                    "name": "Kozhikode",
                    "childCities": {
                        "673003": "Kozhikode",
                        "673121": "Wayanad",
                        "1056162": "Malappuram",
                        "1056342": "Manjeri",
                        "1057242": "Ponnani",
                        "1057782": "Tirur"
                    },
                    "clusterName": "Kozhikode"
                },
                {
                    "id": "1054722",
                    "name": "Chittur",
                    "childCities": {
                        "1054722": "Chittur",
                        "1056882": "Palakkad"
                    },
                    "clusterName": "Palakkad"
                },
                {
                    "id": "695001",
                    "name": "Trivandrum",
                    "childCities": {
                        "695001": "Trivandrum",
                        "1056522": "Nedumangad",
                        "1056702": "Neyyattinkara"
                    },
                    "clusterName": "Trivandrum"
                },
                {
                    "id": "26",
                    "name": "Coimbatore",
                    "childCities": {
                        "26": "Coimbatore",
                        "100236": "Udumalpet"
                    },
                    "clusterName": "Udumalaipettai - Coimbatore"
                },
                {
                    "id": "100194",
                    "name": "EastGodavari",
                    "childCities": {
                        "100194": "EastGodavari",
                        "1004861": "Kakinada",
                        "1010441": "Rajahmundry"
                    },
                    "clusterName": "East Godavari"
                },
                {
                    "id": "534001",
                    "name": "WestGodavari",
                    "childCities": {
                        "534001": "WestGodavari",
                        "1002881": "Eluru"
                    },
                    "clusterName": "West Godavari"
                },
                {
                    "id": "1099183",
                    "name": "Hosur",
                    "childCities": {
                        "1099183": "Hosur",
                        "1100623": "Krishnagiri"
                    },
                    "clusterName": "Hosur - Krishnagiri"
                },
                {
                    "id": "520001",
                    "name": "Vijayawada",
                    "childCities": {
                        "520001": "Vijayawada",
                        "1000901": "Bapatla",
                        "1003961": "Guntur",
                        "1008281": "Narasaraopet",
                        "1014041": "Tenali"
                    },
                    "clusterName": "Guntur - Vijayawada"
                }
            ]
        }

};