import'../assets/sass/web/_home.scss'
import lozad from 'lozad'	
import {getCookie,setCookie} from './services/cookieService'
import './reply/homeReplyservice'
import {dataService} from './services/dataService'
import {isSet,getterSetter,showSpinner,hideSpinner,capitalizeFirstLetter,
		_tplRender,resumeAd,replaceSpacetounderscore,isMobile,replaceSpace,
		getShortMoneyFormat,connectionCheck,setCity} from './utils/common'
import {adServicesInit} from './services/adServices'
import {getLoggedInUser} from './loginService'
import {gtmClickButton,gtmHome} from './services/gtmService'
import {scrollService,currentYPosition,elmYPosition} from './services/anchorSmoothScrollService.js'
getLoggedInUser.then((result) => {
	if(result && result.data && result.data.user){
		getterSetter.set( 'loginData', result);
		getterSetter.set( 'loginstatus', true);
	}else{
		getterSetter.set( 'loginstatus', false);
	}
})
import {getTopBanks,contactService,similarListing} from './services/contactService'
import {searchSuggest} from './services/recentSearchService'
//import WebPush from 'q-push-notifications';
import showGenericPreRequestModal from 'q-push-notifications/lib/prototypes/optional/showGenericPreRequestModal'

require('./controller/shortlistController');
require('./controller/searchController');
require('./services/gaService.js')
require('./controller/citySelectController.js')
require('./controller/otpController.js')
require('./controller/header.js')

var cityName;
var counter = 1;
var cityId = getCookie('prefer_city_id');
var cityName = getCookie('new_prefer_city');
window.Home = {
	init: function () {
		if(cityId && cityName ){
			setCity(cityName,cityId)
		}
		var bootCarousel = $("#brokerCarousel");
		bootCarousel.append("<ol class='carousel-indicators'></ol>");
		var indicators = $(".carousel-indicators");
		bootCarousel.find(".carousel-inner").children(".item").each(function(index) {
			(index === 0) ?
			indicators.append("<li data-target='#brokerCarousel' data-slide-to='" + index + "' class='active'></li>") :
			indicators.append("<li data-target='#brokerCarousel' data-slide-to='" + index + "'></li>");
		});

		$('.single-item').slick();
		
		cityName = getCookie("new_prefer_city") ? (getCookie('new_prefer_city').charAt(0).toUpperCase() + getCookie('new_prefer_city').slice(1)) : '';

		gaService.addPageView('Category_HP', 'RealEstate');
		var citylist = "";
		var $cityPopup = $('#city-select-modal');
		var citySearchKeyword = '';
		var data = dataService.cityList;
		var other_cities = data.allCities || [];
		var all_cities = data.topCities || [];
		var cluster_cities = data.cityClusters || [];
		var $cityDropdown = $('#enquiry_city');
		var enqCity = $('#selectCity_Template').html();
		var enquiryCityHtmlData = _.template(enqCity);
		$cityDropdown.html(enquiryCityHtmlData({'all_cities': all_cities, 'other_cities': other_cities}));
		var clusterName = JSON.parse(localStorage.getItem('clusterName'));
		if(isSet(clusterName)){
			$('.home-search-page-bg #selectedCity').text(clusterName);
			$('#hSelectCity').find('span').attr("title", clusterName);
		}else {
			$('.home-search-page-bg #selectedCity').text(cityName);
		}
		$('.home-search-page-bg #selectCityLabel').hide();
		 var moreCity = $('#moreCity_Template').html();
		var htmlData = '';
		if (isSet(moreCity)) {
			htmlData = _.template(moreCity);
		}
		var temp = $('#city-select');
		$cityPopup.html(htmlData({
			'all_cities': all_cities,
			'other_cities': other_cities,
			'cluster_cities': cluster_cities,
			'keyword': citySearchKeyword
		}));
		$(document).on("citySelectPopup", function (event, data) {
			document.location.reload();
		});
	},

	initReplyPopUp: function () {
		$('#replyModal').on('show.bs.modal', function (e) {
			var tilesDetails = $(e.relatedTarget).data('listing');
			if (isSet(tilesDetails)) {
				tilesDetails = JSON.parse(atob(tilesDetails));
				gtmClickButton('REPLY_INIT', tilesDetails.id);
			}
			_tplRender('replyAd/replyAd',function(template){
				var tpl = _.template(template);
				var formData = replyFormController.init(tilesDetails);
				$("#replyBox_Container .newmodal").html(tpl({'tilesDetails': tilesDetails, 'formData': formData})); 
			})
		});
	},

	trendingLocality: function (){
		var cityId = getCookie('prefer_city_id');
		$.ajax({
			url: "/homes/re2/snbLocalityBanner?aj=1",
			method : "POST",
			data : {cityId:cityId},
			success: function(res) {
				var snbLocalityBanner = res.data;
					Home.localityBanner = res.data;
					if(snbLocalityBanner != null && snbLocalityBanner.length >= 1) {
						var dynamic_width = (isMobile())?snbLocalityBanner.length * 84.66 : snbLocalityBanner.length * 42.33;
						var dynamic_slot = 100 / snbLocalityBanner.length  ;
						var htmlData = `<div class="snbtoplocbox"><div class="snbtoploc"><div class="hottestI">Hottest Investment Destinations</div><div class="qh-control"><div id="leftangle" class="qhprev disable" onclick="Home.carouselControl('left')"><i class="icon-left"></i></div><div id="rightangle" class="qhnext" onclick="Home.carouselControl('right')"><i class="icon-right"></i></div></div><div class="snbtopbox"><div class="snbtopwrapper clearfix" id="QHcontrol" style="width: ${dynamic_width}%">`;
						$.each(snbLocalityBanner, function( key, value ) {
							var cityName = isSet(value.cityName) ? value.cityName : '';
							let utmParameters = '?utm_source=RealestateResponsiveUI&utm_medium=SnB&utm_campaign=TopLocality';
							var localityUrl = `/homes/residential-new-projects-for-sale-in-${isSet(value.localityName)?replaceSpacetounderscore(value.localityName):''}-${isSet(value.cityName)?replaceSpace(value.cityName):''}-${isSet(value.localityId)?value.localityId:''}${utmParameters}`;
							htmlData += `<div class="col-qh-slot" style="width: ${dynamic_slot}%"><div class="snbtoptile"><a onclick="addEventTile('RealEstate', 'QH_home', 'QH_home_hotLocalities_${value.localityName}_click');" href="${localityUrl}" target="_blank" rel="noopener"><div class="aHpage">${isSet(value.localityName)?value.localityName:''}</div><div class="snbtopdes">${isSet(value.usp)?value.usp:''}</div><div class="snbtopdev"><div class="dopDev">Top Developers:</div><p class="snbtopname" title="${isSet(value.topDevelopers)?value.topDevelopers:''}">${isSet(value.topDevelopers)?value.topDevelopers:''}</p></div><div class="snbtopcount clearfix"><div class="stcinfo"><span>${isSet(value.propertyCount)?value.propertyCount : ''}</span><small>Properties</small></div><div class="stcinfo"><span>${isSet(value.projectCount)? value.projectCount : ''}</span><small>Projects</small> </div></div><div class="imintbtn">I'm Interested</div></a></div></div>`;
						});
						htmlData += `</div></div></div></div>`;
						//$("#MobsnbLocalityBanner").html(htmlData); 
						$("#snbLocalityBanner").html(htmlData);
						//$("#snbListLocalityBanner").html(htmlData);
					}else{
						document.getElementById('topLocality').classList.add('hide')
					} 
			}
		});
	},

	getSimilarProject : function (){
		var cityId = getCookie('prefer_city_id');
		var cityName = getCookie('new_prefer_city');
		$.ajax({
			   url: "/homes/re2/snbProjectBanner?aj=1",
			   method : "POST",
			   data : {cityId:cityId},
			   success: function(res) {
					var snbProBanner = res.data;
					if(snbProBanner != null && snbProBanner.length >= 1) {
						var htmlData = `<h2 class="headinghome">Top Projects in <b>${capitalizeFirstLetter(cityName)}</b></h2><div class="scrollm"><div class="snbadbanner">`;
						$.each(snbProBanner, function( key, value ) {
							var projUrl = "/homes/project/";
							var projectname = '';
							var projectId = isSet(value.id) ? replaceSpace(value.id) : '';
							projUrl += projectname =isSet(value.name) ? replaceSpace(value.name) : '';
							projUrl += isSet(value.localityName) ? "+" + replaceSpace(value.localityName) : '';
							projUrl += isSet(value.cityName) ? "+" + replaceSpace(value.cityName) : '';
							projUrl += "+" + value.id;
							projUrl += "?utm_source=quikr-snbpage-right&utm_campaigns=" + projectname + "&utm_medium=snbBanner";
							if(isSet(value.units) && value.units.length >=1 && value.type!== 'Plot' ) {
								var bedRoomsarr = [];
								var units = '';
								 $.each(value.units, function( key, bhkvalue ) {
									if(isSet(bhkvalue.bedRooms)) {
										bedRoomsarr.push(bhkvalue.bedRooms);
									}
								});
								bedRoomsarr = $.unique(bedRoomsarr);
								var units = bedRoomsarr.toString() + ' BHK ';
							} else {
								var units = "";
							}
							var imageUrl = isSet(value.bannerImage) ? "https://teja10.kuikr.com"+ value.bannerImage.replace("80x80", "300x300") : 'https://teja10.kuikr.com/restatic/image/tile-no-photo.jpg';
							htmlData += `<div class="tbtile-wrap"><div class="tbtile"><a onclick="addEventTile('RealEstate', 'QH_hp', 'QH_hp_trendingProjects_${value.name}_click');" href="${projUrl}" target="_blank" rel="noopener"><div class="tbtile-top"><img src="${imageUrl}" alt="${value.name}"></div><div class="tbtile-bottom"><div class="tbtitle">${value.name}</div><div class="nameLo"><span class="subline">${value.localityName}</span><span class="subline">${units}${value.type}</span></div><div class="tbprice"><i class="icon-rupee_2"></i>${getShortMoneyFormat(value.minimumPrice)} - <i class="icon-rupee_2"></i>${getShortMoneyFormat(value.maximumPrice)}</div></div></a></div></div>`
						});
						htmlData += `</div></div>`;
						$("#GridsnbProjectBanner").html(htmlData); 
					}else{
						// Container takes up space, removing it to clear the occupied space
						$('#GridsnbProjectBanner').parent().parent().remove();
					} 
				}
			});
	},

	getSpotLight : function (){
		var cityId = getCookie('prefer_city_id');
		$.ajax({
			url: "/homes/re2/spotlight/"+cityId+"?aj=1",
			method : "GET",
			success: function(res) {
				if(res && res.length){
					var id = {
						"ids":res.join()
					};
					$.ajax({
						url: "/homes/re2/getadbyid/"+globals.pageType+"?aj=1",
						method : "POST",
						data: id,
						success: function(data) {
							$("#homeSpotlight").html(data); 
						}
					});
				} else {
					$("#homeSpotlight").parent().remove();
				}
			}
		});
	},

	topCollections :function() {
		$.ajax({
			url: "/homes/re2/topCollections/"+globals.pageType+"?aj=1",
			method : "GET",
			success: function(data) {
				$("#collectionData").html(data); 
			}
		});
	},

	loadInterstitialAd: function(cityId) {
		$.ajax({
			url: '/homes/re2/dropins/interstitial?cityId=' + cityId,
			method : "GET",
			success: function(data) {
				$("#ad-interstitial").html(data);
				$("#ad-interstitial .modal").modal('show');
			}
		});
	},

	lazyLoadImages:function() {
		lozad('.lozad', {
			load: function(el) {
				if(el.classList.contains('imgbg')){
					let src = el.getAttribute('data-src')
					el.style.backgroundImage = `url(${src})`
				}else{
					el.src = el.dataset.src;
				}
				
			}
		}).observe()
	},

	initCityDescription:function() {
		const elDescriptionPopup = document.getElementById("city-description-popup");
		const elDescriptionSnippet = document.getElementById("city-description-snippet");

		if (elDescriptionPopup && elDescriptionSnippet) {
			if (elDescriptionPopup.children.length > 2) {
				const description = elDescriptionPopup.children.item(2).innerText.trim();
				// const description = elDescriptionPopup.innerText.trim();
				elDescriptionSnippet.innerText = (description.length) > 250? description.substring(0,350).concat("...") : description;
			}
		}
		//console.log(description);
	}
	//my function shld go here and shld be called before the page is rendered
}
Home.carouselControl =(angle)=>{
	let length = Home.localityBanner.length
	if(angle == 'right' && counter<=length-2){
		leftangle.classList.remove('disable');
		Home.translateR = (-100/length)*counter
		QHcontrol.style.transform = "translateX("+Home.translateR+"%)";
		counter = counter+1
		if(counter == length-1){
			rightangle.classList.add('disable')
		}
	}
	if(angle == 'left' && counter>1){
		rightangle.classList.remove('disable');
		Home.translateR = Home.translateR + (100/length)
		QHcontrol.style.transform = "translateX("+Home.translateR+"%)";
		counter = counter-1
		if(counter === 1){
			leftangle.classList.add('disable');
		}
	}

}

Home.stepSelected = 0
function connectswitch() {
	var city = getCookie("new_prefer_city");
	$("#qccity").val(city);
	$("#qccityplaceholder").html('');
	if (city) {
		$("#city_error").hide();
	} else {
		$("#city_error").show();
	}
	$(".showOverlay").css('display', 'block');
}

function crossClick() {
	$(".showOverlay").css('display', 'none');
}

function goNext(step, bool) {
	Home.elements.next();
}

window.addEventTile = (action,label,value)=> {
	gaService.addEvent(action,label,value);
}
function gaEventSportLight(slot) {
	gaService.addEvent('link-RealestateResponsiveUI','HP_Real-Estate','click_spotlight_slot_'+cityName+'_'+slot);
}
function getShareUrl(type, name, id) {
	var url;
	var shareUrl = window.location + "/blog"  + "/" + name + "%2B" +id;
	switch (type) {
		case "FcBk":
			url = "https://www.facebook.com/sharer/sharer.php?u=" + shareUrl;
			break;
		case "Twt":
			url = "https://twitter.com/home?status=" + shareUrl;
			break;
		case "gplus":
			url = "https://plus.google.com/share?url=" + shareUrl;
			break;
		case "Linked":
			url="https://www.linkedin.com/shareArticle?mini=true&url=" + shareUrl;
			break;
		case "watsapp":
			url="https://whatsapp://send?text=" + shareUrl;
			break;
	}

	window.open(url);
	return true;
};


//Show interstitial Banner (13 Dec 2019)
function showinterstitial() {
	if(!isSet(sessionStorage.getItem('interstitial2019')) && globals.interstitial == "1") {
		document.getElementById('bannerpopup').click();
		sessionStorage.setItem('interstitial2019', true);
		let timeleft = 7;
	    let downloadTimer = setInterval(function(){
	    timeleft--;
	    document.getElementById("bannercoutdown").textContent = timeleft;
	    if(timeleft == 0){
	    	document.getElementById('bannerpopupclose').click();
	    }
	    if(timeleft <= 0)
	        clearInterval(downloadTimer);
	    },1000);
	}
}

function gaEventTrending(slot) {
	gaService.addEvent('link-RealestateResponsiveUI','HP_Real-Estate','qa_trending_project_click');
	gaService.addEvent('link-RealestateResponsiveUI','HP_Real-Estate','click_trending_project_'+slot);
}


function gaEventTrendingBuilder(slot){
	gaService.addEvent('link-RealestateResponsiveUI','HP_Real-Estate','qa_trending_builder_click');
	gaService.addEvent('link-RealestateResponsiveUI','HP_Real-Estate','click_trending_builder_'+slot);
}

window.redirctInnerblog = (slug,id) => {
	window.location =  '/homes/blog/' + slug + '+' +id;
}

$(".showmorec a").click (function(){
	$('.table-responsive').css({"max-height" : "5000px", "overflow-y": "auto"}); 
	$('.showmorec').hide();
});

$(".hlangle").click(function () {
	var $header = $(this);
	$header.toggleClass('flip');
	var $content = $header.parent().next();
	$('.hlsubhead').not($content).slideUp(300); 
	$(".hlangle").not($header).removeClass('flip');
	$content.slideToggle(300);
});

window.cancelBubble = (e)=> {
 var evt = e ? e:window.event;
	evt.stopPropagation();
	citySelectController.filterCityEnquiryBox('enquiry_city input');
}

window.homeCommonGa = (type,name)=>{
	var page_type = globals.pageType.toLowerCase();
	gaService.addEvent('RealEstate', 'QH_'+page_type , 'QH_'+page_type+'_'+type+'_'+name+'_click');
}

window.toggleFooterList = (el) =>{
	var parent = el.parentNode;
	el.style.display = 'none';
	if(el.classList.contains('more')){
		parent.querySelector('.less').style.display = 'block'
	}else{
		parent.querySelector('.more').style.display = 'block'
	}
	parent.classList.toggle('open');
}

$(function () {
	Home.init();
	Home.lazyLoadImages();
});
$(window).on('load', function () {
	Home.initReplyPopUp();
	if(!isMobile() && cityId && cityName){
		Home.trendingLocality();
		Home.getSimilarProject();
	}

	// Load the interstitial ad for the city
	if (cityId && cityId == 23) {
		showinterstitial();
	}

	Home.topCollections();
	if(connectionCheck()){
		Home.getSpotLight();
	}
	gtmHome();
	adServicesInit();
	resumeAd();
	searchSuggest();

	//QPUSH NOTOFICATION
	/** 
	WebPush.prototype.showGenericPreRequestModal = showGenericPreRequestModal;
	const config = { vertical: 'homes', context: 'notification' };
	const x = new WebPush(config);

	// showGenericPreRequestModal prototype function has to be imported. (See above)
	x.initialize()
	    .then(subscription => {
	    if (!subscription) {
	        // You could either choose to display your own permission UX or directly display the native popup, so trigger requestPermission accordingly
	        x.canTriggerCustomDialog()
	            .then(() => x.showGenericPreRequestModal())
	            .catch(() => {});
	        }
	    })
	    .catch(() => {});
	    */
	// Initialize the city description section
	Home.initCityDescription();
});
