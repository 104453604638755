export const formValidator = function () {

    var factory = {};
    
    var mobileNumberPattern = function (isdCode) {
        return {
            test: function (value) {
                var re = (isdCode === 91) ? /^[6789]\d{9}$/ : /^[0-9]{8,14}$/;
                return re.test(value);
            }
        };
    };

    var emailPattern = function (value) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(value);
    };

    var namePattern = function (value){
        var re = /^[a-zA-Z\s.]+$/;
        return re.test(value);
    };

    factory.isNameValid = function () {
        var name = $(this).val();
        var valid = false, isEmpty = false;
        var $nameWrapper = $(this).closest('.nameWrapper');
        if(name){
            valid = namePattern(name);
        }
        else{
            valid = false;
            isEmpty = true;
        }
        if(!valid){
            $nameWrapper.find('.error-container').removeClass('hide');
            if(isEmpty){
                $nameWrapper.removeClass('open');
                $nameWrapper.find('.name_required').siblings().hide().end().show();
            }
            else{
                $nameWrapper.addClass('open');
                $nameWrapper.find('.error-container').removeClass('hide');
                $nameWrapper.find('.name_pattern').siblings().hide().end().show();
            }
        }
        else{
            $nameWrapper.find('.error-container').addClass('hide');
            $nameWrapper.addClass('open');
        }
        return valid;
    };

    factory.isEmailValid = function () {
        var email = $(this).val();
        var valid = false, isEmpty = false;
        var $emailWrapper = $(this).closest('.emailWrapper');
        if(email){
            valid = emailPattern(email);
        }
        else{
            valid = false;
            isEmpty = true;
        }
        if(!valid){
            $emailWrapper.find('.error-container').removeClass('hide');
            if(isEmpty){
                $emailWrapper.removeClass('open');
                $emailWrapper.find('.email_required').siblings().hide().end().show();
            }
            else{
                $emailWrapper.addClass('open');
                $emailWrapper.find('.error-container').removeClass('hide');
                $emailWrapper.find('.email_pattern').siblings().hide().end().show();
            }
        }
        else{
            $emailWrapper.find('.error-container').addClass('hide');
            $emailWrapper.addClass('open');
        }
        return valid;
    };

    factory.isMobileNoValid = function () {
        var mobileNo = $(this).val();
        var elPhoneWrapper = $(this).closest('#replyotpshow');
        var elPhoneCode = elPhoneWrapper.find('[name=phonecode]');
        var valid = false, isEmpty = false;
        var $isdcode = $(this).closest('.isdcode');

        // Check if NRI ISD code is selected
        if ('+91' !== elPhoneCode.val()) {
            isEmpty = !mobileNo.trim();
            valid = !isEmpty && /^[0-9]{4,12}$/.test(mobileNo);
        } else {
            if (mobileNo) {
                var isdCodeSelected = $isdcode.find('.stdcode select').val() || 91;
                valid = mobileNumberPattern(parseInt(isdCodeSelected)).test(mobileNo);
                isEmpty = false;
            } else {
                valid = false;
                isEmpty = true;
            }
        }

        if (!valid) {
            $isdcode.find('.error-container').removeClass('hide');
            if (isEmpty) {
                $isdcode.find('.mobileNo_required').siblings().hide().end().show();
            }
            else {
                $isdcode.find('.mobileNo_pattern').siblings().hide().end().show();
                $isdcode.addClass('open');
            }
        }
        else {
            $isdcode.find('.error-container').addClass('hide');
            $isdcode.addClass('open');
        }
        return valid;
    };
    factory.isNameEmpty = function () {
        var name = $(this).val();
        var $nameWrapper = $(this).closest('.nameWrapper');
        if(name || document.activeElement == this){
            $nameWrapper.addClass('open');
        }
        else{
            $nameWrapper.removeClass('open');
        }
    };

    factory.booknowMobileValid = function () {
        var mobileNo = $(this).val();
        var valid = false, isEmpty = false;
        var $mobileWrapper = $(this).closest('.mobileWrapper');

        isEmpty = !mobileNo.trim();
        valid = !isEmpty && /^[0-9]{4,12}$/.test(mobileNo);

        if(mobileNo){
            // var isdCodeSelected = $isdcode.find('.stdcode select').val() || 91;
            var isdCodeSelected = 91;
            valid = mobileNumberPattern(parseInt(isdCodeSelected)).test(mobileNo);
            isEmpty = false;
        }
        else{
            valid = false;
            isEmpty = true;
        }
        if(!valid){
            $mobileWrapper.find('.error-container').removeClass('hide');
            if(isEmpty){
                $mobileWrapper.removeClass('open');
                $mobileWrapper.find('.mobileNo_required').siblings().hide().end().show();
            }
            else{
                $mobileWrapper.addClass('open');
                $mobileWrapper.find('.error-container').removeClass('hide');
                $mobileWrapper.find('.mobileNo_pattern').siblings().hide().end().show();
            }
        }
        else{
            $mobileWrapper.find('.error-container').addClass('hide');
            $mobileWrapper.addClass('open');
        }
        return valid;
    }

    factory.booknowDateValid = function () {
        var dateVal = $(this).val();
        var valid = false, isEmpty = false;
        var $dateWrapper = $(this).closest('.dateWrapper');

        isEmpty = !dateVal.trim();
        valid = !isEmpty;
        if(!valid){
            $dateWrapper.find('.error-container').removeClass('hide');
            if(isEmpty){
                $dateWrapper.removeClass('open');
                $dateWrapper.find('.date_required').siblings().hide().end().show();
            }
        }
        else{
            $dateWrapper.find('.error-container').addClass('hide');
            $dateWrapper.addClass('open');
        }

        return valid;
    }

    factory.booknowAgreeValid = function () {
        var agree = $(this).is(':checked');
        var valid = false, isChecked = false;
        var $agreeWrapper = $(this).closest('.agreeWrapper');

        valid = isChecked = agree;
        if(!valid){
            $agreeWrapper.find('.error-container').removeClass('hide');
            if(isChecked){
                $agreeWrapper.removeClass('open');
                $agreeWrapper.find('.agree_required').siblings().hide().end().show();
            }
        }
        else{
            $agreeWrapper.find('.error-container').addClass('hide');
            $agreeWrapper.addClass('open');
        }
        return valid;
    }

    $(document).on('change', 'div.agreeWrapper input', factory.booknowAgreeValid);
    $(document).on('keyup change', 'div.dateWrapper input', factory.booknowDateValid);
    $(document).on('keyup change', 'div.mobileWrapper input', factory.booknowMobileValid);
    $(document).on('keyup change', 'div.isdcode input', factory.isMobileNoValid);
    $(document).on('keyup change', 'div.emailWrapper input', factory.isEmailValid);
    $(document).on('keyup change focus', 'div.nameWrapper input', factory.isNameValid);

    return factory;

}();

// $(function () {
//     $(document).on('keyup change', 'div.isdcode input', formValidator.isMobileNoValid);
//     $(document).on('keyup change', 'div.emailWrapper input', formValidator.isEmailValid);
//     $(document).on('keyup change focus', 'div.nameWrapper input', formValidator.isNameEmpty);
// });