// TODO: Pending removal of the $filter service created by angular.
import {currencyFilter} from '../utils/common'
export const mailService = function () {

    var factory = {};

    factory.getPropertyCampaignUserContent = function (obj) {
        var mail = '<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">' +
            '<html xmlns="http://www.w3.org/1999/xhtml">' +
            '<head>' +
            '<meta name="viewport" content="width=device-width;initial-scale=1.0; user-scalable=1;" />' +
            '<meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />' +
            '<meta http-equiv="X-UA-Compatible" content="IE=edge" />' +
            '<title>Good news! we have a lead for you</title>' +
            '</head>' +
            '<body style="-webkit-font-smoothing:antialiased; -webkit-text-size-adjust:none; margin:0; padding:0; font-family: Helvetica, Arial, sans-serif;">' +
            '<!--[if (gte mso 9)|(IE)]>' +
            '<table width="600" align="center" bgcolor="#f9f9f9">' +
            '<tr>' +
            '<td>' +
            '<![endif]-->' +
            '<table width="100%" cellpadding="0" cellspacing="0" border="0" align="center" style="font-family: Helvetica, Arial, sans-serif; max-width:600px; background-color:#f9f9f9;color:#666666;text-align:left; padding:0px 2% 10px 2%; background:url(http://teja3.kuikr.com/restatic/image/communication/mailer-bg.jpg) repeat #f9f9f9;">' +
            '<tr>' +
            '<td height="20"></td>' +
            '</tr>' +
            '<tr>' +
            '<td height="43" valign="top">' +
            '<table width="100%" cellspacing="0" cellpadding="0" border="0" align="center" style="max-width:530px;" class="devicetable">' +
            '<tr>' +
            '<td align="left" height="43"><a href="http://www.quikr.com/homes" target="_blank" rel="noopener"><img src="http://teja3.kuikr.com/restatic/image/communication/logo-quikr-homes.png" alt="QuikrHomes" width="152"  height="30" border="0" style="margin:0; display:block; font-family:Helvetica, Arial, sans-serif; color:#007ebe; font-size: 20px; text-align:center; font-weight:bold;"/></a></td>' +
            '</tr>' +
            '</table>' +
            '</td>' +
            '</tr>' +
            '<tr>' +
            '<td height="10"></td>' +
            '</tr>' +
            '<tr>' +
            '<td>' +
            '<table width="100%" align="center" cellspacing="0" cellpadding="0" border="0" style="max-width:530px; width:100%!important;  background:#FFFFFF; border:1px solid #e3e3e3; border-radius: 3px;">' +
            '<tr>' +
            '<td height="35"></td>' +
            '</tr>' +
            '<tr>' +
            '<td align="center"><img src="http://teja3.kuikr.com/restatic/image/communication/user.gif" width="40" height="40" border="0" alt="Good news! we have a lead for you" style="margin:0; display:block;"/></td>' +
            '</tr>' +
            '<tr>' +
            '<td align="center" style="font-size:25px; color:#444444; padding:10px 20px 0px; font-family: Helvetica, Arial, sans-serif;">Good news! we have a lead for you</td>' +
            '</tr>' +
            '<tr>' +
            (obj.redirect ? '<td align="center" style="color:#008BCF; padding:0px 20px 0px; font-size:15px; font-family: Helvetica, Arial, sans-serif;"><span style="color:#444444; font-size:15px; font-family: Helvetica, Arial, sans-serif; text-decoration:none;">for</span> <a href="' + obj.redirect + '" style="color:#008BCF; font-size:15px; font-family: Helvetica, Arial, sans-serif; text-decoration:none;">"' + obj.title + '"</a></td>' : '') +
            '</tr>' +
            '<tr>' +
            '<td height="10"></td>' +
            '</tr>' +
            '<tr>' +
            '<td align="center" height="2" style="font-size:1px; line-height:1px;">' +
            '<table width="140" align="center" border="0" cellpadding="0" cellspacing="0" bgcolor="#008BCF">' +
            '<tbody>' +
            '<tr><td height="2" style="font-size:1px; line-height:1px;"></td></tr>' +
            '</tbody>' +
            '</table>' +
            '</td>' +
            '</tr>' +
            '<tr>' +
            '<td height="45"></td>' +
            '</tr>' +
            '<tr>' +
            '<td>' +
            '<table width="100%" align="center" cellspacing="0" cellpadding="0" border="0" style="padding:0px 20px; text-align:left;">' +
            '<tr>' +
            '<td style="font-size:20px; color:#444444; font-family: Helvetica, Arial, sans-serif;">Hello' + (obj.toName ? ' ' + obj.toName : '') + ',</td>' +
            '</tr>' +
            '<tr>' +
            '<td height="10"></td>' +
            '</tr>' +
            '<tr>' +
            '<td style="font-size:12px; color:#444444; line-height: 19px; font-family: Helvetica, Arial, sans-serif;">Good news! We have the following <span style="font-size:12px; color:#444444; font-family: Helvetica, Arial, sans-serif; font-weight:bold;">lead</span> for you.</td>' +
            '</tr>' +
            '<tr>' +
            '<td height="20"></td>' +
            '</tr>' +
            '</table>' +
            '</td>' +
            '</tr>' +
            '<tr>' +
            '<td align="center" style="padding:0 20px;">' +
            '<table width="100%" cellpadding="0" cellspacing="0" border="0" style="padding:0px;">' +
            '<tr>' +
            '<td>' +
            '<table width="100%" cellpadding="0" cellspacing="0" border="0" style="border:1px solid #f5f5f5; border-radius:3px; ">' +
            '<tr>' +
            '<td align="center" style="text-align:left; vertical-align:top;">' +
            '<table width="100%" cellpadding="0" cellspacing="0" border="0" bgcolor="#f2f2f2" style="border-collapse:collapse; border-spacing:0; padding:0px 0 0px 0; ">' +
            '<tr>' +
            '<td height="15"></td>' +
            '</tr>' +
            '<tr>' +
            '<td>' +
            '<table align="center" width="100%" cellspacing="0" cellpadding="0" border="0" style="font-size:0;">' +
            '<tbody>' +
            (obj.userName ? '<tr><td style="padding:0px; margin:0; color:#444444; font-weight:600; font-size:16px; font-family: Helvetica, Arial, sans-serif; text-align:left;"><p style="padding:0px; margin:0px 20px; color:#444444; font-weight:600; font-size:16px; font-family: Helvetica, Arial, sans-serif; ">' + obj.userName + '</p></td></tr>' : '') +
            '<tr>' +
            '<td style="padding:0px 20px;">' +
            '<table width="100%" cellpadding="0" cellspacing="0" border="0">' +
            '<tbody><tr>' +
            '<td style="display:inline-block; vertical-align:middle;">' +
            '<table align="left" width="220" cellspacing="5" cellpadding="0" border="0">' +
            '<tbody><tr>' +
            '<td align="left" width="25"><img src="http://teja3.kuikr.com/restatic/image/communication/remin-phone-icon.gif" width="20" height="20" alt="phone-icon" border="0" title="phone"></td>' +
            '<td align="left" style="font-size:14px; color:#008BCF; line-height: 22px; padding:0 0 0 0px; font-family: Helvetica, Arial, sans-serif;">' + obj.userMobile + '</td>' +
            '</tr>' +
            '</tbody></table>' +
            '</td>' +
            '<td style="display:inline-block; vertical-align:middle;">' +
            '<table align="left" width="223" cellspacing="5" cellpadding="0" border="0">' +
            '<tbody><tr>' +
            (obj.userEmail ? '<td align="left" width="25" valign="top"><img src="http://teja3.kuikr.com/restatic/image/communication/remind-message-icon.gif" width="25" height="18" alt="message-icon" border="0" title="phone"></td><td align="left" style="font-size:14px; color:#008BCF; line-height: 22px; padding:0 0 0 0px; font-family: Helvetica, Arial, sans-serif; word-break:break-all;"><a href="mailto:' + obj.userEmail + '" style="color:#008BCF; text-decoration:none;">' + obj.userEmail + '</a></td>' : '') +
            '</tr>' +
            '</tbody></table>' +
            '</td>' +
            '</tr>' +
            '</tbody></table>' +
            '</td>' +
            '</tr>' +
            '</tbody></table>' +
            '</td>' +
            '</tr>' +
            '<tr>' +
            '<td height="15"></td>' +
            '</tr>' +
            '</table>' +
            '</td>' +
            '</tr>' +
            '</table>' +
            '</td>' +
            '</tr>' +
            '<tr>' +
            '<td height="10"></td>' +
            '</tr>' +
            '</table>' +
            '</td>' +
            '</tr>' +
            '<tr>' +
            '<td height="20"></td>' +
            '</tr>' +
            '<tr>' +
            '<td style="font-size:12px; font-family: Helvetica, Arial, sans-serif; color:#444444; line-height:normal; padding:0px 20px; text-align:left;">' +
            'Please call the lead immediately, as they are likely to be best served right away.<br>' +
            '<p style="font-size:12px; font-family: Helvetica, Arial, sans-serif; color:#444444; line-height:normal; margin:20px 0 0px; text-align:left;">Here are the <span style="font-size:12px; color:#444444; font-family: Helvetica, Arial, sans-serif; font-weight:bold;">requirement details</span> as captured <span style="font-size:12px; color:#444444; font-family: Helvetica, Arial, sans-serif; font-weight:bold;">automatically by our system</span> by at ' + obj.time + '.</p>' +
            '</td>' +
            '</tr>' +
            '<tr>' +
            '<td height="10"></td>' +
            '</tr>' +
            '<tr>' +
            '<td>' +
            '<table cellpadding="0" cellspacing="0" border="0" width="100%">' +
            '<tbody>' +
            '<tr>' +
            '<td height="15"></td>' +
            '<td width="13" height="15"></td>' +
            '<td height="15"></td>' +
            '<td height="15"></td>' +
            '</tr>' +
            (obj.lookingFor ? '<tr><td width="2%"></td><td align="center" width="8%" valign="top" style="padding-top:3px;"><img src="http://teja3.kuikr.com/restatic/image/communication/tick-bullet.gif" width="13" height="13" border="0" alt="Posted an Ad" style="margin:0; display:block;"></td><td align="left" width="80%" style="padding-right:10px;"><p style="font-size:12px; color:#444444; line-height:19px; font-family: Helvetica, Arial, sans-serif; margin:0; padding:0 0 5px 0;"><span style="font-size:12px; color:#444444; font-family: Helvetica, Arial, sans-serif; font-weight:bold;">Looking for:</span> ' + obj.lookingFor + '</p></td><td width="4%"></td></tr>' : '') +
            '<tr>' +
            '<td height="7"></td>' +
            '<td height="7" ></td>' +
            '<td height="7"></td>' +
            '<td height="7"></td>' +
            '</tr>' +
            (obj.budget ? '<tr><td width="2%"></td><td align="center" width="8%" valign="top" style="padding-top:3px;"><img src="http://teja3.kuikr.com/restatic/image/communication/tick-bullet.gif" width="13" height="13" border="0" alt="Posted an Ad" style="margin:0; display:block;"></td><td align="left" width="80%" style="padding-right:10px;"><p style="font-size:12px; color:#444444; line-height:19px; font-family: Helvetica, Arial, sans-serif; margin:0; padding:0 0 5px 0;"><span style="font-size:12px; color:#444444; font-family: Helvetica, Arial, sans-serif; font-weight:bold;">Budget:</span> ' + currencyFilter(obj.budget) + '</p></td><td width="4%"></td></tr>' : '') +
            '<tr>' +
            '<td height="7"></td>' +
            '<td height="7" ></td>' +
            '<td height="7"></td>' +
            '<td height="7"></td>' +
            '</tr>' +
            '<tr>' +
            '<td width="4%"></td>' +
            '<td align="center" width="8%" valign="top" style="padding-top:3px;"><img src="http://teja3.kuikr.com/restatic/image/communication/tick-bullet.gif" width="13" height="13" border="0" alt="Posted an Ad" style="margin:0; display:block;"></td>' +
            '<td align="left" width="80%" style="padding-right:10px;">' +
            '<p style="font-size:12px; color:#444444; line-height:19px; font-family: Helvetica, Arial, sans-serif; margin:0; padding:0 0 5px 0;">Please call the prospect, he/she is interested and wants to know more details</p>' +
            '</td>' +
            '<td width="4%"></td>' +
            '</tr>' +
            '<tr>' +
            '<td height="15"></td>' +
            '<td height="15"></td>' +
            '<td height="15"></td>' +
            '<td height="15"></td>' +
            '</tr>' +
            '</tbody>' +
            '</table>' +
            '</td>' +
            '</tr>' +
            '<tr>' +
            '<td height="10"></td>' +
            '</tr>' +
            '<tr>' +
            '<td style="font-size:12px; font-family: Helvetica, Arial, sans-serif; color:#444444; line-height:normal; padding:0px 20px; text-align:left;">' +
            'Do help us to serve your requirement in a better way! Write to us at <a href="mailto:customerservice@quikr.com" style="font-size:12px; font-weight:bold; color:#008BCF; font-family: Helvetica, Arial, sans-serif; text-decoration:none;">customerservice@quikr.com</a> or call our contact centre at <span style="font-size:12px; font-family: Helvetica, Arial, sans-serif; color:#444444; line-height:normal; font-weight:bold;">080-33707222</span> for a Quikr resolution!' +
            '</td>' +
            '</tr>' +
            '<tr>' +
            '<td height="30"></td>' +
            '</tr>' +
            '<tr>' +
            '<td style="font-size:12px; font-family: Helvetica, Arial, sans-serif; color:#444444; line-height:normal; padding:0px 20px; text-align:left;">' +
            'Warm Regards,</td>' +
            '</tr>' +
            '<tr>' +
            '<td height="10"></td>' +
            '</tr>' +
            '<tr>' +
            '<td style="font-size:12px; font-family: Helvetica, Arial, sans-serif; color:#444444; line-height:normal; padding:0px 20px; text-align:left;">' +
            '<strong style="font-size:12px; font-family: Helvetica, Arial, sans-serif; color:#444444; line-height:normal;">QuikrHomes </strong><br>' +
            'Aasaan hai Badalna<br>' +
            '</td>' +
            '</tr>' +
            '<tr>' +
            '<td height="20"></td>' +
            '</tr>' +
            '</table>' +
            '</td>' +
            '</tr>' +
            '<tr>' +
            '<td height="10"></td>' +
            '</tr>' +
            '<tr>' +
            '<td align="center" style="color:#444444; font-size:12px; font-family: Helvetica, Arial, sans-serif;">' +
            '1000+ Cities &nbsp; <span style="color:#444444; font-size:12px; font-family: Helvetica, Arial, sans-serif;">|</span>  &nbsp; 70+ Categories &nbsp; <span style="color:#444444; font-size:12px; font-family: Helvetica, Arial, sans-serif;">|</span>  &nbsp; 1Cr+ Listings' +
            '</td>' +
            '</tr>' +
            '<tr>' +
            '<td height="20"></td>' +
            '</tr>' +
            '<tr>' +
            '<td align="center" >' +
            '<table  cellpadding="0" cellspacing="0" align="center" border="0">' +
            '<tr>' +
            '<td width="91"><a href="https://play.google.com/store/apps/details?id=com.quikr&amp;referrer=af_tranid%3D3S99EN98WQXX6ZR3%26pid%3Dmailer" target="_blank" rel="noopener" ><img src="http://teja3.kuikr.com/restatic/image/communication/android-icon.gif" width="91" height="31"  border="0" alt="Android Play Store"/></a></td>' +
            '<td width="8"></td>' +
            '<td width="91"><a href="https://itunes.apple.com/in/app/quikr-free-local-classifieds/id632051273?mt=8" target="_blank" rel="noopener" ><img src="http://teja3.kuikr.com/restatic/image/communication/apple-icon.gif" width="91" height="31"  border="0" alt="Apple Play Store"/></a></td>' +
            '<td width="8"></td>' +
            '<td width="91"><a href="http://www.windowsphone.com/en-us/store/app/quikr-buy-sell/6d36aa77-19a0-4adc-af15-0496dcc05557" target="_blank" rel="noopener" ><img src="http://teja3.kuikr.com/restatic/image/communication/windows-icon.gif" width="91" height="31"  border="0" alt="Window Store"/></a></td>' +
            '</tr>' +
            '</table>' +
            '</td>' +
            '</tr>' +
            '<tr>' +
            '<td height="20"></td>' +
            '</tr>' +
            '<tr>' +
            '<td align="center" >' +
            '<table  cellpadding="0" cellspacing="0" align="center" border="0">' +
            '<tr>' +
            '<td width="27"><a href="https://www.facebook.com/QuikrFans" target="_blank" rel="noopener">' +
            '<img src="http://teja3.kuikr.com/restatic/image/communication/facebook.gif" width="27" height="27"  border="0" alt="Facebook"/></a> </td>' +
            '<td width="6"></td>' +
            '<td width="27"><a href="https://twitter.com/Quikr" target="_blank" rel="noopener">' +
            '<img src="http://teja3.kuikr.com/restatic/image/communication/twitter.gif" width="27" height="27"  border="0" alt="Twitter"/></a></td>' +
            '<td width="6"></td>' +
            '<td width="27"><a href="https://plus.google.com/+quikr/posts" target="_blank" rel="noopener">' +
            '<img src="http://teja3.kuikr.com/restatic/image/communication/gplus.gif" width="27" height="27"  border="0" alt="Google Plus"/></a></td>' +
            '<td width="6"></td>' +
            '<td width="27"><a href="https://in.linkedin.com/company/quikr" target="_blank" rel="noopener">' +
            '<img src="http://teja3.kuikr.com/restatic/image/communication/linkedin.gif" width="27" height="27"  border="0" alt="Linkedin"/></a></td>' +
            '<td width="6"></td>' +
            '<td width="27"><a href="https://www.youtube.com/user/quikrclassifieds" target="_blank" rel="noopener">' +
            '<img src="http://teja3.kuikr.com/restatic/image/communication/youtube.gif" width="27" height="27"  border="0" alt="Youtube"/></a></td>' +
            '</tr>' +
            '</table>' +
            '</td>' +
            '</tr>' +
            '<tr>' +
            '<td height="9"></td>' +
            '</tr>' +
            '<tr>' +
            '<td align="center"  style="font-size:11px; font-family: Helvetica, Arial, sans-serif; color:#444444;">' +
            '<p style="margin:0;padding:3px 0 0px; font-size:11px; font-family: Helvetica, Arial, sans-serif; line-height:13px;">You are receiving this mail from Quikr India Pvt.  You can always <a href="http://www.quikr.com/MyQuikr?action=alertsdetails&from=emailunsub&aid=UjJTMwE7UGpXaAc/VzBTNgVi&allowmail=c2FuaXNoQHF1aWtyLmNvbQ%3D%3D&allowid=NTYzMzA3NTU%3D" style=" font-size:11px; font-family: Helvetica, Arial, sans-serif; color:#008BCF;text-decoration:none;" target="_blank" rel="noopener">Unsubscribe.</a></p>' +
            '<p style="margin:0;padding:3px 0; font-size:11px; font-family: Helvetica, Arial, sans-serif;  line-height:13px;">For any assistance, visit our <a href="http://www.quikr.com/help/" style=" font-size:11px; font-family: Helvetica, Arial, sans-serif; color:#007ebc;text-decoration:none;"  target="_blank" rel="noopener">Help center</a> or write to us at <a href="mailto:support@quikr.com" style=" font-size:11px; font-family: Helvetica, Arial, sans-serif; color:#008BCF;text-decoration:none;" target="_blank" rel="noopener">support@quikr.com.</a></p>' +
            '</td>' +
            '</tr>' +
            '<tr>' +
            '<td align="center" style="font-size:11px; font-family: Helvetica, Arial, sans-serif; color:#008BCF; line-height:13px;">&copy; www.quikr.com</td>' +
            '</tr>' +
            '</table>' +
            '<!--[if (gte mso 9)|(IE)]>' +
            '</td>' +
            '</tr>' +
            '</table>' +
            '<![endif]-->' +
            '</body>' +
            '</html>';

        return mail;
    };

    return factory;
}();
