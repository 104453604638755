import {getterSetter,currencyFilter} from '../utils/common'
import {searchActionService} from '../services/searchActionService'
import {getLoggedInUser} from '../loginService'
var UserId;
var shortlistedItems = [];
var isShortlisted;
var formattedListings = [];
var formattedProjects = [];
var formattedBuilders = [];
var shortlistHtml='';
var defaultTileImage = staticImageHost+'restatic/image/tile-no-photo.jpg';
var login;
var gaPageName = gaPageName;
var userId;
var propertySnippet = globals.propertySnippet && JSON.parse(atob(globals.propertySnippet));

getLoggedInUser.then((result) => {
    if(result && result.data && result.data.user){
        getterSetter.set( 'loginData', result);
        getterSetter.set( 'loginstatus', true);
        var userdata = getterSetter.get('loginData');
            if (!userdata.error) {
            	UserId = userdata.data.user.id;
				login = true;
            }
    }else{
        getterSetter.set( 'loginstatus', false);
    }
})

export function getAllShortlistedItems()
{
	var data = {
		userId: UserId
	};

	$.ajax({
		url: "/homes/re2/fetchShortlists?aj=1",
		method: "POST",
		data:{data:data},
		success: function (data) {
			data = JSON.parse(data);
			if(data){
				var shortlistedResponse = data.data;
				for (var sl = 0; sl < shortlistedResponse.length; sl++) {
					var short_listing = shortlistedResponse[sl];
					$("#activeshortlist_"+short_listing.entityId).addClass( "active");	
				}

				shortlistedItems=data.data;
				PUB_SUB.emit('favourites_count', shortlistedItems.length);
			}else{
				shortlistedItems=[];
				PUB_SUB.emit('favourites_count', shortlistedItems.length);
			}
		}
	});
}


function getEntityDetails(id,callback) {
	var data = {
		userId: id
	};
	$.ajax({
		url: "/homes/re2/getEntityDetails?aj=1",
		method: "POST",
		data:{data:data},
		success: function (data) {
			let response = JSON.parse(data);

			if (response.data) {
				if(Array.isArray(response.data.shortlistedProjectsList)){
					for(var i=0;i<response.data.shortlistedProjectsList.length;i++){
						addProjectsToEntityDetailsObject(response.data.shortlistedProjectsList[i]);
					}
				}
				
				if(Array.isArray(response.data.shortlistedListingsList)){
					for(var i=0;i<response.data.shortlistedListingsList.length;i++){
						addListingsToEntityDetailsObject(response.data.shortlistedListingsList[i]);
					}
				}
	
				if(Array.isArray(response.data.shortListedBuildersList)){
					for(var i=0;i<response.data.shortListedBuildersList.length;i++){
						addBuildersToEntityDetailsObject(response.data.shortListedBuildersList[i]);
					}
				}
	
				callback();
			}
		}
	});
}

function getActiveShortlistedItems(data) {
	return data.filter(function(obj) {
		return obj.shortlistStatus;
	});
}

window.toggleShortlist = (entityId, entityName)=> {
	if(entityId) {
		var data = {
			entityName: entityName,
			entityId: entityId
		};
		if(UserId){
			data["userId"] = UserId;
		}
		if(isShortlisted) {
			unShortlistItem(data);
		} else {
			shortlistItem(data);
		}
	}
}

window.checkShortlistOnPageLoad = (entityId, entityName)=> {
	var thisEntityId = typeof entityId === 'undefined' ? entityid : entityId;
	if(shortlistedItems) {
		for(var i = 0; i < shortlistedItems.length; i++) {
			if(parseInt(shortlistedItems[i].entityId, 10) === parseInt(thisEntityId, 10)) {
				isShortlisted = true;
				break;
			} else {
				isShortlisted = false;
			}
		}
	}
	gaService.addEvent('button-RealestateResponsiveUI', 'SnB_Project_Browse_Real-Estate', 'click_project_shortlist');
}

window.shortlistGa = function(isQAP = false) {
    if(globals.pageType === 'SNB' && (snbFilterController.filter.data.isQAP || isQAP) ) {
		gaService.addEvent('SNB_Real-QuikrPG_Assured', 'SNB_Real-QuikrPG_Assured_shortlist', 'SNB_Real-QuikrPG_Assured_shortlist_init');
	} else if(globals.pageType === 'VAP' && propertySnippet.availableFor === 'pg' && (propertySnippet.isQAP || propertySnippet.isGrabHouse)) {
		gaService.addEvent('VAP_Real-QuikrPG_Assured', 'VAP_Real-QuikrPG_Assured_shortlist', 'VAP_Real-QuikrPG_Assured_shortlist_init');
    } else {
    	gaService.addEvent('RealEstate', 'QH_'+ globals.pageType , 'QH_' + globals.pageType + '_ads_shortlist_click');
    }
}

window.gaOnShortlistClick = (cat,action,label)=> {
	if(shortlistedItems.length > 0) {
		gaService.addEvent(cat, action, label);
	}
}

window.gaOnShortlistClickItem = (cat,action,label,entityId,entityName)=> {
	if(isShortlisted){
		label = "delete_"+label;
	}
	else{
		label = "add_"+label;
	}
	var searchAction = searchActionService.getAction();
	var gaAction = (action.split("_"))[0] + "_" + searchAction + "_Real-Estate";
	gaService.addEvent(cat, gaAction, label);
}


function shortlistItem(data) {
	$.ajax({
		url: "/homes/re2/shortlist?aj=1",
		method: "POST",
		data: {data: data},
		success: function (response) {
			if(response) {
				if(globals.pageType === 'SNB' && snbFilterController.filter.data.isQAP ) {
					gaService.addEvent('SNB_Real-QuikrPG_Assured', 'SNB_Real-QuikrPG_Assured_shortlist', 'SNB_Real-QuikrPG_Assured_shortlist_success');
				} else if(globals.pageType === 'VAP' && propertySnippet.availableFor === 'pg' && (propertySnippet.isQAP || propertySnippet.isGrabHouse)) {
					gaService.addEvent('VAP_Real-QuikrPG_Assured', 'VAP_Real-QuikrPG_Assured_shortlist', 'VAP_Real-QuikrPG_Assured_shortlist_success');
				}

				isShortlisted = true;
				$("#activeshortlist_"+data.entityId).addClass( "active");

				var shortlist_object = {
					entityId: data.entityId,
					entityName: data.entityName
				};

				shortlistedItems.push(shortlist_object);

				PUB_SUB.emit('favourites_count', shortlistedItems.length);
			}
		}
	});
}

function unShortlistItem(data) {
	$.ajax({
		url: "/homes/re2/unshortlist?aj=1",
		method: "POST",
		data: {data: data},
		success: function (response) {
			if(response) {
				isShortlisted = false;

				$("#activeshortlist_"+data.entityId).removeClass( "active");

				shortlistedItems = shortlistedItems.filter(function(obj) {
					return parseInt(obj.entityId,10)!==parseInt(data.entityId,10);
				});
				if(shortlistedItems.length > 0) {
					PUB_SUB.emit('favourites_count', shortlistedItems.length);
				} else {
					document.querySelector('#h_fav > span').innerHTML = 0;
				}
			}
		}
	});	
	
}

function addListingsToEntityDetailsObject(res) {
	if(res) {
		var data = {};
		data.id = res.listingId;
		data.img = constructUrls(res.images[0]);
		data.price = currencyFilter(res.price);
		data.locality = res.locality;
		data.city = res.city;
		data.area = res.area + ' Sqft.';
		data.bhk = parseInt(res.bhk) > 4 ? '4+' : parseInt(res.bhk);
		data.postedBy = res.postedBy === 'Individual' ? 'Owner' : res.postedBy;
		data.link = '/homes/listing/' + res.listingId;
		var listings = formattedListings;
		listings.push(data);
		formattedListings = listings;
	}
}

String.prototype.replaceAll = function(search, replacement) {
	var target = this;
	return target.replace(new RegExp(search, 'g'), replacement);
};

function addProjectsToEntityDetailsObject(res) {
	if(res) {
		var data = {};
		data.id = res.projectId;
		data.url = '/homes/project/' + res.name.replaceAll(' ','-').toLowerCase()
					+ '+' + res.localityName.replaceAll(' ','-').toLowerCase()
					+ '+' + res.cityName.replaceAll(' ','-').toLowerCase()
					+ '+' + res.projectId;

		data.img = res.images.length ?  res.images[0] : defaultTileImage;
		data.name = res.name;
		data.price = currencyFilter(res.minPrice);
		data.locality = res.localityName;
		data.city = res.cityName;
		var projects = formattedProjects;
		projects.push(data);
		formattedProjects = projects;    
	}
}

function addBuildersToEntityDetailsObject(res) {
	if(res) {
		var data = {};
		data.id = res.builderId;
		data.url = '/homes/builder/' + res.name.replaceAll(' ','-').toLowerCase()+ '+' + res.builderId;
		data.img = res.imageUrl.length ? staticImageHost+ res.imageUrl[0]['imageUrl'] : defaultTileImage;
		data.name = res.name;
		data.completedProjects = res.completedProjects;
		data.upcomingProjects = res.upcomingProjects;
		var builders = formattedBuilders;
		builders.push(data);
		formattedBuilders = builders;
	}
}

function constructUrls(imageUrl) {
	var imageUrls = {};
	if(imageUrl && imageUrl.length){
		var stringLength = imageUrl.length;
		var lastStaticIndex = imageUrl.lastIndexOf('nr');
		var lastDecimalIndex = imageUrl.lastIndexOf('.');
		var largeImageAvailable = imageUrl.lastIndexOf('lg');
		if (-1 !== largeImageAvailable)imageUrls.large = staticImageHost + imageUrl.substr(0,  lastStaticIndex) + '_lg' + imageUrl.substr(lastDecimalIndex, (stringLength - 1));
	} else {
		imageUrls.large = defaultTileImage;
	}
	return imageUrls;
}

PUB_SUB.on('favourites_icon_click', function(){
	var page_type = globals.pageType.toLowerCase();
	gaService.addEvent('RealEstate', 'QH_'+page_type , 'QH_'+page_type+'_header_shortlist_click');
	if(document.getElementsByClassName('shortmenulist').length){
		var shortlistHeaderPopup = document.getElementsByClassName('shortmenulist')[0];
		if(shortlistHeaderPopup.style.display === 'block') {
			shortlistHeaderPopup.style.display = 'none';
			return false;
		} else if(shortlistedItems.length > 0){
			shortlistHeaderPopup.style.display = 'block';
		}	
	}

	formattedListings = [];
	formattedProjects = [];
	formattedBuilders = [];

	if (getterSetter.loginstatus) {
	    var userdata = getterSetter.get('loginData');
	    UserId = userdata.data.user.id;
	}
	getEntityDetails(UserId,setshortlistHtml);

});


function setshortlistHtml() {
	var shortlistHtml='';
	shortlistHtml+='<i class="icon-heart"></i>';
	shortlistHtml+='<span class="notification-knob" data-type="fav_count">'+shortlistedItems.length+'</span>';

	shortlistHtml+='<ul class="dropdown-menu shortmenulist" style="display: block;">';
		shortlistHtml+='<li>';
			shortlistHtml+='<button type="button" onclick="shortlistOpen();" class="visible-xs shortclose">×</button>';
			if(formattedListings.length || formattedProjects.length || formattedBuilders.length){
				shortlistHtml+='<div class="shortlistedata">';
					if(formattedListings.length){
						shortlistHtml+='<div class="shorttype">';
							shortlistHtml+='<h4>Shortlisted Properties</h4>';
						
							for (var i = 0; i < formattedListings.length; i++) {
								var listing = formattedListings[i];
					
								shortlistHtml+='<div>';
									shortlistHtml+='<div class="shortlisted-prop clearfix">';
										shortlistHtml+='<a href="/homes/listing/'+listing.id+'" onclick="gaOnShortlistClick(\'link-RealestateResponsiveUI\','+gaPageName+',\'click_listing_shortlist\')">';
										shortlistHtml+='<div class="shortlist-prop-box">';

										shortlistHtml+='<div class="shortlist-img">';
											shortlistHtml+='<img src="'+listing.img.large+'" alt="" />';
										shortlistHtml+='</div>';

										shortlistHtml+='<div class="shortextlg"><i class="icon-rupee_2"></i>';
											if(listing.price){
												shortlistHtml+='<span>'+listing.price+'</span>';							
											}else{
												shortlistHtml+='<span> On Request</span>';
											}
										shortlistHtml+='</div>';

										shortlistHtml+='<div class="shortextsm">'+listing.locality+', '+listing.city+'</div>';
										
										shortlistHtml+='<div class="shortextmd">';
											if(listing.bhk){
												shortlistHtml+='<span>'+listing.bhk+' BHK</span>';							
											}
											if(listing.area){
												shortlistHtml+='<span>'+ " " +listing.area+'</span>';
											}
										shortlistHtml+='</div>';

										shortlistHtml+='</div>';
										shortlistHtml+='</a>';
										

										shortlistHtml+='<div class="shortlist tippos active" onclick="checkShortlistOnPageLoad('+listing.id+',\'LISTING\');gaOnShortlistClickItem(\'button-RealestateResponsiveUI\',\'SnB_Search_Real-Estate,shortlist_listing\','+listing.id+',\'LISTING\'); toggleShortlist('+listing.id+',\'LISTING\');">';		
											shortlistHtml+='<span class="icon-basic-heart-filled short"></span>';
											shortlistHtml+='<span class="icon-basic-heart-filled short"></span>';
											shortlistHtml+='<span class="icon-basic-heart-filled shorted"></span>';
										shortlistHtml+='</div>';

										shortlistHtml+='<div class="shortowner">'+listing.postedBy+'</div>';

										shortlistHtml+='</div>';

								shortlistHtml+='</div>';
							}
						shortlistHtml+='</div>';
					}		

					if(formattedProjects.length){
						shortlistHtml+='<div class="shorttype">';
							shortlistHtml+='<h4>Shortlisted Projects</h4>';
							for (var p = 0; p < formattedProjects.length; p++) {
								var project = formattedProjects[p];
								if(project.img){
									project.img = project.img.replace('80x80','700x700');
								}
								shortlistHtml+='<div>';
									shortlistHtml+='<div class="shortlisted-proj clearfix" style="background: url('+project.img+') no-repeat center center; background-size: 100% 80px;">';
										shortlistHtml+='<div class="grayoverlay">&nbsp;</div>';
										shortlistHtml+='<a href="'+project.url+'" onclick="gaOnShortlistClick(\'link-RealestateResponsiveUI\','+gaPageName+',\'click_project_shortlist\')">';
											shortlistHtml+='<div class="shortextlg clrwht">'+project.name+'</div>';

										shortlistHtml+='<div class="shortextsm clrwht">'+project.locality+', '+project.city+'</div>';
										
										shortlistHtml+='<div class="projprice"><i class="icon-rupee_2"></i>';
											if(project.price){
												shortlistHtml+='<span>'+project.price+' Onwards</span>';							
											}else{
												shortlistHtml+='<span>&#8377; On Request</span>';
											}
										shortlistHtml+='</div>';

										shortlistHtml+='</a>';

										shortlistHtml+='<div class="shortlist tippos active" onclick="checkShortlistOnPageLoad('+project.id+',\'PROJECT\');gaOnShortlistClickItem(\'button-RealestateResponsiveUI\',\'SnB_Search_Real-Estate\',\'shortlist_project\','+project.id+',\'PROJECT\'); toggleShortlist('+project.id+',\'PROJECT\');">';		
											shortlistHtml+='<span class="icon-basic-heart-filled short"></span>';
											shortlistHtml+='<span class="icon-basic-heart-filled shorted"></span>';
										shortlistHtml+='</div>';

									shortlistHtml+='</div>';

								shortlistHtml+='</div>';
							}
						shortlistHtml+='</div>';
					}	

					if(formattedBuilders.length){
						shortlistHtml+='<div class="shorttype">';
							shortlistHtml+='<h4>Shortlisted Builders</h4>';
							for (var b = 0; b < formattedBuilders.length; b++) {
								var builder = formattedBuilders[b];
								
								shortlistHtml+='<div>';
									shortlistHtml+='<div class="shortlisted-prop clearfix ">';
										shortlistHtml+='<a href="'+builder.url+'" onclick="gaOnShortlistClick(\'link-RealestateResponsiveUI\','+gaPageName+',\'click_builder_shortlist\')">';
										shortlistHtml+='<div class="shortlist-prop-box">';

										shortlistHtml+='<div class="shortlist-img">';
											shortlistHtml+='<img src="'+builder.img+'" alt="" />';
										shortlistHtml+='</div>';

										shortlistHtml+='<div class="shortextlg">'+builder.name+'</div>';
										

										shortlistHtml+='<div class="shortextsm"><b>'+builder.upcomingProjects+'</b> Upcoming Projects</div>';
										shortlistHtml+='<div class="shortextsm"><b>'+builder.completedProjects+'</b> Completed Projects</div>';
										
										shortlistHtml+='</div>';
										shortlistHtml+='</a>';

										shortlistHtml+='<div class="shortlist tippos active" onclick="checkShortlistOnPageLoad('+builder.id+',\'BUILDER\');gaOnShortlistClickItem(\'button-RealestateResponsiveUI\',\'SnB_Search_Real-Estate\',\'shortlist_builder\','+builder.id+',\'BUILDER\'); toggleShortlist('+builder.id+',\'BUILDER\');">';
											shortlistHtml+='<span class="icon-basic-heart-filled short"></span>';
											shortlistHtml+='<span class="icon-basic-heart-filled shorted"></span>';
										shortlistHtml+='</div>';

									shortlistHtml+='</div>';
								shortlistHtml+='</div>';
							}
						shortlistHtml+='</div>';
					}

				shortlistHtml+='</div>';
			}
		shortlistHtml+='</li>';
	shortlistHtml+='</ul>';

	$("#h_fav").html(shortlistHtml);
}
