import {isSet} from '../utils/common'
import {dataService} from '../services/dataService'
import {getCookie,removeCookie,setCookie} from '../services/cookieService'
import citiesConstant from '../constants/citiesConstant'

window.citySelectController = function () {

	var citySelectController = {}
	var modalInstance;
	var localitySearchData = '';

	var citySelectFlag = true;
	var headsearch = "form-inline hidden-xs hidden-sm";
	var searchicon = "fa icon-search";
	var data = dataService.cityList;
	var other_cities = data.allCities || [];
	var all_cities = citiesConstant.data.topCities || [];
	var cluster_cities = citiesConstant.data.cityClusters || [];
	var $cityPopup = $('#city-select-modal');
	var temp = $('#city-select');

	var $cityDropdown = $('#enquiry_city');

	var moreCity = $('#moreCity_Template').html();
	var htmlData = '';
	if (isSet(moreCity)) {
		htmlData = _.template(moreCity);
	}
	var enqCity = $('#selectCity_Template').html();
	var enquiryCityHtmlData = '';
	if (isSet(enqCity)) {
		enquiryCityHtmlData = _.template(enqCity);
	}

	var prefer_city_id = !getCookie('prefer_city_id') ? "" : getCookie('prefer_city_id');
	var citySearchKeyword = '';

	citySelectController.init = function () {
		// when coming from quikr.com we only get the cityname and not the id.
		var cityName = getCookie('new_prefer_city');
		//if (cityName) {
		//	citySelectController.setCityID(all_cities, cityName);
		//}
		if(getCookie("new_prefer_city") == 'www'  && getCookie("prefer_city_id") && getCookie("prefer_city_id") !== 1) {
			var cityId = getCookie("prefer_city_id");
			var city_name = this.getCityName(all_cities,cityId);
			var city  = city_name.toLowerCase();
			setCookie('new_prefer_city', city);
			$("#hSelectCity").find('span').text(city_name);

		}else if(getCookie("new_prefer_city") == 'www' || getCookie("prefer_city_id")===1) {
			removeCookie('new_prefer_city');
			removeCookie('prefer_city_id');
			$('#selectCityLabel').text('Select City');
			citySelectController.citySelectPopup()
		}
		if (!isSet(prefer_city_id)) {
			var locPathObj = window.location.pathname;
			var cityObj = {};
			if ("/homes/property" == locPathObj.substr(0, locPathObj.lastIndexOf('/'))) {
				var urlArr = locPathObj.split('-');
				for (var counter = 0; counter < urlArr.length; counter++) {
					if (-1 != urlArr[counter].lastIndexOf('cid')) {
						cityObj.id = urlArr[counter].split('_').pop();

						if (cityObj.id && isSet(all_cities) && isSet(other_cities)) {
							var cities = all_cities.concat(other_cities);

							for (var i = 0; i < cities.length; i++) {
								if (cities[i].id == cityObj.id) {
									cityObj.name = cities[i].name;
								}
							}
						}
					}
				}

				if (!cityName && cityObj && cityObj.name && cityObj.id) {
					setCookie('new_prefer_city', cityObj.name.toLowerCase());
					setCookie('prefer_city_id', cityObj.id);
					$("#hSelectCity").find('span').text(cityObj.name);
					$("#selectCityLabel").text(cityObj.name);
					var city_name = cityObj.name;
					var entity_city = city_name.charAt(0).toUpperCase() + city_name.slice(1);
					$(".nav-select-city").css('display', 'none');
				}
			}
			else if ("/homes/city" == locPathObj.substr(0, locPathObj.lastIndexOf('/'))) {
				cityObj.name = locPathObj.split('/').pop();
				var entity_city = cityObj.name.charAt(0).toUpperCase() + cityObj.name.slice(1);
				if(cityObj.name && dataService.cityList && dataService.cityList.topCities && dataService.cityList.allCities) {
					var cities = dataService.cityList.topCities.concat(dataService.cityList.allCities);

					for (var i = 0; i < cities.length; i++) {
						if (cities[i].name.toLowerCase() == cityObj.name.toLowerCase()) {
							cityObj.id = cities[i].id;
							break;
						}
					}

					if (cityObj.id) {
						setCookie('new_prefer_city', cityObj.name.toLowerCase());
						setCookie('prefer_city_id', cityObj.id);
						$("#hSelectCity").find('span').text(entity_city);
					} else {
						 window.location = window.location.protocol + "//" + window.location.host + '/404Error';
					}
				}
			}
			else {
				setCity(all_cities);
				if (temp) {
					temp.modal({
						backdrop: 'static',
						keyboard: false
					});
				}
			}
		}
	}

	citySelectController.clearSearch = function () {
		gaService.addEvent('button-RealestateResponsiveUI', 'HP_Real-Estate', 'Click_City_Selection');
		var search = {
			name: ""
		}
	}

	citySelectController.setCityID = function(all_cities, cityName) {
		var allCities = all_cities.concat(citiesConstant.data.allCities);
		for (var i = 0; i < allCities.length; i++) {
			var city = allCities[i];
			if (typeof city === "object" && cityName ===  city.name.toLowerCase()) {
				prefer_city_id = city.id;
				setCookie('prefer_city_id', city.id);
				break;
			}
		}
	};

	citySelectController.getCityName = function(all_cities, cityId) {
		var allCities = all_cities.concat(citiesConstant.data.allCities);
		for (var i = 0; i < allCities.length; i++) {
			var city = allCities[i];
			if (typeof city === "object" && cityId ===  city.id) {
				return city.name;
				break;
			}
		}
	};


	function setCity(all_cities) {
		if ($cityPopup) {
			$cityPopup.html(htmlData({
				'all_cities': all_cities,
				'other_cities': other_cities,
				'cluster_cities': cluster_cities,
				'keyword': citySearchKeyword
			}));
		}
		if ($cityDropdown)
			$cityDropdown.html(enquiryCityHtmlData({'all_cities': all_cities, 'other_cities': other_cities, 'cluster_cities': cluster_cities}));
	}

	function setEnquiryBoxCity(all_cities) {
		$cityDropdown.html(enquiryCityHtmlData({'all_cities': all_cities, 'other_cities': other_cities, 'cluster_cities': cluster_cities}));
		$('#enquiry_city .search-select-city').addClass('open');
	}

	citySelectController.filterCity = function () {
		citySearchKeyword = $(this).val().toLowerCase();
		var filteredCities = [];
		if (isSet(citySearchKeyword)) {
			_.each(all_cities, function (city, k) {
				if (city.name.toLowerCase().indexOf(citySearchKeyword) > -1) {
					filteredCities.push(city);
				}
			});
			_.each(other_cities, function (city, k) {
				if (city.name.toLowerCase().indexOf(citySearchKeyword) > -1) {
					filteredCities.push(city);
				}
			});
			setCity(filteredCities);
		} else {
			setCity(all_cities);
		}


		//$("#citytextbox").focus().val($(this).val());

	}

	citySelectController.selectCity = function (id, name, siblingCities = null, childCities = null, clusterName = null) {
		var locPathVar = location.pathname;
		var noCitySelectSearch = false;
		var cityDetails = {
			"city": name,
			"id": id
		};

		if (siblingCities && Object.keys(JSON.parse(siblingCities)).length > 0) {
			setCookie("isCluster", false);
		}
		if(childCities){
			setCookie("isCluster", true);
			localStorage.setItem("clusterName", JSON.stringify(clusterName));

			let childCitiesList = JSON.parse(childCities);
			var childCitiesArray = Object.keys(childCitiesList).map((key) => ({'id': key, 'name': childCitiesList[key]}));
			  console.log(childCitiesArray);
			localStorage.setItem("clusterCities", JSON.stringify(childCitiesArray));
			
		}
		$(document).trigger("citySelectPopup", cityDetails);
		var prefer_city = name;
		var prefer_city_id = id;

		setCookie("new_prefer_city", name.toLowerCase());
		setCookie("prefer_city_id", id);
		var entity_city=name.charAt(0).toUpperCase() + name.slice(1);
		$("#query").attr("placeholder", "Search in "+ entity_city);
		if(clusterName){
			$('#selectedCity').text(clusterName);
			$('#selectedCity').attr("title", clusterName);
			$('#searchHomes').attr("placeholder", 'Search in '+ clusterName);
			$("#hSelectCity").find('span').text(clusterName);
			$('#hSelectCity').find('span').attr("title", clusterName);
		}else{
			$('#selectedCity').text(name);
			$('#searchHomes').attr("placeholder", 'Search in '+ entity_city);
		}
		$(".nav-select-city").css('display', 'none');
		if ((window.location.href).indexOf('/homes/city/') !== -1) {
		   window.location = window.location.protocol + "//" + window.location.host + '/homes';
		}
		if ((window.location.href).indexOf('/postad') !== -1) {
		   window.location.href=window.location.href;
		}
		if ("/homes" == locPathVar ) {
			let params = (new URL(document.location)).searchParams;
			let name = params.get("text");
			if(isSet(name)) {
				$.ajax({
					url: "/homes/re2/attributeDetect",
					dataType: "json",
					method: "POST",
					async: false,
					data: {keywords: name, categoryPgid: 20},
					success: function (data) {
						if (typeof data=='object' &&  data.statusCode == 200 && data.data != null) {
							let homeUrl = data.data.finalAbsoluteUrl;
							window.location.href = homeUrl;
						} else {
							window.location = window.location.protocol + "//" + window.location.host + '/homes/city/' + name.toLowerCase();
						}
					}
				});
			}
		} 
		temp.modal('toggle');
	}

	citySelectController.citySelectPopup = function () {
		modalInstance = $('#city-select').modal({
			backdrop: 'static',
			keyboard: false
		});
	};

	citySelectController.selectCityEnquiryBox = function (id, name) {
		var locPathVar = location.pathname;
		var noCitySelectSearch = false;
		var cityDetails = {
			"city": name,
			"id": id
		};
		setCookie("isCluster", false);
    	localStorage.removeItem('clusterName');
		$(document).trigger("citySelectPopup", cityDetails);

		var prefer_city = name;
		var prefer_city_id = id;

		setCookie("new_prefer_city", name.toLowerCase());
		setCookie("prefer_city_id", id);
		$('#selectedCity').text(name);
		$('#selectCityLabel').hide();
		if ("/homes/city" === locPathVar.substr(0, locPathVar.lastIndexOf('/')) || window.location.pathname === '/homes'){
			window.location.href = window.location.protocol + "//" + window.location.host + '/homes/city/' + name.toLowerCase();
		} 
		//		$rootScope.$broadcast('breadcrumbs');
		//$(document).trigger("gtm", [{city: name, city_id: id}]);
		//$(document).trigger("breadcrumbs");
	}

	citySelectController.filterCityEnquiryBox = function (enqval) {
		//alert( $(this).val());
		var enq_val=$('#'+enqval).val();
		citySearchKeyword = $('#'+enqval).val().toLowerCase();
		var filteredCities = [];
		if (isSet(citySearchKeyword)) {
			_.each(all_cities, function (city, k) {
				if (city.name.toLowerCase().indexOf(citySearchKeyword) > -1) {
					filteredCities.push(city);
				}
			});
			_.each(other_cities, function (city, k) {
				if (city.name.toLowerCase().indexOf(citySearchKeyword) > -1) {
					filteredCities.push(city);
				}
			});
			setEnquiryBoxCity(filteredCities);
		} else {
			setEnquiryBoxCity(all_cities);
		}
		$("#enquiry_city input").focus().val(enq_val);
		var city_name = getCookie('new_prefer_city');
		if(city_name){
			var entity_city=city_name.charAt(0).toUpperCase() + city_name.slice(1);
			$('#selectCityLabel').text(entity_city);
		}
	};

	citySelectController.toggleDisplay = function(elementToToggle){
		var element = $("#" + elementToToggle);
		if ($(element).is(":visible")){
			$(element).hide();
			$("#" + elementToToggle + "Btn").removeClass('active');
		}else {
			if(elementToToggle == 'isCityCollapsed'){
				$('#isClusterCityCollapsed').hide();
				$("#isClusterCityCollapsedBtn").removeClass('active');
			}else {
				$('#isCityCollapsed').hide();
				$("#isCityCollapsedBtn").removeClass('active');
			}
			$(element).show();
			$("#" + elementToToggle + "Btn").addClass('active');
		}
	};

	return citySelectController;

}();


$(function () {
	citySelectController.init();
	//\b(?=\w*[al])\w+\b
	var city_name = getCookie('new_prefer_city');
	if(city_name){
		var entity_city=city_name.charAt(0).toUpperCase() + city_name.slice(1);
		$('#selectCityLabel').text(entity_city);
	}

	$(document).on('keyup', '.popular-city-block input', citySelectController.filterCity);

	//$(document).on('keyup', '#enquiry_city input', citySelectController.filterCityEnquiryBox);

	$(document).on("citySelectPopup", function (event, data) {
		$('#selectCityLabel').text(data.city);
		//$('#selectCityLabel').hide();
	});
});
