import {isSet,connectionCheck} from '../utils/common'
import {getCookie} from '../services/cookieService'
var adServices = {

    init : function() {
        window.gQH  = window.gQH || {
        'cityname': getCookie('new_prefer_city') ? getCookie('new_prefer_city') : "",
        'catname': 'Realestate',
        'intentData': (intentData)?JSON.parse(intentData):[]
        };
        window.qdfp_vp = window.qdfp_vp || {
            id: "RE_"+globals.pageType.toUpperCase(),
            ptargeting: [
            {
                key: 'City',
                value: gQH.cityname
            },
            {
                key: 'Category',
                value: gQH.catname
            },
            {
                key: 'SubCat',
                value: gQH.intentData.type
            },
            {
                key:'bugget_low',
                value:gQH.intentData.priceMin || ''
            },
            {
                key:'bugget_high',
                value: gQH.intentData.priceMax || ''
            },
            {
                key:'locality',
                value:gQH.intentData.locality || ''
            }
            ],
            stargeting: {}
        };

        if($('#mon_interst').attr('slot') === 'mon_interstitial'){
            var interAd = localStorageService.get('interstitial');
            var count = 0;
            if(isSet(interAd)){
               count = parseInt(interAd) + 1;
            }
            localStorageService.set('interstitial',count,1);
            if(count % 5 != 1){
                $('.dfpadpopup').addClass('hide');
                return;
            }
            $('#top_m_ad_blocker').attr('style','display:block');
        }
    }

};
export const adServicesInit = ()=>{
    if(connectionCheck()){
        var gads = document.createElement('script');
        gads.async = true;
        gads.type = 'text/javascript';
        gads.src = QDFP_SCRIPT_URL;
        //gads.src = 'http://teja11.hpfstatic.local/public/mon/qapqdfp/dist/3.3.4/realestate/index.js';
        document.body.appendChild(gads);
        adServices.init();
    }
}