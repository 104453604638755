export function logInRegisterPopUp() {
    if (jQuery.trim(jQuery("#newlogindiv").html()) == "") {
        jQuery('body').css('overflow','hidden');
        var cssLoginModal = document.createElement('link');
        cssLoginModal.rel = 'stylesheet';
        cssLoginModal.href = jQuery('#login-css').val();
        $('.simple-overlay').append('<img src="https://teja8.kuikr.com/public/images/dist/ajax-loader.gif" >');
        $('.simple-overlay img').css({'position': 'absolute','left': 0,'right': 0,'margin': '0 auto','top': '110px'});
        $('.simple-overlay').css('display','block');

        var tmp_url = "/core/init-login-popup";
        var tmp_data = "source=new_popup";
        cssLoginModal.onload = function(){
            // Make Ajax Call to bring the HTML for Login Popup
            jQuery.ajax({
                url:  tmp_url,
                type: 'POST',
                data: tmp_data,
                dataType: "text"
            }).done(function(result) {
                jQuery('#newlogindiv').html(result);
                jQuery('#newlogindiv').find(".popup-content .content-interaction .nav.nav-pills li:first-child a").trigger("click");
                $('.simple-overlay').html('');
                jQuery('#signin_error').hide();
                jQuery("#newlogindiv").show();
                jQuery(".simple-overlay").show().addClass("login-modal-view");
                if($('.login-modal .login-first #FirstEmailOrMobile')){
                    var ele = $('.login-modal #FirstEmailOrMobile');
                    var eleVal = ele.val();
                    jQuery('.login-modal #FirstEmailOrMobile').focus();
                    ele.val(eleVal);
                    if($('.login-modal #fullnameFromPopUp').val()!=undefined){
                        $('.login-modal #fullnameFromPopUp').closest(".custom-form-controls").find("label").addClass("filled");
                    }if($('.login-modal #password').val()!=undefined){
                        $('.login-modal #password').closest(".custom-form-controls").find("label").addClass("filled");
                    }if($('.login-modal #emailidFromPopUp').val()!=undefined){
                        $('.login-modal #emailidFromPopUp').closest(".custom-form-controls").find("label").addClass("filled");
                    }if($('.login-modal #passWord').val()!=undefined){
                        $('.login-modal #passWord').closest(".custom-form-controls").find("label").addClass("filled");
                    }
                }
            });
        };
        document.getElementsByTagName('body')[0].appendChild(cssLoginModal);
    } else {
        $(".login-screen").hide();
        $(".login-screen-exp").show();
        jQuery("#newlogindiv").show();
        jQuery('#newlogindiv').find(".popup-content .content-interaction .nav.nav-pills li:first-child a").trigger("click");
        jQuery(".simple-overlay").show().addClass("login-modal-view");
    }
    $(".login-modal .social-login .email-verfication").hide();
    $(".login-modal .social-login .social-login-icons").show();
    $("body").css("overflow", "hidden");
}