export const urlBuilderService = function () {
    var factory = {};
    var protocol = window.location.protocol || "http:";
    factory.host = window.location.host || '';
    if (factory.host) {
        factory.host = protocol + "//" + factory.host;
    }

    factory.getBaseUrl = function () {
        if (!factory.host) {
            return null;
        }

        return factory.host;
    };

    factory.seoUrl = function(Url, replaceVar) {
        if (Url != undefined) {
            return Url.toLowerCase().trim().replace(/[^\w\s-]+/gi, ' ').replace(/ /g, replaceVar);
        }
    };

    factory.getHomesUrl = function () {
        if (!factory.host) {
            return null;
        }

        return factory.getBaseUrl() + '/homes/';
    };

    factory.getCityUrl = function (city) {
        if (!factory.host) {
            return null;
        }
        return factory.getBaseUrl() + '/homes/re2/city/' + city;
    };

    factory.getApartmentUrl = function (city, cityId, unitType, category) {
        if (!factory.host) {
            return null;
        }

        var url;
        if (category.toLowerCase() == unitType.toLowerCase()) {
            url = category;
        } else {
            url = category + '-' + unitType;
        }
        return factory.getBaseUrl() + '/homes/re2/property/' + url + '-for-sale-in-' + city.toLowerCase() + '-cid_' + cityId;
    };

    factory.getLocalityUrl = function (city, cityId, locality, lId, type, property, category) {
        if (!factory.host) {
            return null;
        }
        var types;
        if (!type || !locality) {
            return false;
        }

        if (type.toLowerCase() == 'pg') {
            types = 'for-pg_flatmates';
        } else {
            types = type.toLowerCase() + '-for-' + property.toLowerCase();
        }
        return factory.getBaseUrl() + '/homes/re2/property/' + category + '-' + types + '-in-' + locality.toLowerCase().replace(/ /g, '_') + '-' + city + '-cid_' + cityId + '-lid_' + lId;
    };

    factory.getBuilderUrl = function (dataObject, redirect) {
        if (!dataObject || !factory.host) {
            return null;
        }

        var url = factory.getHomesUrl() + 'builder/' + factory.seoUrl(dataObject.data.builderName) + '+' + dataObject.data.builderId;

        if (redirect) {
            $(document).attr('href', url);
        } else {
            return url;
        }
    };

    factory.getProjectUrl = function (dataObject, redirect) {
        if (!dataObject || !factory.host) {
            return null;
        }

        redirect = redirect || false;
        if (dataObject.data.page == 'deals') {
            var url = factory.getHomesUrl() + 'project/' + factory.seoUrl(dataObject.data.projectName) + '+' + factory.seoUrl(dataObject.data.locality) + '+' + factory.seoUrl(dataObject.data.city) + '+' + dataObject.data.projectId + '?offer=' + dataObject.data.page;
        } else {
            var url = factory.getHomesUrl() + 'project/' + factory.seoUrl(dataObject.data.projectName) + '+' + factory.seoUrl(dataObject.data.locality) + '+' + factory.seoUrl(dataObject.data.city) + '+' + dataObject.data.projectId;
        }
        if (redirect) {
            $(document).attr('href', url);
        } else {
            return url;
        }
    };

    factory.getIndividualUrl = function (dataObject, redirect) {
        if (!dataObject || !factory.host) {
            return null;
        }
        var usernameVal = dataObject.data.individualName;
        if(usernameVal.indexOf("@") !== -1) {
            usernameVal = usernameVal.substring(0, usernameVal.lastIndexOf("@"));
        }
        var url = factory.getHomesUrl() + 'individual/' + factory.seoUrl(dataObject.data.userType) + '/' + (usernameVal) + '+' + dataObject.data.individualId;

        if (redirect) {
            $(document).attr('href', url);
        } else {
            return url;
        }
    };

    factory.getLocalityPageUrl = function (dataObject, redirect) {
        if (!dataObject || !factory.host || !dataObject.data.localityId) {
            return null;
        }

        var url = factory.getHomesUrl() + factory.seoUrl(dataObject.data.localityName) + '+' + factory.seoUrl(dataObject.data.cityName) + '/' + dataObject.data.localityId;

        if (redirect) {
            $(document).attr('href', url);
        } else {
            return url;
        }
    };

    factory.getLocalityPageUrl = function (dataObject, redirect) {
        if (!dataObject || !factory.host) {
            return null;
        }

        var re = / /g;

        var localityName = dataObject.data.localityName.toLowerCase().replace(re, '_');
        localityName = factory.seoUrl(localityName);

        var cityName = dataObject.data.cityName.toLowerCase().replace(re, '_');
        cityName = factory.seoUrl(cityName);

        var url = factory.getHomesUrl() + 'locality/' + localityName + '-' + cityName + '/' + dataObject.data.localityId;

        if (redirect) {
            $(document).attr('href', url);
        } else {
            return url;
        }
    };

    factory.getBlogsHomeUrl = function () {
        if (!factory.host) {
            return null;
        }
        return factory.getHomesUrl() + 'blog/';
    };

    factory.getPostUrl = function (dataObject, redirect) {
        if (!factory.host) {
            return null;
        }

        var url = factory.getBlogsHomeUrl() + factory.seoUrl(dataObject.data.name) + '+' + dataObject.data.id;

        if (redirect) {
            $(document).attr('href', url);
        } else {
            return url;
        }
    };

    factory.getFestivalDetailsUrl = function (city) {
        if (!factory.host) {
            return null;
        }
        return factory.getHomesUrl() + 'festivalDetails?city=' + city;
    };

    return factory;

}();

