export const getSubCategory = function () {
    var catId = '';

    var factory = {};

    factory.subCatId = function (adType, catName) {
        if (!adType && !catName) return;
        if (adType == 'sell' || adType == 'buy' || adType == 'sale') {
            switch (catName) {
                case "Builder Floor":
                case "BuilderFloor":
                case "Apartment":
                case "ap":
                case "bf":
                    catId = 31;
                    break;
                case "Villa":
                case "vi":
                case "RowHouse":
                    catId = 271;
                    break;
                case "Office":
                case "of":
                case "sh":
                case "bu":
                case "Shop":
                case "Complex":
                    catId = 33;
                    break;
                case "Plot":
                case "cp":
                case "rp":
                case "Land":
                    catId = 217;
                    break;
            }
        } else if (adType == 'rent' || adType == 'rent_in') {

            switch (catName) {
                case "Builder Floor":
                case "BuilderFloor":
                case "Apartment":
                case "ap":
                case "bf":
                    catId = 32;
                    break;
                case "Villa":
                case "vi":
                    catId = 270;
                    break;
                case "Office":
                case "of":
                case "Shop":
                case "sh":
                case "bu":
                case "Complex":
                    catId = 215;
                    break;
            }
        } else if (adType == 'pg') {
            catId = 114;
        } else if (adType == 'flatmate') {
            catId = 138;
        }
        return catId;
    };

    factory.nodeId = function (cityId, catId, alertPayload, callBack) {
        dataService.getNodeId(cityId, catId).then(function (result) {
            var promiseObj;
            if (result) {
                alertPayload.subcatId = result.nodeId,
                    alertPayload.remoteAddr = result.remoteAddr,
                    alertPayload.userAgent = result.userAgent,
                    alertPayload.referrer = result.referrer
            }
            promiseObj = dataService.createAlert(alertPayload).then(function (res) {
                callBack(res);
            });
        });

    };

    return factory;

}();
