import {setCookie} from './cookieService'
import {set} from './localStorageService'
import citiesConstant from '../constants/citiesConstant'
export const dataService = function () {

    var dataSvc = {},
        GET_USER_API = '/homes/re2/auth',
        GET_TOP_CITIES_API = '/homes/cities?aj=1',
        GET_SUGGESTED_LOCLITY_SEARCH_API = '/homes/locality_suggest?aj=1',
        GET_SUGGESTED_PROJECTS_SEARCH_API = '/homes/postAd/projects?aj=1',
        GET_SUGGESTED_PROJECTS_LOCALITY_SEARCH_API = '/homes/re2/postAd/projectsLocalities?aj=1',
        GET_INDIVIDUAL_WITH_ID_API = '/homes/re2/individual/details/',
        GET_INDIVIDUAL_LISTINGS = '/homes/individual/listings?aj=1',
        GET_CALL_LOGGING_API = '/homes/callLogging?aj=1',
        GET_WONOBO_URL = '/homes/wonobo/getEmbeddableUrl?aj=1',
        GET_NEW_LOCALITY_SEARCH_API = '/homes/re2/locality/search?aj=1',
        SEND_MAIL_API = '/homes/re2/sendMail?aj=1',
        GET_USER_AD_CREDIT = '/homes/re2/userCredit/get?aj=1',
        UPDATE_USER_AD_CREDIT = '/homes/userCredit/update?aj=1',
        OTP_CLIENT_ID = 2,
        PROJET_CAMPAIGN_USER_DETAILS = '/homes/re2/project/campaignUserDetails/',
        GET_EAP_DATA = '/homes/getEapData/',
        GET_PRICE_VALUE = '/homes/re2/getPrice/',
        GET_USER_DETAILS = '/homes/getUserDetails?aj=1',
        ADD_MOBILE = '/homes/re2/addMobile?aj=1',
        GET_LOCALITY_FUZZY_DETAILS = '/homes/locality/fuzzy?aj=1',
        GET_NODEID_API = '/homes/re2/nodeId/',
        CREATE_ALERT_API = '/homes/re2/createAlert?aj=1',
        GET_SUB_CAT_ID = '/homes/re2/getSubCatId/',
        GET_ALL_TAGS = '/homes/blog/allTag?aj=1',
        GET_TOP_CATEGORY = '/homes/blog/topCategory?aj=1',
        detect_Email_Mobile_Conflict = '/homes/re2/detectEmailMobileConflict?aj=1',
        FTOKEN = '1233433333',
        POST_QUIKR_SCROW = '/homes/re2/isQuikrPayAvailable?aj=1';

    dataSvc.cityList = citiesConstant.data;
    dataSvc.projectCampaignUserDetails = null;

    dataSvc.addMobile = function (jsonData, callBacks) {
        $.ajax({
            url: ADD_MOBILE,
            type: "POST",
            data: jsonData,
            success: callBacks.success,
            error: callBacks.error
        });
    };

    dataSvc.getProjectData = function (jsonData, callBacks) {
        var deferred = $.Deferred();

        $.ajax({
            url: "/homes/re2/postAd/projects?aj=1",
            type: "POST",
            data: jsonData,
            success: function(data){
                deferred.resolve(data);
            },
            error:  function(data){
                deferred.reject(data);
            }
        });

        return deferred.promise();
    };

    dataSvc.getUserDetails = function (urls, callBacks) {
        $.ajax({
            url: GET_USER_DETAILS + urls,
            type: "GET",
            success: callBacks.success,
            error: callBacks.error
        });
    };

    dataSvc.getCities = function (callBacks) {
        $.ajax({
            url: GET_TOP_CITIES_API,
            type: "GET",
            success: callBacks.success,
            error: callBacks.error
        });
    };

    dataSvc.deleteAd = function (deleteAdJsonIndex) {
        var deferred = $.Deferred();
        $.ajax({
            url: "/homes/re2/deleteAdofaUser?aj=1",
            type: "POST",
            data: deleteAdJsonIndex,
            success: function(data){
                deferred.resolve(data);
            },
            error:  function(data){
                deferred.reject(data);
            }
        });
        return deferred.promise();
    };

    dataSvc.getSuggestedProjects = function (search_term, city_id, rca, callBacks) {
        $.ajax({
            url: GET_SUGGESTED_PROJECTS_SEARCH_API,
            type: "POST",
            data: {
                'st': search_term,
                'ci': city_id,
                'rca': rca
            },
            success: callBacks.success,
            error: callBacks.error
        });
    };

    dataSvc.getSuggestedProjectsLocalty = function (search_term, city_id, rca) {
        var deferred = $.Deferred();

        $.ajax({
            url: GET_SUGGESTED_PROJECTS_LOCALITY_SEARCH_API,
            type: "POST",
            data: {
                'st': search_term,
                'ci': city_id,
                'rca': rca
            },
            success: function(data){
                deferred.resolve(data);
            },
            error:  function(data){
                deferred.reject(data);
            }
        });

        return deferred.promise();
    };

    dataSvc.getSuggestedLocality = function (key, city, callBacks) {
        $.ajax({
            url: GET_SUGGESTED_LOCLITY_SEARCH_API,
            type: "POST",
            data: {
                'keyword': key,
                'city': city,
                'count': 10
            },
            success: callBacks.success,
            error: callBacks.error
        });
    };

    dataSvc.getSuggestedLocalityNew = function (key, city, callBacks) {
        $.ajax({
            url: GET_SUGGESTED_LOCLITY_SEARCH_API + "?query=" + key + "&cityId=" + city,
            type: "GET",
            success: callBacks.success,
            error: callBacks.error
        });
    };

    dataSvc.getLocalityFuzzyDetails = function (key, city, category, callBacks) {
        $.ajax({
            url: GET_LOCALITY_FUZZY_DETAILS,
            type: "POST",
            data: {
                "query": key,
                "cityId": city,
                "category": category
            },
            success: callBacks.success,
            error: callBacks.error
        });
    };

    dataSvc.getIndividualWithId = function (id, callBacks) {
        $.ajax({
            url: GET_INDIVIDUAL_WITH_ID_API + id,
            type: "GET",
            success: callBacks.success,
            error: callBacks.error
        });
    };

    dataSvc.getIndividualListingsWithId = function (id, callBacks) {
        $.ajax({
            url: GET_INDIVIDUAL_LISTINGS,
            type: "POST",
            data: {
                "userid": id
            },
            success: callBacks.success,
            error: callBacks.error
        });
    };

    dataSvc.sendMail = function (data, callBacks) {
        var deferred = $.Deferred();
        $.ajax({
            url: SEND_MAIL_API,
            type: "POST",
            data: data,
            success: function (data) {
                deferred.resolve(data);
            },
            error: function (data) {
                deferred.reject(data);
            }
        });
        return deferred.promise();
    };

    dataSvc.fetchBuilderDataAdmin = function (builderId, callBacks) {
        $.ajax({
            url: '/homes/re2/builder_details_admin/' + builderId,
            type: "GET",
            success: callBacks.success,
            error: callBacks.error
        });
    };

    dataSvc.getadviewcount = function (adId, premiumType, callBacks) {
        $.ajax({
            url: '/homes/re2/listing/getadviewcount?aj=1',
            type: "POST",
            data: {
                "id": adId,
                "Ptype": premiumType,
                "type": "ad"
            },
            success: callBacks.success,
            error: callBacks.error
        });
    };

    dataSvc.addadviewcount = function (adId, callBacks) {
        $.ajax({
            url: '/homes/re2/listing/addadviewcount?aj=1',
            type: "POST",
            data: {
                "id": adId,
                "type": "ad"
            },
            success: callBacks.success,
            error: callBacks.error
        });
    };

    dataSvc.saveLead = function (payload, callBacks) {
        var deferred = $.Deferred();
        $.ajax({
            url: '/homes/re2/saveLead?aj=1',
            type: "POST",
            data: payload,
            success: function (data) {
                deferred.resolve(data);
            },
            error: function (data) {
                deferred.reject(data);
            }
        });
    };

    dataSvc.pintotop = function (payload, callBacks) {
        var deferred = $.Deferred();
        $.ajax({
            url: '/homes/re2/getPinToTopPrice/'+payload,
            type: "GET",
            success: function(data){
                deferred.resolve(data);
            },
            error:  function(data){
                deferred.reject(data);
            }
        });
        return deferred.promise();
    };

    dataSvc.papSaveCall = function (payload, callBacks) {
        var deferred = $.Deferred();
        $.ajax({
            url: '/homes/re2/papSave?aj=1',
            type: "POST",
            data: payload,
            success: function(data){
                deferred.resolve(data);
            },
            error:  function(data){
                deferred.reject(data);
            }
        });
        return deferred.promise();
    };

    dataSvc.updateSaveCall = function (payload, callBacks) {
        var deferred = $.Deferred();
        $.ajax({
            url: '/homes/re2/papUpdate?aj=1',
            type: "POST",
            data: payload,
            success: function(data){
                deferred.resolve(data);
            },
            error:  function(data){
                deferred.reject(data);
            }
        });
        return deferred.promise();
    };

    dataSvc.sendSms = function (payload, callBacks) {
        var deferred = $.Deferred();
        $.ajax({
            url: '/homes/re2/sendSms?aj=1',
            type: "POST",
            data: payload,
            success: function (data) {
                deferred.resolve(data);
            },
            error: function (data) {
                deferred.reject(data);
            }
        });

        return deferred.promise();
    };

    dataSvc.enquiryProcess = function (payload, callBacks) {
        var deferred = $.Deferred();
        $.ajax({
            url: '/homes/re2/enquiryProcess?aj=1&_t='+FTOKEN,
            type: "POST",
            data: payload,
            success: function (data) {
                deferred.resolve(data);
            },
            error: function (data) {
                deferred.reject(data);
            }
        });

        return deferred.promise();
    };
    
    dataSvc.logCall = function (params, callBacks) {
        $.ajax({
            url: GET_CALL_LOGGING_API,
            type: "POST",
            data: params,
            success: callBacks.success,
            error: callBacks.error
        });
    };

    dataSvc.getEmbeddableUrl = function (params, callBacks) {
        $.ajax({
            url: GET_WONOBO_URL,
            type: "POST",
            data: params,
            success: callBacks.success,
            error: callBacks.error
        });
    };

    dataSvc.getPrice = function (city, key, callBacks) {
        var deferred = $.Deferred();

        $.ajax({
            url: GET_PRICE_VALUE + city + "/" + key,
            type: "GET",
            success: function(data){
                deferred.resolve(data);
            },
            error:  function(data){
                deferred.reject(data);
            }
        });

        return deferred.promise();
    };

    dataSvc.getSubCatId = function (city, globalId, callBacks) {
        var deferred = $.Deferred();

        $.ajax({
            url: GET_SUB_CAT_ID + city + "/" + globalId,
            type: "GET",
            success: function(data){
                deferred.resolve(data);
            },
            error:  function(data){
                deferred.reject(data);
            }
        });

        return deferred.promise();
    };

    dataSvc.getLocalitySearchNew = function (params, callBacks) {
        var deferred = $.Deferred();

        $.ajax({
            url: GET_NEW_LOCALITY_SEARCH_API,
            type: "POST",
            data: params,
            success: function (data) {
                deferred.resolve(data);
            },
            error: function (data) {
                deferred.reject(data);
            }
        });

        return deferred.promise();
    };

    dataSvc.fetchProjectCampaignUserDetails = function (projectId) {
        var deferred = $.Deferred();

        if (!projectId) {
            deferred.reject(null);
            return deferred.promise();
        }

        if (dataSvc.projectCampaignUserDetails) {
            deferred.resolve(dataSvc.projectCampaignUserDetails);
            return deferred.promise();
        }

        $.ajax({
            url: PROJET_CAMPAIGN_USER_DETAILS + projectId+"?aj=1",
            type: "GET",
            success: function (data) {
                deferred.resolve(data);
                dataSvc.projectCampaignUserDetails = data;
            },
            error: function (err) {
                deferred.reject(err);
            }
        });
        return deferred.promise();
    };

    dataSvc.saveAutoFillData = function (data) {
        data = data || {};
        if (data.autofill_contactname && data.autofill_contactname.length) setCookie("autofill_contactname", data.autofill_contactname);
        if (data.autofill_no && data.autofill_no.length) setCookie("autofill_no", data.autofill_no);
        if (data.autofill_email && data.autofill_email.length) setCookie("autofill_email", data.autofill_email);
    };

    dataSvc.saveDatalocaStorage = function (data) {
        data = data || {};
        if (data.autofill_contactname && data.autofill_contactname.length) set("autofill_contactname", data.autofill_contactname);
        if (data.autofill_no && data.autofill_no.length) set("autofill_no", data.autofill_no);
        if (data.autofill_email && data.autofill_email.length) set("autofill_email", data.autofill_email);
    };

    dataSvc.getUserAdCredit = function (params, callBacks) {
        var deferred = $.Deferred();

        $.ajax({
            url: GET_USER_AD_CREDIT,
            type: "POST",
            data: params,
            success: function (data) {
                deferred.resolve(data);
            },
            error: function (err) {
                deferred.reject(err);
            }
        });

        return deferred.promise();
    };

    dataSvc.getEapData = function (id, callBacks) {
        var deferred = $.Deferred();

        if (id) {
            $.ajax({
                url: GET_EAP_DATA + id,
                type: "GET",
                success: function (data) {
                    deferred.resolve(data);
                },
                error: function (err) {
                    deferred.reject(err);
                }
            });
        } else {
            deferred.reject(false);
        }

        return deferred.promise();
    };

    dataSvc.anonymousUser = function (urls, callBacks) {
        $.ajax({
            url: "/homes/re2/anonymousUser/" + urls,
            type: "GET",
            success: callBacks.success,
            error: callBacks.error
        });
    };

    dataSvc.getNodeId = function (cityid, catid, callBacks) {
        var deferred = $.Deferred();
        $.ajax({
            url: GET_NODEID_API + cityid + "/" + catid,
            type: "GET",
            success: function (data) {
                deferred.resolve(data);
            },
            error: function (err) {
                deferred.reject(err);
            }
        });
        return deferred.promise();
    };

    dataSvc.createAlert = function (data, callBacks) {
        var deferred = $.Deferred();
        $.ajax({
            url: CREATE_ALERT_API,
            type: "POST",
            data: data,
            success: function (data) {
                deferred.resolve(data);
            },
            error: function (err) {
                deferred.reject(err);
            }
        });
        return deferred.promise();
    };

/*
    dataSvc.getAllTags = function (callBacks) {
        $.ajax({
            url: GET_ALL_TAGS,
            type: "GET",
            success: callBacks.success,
            error: callBacks.error
        });
    };
*/

/*    dataSvc.getTopcategory = function (callBacks) {
        $.ajax({
            url: GET_TOP_CATEGORY,
            type: "GET",
            success: callBacks.success,
            error: callBacks.error
        });
    };*/

    dataSvc.detectEmailMobileConflict = function (data, callBacks) {
        var deferred = $.Deferred();
        $.ajax({
            url: detect_Email_Mobile_Conflict,
            type: "POST",
            data: data,
            success: function (data) {
                deferred.resolve(data);
            },
            error: function (err) {
                deferred.reject(err);
            }
        });
        return deferred.promise();
    };

    return dataSvc;
}();
