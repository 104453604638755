export const windowWidthService = function () {

    var width = {};
    var size = "";

    width.WindowWidth = (function () {
        if (window.innerWidth <= 480)
            size = "xs";   // for mobile phone
        else if (window.innerWidth > 480 && window.innerWidth < 768)
            size = "sm";   //for small devices like tablet
        else if (window.innerWidth > 768 && window.innerWidth <= 992)
            size = "md";   // for medium devices, like destop
        else if (window.innerWidth > 992)
            size = "lg";  //for large devices
        return size;
    })();

    width.channel = (function () {
        var channel = 'Website';
        if (window.innerWidth < 768) channel = 'MobileSite';
        return channel;
    })();

    width.PlatFormChannel = (function () {
        var PlatFormChannel = 'DESKTOP';
        if (window.innerWidth < 768) PlatFormChannel = 'MOBILE';
        return PlatFormChannel;
    })();
    width.isMobileSite = (function () {
        var isMobileSite = false;
        if (window.innerWidth < 768) isMobileSite = true;
        return isMobileSite;
    })();
    return width;
}();