require('./searchController.js')
require('../services/gaService.js')
require('../services/analytic.js')

import {isSet,seoUrl,intentType,propertyType,highlightMatches,isEmpty,storeSearchAndRedirect,convert} from '../utils/common'
import {getCookie,setCookie} from '../services/cookieService'
import {searchActionService} from '../services/searchActionService'
import {logInRegisterPopUp} from '../services/horizontalLoginService'

/** Set Default Sub - Category */
$(document).ready(function () {
    if(isSet(getCookie("new_prefer_city"))){
        var new_prefer_city=getCookie("new_prefer_city");
        var entity_city=new_prefer_city.charAt(0).toUpperCase() + new_prefer_city.slice(1);
        entity_city = (entity_city === 'Www')?'All India':entity_city;
    };
    var clusterName = JSON.parse(localStorage.getItem('clusterName'));
    if(isSet(clusterName)){
        $("#hSelectCity").find('span').text(clusterName);
        $('#hSelectCity').find('span').attr("title", clusterName);
    }
    $(document).on("citySelectPopup", function (event, data) {
            $("#hSelectCity").find('span').text(data.city);
            $('#selectCityLabel').hide();
            $("#selectCityLabel").text(data.city);
        });
        $(window).scroll(function() {
            if ($(this).scrollTop()) {
                $('.goto-top:hidden').stop(true, true).fadeIn();
            } else {
                $('.goto-top').stop(true, true).fadeOut();
            }
        });
        $('.goto-top').click(function() {
            $('html, body').animate({
                scrollTop : $("html").offset().top - 10
            },300);
        });
    $(document).click(function(e){
        if ($(e.target).is('.shortlistedata,.shortlistedata *')) {
            return;
        }
        else
        {
            if(document.getElementsByClassName('shortmenulist').length){
                var shortlistHeaderPopup = document.getElementsByClassName('shortmenulist')[0];
                if(shortlistHeaderPopup.style.display === 'block') {
                    shortlistHeaderPopup.style.display = 'none';
                    return false;
                }
            }
        }
    });
    $("#postAdBtn").attr("title","Post Free Ad");
    /* Search Bar Improvement Start */
    $("#query").on('keyup keypress', function(e) {
        var cityId = getCookie('prefer_city_id');
        var q = $("#query").val();
        var cityIds = '';
        var isClusterSet = getCookie('isCluster');
        if(isSet(isClusterSet) && isClusterSet == 'true'){
            var clusterCityList = JSON.parse(localStorage.getItem('clusterCities'));
            if(clusterCityList && Object.keys(clusterCityList).length > 0){
                var clusterCity = [];
                for(let i = 0; i < clusterCityList.length; i++){
                    clusterCity.push(clusterCityList[i].id);
                }
                cityIds = clusterCity.toString();
            }
        }
        var projectUl = '';
        var localityUl = '';
        var builderUl = '';
        var regionsUl = '';
        var zonesUl = '';
        var poisUl = '';
        var autosuggest = '';
        if(q.length >= 3) {
            $.ajax({
                url: "/homes/re2/autosuggest",
                method: "POST",
                data: {cityid: cityId, text: q, cityIds: cityIds || ''},
                success: function (data) {
                    if(!isEmpty(data)){
                        $.map(data, function (value, key) {
                            var labelvalue = "";
                            if (value.count >= 1 && value.category == 'LocalityProjects') {
                                labelvalue = value.label + "  (" + value.count + " Projects)";
                            }
                            else {
                                labelvalue = value.label;
                            }
                            if(value.category === 'Project' || value.category === 'LocalityProjects') {
                                projectUl = projectUl + '<li onclick="selectAutoSuggest(\'' + value.cityId + '\',\'' + value.category + '\',\'' + escape(value.label) + '\',\'' + value.locality + '\',\'' + value.id + '\');">' + highlightMatches(escape(labelvalue), escape(q)) + ', ' + value.cityName + ' <span>Project</span></li>'; 
                            } else if(value.category === 'Builder') {
                                builderUl = builderUl + '<li onclick="selectAutoSuggest(\'' + cityId + '\',\'' + value.category + '\',\'' + escape(value.label) + '\',\'' + value.locality + '\',\'' + value.id + '\');">' + highlightMatches(escape(labelvalue), escape(q)) + ' <span>Builder</span></li>';
                            } else if(value.category === 'Location'){
                                localityUl = localityUl + '<li onclick="selectAutoSuggest(\'' + value.cityId + '\',\'' + value.category + '\',\'' + escape(value.label) + '\',\'' + value.locality + '\',\'' + value.id + '\');">' + highlightMatches(escape(labelvalue), escape(q)) + ', ' + value.cityName + '<span>Locality</span></li>'; 
                            } else if(value.category === 'Regions'){
                                regionsUl = regionsUl + '<li onclick="selectAutoSuggest(\'' + value.cityId + '\',\'' + value.category + '\',\'' + escape(value.label) + '\',\'' + value.locality + '\',\'' + value.id + '\');">' + highlightMatches(escape(labelvalue), escape(q)) + ', ' + value.cityName + ' <span>Region</span></li>'; 
                            } else if(value.category === 'Pois'){
                                poisUl = poisUl + '<li onclick="selectAutoSuggest(\'' + cityId + '\',\'' + value.category + '\',\'' + escape(value.label) + '\',\'' + value.locality + '\',\'' + value.id + '\');homeTopSearchGa(\' poi \',\'' + escape(value.label) + '\');">' + highlightMatches(escape(labelvalue), escape(q)) + ' <span>Nearby</span></li>';
                            } else {
                                zonesUl = zonesUl + '<li onclick="selectAutoSuggest(\'' + cityId + '\',\'' + value.category + '\',\'' + escape(value.label) + '\',\'' + value.locality + '\',\'' + value.id + '\');">' + highlightMatches(escape(labelvalue), escape(q)) + ' <span>Zone</span></li>'; 
                            }
                        });
                        if(zonesUl != '') {
                            autosuggest = autosuggest + "<ul>" + zonesUl + "</ul>";
                        }
                        if(regionsUl != '') {
                            autosuggest = autosuggest + "<ul>" + regionsUl + "</ul>";
                        }
                        if(localityUl != '') {
                            autosuggest = autosuggest + "<ul>" + localityUl + "</ul>";
                        }
                        if(projectUl != '') {
                            autosuggest = autosuggest + "<ul>" + projectUl + "</ul>";
                        }
                        if(builderUl != '') {
                            autosuggest = autosuggest + "<ul>" + builderUl + "</ul>";
                        }
                        if(poisUl != '') {
                            autosuggest = autosuggest + "<ul>" + poisUl + "</ul>";
                        }
                        $("#searchautosuggestnew").html(autosuggest);
                        $("#searchautosuggestnew").css('display','block');
                    }else{
                        $("#searchautosuggestnew").html('');
                        $("#searchautosuggestnew").css('display','none');
                    }
                }
            });
        } else {
            $("#searchautosuggestnew").html('');
            $("#searchautosuggestnew").css('display','none');
        }
    });
    /* Search Bar Improvement End */
});

// default values
var catNameMap = {
    "res": "residential",
    "com": "commercial",
    "agr": "agricultural"
};

function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

/** Post Ad Button Clicked */
PUB_SUB.on("post_ad_btn_clicked", function () {
    gaService.addEvent('PAP_RealEstate', 'QH_PAP' , 'QH_Post_ad_init');
});

/** When Category Button button is clicked */
PUB_SUB.on("searchedCat_click", function () {
    var removeall = $('#category-dropdown li:first-child').find('a').attr('data-sc_cn');
    if (removeall == "all") {
        $('#category-dropdown li:first-child').remove();
    }
    $('#category-dropdown li').click(function () {
        var subcat = $(this).find('a').attr('data-sc_cn');
        var subcattext = $(this).find('a').attr('data-sc_dn');
        if (subcat == "pg") {
            $("#searchedCat").html("PG" + '<i class="icon-arrow"></i>');
            localStorage.setItem("type", subcat);
            localStorage.setItem("category", "res");
            localStorage.setItem('searchgaType','pg');
        }
        else if (subcat == "commercial") {
            $("#searchedCat").html("Commercial" + '<i class="icon-arrow"></i>');
            localStorage.setItem("type", "buy");
            localStorage.setItem("category", "com");
            localStorage.setItem('searchgaType','commercial');
        }
        else if (subcat == "plot") {
            $("#searchedCat").html("Buy" + '<i class="icon-arrow"></i>');
            localStorage.setItem("type", "buy");
            localStorage.setItem("category", "agr");
            localStorage.setItem("searchgaType", "agriculture");
        }
        else {
            $("#searchedCat").html(capitalize(subcat) + '<i class="icon-arrow"></i>');
            localStorage.setItem("type", subcat);
            localStorage.setItem("category", "res");
            localStorage.setItem("searchgaType", subcat);
        }
    });
});

/** When select city button is clicked */
$(".city-select").on('click', function () {
    $('.citySelect').css('display', 'none');
    $("#interCDoorstep").css('display', 'none');
    $(".frequent_city .title").remove();
});

if ($(window).width() < 360) {
    $(".city-select").find('span').text("");
    $(".city-select").find('span').addClass("icon-location");
}

$('#green_dhtml_content,.frequent_city').on("click", function (event) {
    $(".cont_detail_input").val("");
    $(".frequent_city .title").remove();
    var city = event.target.getAttribute('data');
    var city_id = event.target.getAttribute('data-cid');
    if ($(window).width() < 360) {
        $(".city-select").find('span').text("");
        $(".city-select").find('span').addClass("icon-location");
    } else {
        $("#hSelectCity").find('span').text(city);
        $("#selectCityLabel").text(city);
    }
    setCookie("isCluster", false);
    localStorage.removeItem('clusterName');
    localStorage.removeItem('clusterCities');
    setCookie("new_prefer_city", city);
    setCookie("prefer_city_id", city_id);
    var entity_city=city.charAt(0).toUpperCase() + city.slice(1);
    if(city!="" && city!=null){
        $("#cityDropdown").css('display', 'none');
    }
});


/* Header v2 city select */
$(".select-city").on('click', function () {
    $(".spl-cities").css('display', 'none');
});

$("#hSelectCity").on('click', function () {
    $(".title").css('display', 'none');
});

if ($(window).width() < 360) {
    $(".city-select").find('span').text("");
    $(".city-select").find('span').addClass("icon-location");
}

PUB_SUB.on("city_click", function (event) {
    var city = event.name;
    var city_id = event.id;
    var locPathVar = location.pathname;
    setCookie("new_prefer_city", city);
    setCookie("prefer_city_id", city_id);
    setCookie("isCluster", false);
    localStorage.removeItem('clusterName');
    localStorage.removeItem('clusterCities');
    $("#hSelectCity").find('span').text(city);
    $("#selectCityLabel").text(city);
    var entity_city=city.charAt(0).toUpperCase() + city.slice(1);
    horizontalHeader("header_city");
    if(city!=null && city!=''){
        if ((window.location.href).indexOf('/coworking') !== -1) {
            if ((window.location.href).indexOf('/coworking-spaces') !== -1) {
                fetchCoworkProperty(city_id).then((result) => {
                    if(result && result.total >0) {
                        window.location = window.location.protocol + "//" + window.location.host + '/coworking/coworking-spaces-in-' + city.toLowerCase()+'-cid_' + city_id;
                    } else {
                        window.location = window.location.protocol + "//" + window.location.host + '/coworking';
                    }
                });
            } else {
                fetchCoworkProperty(city_id).then((result) => {
                    if(result && result.total >0) {
                        window.location = window.location.protocol + "//" + window.location.host + '/coworking/coworking-spaces-in-' + city.toLowerCase()+'-cid_' + city_id;
                    } else {
                        alert("Currently we are live in the following cities: Bangalore, Chennai, Hyderabad, Mumbai, Pune and Navi Mumbai. We are adding properties in more cities. Meanwhile, please fill up your requirement and we will find the space for you.");
                        var elmnt = document.getElementById("contactUs");
                        elmnt.scrollIntoView();
                        return false;
                    }
                });
            }
        } else {
            $("#cityDropdown").css('display','none');
            if ("/homes/city" == locPathVar.substr(0, locPathVar.lastIndexOf('/')) || window.location.pathname=='/homes'){
                window.location = window.location.protocol + "//" + window.location.host + '/homes/city/' + city.toLowerCase();
            } else {
                 submitSearch({
                    "resultFlag": 0,
                    "searchKey": $("#query").val(),
                    "categoryName": localStorage.getItem("category") || "res",
                    "adType": localStorage.getItem("type") || "buy",
                    "type": "freetxt"
                });
            }
        }
    }
});

function fetchCoworkProperty(cityId) {
    return fetch(`/homes/re2/checkCoworkProeprty?aj=1&cityId=${cityId}`).then((res) => res.json())
}

function setCity(){
    var cityName = getCookie('new_prefer_city');
    $('#selectCityLabel').text(cityName);
    var entity_city=cityName.charAt(0).toUpperCase() + cityName.slice(1);
    $("#hSelectCity").find('span').text(entity_city);
    $("#selectCityLabel").text(entity_city);
    $(".nav-select-city").css('display','none');
}

$('#query').on('click', function(event){
    setHeaderSearchSuggestFlags();
});

$(window).click(function(e) {
    var container = $("#query"); // Give you class or ID
    if (!container.is(e.target) && container.has(e.target).length === 0) // ... nor a descendant-child of the container
    {
        $('#showHeadersearchSuggest').hide();
    }
});


function showTopList() {
    $("#showHeadersearchSuggest").toggle();
}
/* Header v2 change end */


/** Login Click */
PUB_SUB.on('login_modal', function (event) {
    horizontalHeader("header_login");
    logInRegisterPopUp();
});

PUB_SUB.on('modal_closed', function(eleId) {
    var overlay = document.getElementsByClassName('simple-overlay')[0];
    if(overlay.style.display == 'block') {
        overlay.style.display = 'none';
    }
});

/** Mychat is clicked */
PUB_SUB.on('my_chats_clicked', function () {
    var pathname = window.location.pathname;
    var pathUrl = encodeURIComponent(pathname);
    window.location.href = "//www.quikr.com/SignIn?redirect_homes=" + pathUrl;// Redirect to login page
});

/** Sign out is clicked */
PUB_SUB.on('sign_out_click', function () {
    horizontalHeader("header_profile_logout");
    window.location.href = "//www.quikr.com/bye";
});

/** Hamburger toggle  is clicked */
PUB_SUB.on('hamburger_toggle', function () {
    $("#hamburgerId").addClass("open");
});

PUB_SUB.on('hamburger_city_click', function(){ 
  citySelectController.citySelectPopup();
});

var getDataForSuggest = function () {
    return function findMatches(q, cb, cb2) {
        var cityId = getCookie('prefer_city_id');
        if (isSet(q)) {
            var matches = [];
            $.ajax({
                url: "/homes/re2/autosuggest",
                //dataType: "json",
                method: "POST",
                data: {cityid: cityId, text: q},
                success: function (data) {
                    if (isSet(data)) {
                        cb2($.map(data, function (value, key) {
                            var labelvalue = "";
                            if (value.count >= 1 && value.category != 'Project') {
                                labelvalue = value.label + "  (" + value.count + " Projects)";
                            }
                            else {
                                labelvalue = value.label;
                            }
                            return {
                                label: labelvalue,
                                value: value.label,
                                key: value.id,
                                location: value.locality,
                                linkType: value.category
                            }
                        }));
                    }
                }
            });
        }
    };
};

//PUB_SUB.emit('auto_suggest_keyword', e.target.value.trim());
PUB_SUB.on("auto_suggest_keyword", function () {
    $('#showHeadersearchSuggest').hide();
    if (!isSet(getCookie("prefer_city_id"))) {
        setCookie("prefer_city_id", 23);
    }
    var queryval = $("#query").val();
    if (queryval.length >= 2) {
        if ($("#vap_5").html()) {
            $("ul .ui-autocomplete").attr('style', "top: 228px !important");
        } else {
            $("ul .ui-autocomplete").attr('style', "top: 93px !important");
        }
    }
});


/** Search is submitted */
PUB_SUB.on('submit_search', function () {
    var search_term = {
        "resultFlag": 0,
        "searchKey": $("#query").val(),
        "categoryName": localStorage.getItem("category") || "",
        "adType": localStorage.getItem("type") || "",
        "type": "freetxt"
    };
    submitSearch(search_term);
    searchGa('searchBox');
});
function headerDropDown(){
    let categoryName = localStorage.getItem("category") || "res"
    let subcat = localStorage.getItem("type") || "buy"
    if (subcat == "pg") {
            $("#searchedCat").html("PG" + '<i class="icon-arrow"></i>');
        }
        else if (categoryName == 'com' && subcat == "buy") {
            $("#searchedCat").html("Commercial" + '<i class="icon-arrow"></i>');
        }
        else {
            $("#searchedCat").html(capitalize(subcat) + '<i class="icon-arrow"></i>');
        }
}
headerDropDown();
window.submitSearch = function (search_term) {
    var homeCategorySelected = localStorage.getItem("category");
    var adTypeKey = search_term.adType || localStorage.getItem("type");
    var city_name = getCookie("new_prefer_city"),
        cityId = getCookie("prefer_city_id"),
        catNameMap = {
            "res": "residential",
            "com": "commercial",
            "agr": "agricultural"
        };

    searchActionService.setAction('Search');
    //var term = null != $scope.searchform.locality && "object" == typeof $scope.searchform.locality ? $scope.searchform.locality : {"resultFlag" : 0, "searchKey": document.getElementsByName('searchHomes')[0].value, "type":"freeTXT"};
    //if ("freeTXT" == term.type) {
    gaService.addEvent('Real-Estate_Header_Responsive', 'Snb_Search_Real-Estate', 'search_freetext_'+ getCookie('new_prefer_city'));


    if (!city_name) {
        if (search_term != null && search_term != 'undefined' && search_term.hasOwnProperty('preSearchSuggest') && search_term.preSearchSuggest) {
            city_name = city_name || 'mumbai';
            cityId = cityId || 31;
        } else {
            var noCitySelectSearch = true;
            return true;
        }
    }

    if (search_term != null && search_term != 'undefined' && search_term.hasOwnProperty('type')) {
        var homeUrl = '/homes/';
        var redirectFlag = true;

        if(!search_term.adType) {
            search_term.adType = adTypeKey;
        }
        
        if(!search_term.categoryName) {
            if(typeof homeCategorySelected != 'undefined') {
                search_term.categoryName = homeCategorySelected;
            } else {
                search_term.categoryName = "residential";
            }
        }

        switch (search_term.type.toLowerCase()) {
            case 'localities' :
                homeUrl += 'property/';
                homeUrl += catNameMap[search_term.categoryName ? search_term.categoryName : 'res'];
                if ('pg' == search_term.adType) {
                    homeUrl += '-for-pg_flatmates';
                } else {
                    homeUrl += '-for';
                }
                if (search_term.adType) {
                    switch (search_term.adType) {
                        case 'buy' :
                            homeUrl += '-sale';
                            break;

                        case 'pg' :
                            homeUrl += '-rent';
                            break;

                        default:
                            homeUrl += '-' + search_term.adType;

                    }
                } else {
                    homeUrl += '-sale';
                }

                homeUrl += '-in-';

                homeUrl += search_term.name ? (search_term.name.split(',')[0].trim().replace(/ /g, "_") + '-') : '';
                homeUrl += city_name.replace(/ /g, "_") + '-';

                homeUrl += 'cid_' + cityId;
                homeUrl += search_term.id ? ('-lid_' + search_term.id) : '';

                homeUrl = homeUrl.toLowerCase();
                homeUrl = homeUrl.replace(/[ ]/g, "-");

                break;
            case 'freetxt':
                if (search_term.searchKey != '') {
                    var category_name = catNameMap[search_term.categoryName ? search_term.categoryName : 'res'];
                    var intent_type = intentType(search_term.adType);
                    $.ajax({
                        url: "/homes/re2/attributeDetect",
                        dataType: "json",
                        method: "POST",
                        async: false,
                        data: {keywords: search_term.searchKey, categoryPgid: 20,category:category_name,intent:intent_type},
                        success: function (data) {
                            if (typeof data=='object' &&  data.statusCode == 200 && data.data != null) {
                                homeUrl = data.data.finalAbsoluteUrl;
                                homeUrl += '?text=' + convert(data.data.keyword).replace(/ /g, "-");
                            }
                            else {
                                homeUrl += 'property/';
                                homeUrl += catNameMap[homeCategorySelected?homeCategorySelected:'res'];
                                if ('pg' == adTypeKey) {
                                    homeUrl += '-for-pg_flatmates';
                                } else {
                                    homeUrl += '-for';
                                }
                                if (adTypeKey) {
                                    switch (adTypeKey) {
                                        case 'buy' :
                                            homeUrl += '-sale';
                                            break;

                                        case 'pg' :
                                            homeUrl += 'rent';
                                            break;

                                        default:
                                            homeUrl += '-' + adTypeKey;

                                    }
                                } else {
                                    homeUrl += '-sale';
                                }

                                homeUrl += '-in-';

                                homeUrl += search_term.name ? (search_term.name.split(',')[0].trim().replace(/ /g, "_") + '-') : '';
                                homeUrl += city_name.replace(/ /g, "_") + '-';

                                homeUrl += 'cid_' + cityId;
                                homeUrl += search_term.id ? ('-lid_' + search_term.id) : '';

                                homeUrl = homeUrl.toLowerCase();
                                homeUrl = homeUrl.replace(/[ ]/g, "-");
                                var searchKeyval = search_term.searchKey;
                                if(searchKeyval.indexOf("@") !== -1) {
                                    searchKeyval = searchKeyval.substring(0, searchKeyval.lastIndexOf("@"));
                                }
                                homeUrl += '?text=' + convert(searchKeyval).replace(/ /g, "-");
                            }
                        }
                    });

                }
                else {
                    homeUrl += 'property/';
                    homeUrl += catNameMap[homeCategorySelected ? homeCategorySelected : 'res'];
                    if ('pg' == adTypeKey) {
                        homeUrl += '-for-pg_flatmates';
                    } else if('projects' == adTypeKey){
                        homeUrl += '-projects-for';
                    }else {
                        homeUrl += '-for';
                    }
                    if (adTypeKey) {
                        switch (adTypeKey) {
                            case 'buy' :
                                homeUrl += '-sale';
                                break;
                            case 'projects' :
                                homeUrl += '-sale';
                                break;

                            case 'pg' :
                                homeUrl += '';
                                break;

                            default:
                                homeUrl += '-' + adTypeKey;

                        }
                    } else {
                        homeUrl += '-sale';
                    }

                    homeUrl += '-in-';

                    homeUrl += search_term.name ? (search_term.name.split(',')[0].trim().replace(/ /g, "_") + '-') : '';
                    homeUrl += city_name.replace(/ /g, "_") + '-';

                    homeUrl += 'cid_' + cityId;
                    homeUrl += search_term.id ? ('-lid_' + search_term.id) : '';

                    homeUrl = homeUrl.toLowerCase();
                    homeUrl = homeUrl.replace(/[ ]/g, "-");
                    var searchKeyval = search_term.searchKey;
                    if(searchKeyval.indexOf("@") !== -1) {
                        searchKeyval = searchKeyval.substring(0, searchKeyval.lastIndexOf("@"));
                    }
                    homeUrl += '?text=' + convert(search_term.searchKey).replace(/ /g, "-");
                }
                break;

            case 'projects' :
                if(!isSet(search_term.id)){
                    redirectFlag = false;
                    break;
                }
                homeUrl += 'project/' + seoUrl(search_term.name) + '+' + seoUrl(isSet(search_term.locality) ? search_term.locality : '') + '+' + seoUrl(isSet(city_name) ? city_name : '') + '+' + search_term.id;
                break;

            case 'builders' :
                homeUrl += 'builder/' + seoUrl(search_term.name) + '+' + search_term.id;
                break;

            default:

        }
        storeSearchAndRedirect(globals.pageType,search_term.searchKey,search_term.type.toLowerCase(),homeUrl,redirectFlag)
    }
    //}
}

/** Close Hamburger */
function closeHambuger() {
    $("#hamburgerId").removeClass("open");
    $(".simple-overlay").css('display','none');
}

/** Hamburger Login Register Section click */
function redirectUrl() {
    var pathname = window.location.pathname;
    var pathUrl = encodeURIComponent(pathname);
    window.location.href = "//www.quikr.com/SignIn?redirect_homes=" + pathUrl;
}

window.selectAutoSuggest = function (cityId, linkType, value, suglocation, key) {
    value = unescape(value);
    var cityName = getCookie('new_prefer_city');
    var redirectionURL = '';
    var page = globals.pageType;
    var searchText = page === 'Home' ? document.getElementById('searchHomes').value : document.getElementById('query').value
    var searchTypes = ['Project', 'Builder', 'LocalityProjects', 'Location', 'Regions', 'Zones', 'Pois'];
    var search_term = {
        "resultFlag": 0,
        "searchKey": searchText,
        "categoryName": localStorage.getItem("category") || "",
        "adType": localStorage.getItem("type") || "buy",
        "type": "freetxt"
    };
    if (!searchTypes.includes(linkType)) {
        submitSearch(search_term);
    } else {
        if (linkType === "Project") {
            var project = (value).replace(/\s+/g, '-').toLowerCase();
            var location = (suglocation).replace(/\s+/g, '-').toLowerCase();
            cityName = cityName.replace(/\s+/g, '-').toLowerCase();
            redirectionURL = "/homes/project/" + project + "+" + location + "+" + cityName + "+" + key
        }
        else if (linkType === "Builder") {
            var builder = (value).replace(/\s+/g, '-').toLowerCase();
            redirectionURL = "/homes/builder/" + builder + "+" + key;
        } else if (linkType === "LocalityProjects") {
            var location = (value).replace(/\s+/g, '-').toLowerCase();
            cityName = cityName.replace(/\s+/g, '-').toLowerCase();
            var homeUrl = 'property/';
            homeUrl += catNameMap[search_term.categoryName ? search_term.categoryName : 'res'];
            homeUrl += '-projects';
            homeUrl += '-for-sale';
            homeUrl += '-in-';

            homeUrl += location + "-";
            homeUrl += cityName + "-";

            homeUrl += 'cid_' + cityId;
            homeUrl += '-lid_' + key;

            homeUrl = homeUrl.toLowerCase();
            homeUrl = homeUrl.replace(/[ ]/g, "-");
            var locSnburl = "/homes/" + homeUrl;
            redirectionURL = locSnburl;
        } else if (linkType === "Location" || linkType === "Regions" || linkType === "Zones" || linkType=="Pois") {
            var loc_reg_zone_poi = (value).replace(/\s+/g, '_').toLowerCase();
            cityName = cityName.replace(/\s+/g, '-').toLowerCase();

            var homeUrl = 'property/';
            homeUrl += catNameMap[search_term.categoryName ? search_term.categoryName : 'res'];
            if ('pg' == search_term.adType) {
                homeUrl += '-for-pg_flatmates';
            } else if ('projects' == search_term.adType) {
                homeUrl += '-projects-for';
            } else {
                homeUrl += '-for';
            }
            if (search_term.adType) {
                switch (search_term.adType) {
                    case 'buy':
                        homeUrl += '-sale';
                        break;
                    case 'projects':
                        homeUrl += '-sale';
                        break;
                    case 'pg':
                        homeUrl += '';
                        break;

                    default:
                        homeUrl += '-' + search_term.adType;

                }
            } else {
                homeUrl += '-sale';
            }

            homeUrl += '-in-';
            if (linkType!="Pois") {
                homeUrl += loc_reg_zone_poi + "-";
            } 
            homeUrl += cityName + "-";
            if (linkType=="Pois") {
                homeUrl += "near-"+loc_reg_zone_poi + "-";
            }

            homeUrl += 'cid_' + cityId;

            if (linkType === "Location") {
                homeUrl += '-lid_';
            } else if (linkType === "Regions") {
                homeUrl += '-rid_';
            } else if (linkType === "Pois") {
                homeUrl += '-pid_';
            } else {
                homeUrl += '-zid_';
            }

            homeUrl += key;

            homeUrl = homeUrl.toLowerCase();
            homeUrl = homeUrl.replace(/[ ]/g, "-");
            var loc_reg_zone_Snburl = "/homes/" + homeUrl;
            redirectionURL = loc_reg_zone_Snburl;

        }
        storeSearchAndRedirect(page, value, linkType, redirectionURL)
    }

}

window.topnavGA = function(type){
    var page_type = globals.pageType.toLowerCase();

    switch(page_type){
        case 'snb':
            gaService.addEvent('RealEstate', 'QH_'+page_type+'_'+ snbFilterController.filter.data.type , 'QH_'+page_type+'_'+snbFilterController.filter.data.type+'_header2_'+type+'_click');
            break;
        case 'vap':
            var propertySnippet = JSON.parse(atob(globals.propertySnippet));
            var prop_type = propertyType(propertySnippet.propertyFor);
            gaService.addEvent('RealEstate', 'QH_' + page_type+'_'+prop_type , 'QH_'+page_type+'_'+prop_type+'_header2_'+type+'_click');
            break;
        case 'project':
        case 'builder':
        case 'locality':
        case 'individual':
            gaService.addEvent('RealEstate', 'QH_' + page_type, 'QH_'+page_type+'_header2_'+type+'_click');
            break;
    }
}

window.topnavGAHL = function(type){
    switch(type){
        case 'LoanCalculator':
            gaService.addEvent('QH_loanCalculator', 'QH_loanCalculator_logo', 'QH_loanCalculator_logo_click');
            break;
        case 'LoanEligibility':
            gaService.addEvent('QH_loanCalculator', 'QH_loanCalculator_loanEligibility', 'QH_loanCalculator_loanEligibility_click');
            break;
        case 'EMI':
            gaService.addEvent('QH_loanCalculator', 'QH_loanCalculator_EMI', 'QH_loanCalculator_EMI_click');
            break;
        case 'Prepayment':
            gaService.addEvent('QH_loanCalculator', 'QH_loanCalculator_prepayment', 'QH_loanCalculator_prepayment_click');
            break;
        case 'RateChange':
            gaService.addEvent('QH_loanCalculator', 'QH_loanCalculator_rateChange', 'QH_loanCalculator_rateChange_click');
            break;
        case 'LoanTransfer':
            gaService.addEvent('QH_loanCalculator', 'QH_loanCalculator_loanTransfer', 'QH_loanCalculator_loanTransfer_click');
            break;
        case 'MonthlyLoanRepayment':
            gaService.addEvent('QH_loanCalculator', 'QH_loanCalculator_monthlyLoanRepayment', 'QH_loanCalculator_monthlyLoanRepayment_click');
            break;
        case 'CheckEligibility':
            gaService.addEvent('QH_loanCalculator', 'QH_loanCalculator_checkEligibility', 'QH_loanCalculator_checkEligibility_click');
            break;
        case 'ApplyForLoan':
            gaService.addEvent('QH_loanCalculator', 'QH_loanCalculator_applyForLoan', 'QH_loanCalculator_applyForLoan_click');
            break;
    }
}

window.adviceDropdown = function(isMobile) {
    if(isMobile == true) {
        document.querySelector("#adviceDropdown").classList.toggle("hide");
    }
}

window.coworkRedirection = function() {
    let cityId = isSet(getCookie("prefer_city_id")) ? getCookie("prefer_city_id") : '';
    if(isSet(cityId)) {
        fetchCoworkProperty(cityId).then((result) => {
            if(result && result.length >0) {
                window.location = window.location.protocol + "//" + window.location.host + '/coworking/coworking-spaces-in-' + getCookie("new_prefer_city").toLowerCase()+'-cid_' + cityId;
            } else {
                window.location = window.location.protocol + "//" + window.location.host + '/coworking';
            }
        });
    } else {
        window.location = window.location.protocol + "//" + window.location.host + '/coworking';
    }
}

window.horizontalHeader = function(type){
     var page_type = globals.pageType.toLowerCase();
      gaService.addEvent('RealEstate', 'QH_'+page_type , 'QH_'+page_type+'_'+type+'_click');
}
window.getEventTrackGA= function(obj) {
    if (typeof obj != "undefined") {
        if (typeof obj == "object") {
            var category = obj.category;
            var action = obj.action;
            var label = obj.label;
            if (category != '' && action != '' && label != '') {
                gaService.addEvent(category, action, label);
            }
        }
    }
}
window.scrollTopGa = function(){
    var page_type =(globals.pageType) ? globals.pageType.toLowerCase() : '';
    gaService.addEvent('RealEstate', 'QH_' + page_type, 'QH_'+page_type+'_scrollToTop_click');
}
window.trendingGa = (type,name)=>{
    var page_type = globals.pageType.toLowerCase();
    gaService.addEvent('RealEstate', 'QH_'+page_type , 'QH_'+page_type+'_'+type+'_'+name+'_click');
}

window.similartype = function(similartype,type){
    var page_type = globals.pageType.toLowerCase();

    switch(page_type){
        case 'vap':
            var propertySnippet = JSON.parse(atob(globals.propertySnippet));
            var prop_type = propertyType(propertySnippet.propertyFor);
            gaService.addEvent('RealEstate', 'QH_' + page_type+'_'+prop_type , 'QH_'+page_type+'_'+prop_type+'_'+similartype+'_'+type+'_click');
            break;
        case 'project':
            gaService.addEvent('RealEstate', 'QH_' + page_type, 'QH_'+page_type+'_'+similartype+'_'+type+'_click');
            break;
    }
}