export const searchActionService = function () {

    var defaultSearchAction = 'search';

    var searchActionService = {};

    searchActionService.getAction = function () {
        var action = window.sessionStorage && window.sessionStorage.getItem('search-action') !== null
            ? window.sessionStorage.getItem('search-action')
            : defaultSearchAction;
        return action;
    };

    searchActionService.setAction = function (action) {
        if (window.sessionStorage) {
            window.sessionStorage.setItem('search-action', action);
        }
    };

    searchActionService.setCatIntent = function (data) {
        if (window.localStorage) {
            if(data.cat == 'commercial' && data.type == 'buy'){
                localStorage.setItem("type", "buy");
                localStorage.setItem("category", "com");
            }else if(data.cat == 'residential' &&  data.type !='brokers'){
                localStorage.setItem("type", data.type);
                localStorage.setItem("category", "res");
            }else if(data.cat == 'commercial' && data.type =='rent'){
                localStorage.setItem("type", "rent");
                localStorage.setItem("category", "res");
            }else{
                localStorage.setItem("type", 'buy');
                localStorage.setItem("category", "res");
            }
        }
    };

    return searchActionService;

}();
